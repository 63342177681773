// /Users/aliasaad/Desktop/dstt_react_flask/Frontend/src/components/ReceiptMatcher/styles.jsx

import { Box, Button, Stepper, Alert, TableRow, styled } from '@mui/material';

export const AnimatedContainer = styled(Box)(({ theme }) => ({
'& *': {
  transition: 'all 0.2s ease-in-out',
},
padding: theme.spacing(3),
animation: 'fadeIn 0.5s ease-out',
'@keyframes fadeIn': {
  from: { opacity: 0 },
  to: { opacity: 1 },
}
}));

export const AnimatedStepper = styled(Stepper)(({ theme }) => ({
marginBottom: theme.spacing(4),
'& .MuiStepIcon-root': {
  transition: 'all 0.3s ease-in-out',
  '&.MuiStepIcon-active': {
    transform: 'scale(1.2)',
    filter: 'drop-shadow(0 0 3px rgba(0,0,0,0.2))',
  },
  '&.MuiStepIcon-completed': {
    transform: 'scale(1.1)',
  }
},
'& .MuiStepConnector-line': {
  transition: 'all 0.4s ease-in-out',
}
}));

export const AnimatedButton = styled(Button)(({ theme }) => ({
transition: 'all 0.3s ease-in-out',
'&:hover': {
  transform: 'translateY(-2px)',
  boxShadow: theme.shadows[4],
},
'&:active': {
  transform: 'translateY(0)',
},
'&.Mui-disabled': {
  backgroundColor: theme.palette.action.disabledBackground,
  opacity: 0.7,
}
}));

export const AnimatedAlert = styled(Alert)(({ theme }) => ({
marginTop: theme.spacing(2),
marginBottom: theme.spacing(2),
animation: 'slideIn 0.5s ease-out',
boxShadow: theme.shadows[2],
'& .MuiAlert-message': {
  display: 'flex',
  alignItems: 'center',
},
'@keyframes slideIn': {
  from: {
    opacity: 0,
    transform: 'translateY(-20px)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
}
}));

export const AnimatedSection = styled(Box)(({ animation = 'slideUp' }) => ({
animation: `${animation} 0.5s ease-out`,
marginBottom: '24px',
'@keyframes slideUp': {
  from: {
    opacity: 0,
    transform: 'translateY(20px)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
},
'@keyframes slideDown': {
  from: {
    opacity: 0,
    transform: 'translateY(-20px)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
}
}));

export const AnimatedTableRow = styled(TableRow)(({ theme }) => ({
transition: 'all 0.2s ease-in-out',
'&:hover': {
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  transform: 'scale(1.002)',
},
'&.locked': {
  backgroundColor: theme.palette.action.disabledBackground,
  pointerEvents: 'none',
  opacity: 0.8,
}
}));

export const StyledSessionControls = styled(Box)(({ theme }) => ({
backgroundColor: theme.palette.background.paper,
borderRadius: theme.shape.borderRadius,
padding: theme.spacing(2),
marginBottom: theme.spacing(3),
boxShadow: theme.shadows[1],
animation: 'fadeIn 0.5s ease-out',
'& .session-header': {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
},
'& .session-actions': {
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
},
'& .session-info': {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
},
'& .locked-badge': {
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.warning.contrastText,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.75rem',
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}
}));

export const LoadingOverlay = styled(Box)(({ theme }) => ({
position: 'absolute',
top: 0,
left: 0,
right: 0,
bottom: 0,
backgroundColor: 'rgba(255, 255, 255, 0.7)',
display: 'flex',
justifyContent: 'center',
alignItems: 'center',
zIndex: 1000,
animation: 'fadeIn 0.3s ease-out',
}));

export const ReviewerNotesSection = styled(Box)(({ theme }) => ({
marginTop: theme.spacing(2),
padding: theme.spacing(2),
backgroundColor: theme.palette.background.default,
borderRadius: theme.shape.borderRadius,
border: `1px solid ${theme.palette.divider}`,
'& .MuiTextField-root': {
  width: '100%',
}
}));

export const SessionStatusBadge = styled(Box)(({ theme, status }) => {
const getStatusColor = () => {
  switch (status) {
    case 'approved':
      return theme.palette.success;
    case 'rejected':
      return theme.palette.error;
    case 'pending':
      return theme.palette.warning;
    default:
      return theme.palette.info;
  }
};

return {
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: getStatusColor().light,
  color: getStatusColor().contrastText,
  fontSize: '0.75rem',
  fontWeight: 500,
  gap: theme.spacing(0.5),
};
});