import { createContext, useContext, useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

// Add this for debugging (you can remove it later)
console.log('Auth API URL:', API_URL);

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateAndSetUser = async () => {
      const storedUser = localStorage.getItem('user');
      const storedToken = localStorage.getItem('token');
    
      if (storedUser && storedToken) {
        try {
          // Validate token with backend
          const response = await fetch(`${API_URL}/api/verify-auth/`, {
            headers: {
              'Authorization': `Token ${storedToken}`,
            },
          });

          if (response.ok) {
            const userData = JSON.parse(storedUser);
            setUser(userData);
            console.log('Auth Provider initialized with:', {
              user: userData,
              hasToken: !!storedToken
            });
          } else {
            console.log('Token validation failed, clearing auth state');
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            setUser(null);
          }
        } catch (error) {
          console.error('Error validating token:', error);
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          setUser(null);
        }
      }
      setLoading(false);
    };

    validateAndSetUser();
  }, []);

  const login = (userData) => {
    console.log('Setting user in auth context:', userData);
    setUser(userData);
  };

  const logout = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await fetch(`${API_URL}/api/logout/`, {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          console.error('Logout failed:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('userRole');
    }
  };

  const checkAuth = () => {
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    return !!(token && storedUser && user);
  };

  const getToken = () => {
    const token = localStorage.getItem('token');
    return token ? `Token ${token}` : null;
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user,
        login, 
        logout, 
        checkAuth, 
        getToken,
        isAuthenticated: checkAuth(),
        loading,
        currentUser: user?.user || user,
        role: user?.role || user?.user?.role
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};