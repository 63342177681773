import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { kontos } from './kontos';
import {
  Box, Card, CardContent, Typography, TextField, Button, Grid, 
  Paper, Divider, Alert, IconButton, Dialog, DialogTitle, 
  DialogContent, DialogActions, InputAdornment, Chip
} from '@mui/material';
import * as Lucide from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';

const ClientDetails = () => {
  const { clientId } = useParams();
  const { getToken } = useAuth();
  const [client, setClient] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedKontos, setSelectedKontos] = useState([]);
  const [searchDialog, setSearchDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredKontos, setFilteredKontos] = useState([]);
  const [formData, setFormData] = useState({
    taxConsultantNumber: '',
    clientNumber: '',
    vatNumber: '',
    fiscalYearStart: '',
    fiscalYearEnd: '',
    branche: ''
  });

  useEffect(() => {
    fetchClientDetails();
    setFilteredKontos(kontos);
  }, [clientId]);

  const fetchClientDetails = async () => {
    try {
      console.log('Fetching client details for ID:', clientId);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tax_consultant/clients/${clientId}/`, {
        headers: { 'Authorization': getToken() },
      });
      console.log('Client details response:', response);
      if (!response.ok) throw new Error('Failed to fetch client details');
      const data = await response.json();
      console.log('Client details data:', data);
      console.log('Kontos from response:', data.kontos);
      setClient(data);
      setSelectedKontos(data.kontos || []);
      console.log('Selected kontos after set:', data.kontos || []);
      setFormData({
        taxConsultantNumber: data.tax_consultant_number || '',
        clientNumber: data.client_number || '',
        vatNumber: data.vat_number || '',
        fiscalYearStart: data.fiscal_year_start || '',
        fiscalYearEnd: data.fiscal_year_end || '',
        branche: data.branche || ''
      });
    } catch (error) {
      console.error('Error in fetchClientDetails:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Updating client with data:', {
        tax_consultant_number: formData.taxConsultantNumber,
        client_number: formData.clientNumber,
        vat_number: formData.vatNumber,
        fiscal_year_start: formData.fiscalYearStart,
        fiscal_year_end: formData.fiscalYearEnd,
        branche: formData.branche,
        kontos: selectedKontos
      });
      console.log('FormData:', formData);
      console.log('SelectedKontos:', selectedKontos);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tax_consultant/clients/${clientId}/update/`, {
        method: 'PUT',
        headers: {
          'Authorization': getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tax_consultant_number: formData.taxConsultantNumber,
          client_number: formData.clientNumber,
          vat_number: formData.vatNumber,
          fiscal_year_start: formData.fiscalYearStart,
          fiscal_year_end: formData.fiscalYearEnd,
          branche: formData.branche,
          kontos: selectedKontos
        }),
      });

      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error || 'Failed to update client details');
      
      setClient(prevClient => ({
        ...prevClient,
        ...responseData
      }));
      setSuccess('Kundendaten erfolgreich aktualisiert');
      setIsEditing(false);
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      setError(error.message);
      setTimeout(() => setError(''), 3000);
    }
  };

const handleSearch = (value) => {
  setSearchTerm(value);
  const searchValue = value.toLowerCase();
  const filtered = !searchValue.trim() 
    ? kontos 
    : kontos.filter(konto => 
        konto.konto.toString().includes(searchValue) ||
        konto.beschriftung.toLowerCase().includes(searchValue)
      );
  setFilteredKontos(filtered);
 };
 
 const handleAddKonto = (konto) => {
  const newKonto = {
    id: Date.now(),
    number: konto.konto,
    description: konto.beschriftung
  };
  setSelectedKontos(prevKontos => {
    const exists = prevKontos.some(k => k.number === konto.konto);
    return exists ? prevKontos : [...prevKontos, newKonto];
  });
 };
 
 const handleRemoveKonto = (kontoId) => {
  setSelectedKontos(selectedKontos.filter(k => k.id !== kontoId));
 };
 
 const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData(prev => ({
    ...prev,
    [name]: value
  }));
 };
 
 const renderDetailRow = (label, value, name) => (
  <Grid container spacing={2} sx={{ mb: 2 }}>
    <Grid item xs={4}>
      <Typography variant="subtitle2" color="text.secondary">{label}</Typography>
    </Grid>
    <Grid item xs={8}>
      {isEditing ? (
        <TextField
          fullWidth
          name={name}
          value={formData[name]}
          onChange={handleChange}
          variant="standard"
        />
      ) : (
        <Typography variant="body1">{value || 'Nicht angegeben'}</Typography>
      )}
    </Grid>
  </Grid>
 );
 
 if (loading) return <Typography>Laden...</Typography>;
 if (!client) return <Typography color="error">Kunde nicht gefunden</Typography>;
 
 return (
  <Box sx={{ p: 4 }}>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Paper elevation={0} sx={{ p: 3, mb: 4, bgcolor: 'background.default', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h4" gutterBottom sx={{
            background: 'linear-gradient(45deg, #2E3B55 30%, #4F5B75 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}>
            {client.company_name}
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            {client.client_name}
          </Typography>
        </Box>
        <IconButton 
          onClick={() => setIsEditing(!isEditing)}
          color="primary"
          sx={{
            background: isEditing ? 'rgba(0,0,0,0.1)' : 'transparent',
            '&:hover': { background: 'rgba(0,0,0,0.05)' }
          }}
        >
          {isEditing ? <Lucide.X /> : <Lucide.Edit2 />}
        </IconButton>
      </Paper>
 
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
 
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Stammdaten
          </Typography>
          <Divider sx={{ mb: 3 }} />
          
          {renderDetailRow('Steuerberaternummer', client.tax_consultant_number, 'taxConsultantNumber')}
          {renderDetailRow('Kundennummer', client.client_number, 'clientNumber')}
          {renderDetailRow('UID Nummer', client.vat_number, 'vatNumber')}
          {renderDetailRow('Geschäftsjahr Start', client.fiscal_year_start, 'fiscalYearStart')}
          {renderDetailRow('Geschäftsjahr Ende', client.fiscal_year_end, 'fiscalYearEnd')}
          {renderDetailRow('Branche', client.branche, 'branche')}
 
          <Box sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="subtitle1">
                Kontos
              </Typography>
              {isEditing && (
                <Button
                  startIcon={<Lucide.Plus size={20} />}
                  onClick={() => setSearchDialog(true)}
                  variant="outlined"
                  size="small"
                >
                  Konten hinzufügen
                </Button>
              )}
            </Box>
            
            <Grid container spacing={1}>
              {selectedKontos.map((konto) => (
                <Grid item key={konto.id}>
                  <Chip
                    label={`${konto.number} - ${konto.description}`}
                    onDelete={isEditing ? () => handleRemoveKonto(konto.id) : undefined}
                    sx={{
                      bgcolor: 'background.default',
                      '& .MuiChip-deleteIcon': {
                        color: 'error.main',
                        '&:hover': { color: 'error.dark' }
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            
            <Dialog 
              open={searchDialog} 
              onClose={() => {
                setSearchDialog(false);
                setSearchTerm('');
                setFilteredKontos(kontos);
              }}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>
                Kontenplan Suche
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Suche nach Kontonummer oder Beschriftung..."
                  variant="outlined"
                  sx={{ mb: 2, mt: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lucide.Search size={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
                  {filteredKontos.map((konto) => (
                    <Paper
                      key={konto.konto}
                      sx={{
                        p: 2,
                        mb: 1,
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                      onClick={() => {
                        handleAddKonto(konto);
                        setSearchDialog(false);
                        setSearchTerm('');
                        setFilteredKontos(kontos);
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1" component="span" sx={{ mr: 2 }}>
                          {konto.konto}
                        </Typography>
                        <Typography variant="body1" component="span">
                          {konto.beschriftung}
                        </Typography>
                      </Box>
                      <IconButton size="small">
                        <Lucide.Plus size={20} />
                      </IconButton>
                    </Paper>
                  ))}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  setSearchDialog(false);
                  setSearchTerm('');
                  setFilteredKontos(kontos);
                }}>
                  Schließen
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
 
          {isEditing && (
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  background: 'linear-gradient(45deg, #2E3B55 30%, #4F5B75 90%)',
                  color: 'white',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #4F5B75 30%, #2E3B55 90%)',
                  }
                }}
              >
                Speichern
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </motion.div>
  </Box>
 );
 };
 
 export default ClientDetails;