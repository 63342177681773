import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  IconButton,
  Tooltip,
  CircularProgress,
  Divider,
  Stack,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
} from '@mui/material';
import * as Lucide from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';
import SubscriptionManagement from './SubscriptionManagement';

const API_URL = process.env.REACT_APP_API_URL;

// Add this for debugging (you can remove it later)
console.log('Dashboard API URL:', API_URL);

const Dashboard = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const { getToken, currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Add authentication check effect
  useEffect(() => {
    const token = getToken();
    console.log('Current auth state:', {
      hasToken: !!token,
      currentUser,
      rawToken: localStorage.getItem('token')
    });

    if (!token) {
      navigate('/login');
      return;
    }
  }, [getToken, navigate, currentUser]);
  
  // Form state
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    company_name: '',
    tax_number: '',
    phone_number: '',
    client_number: '',
    vat_number: '',
    fiscal_year_start: '',
    fiscal_year_end: '',
    branche: ''
  });
  const [showPassword, setShowPassword] = useState(true);
  const [forcePasswordChange, setForcePasswordChange] = useState(true);
  const [generatedPassword, setGeneratedPassword] = useState('');

  // Generate a secure password
  const generateSecurePassword = () => {
    const length = 12;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
    let password = '';
    
    // Ensure at least one of each required character type
    password += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[Math.floor(Math.random() * 26)]; // Uppercase
    password += 'abcdefghijklmnopqrstuvwxyz'[Math.floor(Math.random() * 26)]; // Lowercase
    password += '0123456789'[Math.floor(Math.random() * 10)]; // Number
    password += '!@#$%^&*'[Math.floor(Math.random() * 8)]; // Special char

    // Fill the rest randomly
    for (let i = password.length; i < length; i++) {
      password += charset[Math.floor(Math.random() * charset.length)];
    }

    // Shuffle the password
    password = password.split('').sort(() => Math.random() - 0.5).join('');
    
    setFormData(prev => ({
      ...prev,
      password: password
    }));
    setGeneratedPassword(password);
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  // Fetch clients on component mount
  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      setLoading(true);
      const token = getToken();
      console.log('Fetching clients with auth:', {
        token,
        user: JSON.parse(localStorage.getItem('user'))
      });
      if (!token) {
        throw new Error('No authentication token available');
      }
  
      const response = await fetch(`${API_URL}/tax_consultant/my_clients/`, {  

        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
      console.log('Clients response status:', response.status);

      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);

        throw new Error(errorData.detail || `Failed to fetch clients: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Fetched clients data:', data);

      setClients(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError(error.message);
      setClients([]);
    } finally {
      setLoading(false);
    }
  };

  const getActivityStatus = (lastActivity) => {
    if (!lastActivity) return { color: 'default', label: 'Keine Aktivität' };
    
    const activityDate = new Date(lastActivity);
    const now = new Date();
    const diffDays = Math.floor((now - activityDate) / (1000 * 60 * 60 * 24));
    
    if (diffDays < 7) return { color: 'success', label: 'Kürzlich aktiv' };
    if (diffDays < 30) return { color: 'primary', label: 'Aktiv' };
    return { color: 'default', label: 'Inaktiv' };
  };

  const handleClientClick = (clientId) => {
    navigate(`/app/workflow/${clientId}`);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setError('');
    setSuccess('');
    setFormData({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      company_name: '',
      tax_number: '',
      phone_number: '',
      client_number: '',
      vat_number: '',
      fiscal_year_start: '',
      fiscal_year_end: '',
      branche: ''
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // Generate username from email or name
      const generateUsername = () => {
        if (formData.email) {
          // Use email prefix as username
          return formData.email.split('@')[0];
        } else if (formData.first_name && formData.last_name) {
          // Combine first name and last name
          return `${formData.first_name.toLowerCase()}.${formData.last_name.toLowerCase()}`;
        }
        throw new Error('Email oder Name muss angegeben werden');
      };

      // Validate required fields
      if (!formData.email) {
        throw new Error('Email ist erforderlich');
      }
      if (!formData.password) {
        throw new Error('Passwort ist erforderlich');
      }

      const username = generateUsername();
      
      const response = await fetch(`${API_URL}/api/register-client/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify({
          username,
          email: formData.email,
          password: formData.password,
          company_name: formData.company_name,
          client_name: `${formData.first_name} ${formData.last_name}`.trim(),
          tax_consultant_number: formData.tax_consultant_number,
          client_number: formData.client_number,
          vat_number: formData.vat_number,
          fiscal_year_start: formData.fiscal_year_start,
          fiscal_year_end: formData.fiscal_year_end,
          branche: formData.branche,
          role: 'client',
          force_password_change: forcePasswordChange
        })
      });

      // First get the response as text
      const textResponse = await response.text();
      console.log('Raw response:', textResponse);

      // Try to parse the response if it's not empty
      let data = {};
      if (textResponse) {
        try {
          data = JSON.parse(textResponse);
        } catch (parseError) {
          console.error('JSON Parse error:', parseError);
          // If response is ok despite parse error, treat as success
          if (response.ok) {
            setSuccess('Klient erfolgreich registriert');
            fetchClients();
            setTimeout(() => {
              handleCloseDialog();
            }, 2000);
            return;
          }
          throw new Error('Serverfehler: Ungültige Antwort vom Server');
        }
      }

      if (!response.ok) {
        console.error('Error response data:', data);
        
        if (data.errors) {
          const errorMessages = Object.entries(data.errors)
            .map(([field, errors]) => {
              // Handle both array and string error messages
              const errorMsg = Array.isArray(errors) ? errors.join(', ') : errors;
              return `${field}: ${errorMsg}`;
            })
            .join('\n');
          throw new Error(errorMessages);
        } else if (typeof data.error === 'object') {
          // Handle nested error objects
          const errorMessages = Object.entries(data.error)
            .map(([field, errors]) => {
              const errorMsg = Array.isArray(errors) ? errors.join(', ') : errors;
              return `${field}: ${errorMsg}`;
            })
            .join('\n');
          throw new Error(errorMessages);
        } else if (data.error) {
          throw new Error(data.error.toString());
        } else if (data.message) {
          throw new Error(data.message.toString());
        } else if (data.detail) {
          throw new Error(data.detail.toString());
        }
        throw new Error('Ein unerwarteter Fehler ist aufgetreten.');
      }

      setSuccess('Klient erfolgreich registriert');
      fetchClients(); // Refresh clients list
      
      setTimeout(() => {
        handleCloseDialog();
      }, 2000);

    } catch (error) {
      console.error('Registration error:', error);
      // Ensure we're always displaying a string
      const errorMessage = typeof error.message === 'object' 
        ? JSON.stringify(error.message) 
        : error.message || 'Ein Fehler ist bei der Registrierung aufgetreten';
      setError(errorMessage);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }


  const getStatistics = () => {
    return {
      totalClients: clients.length,
      activeClients: clients.filter(client => {
        const lastActivity = new Date(client.last_activity);
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        return lastActivity > thirtyDaysAgo;
      }).length,
      newClientsThisMonth: clients.filter(client => {
        const createdDate = new Date(client.created_at);
        const firstOfMonth = new Date();
        firstOfMonth.setDate(1);
        return createdDate > firstOfMonth;
      }).length
    };
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  const stats = getStatistics();

  return (
    <Container maxWidth="xl">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Header Section */}
        <Box sx={{ mt: 4, mb: 6 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h4" component="h1" sx={{
                background: 'linear-gradient(45deg, #2E3B55 30%, #4F5B75 90%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                mb: 1
              }}>
                Steuerberater Dashboard
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                Verwalten Sie Ihre Klienten, Dokumente und Belegsitzungen
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
                startIcon={<Lucide.UserPlus size={20} />}
                sx={{
                  borderRadius: 2,
                  boxShadow: '0 4px 14px 0 rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 20px 0 rgba(0,0,0,0.15)',
                  }
                }}
              >
                Neuen Klienten registrieren
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* Tabs for different dashboard sections */}
        <Box sx={{ width: '100%', mb: 4 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="dashboard tabs"
          >
            <Tab label="Klienten Übersicht" icon={<Lucide.Users size={16} />} iconPosition="start" />
            <Tab label="Abonnements" icon={<Lucide.CreditCard size={16} />} iconPosition="start" />
          </Tabs>
        </Box>

        {/* Tab Panels */}
        {activeTab === 0 && (
          <>
            {/* Overview Cards */}
            <Grid container spacing={3} sx={{ mb: 6 }}>
              <Grid item xs={12} md={4}>
                <Card sx={{ 
                  bgcolor: 'primary.main', 
                  color: 'white',
                  height: '100%' 
                }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Lucide.Users size={24} />
                      <Typography variant="h6" sx={{ ml: 1 }}>Gesamte Klienten</Typography>
                    </Box>
                    <Typography variant="h3">{stats.totalClients}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ 
                  bgcolor: 'success.main', 
                  color: 'white',
                  height: '100%'  
                }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Lucide.UserCheck size={24} />
                      <Typography variant="h6" sx={{ ml: 1 }}>Aktive Klienten</Typography>
                    </Box>
                    <Typography variant="h3">{stats.activeClients}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ 
                  bgcolor: 'info.main', 
                  color: 'white',
                  height: '100%'  
                }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Lucide.UserPlus size={24} />
                      <Typography variant="h6" sx={{ ml: 1 }}>Neue Klienten (Monat)</Typography>
                    </Box>
                    <Typography variant="h3">{stats.newClientsThisMonth}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Document Sessions Quick Access */}
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" gutterBottom>
                Schnellzugriff
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card sx={{ 
                    height: '100%',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 12px 25px 0 rgba(0,0,0,0.1)',
                    },
                    transition: 'all 0.3s ease',
                  }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Lucide.History size={24} color="#2E3B55" />
                        <Typography variant="h6" sx={{ ml: 1 }}>Alle Belegsitzungen</Typography>
                      </Box>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Übersicht aller Dokumentsitzungen für sämtliche Klienten
                      </Typography>
                      <Button 
                        variant="outlined" 
                        color="primary"
                        startIcon={<Lucide.ArrowRight size={16} />}
                        onClick={() => navigate('/app/sessions')}
                      >
                        Zur Übersicht
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ 
                    height: '100%',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 12px 25px 0 rgba(0,0,0,0.1)',
                    },
                    transition: 'all 0.3s ease',
                  }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Lucide.FileText size={24} color="#2E3B55" />
                        <Typography variant="h6" sx={{ ml: 1 }}>Neue Belegsitzung</Typography>
                      </Box>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        Erstellen Sie eine neue Dokumentsitzung für einen Klienten
                      </Typography>
                      <Button 
                        variant="outlined" 
                        color="primary"
                        startIcon={<Lucide.Plus size={16} />}
                        onClick={() => navigate('/app/sessions/new')}
                      >
                        Sitzung erstellen
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>

            {/* Client List Section */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" gutterBottom>
                Meine Klienten
              </Typography>
              <Grid container spacing={3}>
                {clients.map((client) => (
                  <Grid item xs={12} md={6} lg={4} key={client.id}>
                    <Card sx={{ 
                      height: '100%',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 12px 25px 0 rgba(0,0,0,0.1)',
                      },
                      transition: 'all 0.3s ease',
                    }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                          <Typography variant="h6">
                            {client.company_name || 'Unbenanntes Unternehmen'}
                          </Typography>
                          <Chip
                            size="small"
                            label={getActivityStatus(client.last_activity).label}
                            color={getActivityStatus(client.last_activity).color}
                          />
                        </Box>
                        
                        <Typography color="text.secondary" gutterBottom>
                          {client.client_name}
                        </Typography>

                        <Stack spacing={2} sx={{ mb: 3 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Lucide.Mail size={16} />
                            <Typography variant="body2" color="text.secondary">
                              {client.email}
                            </Typography>
                          </Box>
                          
                          {client.last_activity && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Lucide.Clock size={16} />
                              <Typography variant="body2" color="text.secondary">
                                Letzte Aktivität: {new Date(client.last_activity).toLocaleString()}
                              </Typography>
                            </Box>
                          )}
                        </Stack>

                        <Divider sx={{ my: 2 }} />

                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                          <Button
                            variant="outlined"
                            size="small"
                            startIcon={<Lucide.UserCog size={16} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/app/clients/${client.id}`);
                            }}
                          >
                            Details
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<Lucide.History size={16} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/app/workflow/${client.id}`, {
                                state: { initialStep: 'Dateien hochladen' }
                              });
                            }}
                          >
                            Belegsitzungen
                          </Button>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}

        {activeTab === 1 && (
          <SubscriptionManagement />
        )}

        {/* Registration Dialog */}
        <Dialog 
          open={openDialog} 
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Typography variant="h6" component="div" sx={{ mb: 1 }}>
              Neuen Klienten registrieren
            </Typography>
          </DialogTitle>
          <DialogContent>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success}
              </Alert>
            )}
            {generatedPassword && (
              <Alert severity="info" sx={{ mb: 2 }}>
                Generiertes Passwort: {generatedPassword}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Vorname"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.User size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nachname"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.User size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="E-Mail"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.Mail size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Passwort"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.Lock size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                    endAdornment: (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Passwort generieren">
                          <IconButton onClick={generateSecurePassword} edge="end">
                            <Lucide.RefreshCw size={20} strokeWidth={1.5} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Firmenname"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.Building size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Kundennummer"
                  name="client_number"
                  value={formData.client_number}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.Hash size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Steuernummer"
                  name="tax_number"
                  value={formData.tax_number}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.FileText size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="UID Nummer"
                  name="vat_number"
                  value={formData.vat_number}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.CreditCard size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Telefonnummer"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  required
                  margin="normal"
                  helperText="Format: +49 123 4567890"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.Phone size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Branche"
                  name="branche"
                  value={formData.branche}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.Building2 size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Beginn des Geschäftsjahres"
                  name="fiscal_year_start"
                  type="date"
                  value={formData.fiscal_year_start}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.Calendar size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Ende des Geschäftsjahres"
                  name="fiscal_year_end"
                  type="date"
                  value={formData.fiscal_year_end}
                  onChange={handleChange}
                  required
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                        <Lucide.Calendar size={20} strokeWidth={1.5} />
                      </Box>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={forcePasswordChange}
                    onChange={(e) => setForcePasswordChange(e.target.checked)}
                    color="primary"
                  />
                }
                label="Klient muss Passwort bei erster Anmeldung ändern"
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button onClick={handleCloseDialog} startIcon={<Lucide.X size={20} />}>
              Abbrechen
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              startIcon={<Lucide.UserPlus size={20} />}
            >
              Klient registrieren
            </Button>
          </DialogActions>
        </Dialog>
      </motion.div>
    </Container>
  );
};

export default Dashboard;