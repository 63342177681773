import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Typography, 
  Paper, 
  Fade, 
  Grow, 
  Slide, 
  Portal,
  IconButton,
  Dialog,
  DialogContent
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { 
  CheckCircle as CheckCircleIcon,
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
  Receipt as ReceiptIcon,
  AccountBalance as AccountBalanceIcon,
  Lightbulb as LightbulbIcon
} from '@mui/icons-material';
import { FiZap } from 'react-icons/fi';
import { motion } from 'framer-motion';

// Animation keyframes
const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-2px); }
  100% { transform: translateY(0px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 2px rgba(66, 135, 245, 0.3); }
  50% { box-shadow: 0 0 5px rgba(66, 135, 245, 0.5); }
  100% { box-shadow: 0 0 2px rgba(66, 135, 245, 0.3); }
`;

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

// Styled components
const AnimationContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  zIndex: 1300,
  padding: theme.spacing(2),
  backdropFilter: 'blur(4px)'
}));

const ReceiptCard = styled(Paper)(({ theme, type }) => ({
  width: 350,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
  position: 'relative',
  overflow: 'hidden',
  borderTop: `5px solid ${type === 'kassa' 
    ? theme.palette.primary.dark 
    : type === 'kredit' 
      ? theme.palette.secondary.dark 
      : type === 'manual'
        ? theme.palette.info.dark
        : theme.palette.grey[700]}`,
  background: type === 'kassa'
    ? 'linear-gradient(to bottom, rgba(25, 118, 210, 0.03), rgba(255, 255, 255, 1))'
    : type === 'kredit'
      ? 'linear-gradient(to bottom, rgba(156, 39, 176, 0.03), rgba(255, 255, 255, 1))'
      : type === 'manual'
        ? 'linear-gradient(to bottom, rgba(66, 135, 245, 0.03), rgba(255, 255, 255, 1))'
        : 'white',
  transition: 'all 0.3s ease',
  textAlign: 'left'
}));

const CardHeader = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2.5),
  paddingBottom: theme.spacing(1.5),
  borderBottom: `1px solid ${type === 'kassa' 
    ? 'rgba(25, 118, 210, 0.1)' 
    : type === 'kredit' 
      ? 'rgba(156, 39, 176, 0.1)' 
      : type === 'manual'
        ? 'rgba(66, 135, 245, 0.1)'
        : 'rgba(0, 0, 0, 0.1)'}`,
  '& svg': {
    color: type === 'kassa' 
      ? theme.palette.primary.main 
      : type === 'kredit' 
        ? theme.palette.secondary.main 
        : type === 'manual'
          ? theme.palette.info.main
          : theme.palette.grey[700],
    marginRight: theme.spacing(1.5),
    fontSize: 24
  }
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.1rem',
  letterSpacing: '0.01em'
}));

const InfoRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
  '&:last-child': {
    marginBottom: 0
  }
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: '0.875rem',
  flex: '0 0 40%'
}));

const InfoValue = styled(Typography)(({ theme, highlight }) => ({
  fontWeight: highlight ? 600 : 400,
  fontSize: '0.875rem',
  color: highlight ? theme.palette.primary.dark : theme.palette.text.primary,
  textAlign: 'right',
  flex: '0 0 60%',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const IconContainer = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 60,
  height: 60,
  borderRadius: '50%',
  backgroundColor: type === 'kassa' 
    ? 'rgba(25, 118, 210, 0.1)' 
    : type === 'kredit'
      ? 'rgba(156, 39, 176, 0.1)'
      : 'rgba(66, 135, 245, 0.1)',
  margin: theme.spacing(0, 'auto', 3),
  '& svg': {
    fontSize: 32,
    color: type === 'kassa' 
      ? theme.palette.primary.main 
      : type === 'kredit'
        ? theme.palette.secondary.main
        : theme.palette.info.main
  }
}));

const ArrowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  height: 40,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 0
  },
  '& svg': {
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: 4,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    color: theme.palette.primary.main,
    fontSize: 28,
    animation: `${pulse} 1.5s infinite ease-in-out`
  }
}));

const SuccessMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5, 3),
  backgroundColor: 'rgba(76, 175, 80, 0.1)',
  borderRadius: theme.shape.borderRadius * 2,
  border: '1px solid rgba(76, 175, 80, 0.2)',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  '& svg': {
    color: theme.palette.success.main,
    fontSize: 24
  }
}));

const AIBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  backgroundColor: 'rgba(25, 118, 210, 0.1)',
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.75rem',
  fontWeight: 600,
  color: theme.palette.primary.main,
  '& svg': {
    fontSize: '0.875rem'
  }
}));

const KontoSuggestion = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(2),
  backgroundColor: 'rgba(25, 118, 210, 0.05)',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(25, 118, 210, 0.1)',
  '& svg': {
    color: theme.palette.primary.main,
    marginTop: 2
  }
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  }
}));

// Add a progress indicator for multiple receipts
const ProgressIndicator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  padding: theme.spacing(0.5, 2),
  borderRadius: theme.shape.borderRadius * 3,
  zIndex: 10,
  backdropFilter: 'blur(4px)'
}));

/**
 * TransitionAnimation Component
 * 
 * Displays a transition when receipts are confirmed and processed
 * for accounting purposes. Can handle single or multiple receipts.
 */
const TransitionAnimation = ({ open, onClose, receipt, receipts = [], type, kontoMatches = [] }) => {
  const [animationStage, setAnimationStage] = useState(0);
  const [currentReceiptIndex, setCurrentReceiptIndex] = useState(0);
  
  const multipleReceipts = receipts && receipts.length > 0;
  const totalReceipts = multipleReceipts ? receipts.length : 1;
  const currentReceipt = multipleReceipts ? receipts[currentReceiptIndex] : receipt;
  const currentKontoMatches = multipleReceipts && kontoMatches.length > currentReceiptIndex 
    ? kontoMatches[currentReceiptIndex] 
    : (Array.isArray(kontoMatches) ? kontoMatches : []);
  
  const hasAiSuggestion = currentKontoMatches && currentKontoMatches.length > 0;
  
  // Reset animation when dialog opens or closes
  useEffect(() => {
    if (open) {
      console.log('Animation dialog opened');
      console.log('Multiple receipts:', multipleReceipts, 'Total:', totalReceipts);
      setAnimationStage(0);
      setCurrentReceiptIndex(0);
    } else {
      // Reset when dialog closes
      setAnimationStage(0);
      setCurrentReceiptIndex(0);
    }
  }, [open, multipleReceipts, totalReceipts]);
  
  // Handle animation sequence
  useEffect(() => {
    if (!open) return;
    
    console.log(`Animation sequence started for receipt ${currentReceiptIndex + 1}/${totalReceipts}`);
    
    // Clear any existing timers
    const timers = [];
    
    // Stage 1: Show receipt (immediately)
    setAnimationStage(1);
    console.log(`Stage 1: Showing receipt ${currentReceiptIndex + 1}/${totalReceipts}`);
    
    // Stage 2: Show booking suggestion after 1 second
    const timer1 = setTimeout(() => {
      setAnimationStage(2);
      console.log(`Stage 2: Showing booking suggestion for receipt ${currentReceiptIndex + 1}/${totalReceipts}`);
    }, 1000);
    timers.push(timer1);
    
    // Stage 3: Show success message after 2 more seconds
    const timer2 = setTimeout(() => {
      setAnimationStage(3);
      console.log(`Stage 3: Showing success message for receipt ${currentReceiptIndex + 1}/${totalReceipts}`);
    }, 3000);
    timers.push(timer2);
    
    // Move to next receipt or close after 1.5 more seconds
    const timer3 = setTimeout(() => {
      if (multipleReceipts && currentReceiptIndex < totalReceipts - 1) {
        // Move to next receipt
        console.log(`Moving to next receipt: ${currentReceiptIndex + 2}/${totalReceipts}`);
        setCurrentReceiptIndex(prevIndex => prevIndex + 1);
        setAnimationStage(0);
      } else {
        // All receipts processed, close dialog
        console.log('All receipts processed, closing dialog');
        onClose();
        
        // Add CSS for the highlight effect if it doesn't exist yet
        if (!document.getElementById('highlight-animation-style')) {
          const style = document.createElement('style');
          style.id = 'highlight-animation-style';
          style.innerHTML = `
            @keyframes highlightPulse {
              0% { background-color: rgba(25, 118, 210, 0.05); box-shadow: 0 0 0 rgba(25, 118, 210, 0); }
              50% { background-color: rgba(25, 118, 210, 0.2); box-shadow: 0 0 15px rgba(25, 118, 210, 0.5); }
              100% { background-color: rgba(25, 118, 210, 0.05); box-shadow: 0 0 0 rgba(25, 118, 210, 0); }
            }
            
            @keyframes slideInFromRight {
              0% { transform: translateX(30px); opacity: 0; }
              100% { transform: translateX(0); opacity: 1; }
            }
            
            .highlight-new-transaction {
              animation: highlightPulse 1.5s ease-in-out infinite, slideInFromRight 0.8s ease-out;
              transition: all 0.5s ease;
              position: relative;
              z-index: 1;
            }
            
            .highlight-new-transaction td {
              position: relative;
              overflow: hidden;
            }
            
            .highlight-new-transaction td::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
              transform: translateX(-100%);
              animation: shimmer 2s infinite;
            }
            
            @keyframes shimmer {
              100% { transform: translateX(100%); }
            }
          `;
          document.head.appendChild(style);
        }
      }
    }, 4500);
    timers.push(timer3);
    
    // Cleanup function
    return () => {
      timers.forEach(timer => clearTimeout(timer));
    };
  }, [open, currentReceiptIndex, multipleReceipts, totalReceipts, onClose]);
  
  if (!open) return null;
  if (!currentReceipt && !multipleReceipts) return null;
  
  // Extract receipt information
  const receiptInfo = {};
  if (currentReceipt && currentReceipt.receiptInfo && Array.isArray(currentReceipt.receiptInfo)) {
    currentReceipt.receiptInfo.forEach(item => {
      if (Array.isArray(item) && item.length === 2) {
        receiptInfo[item[0]] = item[1];
      }
    });
  }
  
  const amount = receiptInfo.amount_incl_vat || '0.00';
  const currency = receiptInfo.currency || 'EUR';
  const description = receiptInfo.reservation_details || 
                     receiptInfo.document_description || 
                     `${type.toUpperCase()} Beleg`;
  const partnerName = receiptInfo.partner_name || 'Unbekannt';
  const date = receiptInfo.reservation_date || new Date().toISOString().split('T')[0];
  const invoiceNumber = receiptInfo.invoice_number || receiptInfo.document_number || '-';
  
  // Format date for better display
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    } catch (e) {
      return dateString;
    }
  };
  
  // Format amount for better display
  const formatAmount = (amountString) => {
    try {
      const num = parseFloat(amountString);
      return num.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + ' ' + currency;
    } catch (e) {
      return amountString + ' ' + currency;
    }
  };
  
  // Select only the most important information to display
  const getEssentialInfo = () => {
    const essentialInfo = [
      { label: 'Betrag', value: formatAmount(amount), highlight: true },
      { label: 'Datum', value: formatDate(date) },
      { label: 'Partner', value: partnerName }
    ];
    
    if (invoiceNumber !== '-') {
      essentialInfo.push({ label: 'Rechnungsnr.', value: invoiceNumber });
    }
    
    return essentialInfo;
  };
  
  // Get the appropriate icon based on receipt type
  const getReceiptIcon = () => {
    switch (type) {
      case 'kassa':
        return <ReceiptIcon />;
      case 'kredit':
        return <AccountBalanceIcon />;
      case 'manual':
        return <FiZap style={{ fontSize: '22px' }} />;
      default:
        return <ReceiptIcon />;
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2,
          backgroundColor: '#f5f5f5',
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '300px',
            position: 'relative',
          }}
        >
          {/* Progress indicator for multiple receipts */}
          {multipleReceipts && (
            <Box 
              sx={{ 
                position: 'absolute', 
                top: 0, 
                right: 0, 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1 
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                <Box 
                  component="span" 
                  sx={{ 
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'primary.main',
                    color: 'white',
                    borderRadius: '50%',
                    width: 28,
                    height: 28,
                    mr: 1
                  }}
                >
                  {currentReceiptIndex + 1}
                </Box>
                von {totalReceipts} Belegen
              </Typography>
            </Box>
          )}
          
          {/* Stage 1: Receipt Icon */}
          {animationStage >= 1 && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              {getReceiptIcon()}
              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                {type === 'kassa' ? 'Kassa Beleg' : type === 'kredit' ? 'Kredit Beleg' : 'Manueller Beleg'}
                {currentReceipt && currentReceipt.fileName && (
                  <Typography variant="body2" color="text.secondary">
                    {currentReceipt.fileName}
                  </Typography>
                )}
              </Typography>
            </motion.div>
          )}

          {/* Stage 2: Booking Suggestion */}
          {animationStage >= 2 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ textAlign: 'center', marginBottom: '16px' }}
            >
              <AccountBalanceIcon
                sx={{
                  fontSize: 60,
                  color: 'primary.main',
                  mb: 1,
                }}
              />
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {hasAiSuggestion ? (
                  <>
                    Buchungsvorschlag: {currentKontoMatches[0]?.konto_name || 'Konto'} (
                    {currentKontoMatches[0]?.konto_number || 'N/A'})
                  </>
                ) : (
                  'Buchung wird vorbereitet'
                )}
              </Typography>
            </motion.div>
          )}

          {/* Stage 3: Success Message */}
          {animationStage >= 3 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ textAlign: 'center' }}
            >
              <CheckCircleIcon
                sx={{
                  fontSize: 60,
                  color: 'success.main',
                  mb: 1,
                }}
              />
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {multipleReceipts ? 
                  `Beleg ${currentReceiptIndex + 1} von ${totalReceipts} verarbeitet` : 
                  'Beleg erfolgreich verarbeitet'}
              </Typography>
            </motion.div>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

TransitionAnimation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  receipt: PropTypes.object,
  receipts: PropTypes.array,
  type: PropTypes.oneOf(['kassa', 'kredit', 'manual']),
  kontoMatches: PropTypes.array
};

TransitionAnimation.defaultProps = {
  open: false,
  type: 'kassa',
  receipts: [],
  kontoMatches: []
};

export default TransitionAnimation; 