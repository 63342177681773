import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Grid,
} from '@mui/material';
import * as Lucide from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';

const ClientDashboard = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
  
    useEffect(() => {
      console.log('Current user in ClientDashboard:', currentUser);
    }, [currentUser]);
  
    const handleDocumentsClick = () => {
      console.log('Clicked Belege verwalten with user:', currentUser);
      if (currentUser && currentUser.id) {
        console.log('Navigating to:', `/app/workflow/${currentUser.id}`);
        navigate(`/app/workflow/${currentUser.id}`);
      } else {
        console.error('No user ID available');
      }
    };
  
    // Log when rendering
    console.log('Rendering ClientDashboard with currentUser:', currentUser);

  return (
    <Container maxWidth="lg">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" sx={{
            background: 'linear-gradient(45deg, #2E3B55 30%, #4F5B75 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 'bold'
          }}>
            Willkommen im Kundenportal
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {currentUser?.email}
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 3,
                height: '100%',
                background: 'linear-gradient(135deg, #fff 0%, #f8f9fa 100%)',
                borderRadius: 2,
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 12px 25px 0 rgba(0,0,0,0.1)',
                }
              }}
            >
              <Typography variant="h6" gutterBottom>
                Ihre Dokumente
              </Typography>
              <Typography color="text.secondary" paragraph>
                Hier können Sie Ihre Belege hochladen und verwalten.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Lucide.FileText size={20} />}
                onClick={handleDocumentsClick}
                sx={{
                  mt: 2,
                  borderRadius: 2,
                  boxShadow: '0 4px 14px 0 rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 20px 0 rgba(0,0,0,0.15)',
                  }
                }}
              >
                Belege verwalten
              </Button>
            </Paper>
          </Grid>

          {/* Quick Info Card */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 3,
                height: '100%',
                background: 'linear-gradient(135deg, #fff 0%, #f8f9fa 100%)',
                borderRadius: 2
              }}
            >
              <Typography variant="h6" gutterBottom>
                Schnellinfo
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  <Lucide.Info size={16} style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Laden Sie Ihre Belege regelmäßig hoch
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  <Lucide.Clock size={16} style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Zeitnahe Bearbeitung durch Ihren Steuerberater
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Lucide.HelpCircle size={16} style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  Bei Fragen kontaktieren Sie Ihren Steuerberater
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </motion.div>
    </Container>
  );
};

export default ClientDashboard;