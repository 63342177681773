import { kontoFrequency, kontoRules } from '../constants/kontoData';

// Helper function to format confidence percentage
const formatConfidence = (confidence) => {
  // Check if confidence is already a percentage (>= 1) or a decimal (< 1)
  const value = confidence >= 1 ? confidence : confidence * 100;
  // Return just the number followed by % symbol
  return `${Math.round(value)}%`;
};

export const calculateConfidence = (transaction, konto, matchStrength, index) => {
  const partnerLower = transaction.partner_name?.toLowerCase() || '';
  const amountAbs = Math.abs(transaction.amount);
  
  const keywordScore = matchStrength * 0.4;
  
  let amountScore = 0;
  if (konto.konto === '5770' && amountAbs > 1000) amountScore = 0.2;
  else if (konto.konto === '4000' && transaction.amount > 0) amountScore = 0.2;
  else if (konto.konto === '7600' && amountAbs < 100 && transaction.amount < 0) amountScore = 0.2;
  else if (konto.konto === '5200' && amountAbs >= 100 && transaction.amount < 0) amountScore = 0.2;
  
  const positionScore = (3 - index) * 0.1;
  const frequencyScore = (kontoFrequency[konto.konto] || 0) * 2;
  
  return {
    confidence: Math.min(Math.round((keywordScore + amountScore + positionScore + frequencyScore) * 100) / 100, 0.99),
    keywordScore,
    amountScore,
    positionScore,
    frequencyScore
  };
};

export const getConfidenceExplanation = (suggestion) => {
  const factors = [
    `Schlüsselwort-Übereinstimmung: ${formatConfidence(suggestion.keywordScore)}`,
    `Betragsrelevanz: ${formatConfidence(suggestion.amountScore)}`,
    `Vorschlagsreihenfolge: ${formatConfidence(suggestion.positionScore)}`,
    `Historische Nutzung: ${formatConfidence(suggestion.frequencyScore)}`
  ];
  return factors.join('\n');
};

export const getSuggestedKontos = (transaction, allKontos = []) => {
  if (!transaction || !allKontos?.length) return [];

  const partnerLower = transaction.partner_name?.toLowerCase() || '';
  const amountAbs = Math.abs(transaction.amount || 0);

  const matchingKontos = kontoRules
    .filter(rule => partnerLower.includes(rule.keyword))
    .map(rule => {
      const konto = allKontos.find(k => k?.konto === rule.konto);
      return konto ? { ...konto, matchStrength: rule.strength } : null;
    })
    .filter(Boolean);

  const generalSuggestions = [];
  if (amountAbs > 1000) {
    const konto = allKontos.find(k => k?.konto === '5770');
    if (konto) generalSuggestions.push({ ...konto, matchStrength: 0.5 });
  }

  if (transaction.amount > 0) {
    const konto = allKontos.find(k => k?.konto === '4000');
    if (konto) generalSuggestions.push({ ...konto, matchStrength: 0.5 });
  } else {
    const konto = allKontos.find(k => k?.konto === (amountAbs < 100 ? '7600' : '5200'));
    if (konto) generalSuggestions.push({ ...konto, matchStrength: 0.4 });
  }

  const allSuggestions = [...new Set([...matchingKontos, ...generalSuggestions])];

  return allSuggestions.slice(0, 3).map((konto, index) => {
    const confidenceData = calculateConfidence(transaction, konto, konto.matchStrength, index);
    return { ...konto, ...confidenceData };
  });
};