// FileUploadSection.jsx
import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  IconButton, 
  Tooltip, 
  LinearProgress,
  Paper,
  Fade,
  Zoom,
  Card,
  CardContent,
  Alert,
  Collapse,
  Chip,
  useTheme,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MobileStepper,
  Slider,
} from '@mui/material';
import { 
  Upload as UploadIcon,
  Description as DescriptionIcon,
  AccountBalance as AccountBalanceIcon,
  Info as InfoIcon,
  AutoAwesome as AutoAwesomeIcon,
  FileCopy as FileIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckIcon,
  CloudUpload as CloudUploadIcon,
  Psychology as PsychologyIcon,
  Scanner as ScannerIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Visibility as VisibilityIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { useAuth } from '../hooks/useAuth';
import SwipeableViews from 'react-swipeable-views-react-18-fix';

// Styled components
const UploadZone = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragActive'
})(({ theme, isDragActive }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  position: 'relative',
  maxWidth: '400px',
  margin: '0 auto',
  border: `2px dashed ${isDragActive ? theme.palette.primary.main : theme.palette.divider}`,
  transition: 'all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)',
  overflow: 'hidden',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at center, ${alpha(theme.palette.primary.main, 0.12)}, transparent 70%)`,
    opacity: 0,
    transition: 'opacity 1.2s cubic-bezier(0.4, 0, 0.2, 1)',
    zIndex: 0,
  },

  '&:hover': {
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-4px)',
    boxShadow: `0 8px 32px -8px ${theme.palette.primary.main}20`,
    '& .upload-icon': {
      transform: 'scale(1.2) translateY(-8px)',
      color: theme.palette.primary.main,
    },
    '& .ai-indicator': {
      transform: 'translateY(-4px)',
      '& .sparkle': {
        transform: 'rotate(180deg) scale(1.2)',
      }
    },
    '&::before': {
      opacity: 1,
    }
  }
}));

const UploadCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)',
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(3),

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    transition: 'opacity 1.2s cubic-bezier(0.4, 0, 0.2, 1)',
    zIndex: 0,
  },

  '&.csv-card': {
    '&::before': {
      background: `radial-gradient(circle at center, ${alpha('#00838f', 0.12)}, transparent 70%)`,
    },
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: `0 8px 32px -8px ${alpha('#00838f', 0.2)}`,
      borderColor: '#00838f',
      '& .upload-icon': {
        color: '#00838f',
        transform: 'scale(1.2) translateY(-8px)',
      },
      '&::before': {
        opacity: 1,
      }
    }
  },

  '&.camt-card': {
    '&::before': {
      background: `radial-gradient(circle at center, ${alpha('#5c6bc0', 0.12)}, transparent 70%)`,
    },
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: `0 8px 32px -8px ${alpha('#5c6bc0', 0.2)}`,
      borderColor: '#5c6bc0',
      '& .upload-icon': {
        color: '#5c6bc0',
        transform: 'scale(1.2) translateY(-8px)',
      },
      '&::before': {
        opacity: 1,
      }
    }
  }
}));

const FileCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    transform: 'translateX(5px)',
    boxShadow: `0 8px 24px -8px ${theme.palette.primary.main}20`,
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.error.main,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: `${theme.palette.error.light}20`,
    transform: 'scale(1.1)',
  }
}));

const StyledDescriptionIcon = styled(DescriptionIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  '& .MuiLinearProgress-root': {
    height: 8,
    borderRadius: 4,
    backgroundColor: `${theme.palette.primary.light}30`,
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
  }
}));

const FileUploadSection = ({ 
  csvFile,
  camtFile,
  receiptFiles = [], // Provide default empty array
  handleCsvUpload,
  handleCamtUpload,
  handleReceiptUpload,
  handleRemoveReceipt,
  handleOpenCsvDialog,
  handleOpenCamtDialog,
  processingPdf,
  pdfProcessingProgress,
  disabled
}) => {
  const { isAuthenticated, user } = useAuth();
  const theme = useTheme();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [dateRangeOpen, setDateRangeOpen] = useState(false);
  const [selectedCamtFiles, setSelectedCamtFiles] = useState([]);
  const [sessionStartDate, setSessionStartDate] = useState('');
  const [sessionEndDate, setSessionEndDate] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [zoomDialogOpen, setZoomDialogOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);
  const maxSteps = receiptFiles.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 25, 400));
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 25, 25));
  };

  const handleZoomChange = (event, newValue) => {
    setZoomLevel(newValue);
  };

  // Function to generate preview URL for files
  const getPreviewUrl = (file) => {
    if (!file) return null;
    try {
      if (file instanceof File || file instanceof Blob) {
        if (file.type === 'application/pdf' || file.type.startsWith('image/')) {
          return URL.createObjectURL(file);
        }
      } else if (typeof file === 'string') {
        // If it's already a URL string
        return file;
      }
    } catch (error) {
      console.error('Error creating preview URL:', error);
    }
    return null;
  };

  const handleCsvFileUpload = (event) => {
    if (!isAuthenticated) {
      setError('Bitte melden Sie sich an, um Dateien hochzuladen');
      return;
    }
    handleCsvUpload(event);
  };

  const handleCamtFileSelect = (event) => {
    if (!isAuthenticated) {
      setError('Bitte melden Sie sich an, um Dateien hochzuladen');
      return;
    }
    
    // Check if we have files from the event
    const files = event?.target?.files;
    if (!files) {
      console.error('No files selected');
      return;
    }
    
    setSelectedCamtFiles(Array.from(files));
    setDateRangeOpen(true);
  };

  const handleCamtFileUpload = () => {
    if (selectedCamtFiles.length > 0 && sessionStartDate && sessionEndDate) {
      // Create a synthetic event with the files
      const syntheticEvent = {
        target: {
          files: selectedCamtFiles,
          value: '' // Add this to prevent issues with file input reset
        }
      };

      // Add date range to each file's name temporarily to pass the information
      const filesWithDates = selectedCamtFiles.map(file => {
        const newFile = new File([file], file.name, { type: file.type });
        newFile.sessionStartDate = sessionStartDate;
        newFile.sessionEndDate = sessionEndDate;
        return newFile;
      });

      // Update the synthetic event with the modified files
      syntheticEvent.target.files = filesWithDates;

      // Call the existing handler with our synthetic event
      handleCamtUpload(syntheticEvent);
      
      // Reset state
      setDateRangeOpen(false);
      setSelectedCamtFiles([]);
      setSessionStartDate('');
      setSessionEndDate('');
    }
  };

  const handleUpload = (event) => {
    if (!isAuthenticated) {
      setError('Bitte melden Sie sich an, um Dateien hochzuladen');
      return;
    }
    handleCsvUpload(event);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = [...e.dataTransfer.files];
    if (files.length > 50) {
      setError("Maximal 50 Dateien erlaubt");
      return;
    }

    const validFiles = files.filter(file => 
      file.type === 'application/pdf' || file.type.startsWith('image/')
    );

    if (validFiles.length !== files.length) {
      setError("Einige Dateien wurden nicht unterstützt");
    }

    handleReceiptUpload({ target: { files: validFiles } });
    setSuccessMessage(`${validFiles.length} Dateien erfolgreich hochgeladen`);
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <Box sx={{ 
      p: 4, 
      borderRadius: 4,
      background: (theme) => `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[50]})`,
      boxShadow: (theme) => `0 12px 48px -12px ${theme.palette.primary.main}10`
    }}>
      <Typography variant="h5" gutterBottom sx={{ 
        mb: 4, 
        fontWeight: 600,
        color: (theme) => theme.palette.text.primary,
        textAlign: 'center'
      }}>
        Dokumente Hochladen
      </Typography>

      {error && (
        <Collapse in={!!error}>
          <Alert 
            severity="error" 
            onClose={() => setError(null)}
            sx={{ mb: 3, borderRadius: 2 }}
          >
            {error}
          </Alert>
        </Collapse>
      )}

      {successMessage && (
        <Collapse in={!!successMessage}>
          <Alert 
            severity="success" 
            onClose={() => setSuccessMessage(null)}
            sx={{ mb: 3, borderRadius: 2 }}
          >
            {successMessage}
          </Alert>
        </Collapse>
      )}

      {/* Upload Cards Grid */}
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', 
        gap: 2,
        mb: 4
      }}>
        {/* CSV Upload Card */}
        <UploadCard 
          className="csv-card"
          elevation={0}
          onClick={() => document.querySelector('input[accept=".csv"]').click()}
        >
          <Box sx={{ 
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            zIndex: 1
          }}>
            <DescriptionIcon 
              className="upload-icon"
              sx={{ 
                fontSize: 40,
                color: 'text.secondary',
                transition: 'all 1s cubic-bezier(0.34, 1.56, 0.64, 1)',
                transformOrigin: 'center'
              }} 
            />
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              CSV-Datei hochladen
            </Typography>
            {csvFile && (
              <Tooltip title="CSV-Datei auswählen">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenCsvDialog();
                  }}
                  size="small"
                  sx={{ 
                    transition: 'all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)',
                    '&:hover': {
                      transform: 'scale(1.1) rotate(12deg)',
                    }
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <input 
            type="file" 
            accept=".csv" 
            onChange={(e) => handleCsvUpload(e)} 
            hidden 
            disabled={disabled}
          />
        </UploadCard>

        {/* CAMT Upload Card */}
        <UploadCard 
          className="camt-card"
          elevation={0}
          onClick={() => document.querySelector('input[accept=".xml,.camt"]').click()}
        >
          <Box sx={{ 
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            zIndex: 1
          }}>
            <AccountBalanceIcon 
              className="upload-icon"
              sx={{ 
                fontSize: 40,
                color: 'text.secondary',
                transition: 'all 1s cubic-bezier(0.34, 1.56, 0.64, 1)',
                transformOrigin: 'center'
              }} 
            />
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1
            }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                CAMT-Datei hochladen
              </Typography>
              {camtFile && (
                <Tooltip title="CAMT Vorschau">
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleOpenCamtDialog();
                    }}
                    size="small"
                    sx={{ 
                      color: '#5c6bc0',
                      transition: 'all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)',
                      '&:hover': {
                        transform: 'scale(1.1) rotate(12deg)',
                        bgcolor: alpha('#5c6bc0', 0.08)
                      }
                    }}
                  >
                    <VisibilityIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          <input 
            type="file" 
            accept=".xml,.camt" 
            onChange={handleCamtFileSelect} 
            multiple
            hidden 
            disabled={disabled}
          />
        </UploadCard>
      </Box>

      {/* Date Range Dialog */}
      <Dialog 
        open={dateRangeOpen} 
        onClose={() => setDateRangeOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 3,
            width: '100%',
            maxWidth: 500,
            p: 2,
            background: (theme) => `linear-gradient(45deg, ${alpha('#5c6bc0', 0.05)}, ${alpha(theme.palette.background.paper, 1)} 30%)`,
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 3,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: '#5c6bc0',
          '& .icon': {
            transition: 'transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)',
            '&:hover': {
              transform: 'rotate(15deg) scale(1.1)'
            }
          }
        }}>
          <AccountBalanceIcon className="icon" sx={{ fontSize: 28 }} />
          Zeitraum für CAMT Dateien
        </DialogTitle>

        <DialogContent sx={{ pt: 1 }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 3
          }}>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                p: 1.5,
                borderRadius: 1,
                bgcolor: (theme) => alpha('#5c6bc0', 0.04),
                border: '1px dashed',
                borderColor: (theme) => alpha('#5c6bc0', 0.2),
              }}
            >
              <FileIcon sx={{ fontSize: 20, color: '#5c6bc0' }} />
              {selectedCamtFiles.length > 0 
                ? `${selectedCamtFiles.length} ${selectedCamtFiles.length === 1 ? 'Datei' : 'Dateien'} ausgewählt` 
                : 'Keine Dateien ausgewählt'}
            </Typography>

            <Box sx={{ 
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap'
            }}>
              <Box sx={{ flex: 1, minWidth: 200 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 1,
                    color: '#5c6bc0',
                    fontWeight: 500
                  }}
                >
                  Startdatum des Zeitraums
                </Typography>
                <TextField
                  type="date"
                  value={sessionStartDate}
                  onChange={(e) => setSessionStartDate(e.target.value)}
                  fullWidth
                  sx={{ 
                    '& .MuiOutlinedInput-root': {
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        '& fieldset': {
                          borderColor: '#5c6bc0',
                        }
                      },
                      '&.Mui-focused': {
                        '& fieldset': {
                          borderColor: '#5c6bc0',
                        }
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ flex: 1, minWidth: 200 }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    mb: 1,
                    color: '#5c6bc0',
                    fontWeight: 500
                  }}
                >
                  Enddatum des Zeitraums
                </Typography>
                <TextField
                  type="date"
                  value={sessionEndDate}
                  onChange={(e) => setSessionEndDate(e.target.value)}
                  fullWidth
                  sx={{ 
                    '& .MuiOutlinedInput-root': {
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        '& fieldset': {
                          borderColor: '#5c6bc0',
                        }
                      },
                      '&.Mui-focused': {
                        '& fieldset': {
                          borderColor: '#5c6bc0',
                        }
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3, gap: 1 }}>
          <Button 
            onClick={() => setDateRangeOpen(false)}
            sx={{ 
              color: 'text.secondary',
              transition: 'all 0.3s ease',
              '&:hover': {
                bgcolor: alpha('#5c6bc0', 0.08),
              }
            }}
          >
            Abbrechen
          </Button>
          <Button 
            variant="contained" 
            onClick={handleCamtFileUpload}
            disabled={!sessionStartDate || !sessionEndDate || selectedCamtFiles.length === 0}
            sx={{
              bgcolor: '#5c6bc0',
              '&:hover': {
                bgcolor: '#3f51b5',
              },
              transition: 'all 0.3s ease'
            }}
          >
            {`${selectedCamtFiles.length} ${selectedCamtFiles.length === 1 ? 'Datei' : 'Dateien'} hochladen`}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Receipts Upload Zone */}
      <Box sx={{ mt: 4 }}>
        <UploadZone
          isDragActive={dragActive}
          onDragEnter={() => setDragActive(true)}
          onDragLeave={() => setDragActive(false)}
          onDrop={() => setDragActive(false)}
          onClick={() => document.querySelector('input[accept="application/pdf,image/*"]').click()}
        >
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2
          }}>
            <Box sx={{ 
              position: 'relative', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1
            }}>
              <FileIcon 
                className="upload-icon"
                sx={{ 
                  fontSize: 48,
                  color: (theme) => dragActive ? theme.palette.primary.main : theme.palette.grey[400],
                  transition: 'all 1s cubic-bezier(0.34, 1.56, 0.64, 1)',
                  filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))',
                  transformOrigin: 'center'
                }} 
              />
            </Box>

            <Typography variant="body1" sx={{ 
              color: 'text.secondary',
              textAlign: 'center',
              zIndex: 1,
              transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
            }}>
              Ihre Belege als PDF oder Bilder hochladen
            </Typography>

            <Box 
              className="ai-indicator"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'primary.main',
                zIndex: 1,
                transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
              }}
            >
              <AutoAwesomeIcon 
                className="sparkle"
                sx={{ 
                  fontSize: 16,
                  transition: 'all 1.2s cubic-bezier(0.34, 1.56, 0.64, 1)',
                  transformOrigin: 'center'
                }} 
              />
              <Typography variant="caption">
                KI-Analyse startet automatisch
              </Typography>
            </Box>

            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.5,
              zIndex: 1,
              opacity: 0.7,
              transition: 'opacity 0.3s ease'
            }}>
              <InfoIcon sx={{ 
                fontSize: 16,
                color: 'text.secondary'
              }} />
              <Typography variant="caption" color="text.secondary">
                Max. 50 Dateien
              </Typography>
            </Box>
          </Box>
          <input
            type="file"
            accept="application/pdf,image/*"
            onChange={handleReceiptUpload}
            multiple
            hidden
            disabled={disabled}
          />
        </UploadZone>
      </Box>

      {/* Gallery View for Receipts */}
      {receiptFiles.length > 0 && (
        <Box sx={{ 
          mt: 4,
          maxWidth: '90%', 
          mx: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: (theme) => `0 8px 32px -8px ${theme.palette.primary.main}20`,
          overflow: 'hidden'
        }}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {receiptFiles.map((file, index) => (
              <Box key={file.name} sx={{ p: 3 }}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box sx={{ position: 'relative' }}>
                    {/* Preview Container */}
                    <Box
                      sx={{
                        height: '70vh', 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'grey.100',
                        borderRadius: 2,
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      {file.type === 'application/pdf' ? (
                        <iframe
                          src={getPreviewUrl(file)}
                          style={{ 
                            width: '100%', 
                            height: '100%', 
                            border: 'none',
                            minHeight: '600px', 
                          }}
                          title={`PDF Preview - ${file.name}`}
                        />
                      ) : (
                        <Box sx={{ 
                          width: '100%', 
                          height: '100%', 
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative'
                        }}>
                          <img
                            src={getPreviewUrl(file)}
                            alt={file.name || 'Receipt preview'}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '70vh',
                              objectFit: 'contain',
                              cursor: 'zoom-in',
                            }}
                            onClick={() => setZoomDialogOpen(true)}
                            onError={(e) => {
                              console.error('Error loading image:', e);
                              e.target.src = ''; // Clear the source on error
                              e.target.alt = 'Error loading image';
                            }}
                          />
                          <IconButton
                            onClick={() => setZoomDialogOpen(true)}
                            sx={{
                              position: 'absolute',
                              right: 16,
                              top: 16,
                              bgcolor: 'rgba(255, 255, 255, 0.8)',
                              '&:hover': {
                                bgcolor: 'rgba(255, 255, 255, 0.9)',
                              },
                            }}
                          >
                            <ZoomInIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>

                    {/* File Info */}
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        {file.name}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {(file.size / 1024 / 1024).toFixed(2)} MB
                      </Typography>
                    </Box>

                    {/* Action Buttons */}
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      gap: 3,
                      mt: 3 
                    }}>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleRemoveReceipt(index)}
                        size="large"
                        sx={{
                          borderRadius: 2,
                          px: 4, 
                          py: 1.5, 
                          '&:hover': {
                            transform: 'scale(1.02)',
                          }
                        }}
                      >
                        Löschen
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CheckIcon />}
                        onClick={() => handleNext()}
                        size="large"
                        sx={{
                          borderRadius: 2,
                          px: 4, 
                          py: 1.5, 
                          '&:hover': {
                            transform: 'scale(1.02)',
                          }
                        }}
                      >
                        Behalten
                      </Button>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ))}
          </SwipeableViews>

          {/* Navigation Stepper */}
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              bgcolor: 'background.paper',
              borderTop: 1,
              borderColor: 'divider',
              py: 2, 
            }}
            nextButton={
              <Button
                size="large"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                endIcon={theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              >
                Weiter
              </Button>
            }
            backButton={
              <Button
                size="large"
                onClick={handleBack}
                disabled={activeStep === 0}
                startIcon={theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              >
                Zurück
              </Button>
            }
          />
        </Box>
      )}

      {/* Zoom Dialog */}
      <Dialog
        open={zoomDialogOpen}
        onClose={() => {
          setZoomDialogOpen(false);
          setZoomLevel(100);
        }}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          sx: {
            height: '90vh',
            bgcolor: 'background.paper',
            position: 'relative',
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 1
        }}>
          <Typography variant="h6">
            {receiptFiles[activeStep]?.name}
          </Typography>
          <IconButton
            onClick={() => {
              setZoomDialogOpen(false);
              setZoomLevel(100);
            }}
            sx={{ color: 'text.secondary' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ 
          p: 0, 
          height: 'calc(100% - 120px)',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            borderRadius: '10px',
          },
        }}>
          <Box sx={{ 
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
          }}>
            <Box sx={{ 
              width: '100%', 
              height: '100%', 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}>
              {receiptFiles[activeStep] && (
                <img
                  src={getPreviewUrl(receiptFiles[activeStep])}
                  alt={receiptFiles[activeStep]?.name || 'Receipt preview'}
                  style={{
                    transform: `scale(${zoomLevel / 100})`,
                    transformOrigin: 'center center',
                    maxWidth: '100%',
                    maxHeight: '70vh',
                    objectFit: 'contain',
                    transition: 'transform 0.3s ease-in-out'
                  }}
                  onError={(e) => {
                    console.error('Error loading image:', e);
                    e.target.src = ''; // Clear the source on error
                    e.target.alt = 'Error loading image';
                  }}
                />
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ 
          p: 2, 
          bgcolor: 'background.paper',
          borderTop: 1,
          borderColor: 'divider',
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2,
            width: '100%',
          }}>
            <IconButton onClick={handleZoomOut} disabled={zoomLevel <= 25}>
              <ZoomOutIcon />
            </IconButton>
            <Slider
              value={zoomLevel}
              onChange={handleZoomChange}
              min={25}
              max={400}
              step={25}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              sx={{ mx: 2, flexGrow: 1 }}
            />
            <IconButton onClick={handleZoomIn} disabled={zoomLevel >= 400}>
              <ZoomInIcon />
            </IconButton>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Progress Indicator */}
      {processingPdf && (
        <Fade in={processingPdf}>
          <Box sx={{ 
            mt: 4, 
            p: 3, 
            borderRadius: 3,
            bgcolor: 'background.paper',
            boxShadow: (theme) => `0 8px 32px -8px ${theme.palette.primary.main}40`,
            border: '1px solid',
            borderColor: (theme) => `${theme.palette.primary.main}30`,
            position: 'relative',
            overflow: 'hidden'
          }}>
            {/* Animated background effect */}
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0.05,
              background: 'linear-gradient(45deg, transparent 25%, rgba(0,0,0,0.05) 25%, rgba(0,0,0,0.05) 50%, transparent 50%, transparent 75%, rgba(0,0,0,0.05) 75%, rgba(0,0,0,0.05))',
              backgroundSize: '20px 20px',
              animation: 'moveBackground 2s linear infinite',
              '@keyframes moveBackground': {
                '0%': {
                  backgroundPosition: '0 0'
                },
                '100%': {
                  backgroundPosition: '40px 40px'
                }
              }
            }} />
            
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              mb: 2
            }}>
              <AutoAwesomeIcon 
                sx={{ 
                  color: 'primary.main',
                  animation: 'pulse 2s infinite',
                  '@keyframes pulse': {
                    '0%': {
                      transform: 'scale(0.95)',
                      opacity: 0.7,
                    },
                    '70%': {
                      transform: 'scale(1.2)',
                      opacity: 1,
                    },
                    '100%': {
                      transform: 'scale(0.95)',
                      opacity: 0.7,
                    }
                  }
                }} 
              />
              <Typography variant="h6" align="center" sx={{ fontWeight: 500 }}>
                KI-Analyse läuft...
              </Typography>
            </Box>
            
            <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
              {camtFile ? 
                `Ihre PDF-Belege werden intelligent verarbeitet und mit den CAMT-Daten abgeglichen (${Math.round(pdfProcessingProgress)}%)` :
                `Ihre Belege werden intelligent verarbeitet (${Math.round(pdfProcessingProgress)}%)`
              }
            </Typography>
            
            <LinearProgress 
              variant="determinate" 
              value={pdfProcessingProgress}
              sx={{
                height: 8,
                borderRadius: 4,
                bgcolor: (theme) => `${theme.palette.primary.light}30`,
                '& .MuiLinearProgress-bar': {
                  borderRadius: 4,
                  background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
                }
              }}
            />
            
            {pdfProcessingProgress >= 90 && (
              <Typography variant="body2" color="primary" align="center" sx={{ mt: 2, fontStyle: 'italic' }}>
                Fast fertig! Ihre Belege werden gleich angezeigt...
              </Typography>
            )}
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default FileUploadSection;