import Papa from 'papaparse';
import { uploadReceipt } from '../services/api';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const getAuthHeadersWithMultipart = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': `Token ${token}`,
    'Content-Type': 'multipart/form-data'
  };
};

export const processBankFile = (file, setTransactions, setShowUploadReceiptsPrompt, setProcessingError) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const content = e.target.result;
    Papa.parse(content, {
      header: true,
      complete: (result) => {
        const bankData = result.data;
        const formattedTransactions = bankData
          .filter(transaction => 
            transaction['Booking Date'] && 
            transaction['Amount'] && 
            !isNaN(parseFloat(transaction['Amount']))
          )
          .map((transaction, index) => ({
            id: index + 1,
            date: transaction['Booking Date'],
            amount: parseFloat(transaction['Amount']),
            partnerName: transaction['Partner Name'],
            status: 'unmatched'
          }));
        setTransactions(formattedTransactions);
        setShowUploadReceiptsPrompt(true);
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        setProcessingError('Error processing bank file. Please try again.');
      }
    });
  };
  reader.readAsText(file);
};

export const processReceipt = async (file) => {
  if (file.type === 'application/json') {
    const jsonResult = await file.text();
    try {
      const result = JSON.parse(jsonResult);
      return Array.isArray(result) ? result : [result];
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  } else {
    try {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append('file', file);
      
      // Make a direct call to the process-receipt endpoint for AI processing
      const response = await axios.post(
        `${API_BASE_URL}/api/process-receipt`, 
        formData,
        {
          headers: getAuthHeadersWithMultipart()
        }
      );
      
      console.log('AI Receipt Processing Result:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error processing receipt with AI:', error);
      // Fall back to the regular upload if AI processing fails
      return await uploadReceipt(file);
    }
  }
};