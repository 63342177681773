// src/components/CsvPreviewDialog.jsx
import React from 'react';
import {
Dialog,
DialogTitle,
DialogContent,
Table,
TableBody,
TableCell,
TableContainer,
TableHead,
TableRow,
Paper,
IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const CsvPreviewDialog = ({ 
open, 
onClose, 
csvTransactions = [], // Provide default empty array
TransitionComponent,
TransitionProps 
}) => {
// Early return if no transactions
if (!Array.isArray(csvTransactions)) {
  return null;
}

// Get headers from first transaction if available
const headers = csvTransactions.length > 0 
  ? Object.keys(csvTransactions[0]) 
  : [];

return (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="lg"
    fullWidth
    TransitionComponent={TransitionComponent}
    TransitionProps={TransitionProps}
  >
    <DialogTitle>
      CSV Vorschau
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="csv preview table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {csvTransactions.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {headers.map((header, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {row[header]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogContent>
  </Dialog>
);
};

export default CsvPreviewDialog;