import axios from 'axios';

// Axios configuration
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Get API URL from environment variable
export const API_BASE_URL = process.env.REACT_APP_API_URL;

// Add this for debugging (you can remove it later)
console.log('Config API URL:', API_BASE_URL);


// Token validation and refresh mechanism
const validateToken = async (token) => {
  if (!token) return false;
  
  try {
    const response = await axios.get(`${API_BASE_URL}/verify-auth/`, {
      headers: { 
        'Authorization': token.startsWith('Token ') ? token : `Token ${token}`
      }
    });
    return response.status === 200;
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
};

// Request Queue Management
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

axios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token.startsWith('Token ') ? token : `Token ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers.Authorization = `Token ${token}`;
            return axios(originalRequest);
          })
          .catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const token = localStorage.getItem('token');
        const isValid = await validateToken(token);

        if (!isValid) {
          processQueue(new Error('Token validation failed'));
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.href = '/login';
          return Promise.reject(error);
        }

        processQueue(null, token);
        originalRequest.headers.Authorization = `Token ${token}`;
        return axios(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

// Step configurations
export const steps = ['Dashboard', 'Dateien hochladen', 'Dateien verarbeiten', 'Ergebnisse anzeigen'];

// Tax Categories Configuration
export const TAX_CATEGORIES = {
  // U = Umsatzsteuer (Sales Tax/VAT)
  //These are typically for sales/income a company makes
  U: {
    label: 'Umsatzsteuer',
    codes: {
      'U20': { code: '101', rate: 20 }, // 20% VAT
      'U10': { code: '102', rate: 10 }, // 10% VAT
      'U13': { code: '103', rate: 13 }  // 13% VAT
    }
  },
  // V = Vorsteuer (Input Tax)
  //These are for purchases/expenses a company makes within Austria
  V: {
    label: 'Vorsteuer',
    codes: {
      'V20': { code: '401', rate: 20 }, // 20% Input tax
      'V10': { code: '402', rate: 10 }, // 10% Input tax
      'V13': { code: '403', rate: 13 }  // 13% Input tax
    }
  },
  // E = Erwerbsteuer (Acquisition Tax)
  //These are for purchases from other EU countries
  E: {
    label: 'Erwerbsteuer',
    codes: {
      'E20': { code: '701', rate: 20 }, // 20% Acquisition tax
      'E10': { code: '702', rate: 10 }  // 10% Acquisition tax
    }
  },
  // R = Reverse Charge
  // These are for specific B2B services where the tax liability shifts to the recipient
  R: {
    label: 'Reverse Charge',
    codes: {
      'R20': { code: '501', rate: 20 }, // 20% Reverse charge
      'R10': { code: '502', rate: 10 }  // 10% Reverse charge
    }
  }
  };

  
  export const fetchKontos = async (clientId) => {
    if (!clientId) {
      console.warn('No clientId provided to fetchKontos');
      return [];
    }
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tax_consultant/clients/${clientId}/`, {
        headers: getAuthHeaders()
      });
      return response.data.kontos || [];
    } catch (error) {
      console.error('Error fetching kontos:', error);
      return [];
    }
  };
  
  // Update allKontos to be a function instead of a variable
  export const getAllKontos = async (clientId) => {
    return await fetchKontos(clientId);
  };
  
  // Update where allKontos is used:
  export let allKontos = [];
  
  // Initialize kontos when config is loaded
  (async () => {
    allKontos = await fetchKontos();
  })();

// API endpoints
export const API_ENDPOINTS = {
  PROCESS_CSV: `${API_BASE_URL}/api/process-csv`,
  PROCESS_PDF: `${API_BASE_URL}/api/process-pdf`,
  PROCESS_RECEIPT: `${API_BASE_URL}/api/process-receipt`,
  MATCH_RECEIPT: `${API_BASE_URL}/api/match-receipt`,
  PROCESS_KASSA_RECEIPT: `${API_BASE_URL}/api/process-kassa-receipt/`,
  PROCESS_KREDIT_RECEIPT: `${API_BASE_URL}/api/process-kredit-receipt/`,
  LOGIN: `${API_BASE_URL}/login/`,
  LOGOUT: `${API_BASE_URL}/logout/`,
  VERIFY_AUTH: `${API_BASE_URL}/verify-auth/`,
};

// File Upload Configurations
export const FILE_UPLOAD_CONFIG = {
  MAX_FILE_SIZE: 10 * 1024 * 1024,
  ACCEPTED_FILE_TYPES: {
    RECEIPTS: '.pdf,.jpg,.jpeg,.png',
    CSV: '.csv'
  },
  MAX_FILES: {
    RECEIPTS: 50,
    CSV: 1
  }
};

// Export Format Configurations
export const EXPORT_FORMATS = {
  BMD: {
    name: 'BMD',
    delimiter: ';',
    encoding: 'utf-8',
    headers: [
      'satzart', 'symbol', 'konto', 'gkonto', 'belegnr', 'belegdatum',
      'text', 'betrag', 'prozent', 'steuercode', 'buchcode', 'steuer', 'Dokument'
    ]
  },
  DATEV: {
    name: 'DATEV',
    delimiter: ',',
    encoding: 'utf-8',
    headers: [
      'Umsatz', 'Soll/Haben', 'WKZ Umsatz', 'Kurs', 'Basis-Umsatz',
      'WKZ Basis-Umsatz', 'Konto', 'Gegenkonto', 'BU-Schlüssel',
      'Belegdatum', 'Belegfeld 1', 'Belegfeld 2', 'Skonto', 'Buchungstext',
      'Steuercode', 'Steuerprozent'
    ]
  }
};

// Progress Indicator Configurations
export const PROGRESS_CONFIG = {
  STEPS: {
    FILE_UPLOAD: 1,
    PROCESSING: 2,
    MATCHING: 3
  },
  PROGRESS_WEIGHTS: {
    PDF_PROCESSING: 0.3,
    RECEIPT_PROCESSING: 0.4,
    MATCHING: 0.3
  }
};

// Animation Configurations
export const ANIMATION_CONFIG = {
  DURATION: {
    FAST: 300,
    MEDIUM: 500,
    SLOW: 800
  },
  EASING: {
    EASE_OUT: 'ease-out',
    EASE_IN_OUT: 'ease-in-out'
  }
};

// Error Messages
export const ERROR_MESSAGES = {
  CSV_UPLOAD: 'Fehler beim CSV-Upload',
  PDF_PROCESSING: 'Fehler bei der PDF-Verarbeitung',
  RECEIPT_PROCESSING: 'Fehler bei der Belegverarbeitung',
  MATCHING: 'Fehler beim Zuordnungsprozess',
  EXPORT: 'Fehler beim Export',
  FILE_SIZE: 'Datei ist zu groß',
  FILE_TYPE: 'Dateityp nicht unterstützt',
  MAX_FILES: 'Maximale Anzahl an Dateien erreicht',
  UNAUTHORIZED: 'Bitte melden Sie sich an, um fortzufahren',
  TOKEN_EXPIRED: 'Ihre Sitzung ist abgelaufen. Bitte erneut anmelden.',
  NETWORK_ERROR: 'Netzwerkfehler. Bitte überprüfen Sie Ihre Verbindung.',
};

// Success Messages
export const SUCCESS_MESSAGES = {
  CSV_UPLOAD: 'CSV-Datei erfolgreich hochgeladen',
  PDF_PROCESSING: 'PDF erfolgreich verarbeitet',
  RECEIPT_UPLOAD: (count) => `${count} Beleg(e) erfolgreich hochgeladen`,
  MATCHING: 'Zuordnungsprozess abgeschlossen',
  EXPORT: (format) => `${format}-Export erfolgreich erstellt`,
  LOGIN: 'Erfolgreich angemeldet',
  LOGOUT: 'Erfolgreich abgemeldet',
};

// Authentication helper functions
export const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': token.startsWith('Token ') ? token : `Token ${token}`,
    'Accept': 'application/json'
  };
};

export const getAuthHeadersWithMultipart = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': token.startsWith('Token ') ? token : `Token ${token}`,
    'Accept': 'application/json'
  };
};

export default {
  API_BASE_URL,
  steps,
  TAX_CATEGORIES,
  allKontos,
  API_ENDPOINTS,
  FILE_UPLOAD_CONFIG,
  EXPORT_FORMATS,
  PROGRESS_CONFIG,
  ANIMATION_CONFIG,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  getAuthHeaders,
  getAuthHeadersWithMultipart,
  validateToken
};