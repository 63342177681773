import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': `Token ${token}`,
    'Content-Type': 'application/json'
  };
};

export const getReceiptUrl = (clientId, filename) => {
  return `${API_BASE_URL}/api/workflow-sessions/receipts/${clientId}/${filename}/`;
};

export const uploadReceipt = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  
  try {
    const response = await axios.post(`${API_BASE_URL}/upload-receipt`, formData, {
      headers: { 
        ...getAuthHeaders(),
        'Content-Type': 'multipart/form-data'
      },
    });
    console.log('OCR Result for', file.name, ':', response.data);
    return response.data;
  } catch (error) {
    console.error('Error processing receipt:', error);
    return null;
  }
};

export const uploadCamtFile = async (formData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/process-camt`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
    
    // Handle overflow transactions in the response
    const { transactions, overflow_transactions, account } = response.data;
    
    // If there are overflow transactions, show a warning to the user
    if (overflow_transactions && overflow_transactions.length > 0) {
      // You can handle this in your UI components by checking the response
      console.warn(`Found ${overflow_transactions.length} transactions outside the selected date range`);
    }
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadManuallyMatchedReceipt = async (formData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/workflow-sessions/upload-manually-matched-receipt/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading manually matched receipt:', error);
    throw error;
  }
};