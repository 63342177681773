import React, { useState, useEffect, useCallback } from 'react';
import { 
Select, 
MenuItem, 
Tooltip, 
Typography, 
Box,
Divider,
Chip
} from '@mui/material';
import { FiChevronDown } from 'react-icons/fi';
import PropTypes from 'prop-types';

// Tax categories configuration
const TAX_CATEGORIES = {
U: {
  label: 'Umsatzsteuer',
  codes: {
    'U20': { code: '101', rate: 20 },
    'U10': { code: '102', rate: 10 },
    'U13': { code: '103', rate: 13 }
  }
},
EU: {
  label: 'EU-Steuer',
  codes: {
    'EU-Leist': { code: '104', rate: 0, label: 'EU-Leist 0% (Leistung)' },
    'EU-Ware': { code: '105', rate: 0, label: 'EU-Ware 0%' }
  }
},
V: {
  label: 'Vorsteuer',
  codes: {
    'V20': { code: '401', rate: 20 },
    'V10': { code: '402', rate: 10 },
    'V13': { code: '403', rate: 13 }
  }
},
E: {
  label: 'Erwerbsteuer',
  codes: {
    'E20': { code: '701', rate: 20 },
    'E10': { code: '702', rate: 10 }
  }
},
R: {
  label: 'Reverse Charge',
  codes: {
    'R20': { code: '501', rate: 20 },
    'R10': { code: '502', rate: 10 }
  }
},
};

const TaxCodeSelect = ({ 
transaction, 
selectedTaxCodes, 
handleTaxCodeChange 
}) => {
  const [localValue, setLocalValue] = useState('');
  const [selectedTaxCode, setSelectedTaxCode] = useState(null);
  
  const transactionId = transaction?.id;
  const hasMatch = transaction.receiptData !== undefined;
  
  // Get AI suggestion from the backend
  const aiSuggestion = transaction.receiptData?.tax_category || '';
  const hasAiSuggestion = !!aiSuggestion;
  
  // Determine transaction amount and filter categories accordingly
  const transactionAmount = parseFloat(transaction.amount);
  const isNegativeAmount = transactionAmount < 0;
  
  // Log for debugging
  console.log('Transaction:', transaction.id, 'amount:', transactionAmount, 'isNegative:', isNegativeAmount);
  console.log('Transaction:', transaction.id, 'receiptData:', transaction.receiptData);
  console.log('AI Suggestion:', aiSuggestion, 'hasAiSuggestion:', hasAiSuggestion);
  console.log('Selected Tax Codes:', selectedTaxCodes[transactionId]);
  
  // Find the tax code details for the AI suggestion
  const findTaxCodeDetails = useCallback((taxCode) => {
    if (!taxCode) return null;
    
    for (const [category, categoryData] of Object.entries(TAX_CATEGORIES)) {
      if (categoryData.codes[taxCode]) {
        return {
          ...categoryData.codes[taxCode],
          code: taxCode,
          category
        };
      }
    }
    return null;
  }, []);
  
  // Create a memoized handleTaxCodeSelect function to handle direct selection
  const handleTaxCodeSelect = useCallback((taxCode) => {
    if (!transactionId) return; // Early return if no transaction ID
    
    console.log('handleTaxCodeSelect called with:', taxCode);
    
    // Find the selected tax code details
    const selectedTaxCodeInfo = findTaxCodeDetails(taxCode);
    console.log('Selected tax code info:', selectedTaxCodeInfo);
    
    // Update local state
    setLocalValue(taxCode);
    setSelectedTaxCode(selectedTaxCodeInfo);
    
    // Call the parent handler
    handleTaxCodeChange(transactionId, taxCode);
  }, [transactionId, handleTaxCodeChange, findTaxCodeDetails]);

  // Initialize and update local value when selectedTaxCodes changes or when AI suggestion is available
  useEffect(() => {
    if (transactionId) {
      // First check if there's a manually selected tax code
      const selectedValue = selectedTaxCodes[transactionId];
      
      if (selectedValue) {
        console.log('Setting localValue from selectedTaxCodes:', selectedValue);
        setLocalValue(selectedValue);
        
        // Find and set the selected tax code details
        const selectedTaxCodeInfo = findTaxCodeDetails(selectedValue);
        console.log('Selected tax code info:', selectedTaxCodeInfo);
        setSelectedTaxCode(selectedTaxCodeInfo);
      } 
      // If no manual selection but AI suggestion is available, use that
      else if (hasAiSuggestion && aiSuggestion) {
        console.log('Setting localValue from AI suggestion:', aiSuggestion);
        setLocalValue(aiSuggestion);
        
        // Find and set the AI tax code details
        const aiTaxCodeInfo = findTaxCodeDetails(aiSuggestion);
        console.log('AI tax code info:', aiTaxCodeInfo);
        setSelectedTaxCode(aiTaxCodeInfo);
        
        // Also update the parent's state to reflect this selection
        handleTaxCodeChange(transactionId, aiSuggestion);
      }
    }
  }, [selectedTaxCodes, transactionId, findTaxCodeDetails, hasAiSuggestion, aiSuggestion, handleTaxCodeChange]);

  // Return null if no transaction ID
  if (!transactionId) return null;

  const handleChange = (event) => {
    const newValue = event.target.value;
    console.log('handleChange called with:', newValue);
    
    // Use the common handler function
    handleTaxCodeSelect(newValue);
  };

  // Render function for the selected value
  const renderValue = (selected) => {
    if (!selected) {
      // If no selection but AI suggestion is available, show that
      if (hasAiSuggestion) {
        const aiTaxCodeInfo = findTaxCodeDetails(aiSuggestion);
        if (aiTaxCodeInfo) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2">
                {aiSuggestion} ({aiTaxCodeInfo.rate}%)
              </Typography>
              <Chip
                size="small"
                label="KI"
                color="primary"
                variant="outlined"
                sx={{ minWidth: '40px' }}
              />
            </Box>
          );
        }
      }
      return '';
    }
    
    const taxCodeInfo = findTaxCodeDetails(selected);
    if (!taxCodeInfo) return selected;
    
    const isAISuggestion = selected === aiSuggestion;
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body2">
          {selected} ({taxCodeInfo.rate}%)
        </Typography>
        {isAISuggestion && (
          <Chip
            size="small"
            label="KI"
            color="primary"
            variant="outlined"
            sx={{ minWidth: '40px' }}
          />
        )}
      </Box>
    );
  };

  // Filter tax categories based on transaction amount
  const getFilteredTaxCategories = () => {
    const filteredCategories = {};
    
    // Always include these categories regardless of amount
    const alwaysIncludeCategories = ['EU', 'E', 'R'];
    
    // Include Umsatzsteuer only for positive amounts
    if (transactionAmount >= 0) {
      filteredCategories['U'] = TAX_CATEGORIES['U'];
    }
    
    // Include Vorsteuer only for negative amounts
    if (transactionAmount < 0) {
      filteredCategories['V'] = TAX_CATEGORIES['V'];
    }
    
    // Add the always-include categories
    alwaysIncludeCategories.forEach(category => {
      if (TAX_CATEGORIES[category]) {
        filteredCategories[category] = TAX_CATEGORIES[category];
      }
    });
    
    return filteredCategories;
  };
  
  // Get filtered categories based on transaction amount
  const filteredTaxCategories = getFilteredTaxCategories();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Select
        size="small"
        value={localValue || (hasAiSuggestion ? aiSuggestion : '')}
        onChange={handleChange}
        renderValue={renderValue}
        IconComponent={FiChevronDown}
        sx={{ 
          minWidth: 120,
          bgcolor: hasAiSuggestion && !selectedTaxCodes[transactionId]
            ? 'action.selected' 
            : hasMatch && !localValue && !hasAiSuggestion
              ? 'warning.lighter'
              : 'inherit',
          '& .MuiSelect-select': {
            paddingY: '8px', // Adjust vertical padding
          }
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300
            }
          }
        }}
      >
        {/* AI Suggestion Section */}
        {hasAiSuggestion && (
          <>
            <MenuItem disabled>
              <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                KI-Vorschlag
              </Typography>
            </MenuItem>
            {(() => {
              const aiTaxCodeInfo = findTaxCodeDetails(aiSuggestion);
              const isSelected = aiSuggestion === localValue;
              
              if (!aiTaxCodeInfo) return null;
              
              return (
                <MenuItem 
                  key={`ai-suggestion-${aiSuggestion}`} 
                  value={aiSuggestion}
                  selected={isSelected}
                  onClick={() => handleTaxCodeSelect(aiSuggestion)}
                  sx={{
                    bgcolor: isSelected ? 'primary.light' : 'action.selected',
                    '&:hover': { bgcolor: 'action.hover' },
                    '&.Mui-selected': { bgcolor: 'primary.light' },
                    cursor: 'pointer'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}>
                    <Typography variant="body2" sx={{ flex: 1, fontWeight: 'medium' }}>
                      {aiSuggestion} ({aiTaxCodeInfo.rate}%)
                      {aiTaxCodeInfo.label && (
                        <Typography 
                          component="span" 
                          variant="caption" 
                          color="text.secondary" 
                          sx={{ ml: 1 }}
                        >
                          {aiTaxCodeInfo.label}
                        </Typography>
                      )}
                      <Typography 
                        component="span" 
                        variant="caption" 
                        color="text.secondary" 
                        sx={{ ml: 1 }}
                      >
                        {aiTaxCodeInfo.code}
                      </Typography>
                    </Typography>
                    <Chip 
                      size="small" 
                      label="KI"
                      color="primary"
                      variant="filled"
                      sx={{ minWidth: '40px' }}
                    />
                  </Box>
                </MenuItem>
              );
            })()}
            <Divider sx={{ my: 1 }} />
          </>
        )}
        
        {/* Manual Selection Section */}
        <MenuItem disabled>
          <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
            Manuelle Auswahl
          </Typography>
        </MenuItem>
        {Object.entries(filteredTaxCategories).map(([category, { label, codes }]) => [
          <MenuItem key={category} disabled sx={{ 
            bgcolor: 'background.default',
            fontWeight: 'bold',
            fontSize: '0.9rem',
            color: 'text.secondary'
          }}>
            {label}
          </MenuItem>,
          ...Object.entries(codes).map(([code, details]) => {
            // Skip the AI suggestion in manual options to avoid duplication
            if (code === aiSuggestion) return null;
            
            const isSelected = code === localValue;
            
            return (
              <MenuItem 
                key={code} 
                value={code}
                selected={isSelected}
                onClick={() => handleTaxCodeSelect(code)}
                sx={{
                  pl: 3,
                  '&:hover': {
                    bgcolor: 'action.hover'
                  },
                  '&.Mui-selected': { bgcolor: 'primary.light' }
                }}
              >
                <Typography variant="body2">
                  {code} ({details.rate}%)
                  {details.label && (
                    <Typography 
                      component="span" 
                      variant="caption" 
                      color="text.secondary" 
                      sx={{ ml: 1 }}
                    >
                      {details.label}
                    </Typography>
                  )}
                  <Typography 
                    component="span" 
                    variant="caption" 
                    color="text.secondary" 
                    sx={{ ml: 1 }}
                  >
                    {details.code}
                  </Typography>
                </Typography>
              </MenuItem>
            );
          })
        ]).flat().filter(Boolean)}
      </Select>
      {hasMatch && !localValue && !hasAiSuggestion && (
        <Typography 
          variant="caption" 
          color="warning.main" 
          sx={{ 
            fontSize: '0.7rem',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          ⚠️ Steuer wählen
        </Typography>
      )}
    </Box>
  );
};

TaxCodeSelect.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    amount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    receiptData: PropTypes.shape({
      tax_category: PropTypes.string
    })
  }).isRequired,
  selectedTaxCodes: PropTypes.object.isRequired,
  handleTaxCodeChange: PropTypes.func.isRequired
};

export default TaxCodeSelect;