import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Step, 
  StepLabel, 
  Tooltip, 
  Slide,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Divider,
  Backdrop
} from '@mui/material';
import { FiDownload, FiSave, FiLock, FiUnlock, FiZap } from 'react-icons/fi';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import { useRoleAuth } from '../../hooks/useRoleAuth';
import { useSplitTransactions } from '../../hooks/useSplitTransactions';
import { getReceiptUrl, getAuthHeaders, uploadManuallyMatchedReceipt } from '../../services/api';
import { processReceipt } from '../../utils/fileProcessing';

// Custom hooks and configurations
import { useReceiptMatcherState } from './state';
import { useSession } from '../../hooks/useSession';
import { steps, API_BASE_URL } from './config';
import * as handlers from './handlers';
import { handleKassaKreditUpload, handleConfirmKassaKreditReceipt } from './handlers';
import SessionsListWithActions from '../SessionControls/SessionsListWithActions';

// Styled components and components
import {
  AnimatedContainer, AnimatedStepper, AnimatedButton,
  AnimatedAlert, AnimatedSection, StyledSessionControls
} from './styles';
import SessionControls from '../SessionControls';
import FileUploadSection from '../FileUploadSection';
import EnhancedProgressIndicator from '../EnhancedProgressIndicator';
import MatchedTransactionsTable from '../MatchedTransactionsTable';
import UnmatchedTransactionsTable from '../UnmatchedTransactionsTable';
import PreviewDialog from '../PreviewDialog';
import CsvPreviewDialog from '../CsvPreviewDialog';
import CamtPreviewDialog from '../CamtPreviewDialog';
import KassaKreditBlock from './KassaKreditBlock';
import TransitionAnimation from './TransitionAnimation';
import { styled } from '@mui/material/styles';

const API_CONFIG = {
  BASE_URL: API_BASE_URL,
  ENDPOINTS: {
    SESSIONS: '/api/workflow-sessions/list/', // Updated to match the actual path
    SAVE_SESSION: '/api/workflow-sessions/save/',
    LOAD_SESSION: '/api/workflow-sessions/load/',
    LOCK_SESSION: '/api/workflow-sessions/lock/',
    UNLOCK_SESSION: '/api/workflow-sessions/unlock/',
    DELETE_SESSION: '/api/workflow-sessions/delete/',
    UPDATE_SESSION_STATUS: '/api/workflow-sessions/status/'
  },
  RETRY_ATTEMPTS: 3,
  RETRY_DELAY: 1000,
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const fetchSessionsWithRetry = async (clientId, retryCount = 0) => {
  try {
    const url = new URL(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.SESSIONS}`);
    if (clientId) {
      url.searchParams.append('client_id', clientId);
    }
    
    const response = await fetch(url, {
      headers: getAuthHeaders(),
      method: 'GET'
    });

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('Endpunkt nicht gefunden. Bitte überprüfen Sie die API-Konfiguration.');
      }
      throw new Error(`HTTP-Fehler! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    if (error.message.includes('Failed to fetch')) {
      console.error('Netzwerkfehler - Server möglicherweise nicht erreichbar:', error);
      throw new Error('Keine Verbindung zum Server möglich. Bitte überprüfen Sie, ob der Server läuft.');
    }

    if (retryCount < API_CONFIG.RETRY_ATTEMPTS) {
      await delay(API_CONFIG.RETRY_DELAY * (retryCount + 1));
      return fetchSessionsWithRetry(clientId, retryCount + 1);
    }
    throw error;
  }
};

// Styled component for transaction section titles
const TransactionTitle = styled(Typography)(({ theme, type }) => ({
  position: 'relative',
  display: 'inline-block',
  fontWeight: 600,
  color: type === 'matched' 
    ? theme.palette.success.dark 
    : type === 'unmatched' 
      ? theme.palette.error.dark 
      : theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -4,
    left: 0,
    width: '40%',
    height: 3,
    backgroundColor: type === 'matched' 
      ? theme.palette.success.dark 
      : type === 'unmatched' 
        ? theme.palette.error.dark 
        : theme.palette.text.primary,
    borderRadius: 2
  }
}));

// Styled component for transaction section blocks
const TransactionBlock = styled(Box)(({ theme, type }) => ({
  width: '100%',
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 1.2,
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  transition: 'all 0.2s ease',
  borderTop: `5px solid ${type === 'matched' 
    ? theme.palette.success.dark 
    : type === 'unmatched' 
      ? theme.palette.error.dark 
      : theme.palette.text.primary}`,
  background: type === 'matched'
    ? 'linear-gradient(to bottom, rgba(52, 168, 83, 0.03), rgba(255, 255, 255, 1))'
    : type === 'unmatched'
      ? 'linear-gradient(to bottom, rgba(234, 67, 53, 0.03), rgba(255, 255, 255, 1))'
      : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.03), rgba(255, 255, 255, 1))',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0,0,0,0.12)',
  }
}));

const ReceiptMatcher = () => {
  const { clientId } = useParams();
  const { currentUser, role, getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [processingStatus, setProcessingStatus] = useState('processing');
  
  const { 
    isTaxConsultant: checkIsTaxConsultant, 
    isClient: checkIsClient,
    isReviewer: checkIsReviewer
  } = useRoleAuth();

  const isTaxConsultant = checkIsTaxConsultant() || role === 'tax_consultant';
  const isClient = checkIsClient() || role === 'client';
  const isReviewer = checkIsReviewer() || role === 'reviewer';

  const {
    transactionSplits,
    saveSplit,
    getSplitsForTransaction,
    removeSplitsForTransaction
  } = useSplitTransactions();

  const {
    fileStates: {
      csvFile, setCsvFile,
      camtFile, setCamtFile,
      receiptFiles, setReceiptFiles,
      receiptInfos, setReceiptInfos,
      processingPdf, setProcessingPdf,
      pdfProcessingProgress, setPdfProcessingProgress,
    },
    kassaKreditStates: {
      kassaReceipts, setKassaReceipts,
      kreditReceipts, setKreditReceipts,
      isProcessing, setIsProcessing,
    },
    transactionStates: {
      csvTransactions, setCsvTransactions,
      camtTransactions, setCamtTransactions,
      matchingTransactions, setMatchingTransactions,
      unmatchedTransactions, setUnmatchedTransactions,
      manuallyMatchedReceipts, setManuallyMatchedReceipts,
      selectedKontos, setSelectedKontos,
      selectedTaxCodes, setSelectedTaxCodes,
      transactionStatuses, setTransactionStatuses,
    },
    uiStates: {
      loading, setLoading, error, setError,
      activeStep, setActiveStep, successMessage, setSuccessMessage,
      openReceiptDialog, setOpenReceiptDialog,
      currentReceiptPreviewUrl, setCurrentReceiptPreviewUrl,
      openCsvDialog, setOpenCsvDialog,
      progress, setProgress, currentStep, setCurrentStep,
      totalSteps, setTotalSteps, receiptsProcessed, setReceiptsProcessed,
    },
    animationStates: {
      transitionAnimation, setTransitionAnimation
    }
  } = useReceiptMatcherState();

  const {
    sessionId,
    lastSaved,
    reviewStatus,
    reviewerNotes,
    isLocked,
    lockedBy,
    loading: sessionLoading,
    error: sessionError,
    savingProgress,
    savingState,
    sessionHistory,
    saveSession,
    loadSession,
    unlockSession,
    updateSessionStatus,
    deleteSession,
    lockSession,
    setReviewerNotes,
  } = useSession();

  const [openCamtDialog, setOpenCamtDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentReceiptTitle, setCurrentReceiptTitle] = useState('Belegvorschau');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    if (!token || !user) {
      window.location.href = '/login';
    }
  }, []);

  useEffect(() => {
    // This effect is for role checks
    return () => {
      // Cleanup for role checks
    };
  }, [role, checkIsTaxConsultant, checkIsClient, checkIsReviewer]);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        setIsLoading(true);
        console.log('Attempting to fetch sessions...');
        // Only pass clientId if we're in the client view
        const data = await fetchSessionsWithRetry(isTaxConsultant ? null : clientId);
        console.log('Fetched sessions:', data);
        setSessions(data);
      } catch (error) {
        console.error('Error fetching sessions:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSessions();
  }, [clientId, isTaxConsultant, setError]);

  useEffect(() => {
    if (isTaxConsultant) {
      // For tax consultants, show all sessions but highlight the current client's sessions if clientId is present
      if (clientId) {
        const filtered = sessions.filter(session => session.client === parseInt(clientId));
        setFilteredSessions(filtered);
      } else {
        setFilteredSessions(sessions);
      }
    } else {
      // For clients, always show their sessions
      setFilteredSessions(sessions);
    }
  }, [sessions, clientId, isTaxConsultant]);

  useEffect(() => {
    if (isLocked && isClient) {
      setOpenSnackbar(true);
    }
  }, [isLocked, isClient]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const isInteractionDisabled = isLocked && isClient;

  const handleSaveSession = async () => {
    try {
      setLoading(true);
      setError('');

      // Create a deep copy of the session data
      const sessionData = {
        client_id: Number(clientId), // Add client_id
        matching_transactions: [...matchingTransactions],
        unmatched_transactions: [...unmatchedTransactions],
        selected_kontos: { ...selectedKontos },
        selected_tax_codes: { ...selectedTaxCodes },
        manually_matched_receipts: { ...manuallyMatchedReceipts },
        transaction_statuses: { ...transactionStatuses },
        transaction_splits: { ...transactionSplits },
        kassa_receipts: [...kassaReceipts],
        kredit_receipts: [...kreditReceipts],
        active_step: activeStep,
        last_modified: new Date().toISOString(), // Add last_modified
        modified_by: currentUser.id // Add modified_by
      };

      // Add receipt files with their content
      if (receiptFiles && Array.isArray(receiptFiles) && activeStep >= 0 && activeStep < receiptFiles.length) {
        sessionData.receiptFiles = await Promise.all(receiptFiles.map(async (file) => {
          if (file instanceof File) {
            // Convert File to base64
            const buffer = await file.arrayBuffer();
            const base64 = btoa(
              new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return {
              name: file.name,
              type: file.type,
              size: file.size,
              lastModified: file.lastModified,
              content: base64
            };
          }
          return file;
        }));
      }

      // Add Kassa and Kredit receipt files with their content
      const processReceiptFiles = async (receipts) => {
        return Promise.all(receipts.map(async (receipt) => {
          const result = { ...receipt };
          if (receipt.file instanceof File) {
            // Convert File to base64
            const buffer = await receipt.file.arrayBuffer();
            const base64 = btoa(
              new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            result.fileContent = base64;
            delete result.file; // Remove the File object as it can't be serialized
          }
          return result;
        }));
      };

      // Process Kassa and Kredit receipts
      sessionData.kassa_receipts = await processReceiptFiles(kassaReceipts);
      sessionData.kredit_receipts = await processReceiptFiles(kreditReceipts);

      // Save the session
      await saveSession(sessionData);
      setSuccessMessage('Session erfolgreich gespeichert');

      // Refresh sessions list
      const data = await fetchSessionsWithRetry(clientId);
      setSessions(data);
      
    } catch (err) {
      console.error('Save session error:', err);
      setError(err.message || 'Fehler beim Speichern der Session');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (status, notes = '') => {
    try {
      await updateSessionStatus(sessionId, status, notes);
      setSuccessMessage(`Session ${status} erfolgreich`);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSessionSelect = async (sessionOrId) => {
    // Handle both session object and session ID
    const session = typeof sessionOrId === 'object' ? sessionOrId : 
                   sessions.find(s => s.session_id === sessionOrId);

    if (!session?.session_id) {
      console.error('Invalid session object or ID:', sessionOrId);
      setError('Ungültige Sitzung ausgewählt');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      
      // Update URL with session_id
      const url = new URL(window.location.href);
      url.searchParams.set('session_id', session.session_id);
      window.history.pushState({}, '', url);
      
      // Clear existing state first
      setMatchingTransactions([]);
      setUnmatchedTransactions([]);
      setSelectedKontos({});
      setSelectedTaxCodes({});
      setTransactionStatuses({});
      setReceiptFiles([]);
      setActiveStep(0);
      
      // Set session ID
      setSelectedSession(session);
      
      // Wait a bit to ensure state is cleared
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // Load session data
      const sessionData = await loadSession(session.session_id);
      console.log('Loaded session data:', sessionData);
      
      if (!sessionData) {
        throw new Error('Keine Sitzungsdaten vom Server erhalten');
      }

      // Process and set matching transactions first
      if (Array.isArray(sessionData.matching_transactions)) {
        console.log('Raw matching transactions:', sessionData.matching_transactions);
        const processedTransactions = sessionData.matching_transactions.map(transaction => ({
          ...transaction,
          hasSplits: Boolean(transaction.hasSplits),
          isSubTransaction: Boolean(transaction.isSubTransaction),
          parentTransactionId: transaction.parentTransactionId || null,
          amount: parseFloat(transaction.amount) || 0,
          date: transaction.date || new Date().toISOString(),
          id: transaction.id || undefined,
          description: transaction.description || '',
          receiptData: {
            ...transaction.receiptData,
            konto_matches: transaction.receiptData?.konto_matches || [],
            receipt_info: transaction.receiptData?.receipt_info || [],
            saved_matches: transaction.receiptData?.saved_matches || [],
          },
          receiptInfo: transaction.receiptInfo ? {
            ...transaction.receiptInfo,
            fileAvailable: transaction.receiptInfo.fileAvailable ?? true,
            path: transaction.receiptInfo.path ? 
              (transaction.receiptInfo.path.startsWith('/') ? transaction.receiptInfo.path : `/${transaction.receiptInfo.path}`)
              : null
          } : null
        }));
        
        await new Promise(resolve => setTimeout(resolve, 50));
        setMatchingTransactions(processedTransactions);
      }
      
      // Process and set other data with small delays to prevent state update collisions
      if (Array.isArray(sessionData.unmatched_transactions)) {
        await new Promise(resolve => setTimeout(resolve, 50));
        setUnmatchedTransactions(sessionData.unmatched_transactions);
      }
      
      if (sessionData.selected_kontos) {
        await new Promise(resolve => setTimeout(resolve, 50));
        setSelectedKontos(sessionData.selected_kontos);
      }
      
      if (sessionData.selected_tax_codes) {
        await new Promise(resolve => setTimeout(resolve, 50));
        setSelectedTaxCodes(sessionData.selected_tax_codes);
      }
      
      if (sessionData.manually_matched_receipts) {
        await new Promise(resolve => setTimeout(resolve, 50));
        setManuallyMatchedReceipts(sessionData.manually_matched_receipts);
      }
      
      if (sessionData.transaction_statuses) {
        await new Promise(resolve => setTimeout(resolve, 50));
        setTransactionStatuses(sessionData.transaction_statuses);
      }
      
      // Handle transaction splits
      if (sessionData.transaction_splits && typeof sessionData.transaction_splits === 'object') {
        for (const [transactionId, splits] of Object.entries(sessionData.transaction_splits)) {
          if (Array.isArray(splits)) {
            for (const split of splits) {
              await new Promise(resolve => setTimeout(resolve, 20));
              saveSplit(transactionId, split);
            }
          }
        }
      }
      
      // Set active step last
      if (typeof sessionData.active_step === 'number') {
        await new Promise(resolve => setTimeout(resolve, 50));
        setActiveStep(sessionData.active_step);
      }

      // Process receipt files last
      if (Array.isArray(sessionData.receipt_files)) {
        const processedFiles = sessionData.receipt_files.map(file => ({
          ...file,
          fileAvailable: file.fileAvailable ?? true,
          path: file.path ? 
            (file.path.startsWith('/') ? file.path : `/${file.path}`)
            : null
        }));
        
        await new Promise(resolve => setTimeout(resolve, 50));
        setReceiptFiles(processedFiles);
      }
      
      // Process Kassa receipts
      if (Array.isArray(sessionData.kassa_receipts)) {
        const processedKassaReceipts = sessionData.kassa_receipts.map(receipt => ({
          ...receipt,
          fileAvailable: receipt.fileAvailable ?? true,
          path: receipt.path ? 
            (receipt.path.startsWith('/') ? receipt.path : `/${receipt.path}`)
            : null
        }));
        
        await new Promise(resolve => setTimeout(resolve, 50));
        setKassaReceipts(processedKassaReceipts);
      }
      
      // Process Kredit receipts
      if (Array.isArray(sessionData.kredit_receipts)) {
        const processedKreditReceipts = sessionData.kredit_receipts.map(receipt => ({
          ...receipt,
          fileAvailable: receipt.fileAvailable ?? true,
          path: receipt.path ? 
            (receipt.path.startsWith('/') ? receipt.path : `/${receipt.path}`)
            : null
        }));
        
        await new Promise(resolve => setTimeout(resolve, 50));
        setKreditReceipts(processedKreditReceipts);
      }

      console.log('Session loading completed successfully');
      
    } catch (err) {
      console.error('Error selecting session:', err);
      setError('Fehler beim Laden der Sitzung: ' + err.message);
      
      // Reset states on error
      setMatchingTransactions([]);
      setUnmatchedTransactions([]);
      setSelectedKontos({});
      setSelectedTaxCodes({});
      setTransactionStatuses({});
      setReceiptFiles([]);
      setActiveStep(0);
      
    } finally {
      setIsLoading(false);
    }
  };

  const handleLockSession = async () => {
    try {
      setLoading(true);
      setError('');
      if (!sessionId) throw new Error('Keine gültige Session ID verfügbar.');
      const success = await lockSession(sessionId);
      if (success) {
        setSuccessMessage('Session erfolgreich gesperrt');
        // Refresh sessions list
        const data = await fetchSessionsWithRetry(isTaxConsultant ? null : clientId);
        setSessions(data);
      }
    } catch (err) {
      console.error('Lock session error:', err);
      setError(err.message || 'Fehler beim Sperren der Session');
    } finally {
      setLoading(false);
    }
  };
  
  const handleUnlockSession = async () => {
    try {
      setLoading(true);
      setError('');
      if (!sessionId) throw new Error('Keine gültige Session ID verfügbar.');
      const success = await unlockSession(sessionId);
      if (success) {
        setSuccessMessage('Session erfolgreich entsperrt');
        // Refresh sessions list
        const data = await fetchSessionsWithRetry(isTaxConsultant ? null : clientId);
        setSessions(data);
      }
    } catch (err) {
      console.error('Unlock session error:', err);
      setError(err.message || 'Fehler beim Entsperren der Session');
    } finally {
      setLoading(false);
    }
  };

  const approveSession = () => handleStatusUpdate('approved');
  const rejectSession = () => handleStatusUpdate('rejected');

  const handleCsvUpload = (event) => {
    if (isLocked) return;
    handlers.handleCsvUpload(event, {
      setCsvFile,
      setCsvTransactions,
      setSuccessMessage,
      setError
    });
  };

  const handleCamtUpload = (event) => {
    if (isLocked) return;
    handlers.handleCamtUpload(event, {
      setCamtFile,
      setCamtTransactions,
      setSuccessMessage,
      setError,
      setLoading
    });
  };

  const handleReceiptUpload = (event) => {
    if (isLocked) return;
    handlers.handleReceiptUpload(event, {
      setReceiptFiles,
      setProcessingPdf,
      setPdfProcessingProgress,
      setSuccessMessage,
      setError,
      receiptFiles
    });
  };

  const handleRemoveReceipt = (index) => {
    if (isLocked) return;
    handlers.handleRemoveReceipt(index, setReceiptFiles);
  };

  const handleKontoChange = (transactionId, konto) => {
    if (isLocked) return;
    handlers.handleKontoChange(transactionId, konto, setSelectedKontos);
  };

  const handleTaxCodeChange = (transactionId, taxCode) => {
    if (isLocked) return;
    handlers.handleTaxCodeChange(transactionId, taxCode, setSelectedTaxCodes);
  };

  const handleViewReceipt = async (receipt) => {
    try {
      console.log('Viewing receipt:', receipt);
      console.log('Receipt properties:', Object.keys(receipt));
      console.log('Receipt type:', receipt.type);
      console.log('Is PDF?', receipt.isPdf);
      console.log('Has convertedImage?', !!receipt.convertedImage);
      console.log('Has previewUrl?', !!receipt.previewUrl);
      console.log('Use original PDF?', !!receipt.useOriginalPdf);
      
      // Set a proper title for the dialog
      let dialogTitle = 'Belegvorschau';
      if (receipt.fileName || receipt.name) {
        dialogTitle = receipt.fileName || receipt.name;
        if (receipt.isPdf || (receipt.fileName && receipt.fileName.toLowerCase().endsWith('.pdf')) || 
            (receipt.name && receipt.name.toLowerCase().endsWith('.pdf'))) {
          dialogTitle += ' (PDF)';
        }
      }
      setCurrentReceiptTitle(dialogTitle);
      
      // If we already have a previewUrl (from MatchedTransactionsTable or PDF conversion), use it directly
      if (receipt.previewUrl) {
        console.log('Using provided previewUrl:', receipt.previewUrl);
        setCurrentReceiptPreviewUrl(receipt.previewUrl);
        setOpenReceiptDialog(true);
        return;
      }
      
      // For Kassa/Kredit receipts that are PDFs and should use the original PDF file
      if ((receipt.type === 'kassa' || receipt.type === 'kredit' || receipt.isKassaKredit) && receipt.isPdf && receipt.useOriginalPdf && receipt.file) {
        console.log('Using original PDF file for Kassa/Kredit receipt');
        try {
          const blob = new Blob([await receipt.file.arrayBuffer()], { type: 'application/pdf' });
          console.log('Created blob from original PDF file:', blob);
          const previewUrl = URL.createObjectURL(blob);
          console.log('Created preview URL for original PDF:', previewUrl);
          setCurrentReceiptPreviewUrl(previewUrl);
          setOpenReceiptDialog(true);
          return;
        } catch (error) {
          console.error('Error creating blob from original PDF file:', error);
        }
      }
      
      // For Kassa/Kredit receipts, check if it's a PDF with a converted image
      if ((receipt.type === 'kassa' || receipt.type === 'kredit' || receipt.isKassaKredit) && receipt.isPdf && receipt.convertedImage) {
        console.log('Using converted image for PDF Kassa/Kredit receipt');
        console.log('Converted image:', receipt.convertedImage);
        try {
          const blob = new Blob([await receipt.convertedImage.arrayBuffer()], { type: receipt.convertedImage.type });
          console.log('Created blob from convertedImage:', blob);
          const previewUrl = URL.createObjectURL(blob);
          console.log('Created preview URL:', previewUrl);
          setCurrentReceiptPreviewUrl(previewUrl);
          setOpenReceiptDialog(true);
          return;
        } catch (error) {
          console.error('Error creating blob from convertedImage:', error);
        }
      }
      
      // For Kassa/Kredit receipts with original file (non-PDF or fallback)
      if ((receipt.type === 'kassa' || receipt.type === 'kredit' || receipt.isKassaKredit) && receipt.file) {
        console.log('Using direct file for Kassa/Kredit receipt');
        console.log('File:', receipt.file);
        try {
          // If it's a PDF, we need to handle it differently
          if (receipt.file.type === 'application/pdf') {
            console.log('File is a PDF, creating direct PDF preview');
            const blob = new Blob([await receipt.file.arrayBuffer()], { type: 'application/pdf' });
            const previewUrl = URL.createObjectURL(blob);
            console.log('Created PDF preview URL:', previewUrl);
            setCurrentReceiptPreviewUrl(previewUrl);
            setOpenReceiptDialog(true);
            return;
          }
          
          // For non-PDF files
          const blob = new Blob([await receipt.file.arrayBuffer()], { type: receipt.file.type });
          console.log('Created blob from file:', blob);
          const previewUrl = URL.createObjectURL(blob);
          console.log('Created preview URL:', previewUrl);
          setCurrentReceiptPreviewUrl(previewUrl);
          setOpenReceiptDialog(true);
          return;
        } catch (error) {
          console.error('Error creating blob from file:', error);
        }
      }
      
      // For other receipts, proceed with normal flow
      if (receipt instanceof File) {
        console.log('Receipt is a File object');
        try {
          // If it's a PDF, we need to handle it differently
          if (receipt.type === 'application/pdf') {
            console.log('File is a PDF, creating direct PDF preview');
            const blob = new Blob([await receipt.arrayBuffer()], { type: 'application/pdf' });
            const previewUrl = URL.createObjectURL(blob);
            console.log('Created PDF preview URL:', previewUrl);
            setCurrentReceiptPreviewUrl(previewUrl);
            setOpenReceiptDialog(true);
            return;
          }
          
          // For non-PDF files
          const blob = new Blob([await receipt.arrayBuffer()], { type: receipt.type });
          console.log('Created blob from File object:', blob);
          const previewUrl = URL.createObjectURL(blob);
          console.log('Created preview URL:', previewUrl);
          setCurrentReceiptPreviewUrl(previewUrl);
          setOpenReceiptDialog(true);
          return;
        } catch (error) {
          console.error('Error creating blob from File object:', error);
        }
      } 
      
      // For receipt info objects
      if (receipt?.name) {
        console.log('Receipt has name property:', receipt.name);
        
        // If path is missing, construct it
        if (!receipt.path) {
          console.warn('Receipt path is missing, constructing path');
          receipt.path = `/api/workflow-sessions/receipts/${clientId}/${receipt.name}/`;
        }
        
        // Check if this is a Kassa/Kredit receipt
        const isKassaKredit = receipt.isKassaKredit || receipt.type === 'kassa' || receipt.type === 'kredit';
        console.log('Is Kassa/Kredit receipt:', isKassaKredit);
        
        console.log('Using receipt path:', receipt.path);
        const url = getReceiptUrl(clientId, receipt.name);
        console.log('Fetching receipt from:', url);
        
        try {
          const response = await fetch(url, {
            headers: getAuthHeaders()
          });
          
          if (!response.ok) {
            throw new Error(`Failed to fetch receipt: ${response.status}`);
          }
          
          const blob = await response.blob();
          console.log('Fetched blob:', blob);
          
          // Check if it's a PDF
          const isPdf = receipt.name.toLowerCase().endsWith('.pdf') || blob.type === 'application/pdf';
          console.log('Is fetched file a PDF?', isPdf);
          
          const previewUrl = URL.createObjectURL(blob);
          console.log('Created preview URL:', previewUrl);
          setCurrentReceiptPreviewUrl(previewUrl);
          setOpenReceiptDialog(true);
        } catch (error) {
          console.error('Error fetching receipt:', error);
          
          // If this is a Kassa/Kredit receipt and fetching failed, try an alternative URL
          if (isKassaKredit) {
            console.log('Trying alternative URL for Kassa/Kredit receipt');
            const alternativeUrl = `/api/workflow-sessions/kassa-kredit-receipts/${clientId}/${receipt.type}/${receipt.name}/`;
            console.log('Alternative URL:', alternativeUrl);
            try {
              const response = await fetch(alternativeUrl, {
                headers: getAuthHeaders()
              });
              
              if (!response.ok) {
                throw new Error(`Failed to fetch receipt from alternative URL: ${response.status}`);
              }
              
              const blob = await response.blob();
              console.log('Fetched blob from alternative URL:', blob);
              
              // Check if it's a PDF
              const isPdf = receipt.name.toLowerCase().endsWith('.pdf') || blob.type === 'application/pdf';
              console.log('Is fetched file from alternative URL a PDF?', isPdf);
              
              const previewUrl = URL.createObjectURL(blob);
              console.log('Created preview URL from alternative URL:', previewUrl);
              setCurrentReceiptPreviewUrl(previewUrl);
              setOpenReceiptDialog(true);
            } catch (altError) {
              console.error('Error fetching receipt from alternative URL:', altError);
              setError(`Fehler beim Laden des Belegs (auch über alternative URL): ${altError.message}`);
            }
          } else {
            setError(`Fehler beim Anzeigen des Belegs: ${error.message}`);
          }
        }
      } else {
        throw new Error('Invalid receipt data');
      }
    } catch (error) {
      console.error('Error viewing receipt:', error);
      setError(`Fehler beim Anzeigen des Belegs: ${error.message}`);
    }
  };

  // This function is needed for the MatchedTransactionsTable component
  const handleOpenReceiptDialog = async (receipt) => {
    console.log('handleOpenReceiptDialog called with:', receipt);
    
    // If the receipt already has a previewUrl, use it directly
    if (receipt.previewUrl) {
      console.log('Using provided previewUrl:', receipt.previewUrl);
      
      // Set a proper title for the dialog
      let dialogTitle = 'Belegvorschau';
      if (receipt.fileName || receipt.name) {
        dialogTitle = receipt.fileName || receipt.name;
        if (receipt.isPdf || (receipt.fileName && receipt.fileName.toLowerCase().endsWith('.pdf')) || 
            (receipt.name && receipt.name.toLowerCase().endsWith('.pdf'))) {
          dialogTitle += ' (PDF)';
        }
      }
      setCurrentReceiptTitle(dialogTitle);
      
      setCurrentReceiptPreviewUrl(receipt.previewUrl);
      setOpenReceiptDialog(true);
      return;
    }
    
    // Otherwise, use the standard handleViewReceipt function
    return handleViewReceipt(receipt);
  };

  const handleCloseReceiptDialog = () => {
    if (currentReceiptPreviewUrl) {
      URL.revokeObjectURL(currentReceiptPreviewUrl);
    }
    setOpenReceiptDialog(false);
    setCurrentReceiptPreviewUrl('');
    setCurrentReceiptTitle('Belegvorschau');
  };

  const handleOpenCsvDialog = () => setOpenCsvDialog(true);
  const handleCloseCsvDialog = () => setOpenCsvDialog(false);

  const handleOpenCamtDialog = () => setOpenCamtDialog(true);
  const handleCloseCamtDialog = () => setOpenCamtDialog(false);

  const handleNewSession = async () => {
    try {
      // Get client data to access fiscal_year_start
      let clientData = null;
      const targetClientId = isTaxConsultant ? clientId : currentUser.id;
      
      try {
        // Fetch client data to get fiscal_year_start
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tax_consultant/clients/${targetClientId}/`, {
          headers: { 'Authorization': getToken() },
        });
        
        if (response.ok) {
          clientData = await response.json();
        }
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
      
      // Determine tax year based on fiscal_year_start if available
      let taxYear = new Date().getFullYear();
      if (clientData && clientData.fiscal_year_start) {
        taxYear = new Date(clientData.fiscal_year_start).getFullYear();
      }
      
      // Create new session data
      const newSessionData = {
        client_id: targetClientId,
        tax_year: taxYear,
        client_fiscal_year_start: clientData?.fiscal_year_start || null,
        status: 'pending'
      };

      // Save new session to get a new session ID
      const savedSession = await saveSession(newSessionData);
      
      // Make sure we have a session ID
      if (!savedSession.session_id) {
        throw new Error('No session ID received from server');
      }

      // Update URL with new session ID
      const url = new URL(window.location.href);
      url.searchParams.set('session_id', savedSession.session_id);
      window.history.pushState({}, '', url.toString());

      // Update local state
      setSelectedSession({
        session_id: savedSession.session_id,
        clientName: isTaxConsultant ? `Kunde ${targetClientId}` : `Kunde ${currentUser.id}`,
        taxYear: taxYear,
        client_fiscal_year_start: clientData?.fiscal_year_start || null,
        isLocked: false,
        ...savedSession
      });
  
      // Reset all state to default values
      setCsvFile(null);
      setCamtFile(null);
      setReceiptFiles([]);
      setReceiptInfos([]);
      setCsvTransactions([]);
      setCamtTransactions([]);
      setMatchingTransactions([]);
      setUnmatchedTransactions([]);
      setSelectedKontos({});
      setSelectedTaxCodes({});
      setTransactionStatuses({});
      setActiveStep(0);
    } catch (error) {
      console.error('Failed to create new session:', error);
      // You may want to show an error message to the user here
    }
  };

  const handlePause = () => {
    setProcessingStatus('paused');
    // Add any additional pause logic here
  };

  const handleResume = () => {
    setProcessingStatus('processing');
    // Add any additional resume logic here
  };

  const handleRetry = () => {
    setProcessingStatus('processing');
    // Add retry logic here, e.g., reprocess current files
  };

  const handleManuallyMatchedReceiptUpload = async (transactionId, formData) => {
    try {
      setLoading(true);
      setError(null);
      
      // Add session ID to the formData
      formData.append('sessionId', sessionId);

      // Check if this is a receipt-less transaction
      const isReceiptless = formData.get('isReceiptless') === 'true';
      
      let response;
      let receiptData = {
        receipt_info: [],
        konto_matches: [],
        uid_number: null,
        tax_category: null
      };
      
      // Store the original file for animation
      const receiptFile = !isReceiptless ? formData.get('file') : null;
      let processedReceipt = null;
      
      // Only upload and process file if not receipt-less
      if (!isReceiptless) {
        // Get the file from the formData to process with AI first
        if (receiptFile) {
          try {
            console.log('Processing manually uploaded receipt with AI:', receiptFile.name);
            
            // Process the receipt with AI to extract tax information and konto suggestions
            processedReceipt = await processReceipt(receiptFile);
            console.log('AI processed receipt result:', processedReceipt);
            
            if (processedReceipt) {
              // Extract receipt info from the processed receipt
              if (processedReceipt.receipt_info) {
                receiptData.receipt_info = processedReceipt.receipt_info;
                
                // Convert receipt_info array to object for easier access
                const receiptInfoObj = {};
                processedReceipt.receipt_info.forEach(item => {
                  if (Array.isArray(item) && item.length === 2) {
                    receiptInfoObj[item[0]] = item[1];
                  }
                });
                
                console.log('Extracted receipt info object:', receiptInfoObj);
                
                // Extract tax category if available
                if (receiptInfoObj.tax_category) {
                  receiptData.tax_category = receiptInfoObj.tax_category;
                  console.log('Found tax category:', receiptData.tax_category);
                }
                
                // Extract UID number if available
                if (receiptInfoObj.uid_number) {
                  receiptData.uid_number = receiptInfoObj.uid_number;
                  console.log('Found UID number:', receiptData.uid_number);
                }
              }
              
              // Extract konto matches if available
              if (processedReceipt.konto_matches) {
                receiptData.konto_matches = processedReceipt.konto_matches;
                console.log('Found konto matches:', receiptData.konto_matches);
              }
            }
          } catch (error) {
            console.error('Error processing receipt with AI:', error);
            // Continue with the upload even if AI processing fails
          }
        }
        
        // Now upload the receipt
        response = await uploadManuallyMatchedReceipt(formData);
      } else {
        // For receipt-less transactions, we don't need to upload a file
        // but we still need to create a response object with the necessary data
        response = {
          file_path: null,
          comment: formData.get('comment'),
          is_private: formData.get('isPrivate') === 'true',
          is_receiptless: true
        };
      }

      // Get the filename from the path if available
      const filename = response.file_path ? response.file_path.split('/').pop() : null;

      // Update the manually matched receipts state
      setManuallyMatchedReceipts(prev => ({
        ...prev,
        [transactionId]: {
          name: filename, // Will be null for receipt-less transactions
          path: response.file_path, // Will be null for receipt-less transactions
          comment: response.comment,
          isPrivate: response.is_private,
          isReceiptless: isReceiptless
        }
      }));

      // Update transaction statuses
      setTransactionStatuses(prev => ({
        ...prev,
        [transactionId]: {
          status: 'matched',
          comment: response.comment,
          isPrivate: response.is_private,
          isReceiptless: isReceiptless,
          manuallyMatched: true // Flag to indicate this was manually matched
        }
      }));

      // Find the transaction in unmatchedTransactions
      const transaction = unmatchedTransactions.find(t => t.id === transactionId);
      
      if (transaction) {
        console.log('Found transaction to migrate:', transaction);
        
        // Move the transaction from unmatched to matching transactions
        setUnmatchedTransactions(prev => prev.filter(t => t.id !== transactionId));
        
        // Add to matching transactions with receipt info and AI-processed data
        const migratedTransaction = {
          ...transaction,
          matched: true,
          receiptInfo: {
            name: filename,
            path: response.file_path,
            comment: response.comment,
            isPrivate: response.is_private,
            isReceiptless: isReceiptless,
            fileAvailable: !isReceiptless
          },
          // Add receiptData with AI-extracted information
          receiptData: receiptData,
          manuallyMatched: true // Flag to indicate this was manually matched
        };
        
        console.log('Migrating transaction with AI data:', migratedTransaction);
        setMatchingTransactions(prev => [...prev, migratedTransaction]);
        
        // If we have AI-suggested tax category, update the selectedTaxCodes
        if (receiptData.tax_category) {
          console.log('Setting tax code from AI suggestion:', receiptData.tax_category);
          setSelectedTaxCodes(prev => ({
            ...prev,
            [transactionId]: receiptData.tax_category
          }));
        }
        
        // If we have AI-suggested konto matches, update the selectedKontos with the highest confidence match
        if (receiptData.konto_matches && receiptData.konto_matches.length > 0) {
          // Sort by confidence and take the highest
          const sortedMatches = [...receiptData.konto_matches].sort((a, b) => 
            (b.confidence || 0) - (a.confidence || 0)
          );
          
          if (sortedMatches[0] && sortedMatches[0].konto_number) {
            console.log('Setting konto from AI suggestion:', sortedMatches[0].konto_number);
            setSelectedKontos(prev => ({
              ...prev,
              [transactionId]: sortedMatches[0].konto_number
            }));
          }
        }
        
        // Show transition animation
        if (!isReceiptless) {
          // Create a receipt object for the animation
          const animationReceipt = {
            id: `manual_${transactionId}`,
            fileName: receiptFile ? receiptFile.name : 'Beleg',
            receiptInfo: receiptData.receipt_info,
            kontoMatches: receiptData.konto_matches || [],
            // Add additional fields needed for the animation
            amount: transaction.amount,
            date: transaction.date,
            partner_name: transaction.partner_name || transaction.creditorName || transaction.debtorName || 'Unbekannt',
            tax_category: receiptData.tax_category
          };
          
          console.log('Showing animation for manually uploaded receipt:', animationReceipt);
          setTransitionAnimation({
            open: true,
            receipt: animationReceipt,
            receipts: [],
            type: 'manual', // Use 'manual' as the type for manually uploaded receipts
            kontoMatches: receiptData.konto_matches || []
          });
          
          // Schedule smooth scrolling to the matched transactions table after animation completes
          setTimeout(() => {
            // Find the matched transactions table heading
            const matchedHeading = Array.from(document.querySelectorAll('h6')).find(
              el => el.textContent.includes('Zugeordnete Transaktionen')
            );
            
            if (matchedHeading) {
              // Scroll to the heading
              matchedHeading.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 3000); // Wait for animation to complete
        }
      }

      setSuccessMessage(isReceiptless ? 'Transaktion als beleglos markiert' : 'Beleg erfolgreich hochgeladen und mit KI verarbeitet');
      
      // Save the session to persist these changes
      await handleSaveSession();
    } catch (error) {
      console.error('Error uploading receipt:', error);
      setError('Fehler beim Hochladen des Belegs: ' + (error.response?.data?.error || error.message));
    } finally {
      setLoading(false);
    }
  };

  // Handler for uploading Kassa receipts
  const handleKassaReceiptUpload = async (file) => {
    await handleKassaKreditUpload(file, 'kassa', {
      setIsProcessing,
      setError,
      setKassaReceipts,
      setKreditReceipts,
      clientId
    }, sessionId);
  };
  
  // Handler for confirming Kassa receipts
  const handleKassaReceiptConfirm = (receipt, type, isBatch = false, batchReceipts = []) => {
    handleConfirmKassaKreditReceipt(receipt, 'kassa', {
      setKassaReceipts,
      setKreditReceipts,
      setMatchingTransactions,
      setSelectedKontos,
      setSelectedTaxCodes,
      setSuccessMessage,
      setError,
      setTransitionAnimation
    }, sessionId, isBatch, batchReceipts);
  };
  
  // Handler for uploading Kredit receipts
  const handleKreditReceiptUpload = async (file) => {
    await handleKassaKreditUpload(file, 'kredit', {
      setIsProcessing,
      setError,
      setKassaReceipts,
      setKreditReceipts,
      clientId
    }, sessionId);
  };
  
  // Handler for confirming Kredit receipts
  const handleKreditReceiptConfirm = (receipt, type, isBatch = false, batchReceipts = []) => {
    handleConfirmKassaKreditReceipt(receipt, 'kredit', {
      setKassaReceipts,
      setKreditReceipts,
      setMatchingTransactions,
      setSelectedKontos,
      setSelectedTaxCodes,
      setSuccessMessage,
      setError,
      setTransitionAnimation
    }, sessionId, isBatch, batchReceipts);
  };
  
  // Handle closing the transition animation
  const handleCloseTransitionAnimation = () => {
    setTransitionAnimation({
      open: false,
      receipt: null,
      receipts: [],
      type: 'kassa',
      kontoMatches: []
    });
  };

  // Move this useEffect before any conditional returns
  useEffect(() => {
    if (error) {
      // Clear error after 5 seconds
      const timer = setTimeout(() => {
        setError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error, setError]);

  // Render protection for unauthorized access
  if (!localStorage.getItem('token')) return <Navigate to="/login" />;

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error" variant="h6">
          Fehler beim Laden der Sitzung: {error}
        </Typography>
      </Box>
    );
  }

  return (
    <AnimatedContainer>
      <Typography variant="h4" gutterBottom sx={{ animation: 'fadeIn 0.8s ease-out' }}>
        Belegabgleich
      </Typography>
  
      {!selectedSession ? (
        <SessionsListWithActions
          sessions={filteredSessions}
          onSelectSession={handleSessionSelect}
          onNewSession={handleNewSession}
        />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Button
            variant="outlined"
            onClick={() => setSelectedSession(null)}
            startIcon={<ArrowBackIcon />}
          >
            Zurück zur Übersicht
          </Button>
          </Box>

          <StyledSessionControls>
            <SessionControls
              isReviewer={isReviewer}
              sessionId={sessionId}
              reviewStatus={reviewStatus}
              lastSaved={lastSaved}
              onSave={handleSaveSession}
              onApprove={approveSession}
              onReject={rejectSession}
              reviewerNotes={reviewerNotes}
              setReviewerNotes={setReviewerNotes}
              isLocked={isLocked}
              lockedBy={lockedBy}
              onLock={handleLockSession}
              onUnlock={handleUnlockSession}
              onDelete={deleteSession}
              loading={sessionLoading}
              currentUser={currentUser}
              disabled={isLocked && lockedBy !== currentUser?.id}
              savingState={savingState}
              savingProgress={savingProgress}
              sessionHistory={sessionHistory}
            />
          </StyledSessionControls>
  
          {(error || sessionError) && (
            <AnimatedAlert severity="error">
              {error || sessionError}
            </AnimatedAlert>
          )}
  
          {successMessage && (
            <AnimatedAlert severity="success" onClose={() => setSuccessMessage('')}>
              {successMessage}
            </AnimatedAlert>
          )}
  
          <AnimatedStepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </AnimatedStepper>
  
          {activeStep === 0 && (
            <AnimatedSection animation="slideDown">
              <FileUploadSection
                csvFile={csvFile}
                camtFile={camtFile}
                receiptFiles={receiptFiles}
                handleCsvUpload={handleCsvUpload}
                handleCamtUpload={(e) => handleCamtUpload(e, {
                  setCamtFile,
                  setCamtTransactions,
                  setSuccessMessage,
                  setError,
                  setLoading
                })}
                handleReceiptUpload={handleReceiptUpload}
                handleRemoveReceipt={handleRemoveReceipt}
                handleOpenCsvDialog={handleOpenCsvDialog}
                handleOpenCamtDialog={handleOpenCamtDialog}
                processingPdf={processingPdf}
                pdfProcessingProgress={pdfProcessingProgress}
                disabled={loading}
              />
  
              {((csvFile || camtFile) && receiptFiles.length > 0) && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <AnimatedButton
                    variant="contained"
                    color="primary"
                    onClick={() => handlers.processFiles({
                      setLoading,
                      setError,
                      setSuccessMessage,
                      setActiveStep,
                      setProgress,
                      setCurrentStep,
                      setTotalSteps,
                      setReceiptsProcessed,
                      setReceiptInfos,
                      setMatchingTransactions,
                      setUnmatchedTransactions,
                      setSelectedTaxCodes,
                      receiptFiles,
                      csvTransactions: csvFile ? csvTransactions : camtTransactions
                    })}
                    disabled={loading || processingPdf || (isLocked && lockedBy !== currentUser.id)}
                  >
                    Dateien verarbeiten
                  </AnimatedButton>
                </Box>
              )}
            </AnimatedSection>
          )}
  
          {loading && (
            <AnimatedSection>
              <EnhancedProgressIndicator 
                progress={progress}
                currentStep={currentStep}
                totalSteps={totalSteps}
                receiptsProcessed={receiptsProcessed}
                totalReceipts={receiptFiles.length}
                matchesFound={matchingTransactions.length}
                onPause={handlePause}
                onResume={handleResume}
                onRetry={handleRetry}
                status={processingStatus}
              />
            </AnimatedSection>
          )}
  
          {activeStep === 1 && (
            <>
              {/* This step intentionally left empty */}
            </>
          )}
  
          {activeStep === 2 && (
            <AnimatedSection>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Tooltip title={isInteractionDisabled ? "Session ist abgeschlossen" : "Session speichern"}>
                  <span>
                    <AnimatedButton
                      variant="contained"
                      color="primary"
                      startIcon={<FiSave />}
                      onClick={handleSaveSession}
                      disabled={sessionLoading || isInteractionDisabled || (isLocked && lockedBy !== currentUser.id)}
                      sx={{ mr: 1 }}
                    >
                      Session speichern
                    </AnimatedButton>
                  </span>
                </Tooltip>
                {isTaxConsultant && (
                  <Tooltip title={isLocked ? "Session entsperren" : "Session abschließen"}>
                    <AnimatedButton
                      variant="contained"
                      color={isLocked ? "warning" : "secondary"}
                      startIcon={isLocked ? <FiUnlock /> : <FiLock />}
                      onClick={isLocked ? handleUnlockSession : handleLockSession}
                      disabled={isLocked && lockedBy !== currentUser.id}
                      sx={{ mr: 1 }}
                    >
                      {isLocked ? "Entsperren" : "Abschließen"}
                    </AnimatedButton>
                  </Tooltip>
                )}
                <Tooltip title={isInteractionDisabled ? "Session ist abgeschlossen" : "BMD-Export erstellen"}>
                  <span>
                    <AnimatedButton
                      variant="contained"
                      color="primary"
                      startIcon={<FiDownload />}
                      onClick={() => handlers.handleExport('BMD', {
                        matchingTransactions,
                        unmatchedTransactions,
                        manuallyMatchedReceipts,
                        selectedTaxCodes,
                        selectedKontos,
                        transactionSplits,
                        setSuccessMessage,
                        setError
                      })}
                      disabled={isInteractionDisabled || (isLocked && lockedBy !== currentUser.id)}
                      sx={{ mr: 1 }}
                    >
                      BMD Export
                    </AnimatedButton>
                  </span>
                </Tooltip>
                <Tooltip title={isInteractionDisabled ? "Session ist abgeschlossen" : "DATEV-Export erstellen"}>
                  <AnimatedButton
                    variant="contained"
                    color="secondary"
                    startIcon={<FiDownload />}
                    onClick={() => handlers.handleExport('DATEV', {
                      matchingTransactions,
                      unmatchedTransactions,
                      manuallyMatchedReceipts,
                      selectedTaxCodes,
                      selectedKontos,
                      transactionSplits,
                      setSuccessMessage,
                      setError
                    })}
                    disabled={isInteractionDisabled || (isLocked && lockedBy !== currentUser.id)}
                  >
                    DATEV Export
                  </AnimatedButton>
                </Tooltip>
              </Box>
  
              <TransactionTitle variant="h6" gutterBottom type="matched">
                Zugeordnete Transaktionen
              </TransactionTitle>
              <TransactionBlock type="matched">
                <MatchedTransactionsTable
                  matchingTransactions={matchingTransactions}
                  receiptFiles={receiptFiles}
                  handleOpenReceiptDialog={handleOpenReceiptDialog}
                  selectedKontos={selectedKontos}
                  handleKontoChange={handleKontoChange}
                  selectedTaxCodes={selectedTaxCodes}
                  handleTaxCodeChange={handleTaxCodeChange}
                  transactionStatuses={transactionStatuses}
                  disabled={isInteractionDisabled || (isLocked && lockedBy !== currentUser.id)}
                  onSaveSplits={(transactionId, splitData) => 
                    handlers.handleSaveSplits(transactionId, splitData, {
                      setMatchingTransactions,
                      saveSplit,
                      handleSaveSession
                    })
                  }
                  transactionSplits={transactionSplits}
                  getSplitsForTransaction={getSplitsForTransaction}
                  removeSplitsForTransaction={removeSplitsForTransaction}
                  clientId={clientId}
                />
              </TransactionBlock>
  
              <TransactionTitle variant="h6" gutterBottom type="unmatched" sx={{ mt: 4 }}>
                Nicht Zugeordnete Transaktionen
              </TransactionTitle>
              <TransactionBlock type="unmatched">
                <UnmatchedTransactionsTable
                  unmatchedTransactions={unmatchedTransactions}
                  manuallyMatchedReceipts={manuallyMatchedReceipts}
                  selectedKontos={selectedKontos}
                  selectedTaxCodes={selectedTaxCodes}
                  handleKontoChange={(transactionId, konto) => 
                    handlers.handleKontoChange(transactionId, konto, setSelectedKontos)
                  }
                  handleTaxCodeChange={(transactionId, taxCode) => 
                    handlers.handleTaxCodeChange(transactionId, taxCode, setSelectedTaxCodes)
                  }
                  handleManuallyMatchedReceiptUpload={handleManuallyMatchedReceiptUpload}
                  handleViewManuallyMatchedReceipt={handleViewReceipt}
                  transactionStatuses={transactionStatuses}
                  clientId={clientId}
                  disabled={isInteractionDisabled || (isLocked && lockedBy !== currentUser.id)}
                />
              </TransactionBlock>

              {/* Only show Kassa and Kredit blocks when we have matched transactions */}
              {matchingTransactions.length > 0 && (
                <>
                  {/* Add a divider and spacing between unmatched transactions and receipt blocks */}
                  <Box sx={{ mt: 6, mb: 4 }}>
                    <Divider sx={{ 
                      borderColor: 'rgba(0, 0, 0, 0.08)',
                      borderWidth: '1px',
                      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                    }} />
                  </Box>
                  
                  {/* Kassa Block */}
                  <KassaKreditBlock
                    title="Kassa Belege"
                    receipts={kassaReceipts}
                    isProcessing={isProcessing.kassa}
                    onUpload={handleKassaReceiptUpload}
                    onViewReceipt={handleViewReceipt}
                    type="kassa"
                    disabled={isLocked && lockedBy !== currentUser.id}
                    sessionId={sessionId}
                    states={{
                      setKassaReceipts,
                      setKreditReceipts,
                      setMatchingTransactions,
                      setSelectedKontos,
                      setSelectedTaxCodes,
                      setSuccessMessage,
                      setError,
                      setTransitionAnimation
                    }}
                  />

                  {/* Kredit Block */}
                  <KassaKreditBlock
                    title="Kredit Belege"
                    receipts={kreditReceipts}
                    isProcessing={isProcessing.kredit}
                    onUpload={handleKreditReceiptUpload}
                    onViewReceipt={handleViewReceipt}
                    type="kredit"
                    disabled={isLocked && lockedBy !== currentUser.id}
                    sessionId={sessionId}
                    states={{
                      setKassaReceipts,
                      setKreditReceipts,
                      setMatchingTransactions,
                      setSelectedKontos,
                      setSelectedTaxCodes,
                      setSuccessMessage,
                      setError,
                      setTransitionAnimation
                    }}
                  />
                </>
              )}
            </AnimatedSection>
          )}
  
          <PreviewDialog
            open={openReceiptDialog}
            onClose={handleCloseReceiptDialog}
            previewUrl={currentReceiptPreviewUrl}
            title={currentReceiptTitle}
            TransitionComponent={Slide}
            TransitionProps={{ direction: "up" }}
          />
  
          <CsvPreviewDialog
            open={openCsvDialog}
            onClose={handleCloseCsvDialog}
            csvTransactions={csvTransactions}
            TransitionComponent={Slide}
            TransitionProps={{ direction: "up" }}
          />
  
          <CamtPreviewDialog
            open={openCamtDialog}
            onClose={handleCloseCamtDialog}
            camtTransactions={camtTransactions}
            TransitionComponent={Slide}
          />
  
          <Snackbar 
            open={openSnackbar} 
            autoHideDuration={5000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
              Diese Session ist abgeschlossen!
            </Alert>
          </Snackbar>
          
          {/* Add the transition animation component */}
          <TransitionAnimation
            open={transitionAnimation.open}
            onClose={handleCloseTransitionAnimation}
            receipt={transitionAnimation.receipt}
            receipts={transitionAnimation.receipts}
            type={transitionAnimation.type}
            kontoMatches={transitionAnimation.kontoMatches}
          />
        </>
      )}
    </AnimatedContainer>
  );
};

export default ReceiptMatcher;