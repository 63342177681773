import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const CamtPreviewDialog = ({ 
  open, 
  onClose, 
  camtTransactions = [], // Provide default empty array
  TransitionComponent,
  TransitionProps 
}) => {
  // Early return if no transactions
  if (!Array.isArray(camtTransactions)) {
    return null;
  }

  // Define the headers we want to display
  const displayHeaders = [
    'valutaDate',
    'bookingDate',
    'amount',
    'currency',
    'creditorName',
    'debtorName',
    'details',
    'reference',
    'iban',
    'bic'
  ];

  // Map German translations for headers
  const headerTranslations = {
    valutaDate: 'Valuta Datum',
    bookingDate: 'Buchungsdatum',
    amount: 'Betrag',
    currency: 'Währung',
    creditorName: 'Kreditor',
    debtorName: 'Debitor',
    details: 'Verwendungszweck',
    reference: 'Referenz',
    iban: 'IBAN',
    bic: 'BIC'
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
    >
      <DialogTitle>
        CAMT Vorschau
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {camtTransactions.length === 0 ? (
          <Typography variant="body1" align="center" sx={{ py: 2 }}>
            Keine CAMT-Transaktionen verfügbar
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="camt preview table">
              <TableHead>
                <TableRow>
                  {displayHeaders.map((header) => (
                    <TableCell key={header}>{headerTranslations[header] || header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {camtTransactions.map((transaction, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {displayHeaders.map((header) => (
                      <TableCell key={header}>
                        {header === 'details' || header === 'reference' ? (
                          transaction[header] ? (
                            <Tooltip title={transaction[header]}>
                              <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                                {transaction[header].length > 30 ? 
                                  `${transaction[header].substring(0, 30)}...` : 
                                  transaction[header]}
                              </Typography>
                            </Tooltip>
                          ) : '-'
                        ) : (
                          transaction[header] || '-'
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CamtPreviewDialog;