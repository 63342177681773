import { useState } from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export const useSession = () => {
  const [sessionId, setSessionId] = useState(null);
  const [sessionHistory, setSessionHistory] = useState([]);
  const [lastSaved, setLastSaved] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState(null);
  const [reviewStatus, setReviewStatus] = useState('pending');
  const [reviewerNotes, setReviewerNotes] = useState('');
  const [savingProgress, setSavingProgress] = useState(0);
  const [savingState, setSavingState] = useState('idle'); // 'idle' | 'saving' | 'success' | 'error'

  const API_URL = process.env.REACT_APP_API_URL;
  const BACKEND_URL = `${API_URL}/api/workflow-sessions`;
 
  // Add this for debugging (you can remove it later)
  console.log('Session API URL:', BACKEND_URL);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      localStorage.clear();
      window.location.href = '/login';
      throw new Error('Kein Authentifizierungstoken gefunden');
    }
    return {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json'
    };
  };

  const handleResponse = async (response, errorMessage) => {
    console.log('API Response:', {
      url: response.url,
      status: response.status,
      statusText: response.statusText
    });

    if (response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
      throw new Error('Sitzung abgelaufen');
    }
   
    if (!response.ok) {
      const errorText = await response.text();
      try {
        const errorJson = JSON.parse(errorText);
        throw new Error(errorJson.message || errorMessage);
      } catch (e) {
        throw new Error(`Serverfehler: ${response.status} - ${response.statusText}`);
      }
    }
    return await response.json();
  };

  const saveSession = async (sessionData) => {
    let progressInterval;
    try {
      setLoading(true);
      setSavingState('saving');
      setSavingProgress(0);

      if (isLocked && lockedBy !== JSON.parse(localStorage.getItem('user'))?.id) {
        throw new Error('Gesperrte Sitzung kann nicht geändert werden');
      }

      if (!sessionId && !sessionData.client_id) {
        throw new Error('Client-ID ist für neue Sitzungen erforderlich');
      }

      // Add tracking information
      const currentUser = JSON.parse(localStorage.getItem('user'));
      const trackingInfo = {
        timestamp: new Date().toISOString(),
        user: currentUser?.username || 'Unbekannter Benutzer',
        action: sessionId ? 'Aktualisierung' : 'Erstellung',
        changes: {
          previousStatus: reviewStatus,
          newStatus: sessionData.reviewStatus || reviewStatus,
          modifiedFields: Object.keys(sessionData).filter(key => 
            sessionData[key] !== undefined && 
            !['session_id', 'client_id'].includes(key)
          )
        }
      };

      // Prepare the request data
      const requestData = {
        ...sessionData,
        session_id: sessionId, // Include existing session ID if available
        tracking_info: trackingInfo,
        status_history: [
          ...(sessionData.status_history || []),
          {
            status: reviewStatus,
            timestamp: new Date().toISOString(),
            user: currentUser?.username,
            notes: reviewerNotes || 'Keine Anmerkungen'
          }
        ]
      };

      // Simulate progress for better UX
      progressInterval = setInterval(() => {
        setSavingProgress(prev => Math.min(prev + 15, 90));
      }, 300);

      console.log('Saving session with data:', requestData);

      const response = await fetch(`${BACKEND_URL}/save/`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(requestData)
      });

      const data = await handleResponse(response, 'Fehler beim Speichern der Sitzung');
      
      clearInterval(progressInterval);
      
      if (data.success) {
        setSavingProgress(100);
        setSavingState('success');
        
        // Update session ID only if it's a new session
        if (!sessionId && data.session_id) {
          setSessionId(data.session_id);
        }
        
        // Update session history
        if (data.status_history) {
          setSessionHistory(data.status_history);
        }
        
        setLastSaved(new Date());
        console.log('Sitzung erfolgreich gespeichert:', data);
        
        // Reset states after success animation
        setTimeout(() => {
          setSavingState('idle');
          setSavingProgress(0);
          setLoading(false);
        }, 1500);
        
        return data;
      }
      throw new Error(data.message || 'Fehler beim Speichern der Sitzung');
    } catch (err) {
      clearInterval(progressInterval);
      setSavingState('error');
      console.error('Fehler beim Speichern der Sitzung:', err);
      setError(err.message);
      
      setTimeout(() => {
        setSavingState('idle');
        setSavingProgress(0);
        setLoading(false);
      }, 1500);
      
      throw err;
    }
  };

  const getSessionHistory = () => {
    return sessionHistory.map(entry => ({
      ...entry,
      formattedTimestamp: format(new Date(entry.timestamp), 'dd.MM.yyyy HH:mm', { locale: de })
    }));
  };

  const loadSession = async (id) => {
    try {
      setLoading(true);
      setError(null); // Clear any previous errors
      
      const response = await fetch(`${BACKEND_URL}/load/${id}/`, {
        headers: getAuthHeaders()
      });

      const data = await handleResponse(response, 'Fehler beim Laden der Sitzung');
      console.log('Raw session data:', data);

      // Validate required data
      if (!data || typeof data !== 'object') {
        throw new Error('Ungültige Sitzungsdaten empfangen');
      }

      // Ensure all required fields exist with default values
      const processedData = {
        matching_transactions: data.matching_transactions || [],
        unmatched_transactions: data.unmatched_transactions || [],
        selected_kontos: data.selected_kontos || {},
        selected_tax_codes: data.selected_tax_codes || {},
        manually_matched_receipts: data.manually_matched_receipts || {},
        transaction_statuses: data.transaction_statuses || {},
        transaction_splits: data.transaction_splits || {},
        active_step: data.active_step || 0,
        is_locked: Boolean(data.is_locked),
        locked_by: data.locked_by || null,
        updated_at: data.updated_at || new Date().toISOString(),
        kassa_receipts: data.kassa_receipts || [],
        kredit_receipts: data.kredit_receipts || []
      };

      // Process matching transactions
      if (processedData.matching_transactions.length > 0) {
        processedData.matching_transactions = processedData.matching_transactions.map(transaction => ({
          ...transaction,
          hasSplits: Boolean(transaction.hasSplits),
          isSubTransaction: Boolean(transaction.isSubTransaction),
          parentTransactionId: transaction.parentTransactionId || null,
          amount: parseFloat(transaction.amount) || 0,
          date: transaction.date || new Date().toISOString(),
          id: transaction.id,
          description: transaction.description || '',
          // Ensure receiptInfo is properly structured
          receiptInfo: transaction.receiptInfo ? {
            ...transaction.receiptInfo,
            fileAvailable: transaction.receiptInfo.fileAvailable ?? true,
            path: transaction.receiptInfo.path ? 
              (transaction.receiptInfo.path.startsWith('/') ? transaction.receiptInfo.path : `/${transaction.receiptInfo.path}`)
              : null
          } : null
        }));
      }

      // Process transaction splits
      if (Object.keys(processedData.transaction_splits).length > 0) {
        const validTransactionIds = new Set(
          processedData.matching_transactions
            .filter(t => !t.isSubTransaction)
            .map(t => t.id)
        );

        // Remove splits for non-existent transactions
        processedData.transaction_splits = Object.fromEntries(
          Object.entries(processedData.transaction_splits)
            .filter(([transactionId]) => validTransactionIds.has(transactionId))
        );
      }

      console.log('Processed session data:', processedData);

      // Update state
      setSessionId(id);
      setIsLocked(processedData.is_locked);
      setLockedBy(processedData.locked_by);
      setLastSaved(new Date(processedData.updated_at));

      return processedData;
    } catch (err) {
      console.error('Fehler beim Laden der Sitzung:', err);
      setError(err.message || 'Fehler beim Laden der Sitzung');
      return null;
    } finally {
      setLoading(false);
    }
  };

  const lockSession = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_URL}/lock/`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ session_id: id }),
      });

      const data = await handleResponse(response, 'Fehler beim Sperren der Sitzung');
      
      if (data.success) {
        setIsLocked(true);
        setLockedBy(JSON.parse(localStorage.getItem('user'))?.id);
        return true;
      }
      throw new Error(data.message || 'Fehler beim Sperren der Sitzung');
    } catch (err) {
      console.error('Fehler beim Sperren der Sitzung:', err);
      setError(err.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const unlockSession = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_URL}/unlock/`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ session_id: id }),
      });

      const data = await handleResponse(response, 'Fehler beim Entsperren der Sitzung');

      if (data.success) {
        setIsLocked(false);
        setLockedBy(null);
        return true;
      }
      throw new Error(data.message || 'Fehler beim Entsperren der Sitzung');
    } catch (err) {
      console.error('Fehler beim Entsperren der Sitzung:', err);
      setError(err.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const listSessions = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_URL}/list/`, {
        headers: getAuthHeaders()
      });

      return await handleResponse(response, 'Fehler beim Abrufen der Sitzungen');
    } catch (err) {
      console.error('Fehler beim Auflisten der Sitzungen:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteSession = async (id) => {
    try {
      setLoading(true);
      if (isLocked) {
        throw new Error('Gesperrte Sitzung kann nicht gelöscht werden');
      }

      const response = await fetch(`${BACKEND_URL}/delete/${id}/`, {
        method: 'POST',
        headers: getAuthHeaders()
      });

      return await handleResponse(response, 'Fehler beim Löschen der Sitzung');
    } catch (err) {
      console.error('Fehler beim Löschen der Sitzung:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateSessionStatus = async (id, status, notes = '') => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_URL}/status/${id}/`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ status, notes })
      });

      const data = await handleResponse(response, 'Fehler beim Aktualisieren des Status');
      if (data.success) {
        setReviewStatus(status);
        setReviewerNotes(notes);
      }
      return data;
    } catch (err) {
      console.error('Fehler beim Aktualisieren des Status:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    sessionId,
    lastSaved,
    loading,
    error,
    isLocked,
    lockedBy,
    reviewStatus,
    reviewerNotes,
    setReviewerNotes,
    savingProgress,
    savingState,
    sessionHistory: getSessionHistory(),
    saveSession,
    loadSession,
    lockSession,
    unlockSession,
    listSessions,
    deleteSession,
    updateSessionStatus
  };
};