import { useAuth } from './useAuth';
import { useEffect } from 'react';

export const ROLES = {
    TAX_CONSULTANT: 'tax_consultant',
    CLIENT: 'client',
    REVIEWER: 'reviewer',
};

export const PERMISSIONS = {
    CAN_REVIEW: 'can_review',
    CAN_MANAGE_CLIENTS: 'can_manage_clients',
    CAN_UPLOAD_DOCUMENTS: 'can_upload_documents',
    CAN_MATCH_TRANSACTIONS: 'can_match_transactions',
};

const ROLE_PERMISSIONS = {
    [ROLES.TAX_CONSULTANT]: [
        PERMISSIONS.CAN_MANAGE_CLIENTS,
        PERMISSIONS.CAN_MATCH_TRANSACTIONS,
        PERMISSIONS.CAN_UPLOAD_DOCUMENTS,
    ],
    [ROLES.REVIEWER]: [
        PERMISSIONS.CAN_REVIEW,
        PERMISSIONS.CAN_MATCH_TRANSACTIONS,
    ],
    [ROLES.CLIENT]: [
        PERMISSIONS.CAN_UPLOAD_DOCUMENTS,
    ],
};

export const useRoleAuth = () => {
    const { currentUser, role } = useAuth();
    const userRole = role || currentUser?.role;

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            console.log('useRoleAuth Debug:', {
                currentUser,
                role,
                userRole,
                ROLES,
                checkResult: userRole === ROLES.TAX_CONSULTANT
            });
        }
    }, [currentUser, role, userRole]);
    
    const checkRole = (requiredRole) => {
        return userRole === requiredRole;
    };

    const checkPermission = (requiredPermission) => {
        const permissions = ROLE_PERMISSIONS[userRole] || [];
        return permissions.includes(requiredPermission);
    };

    const hasMultiplePermissions = (requiredPermissions) => {
        return requiredPermissions.every(permission => checkPermission(permission));
    };

    return {
        isReviewer: () => checkRole(ROLES.REVIEWER),
        isTaxConsultant: () => checkRole(ROLES.TAX_CONSULTANT),
        isClient: () => checkRole(ROLES.CLIENT),
        
        canReview: () => checkPermission(PERMISSIONS.CAN_REVIEW),
        canManageClients: () => checkPermission(PERMISSIONS.CAN_MANAGE_CLIENTS),
        canUploadDocuments: () => checkPermission(PERMISSIONS.CAN_UPLOAD_DOCUMENTS),
        canMatchTransactions: () => checkPermission(PERMISSIONS.CAN_MATCH_TRANSACTIONS),
        
        checkRole,
        checkPermission,
        hasMultiplePermissions,
        
        ROLES,
        PERMISSIONS,
        userRole,
    };
};