import React, { useState, useEffect } from 'react';
import { 
Dialog, 
DialogTitle, 
DialogContent, 
IconButton, 
Box,
SpeedDial,
SpeedDialAction,
styled,
Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
position: 'absolute',
right: theme.spacing(2),
top: theme.spacing(2),
zIndex: 1,
}));

const ScrollableContainer = styled(Box)(({ theme }) => ({
position: 'relative',
width: '100%',
height: 'calc(90vh - 64px)',
backgroundColor: theme.palette.grey[100],
borderRadius: theme.shape.borderRadius,
overflow: 'auto',
cursor: 'grab',
'&:active': {
  cursor: 'grabbing',
},
'&::-webkit-scrollbar': {
  width: '10px',
  height: '10px',
},
'&::-webkit-scrollbar-track': {
  background: theme.palette.grey[200],
  borderRadius: '5px',
},
'&::-webkit-scrollbar-thumb': {
  background: theme.palette.grey[400],
  borderRadius: '5px',
  '&:hover': {
    background: theme.palette.grey[500],
  },
},
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
position: 'relative',
minHeight: '100%',
display: 'flex',
justifyContent: 'flex-start',
alignItems: 'flex-start',
padding: theme.spacing(1),
cursor: 'grab',
'&:active': {
  cursor: 'grabbing',
}
}));

const StyledImage = styled('img')(({ zoom = 150, rotation = 0 }) => ({
maxWidth: '100%',
height: 'auto',
transform: `scale(${zoom/100}) rotate(${rotation}deg)`,
transformOrigin: 'top center',
cursor: 'grab',
userSelect: 'none',
'&:active': {
  cursor: 'grabbing',
}
}));

const ZoomIndicator = styled(Typography)(({ theme }) => ({
position: 'fixed', 
bottom: theme.spacing(4),
left: theme.spacing(4),
padding: theme.spacing(0.5, 1),
backgroundColor: 'rgba(0, 0, 0, 0.5)',
color: 'white',
borderRadius: theme.shape.borderRadius,
fontSize: '0.875rem',
zIndex: 1,
}));

const INITIAL_STATE = {
zoom: 75, 
rotation: 0,
position: { x: 0, y: 0 },
isDragging: false,
dragStart: { x: 0, y: 0 }
};

const PreviewDialog = ({ open, onClose, previewUrl, title }) => {
const [zoom, setZoom] = useState(INITIAL_STATE.zoom);
const [rotation, setRotation] = useState(INITIAL_STATE.rotation);
const [isDragging, setIsDragging] = useState(INITIAL_STATE.isDragging);
const [dragStart, setDragStart] = useState(INITIAL_STATE.dragStart);
const [position, setPosition] = useState(INITIAL_STATE.position);
const [isPdf, setIsPdf] = useState(false);

useEffect(() => {
  if (!open || previewUrl) {
    setZoom(INITIAL_STATE.zoom);
    setRotation(INITIAL_STATE.rotation);
    setIsDragging(INITIAL_STATE.isDragging);
    setDragStart(INITIAL_STATE.dragStart);
    setPosition(INITIAL_STATE.position);
    
    // Check if the URL is for a PDF
    if (previewUrl) {
      // Check if the URL contains a PDF file extension or if it's a blob URL for a PDF
      const isPdfUrl = previewUrl.toLowerCase().includes('.pdf') || 
                       (previewUrl.startsWith('blob:') && title && title.toLowerCase().endsWith('.pdf'));
      console.log('Preview URL:', previewUrl);
      console.log('Is PDF URL:', isPdfUrl);
      setIsPdf(isPdfUrl);
    } else {
      setIsPdf(false);
    }
  }
}, [open, previewUrl, title]);

const handleZoomIn = () => setZoom(prev => Math.min(prev + 25, 400));
const handleZoomOut = () => setZoom(prev => Math.max(prev - 25, 50));
const handleRotateLeft = () => setRotation(prev => prev - 90);
const handleRotateRight = () => setRotation(prev => prev + 90);

const handleFullscreen = () => {
  const element = document.documentElement;
  if (!document.fullscreenElement) {
    element.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
};

const handleDownload = () => {
  const link = document.createElement('a');
  link.href = previewUrl;
  link.download = title || 'beleg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleMouseDown = (e) => {
  if (e.button === 0) {
    e.preventDefault();
    setIsDragging(true);
    const container = document.querySelector('.scrollable-container');
    if (container) {
      setDragStart({ 
        x: e.clientX + container.scrollLeft,
        y: e.clientY + container.scrollTop
      });
    }
  }
};

const handleMouseMove = (e) => {
  if (isDragging) {
    e.preventDefault();
    const container = document.querySelector('.scrollable-container');
    if (container) {
      const dx = e.clientX - dragStart.x;
      const dy = e.clientY - dragStart.y;
      container.scrollLeft = -dx;
      container.scrollTop = -dy;
    }
  }
};

const handleMouseUp = () => {
  setIsDragging(false);
};

const handleWheel = (e) => {
  if (e.ctrlKey) {
    e.preventDefault();
    const delta = e.deltaY * -0.01;
    setZoom(prev => Math.min(Math.max(50, prev + delta * 25), 400));
  }
};

const handleClose = () => {
  setZoom(INITIAL_STATE.zoom);
  setRotation(INITIAL_STATE.rotation);
  setIsDragging(INITIAL_STATE.isDragging);
  setDragStart(INITIAL_STATE.dragStart);
  setPosition(INITIAL_STATE.position);
  onClose();
};

const actions = [
  { icon: <ZoomInIcon />, name: 'Vergrößern', onClick: handleZoomIn },
  { icon: <ZoomOutIcon />, name: 'Verkleinern', onClick: handleZoomOut },
  { icon: <RotateLeftIcon />, name: 'Nach links drehen', onClick: handleRotateLeft },
  { icon: <RotateRightIcon />, name: 'Nach rechts drehen', onClick: handleRotateRight },
  { icon: <FullscreenIcon />, name: 'Vollbild', onClick: handleFullscreen },
  { icon: <DownloadIcon />, name: 'Herunterladen', onClick: handleDownload },
];

return (
  <Dialog
    open={open}
    onClose={handleClose}
    maxWidth="lg"
    fullWidth
    PaperProps={{
      sx: { 
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiDialogTitle-root': {
          padding: '12px 24px',
          minHeight: '64px'
        }
      }
    }}
  >
    <DialogTitle>
      {title}
      <IconButton
        aria-label="schließen"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{ padding: 1, overflow: 'hidden' }}>
      <ScrollableContainer className="scrollable-container"
        onWheel={handleWheel}
      >
        {isPdf ? (
          // Render PDF using iframe
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <iframe
              src={previewUrl}
              title={title || "PDF Vorschau"}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                minHeight: '600px',
              }}
            />
          </Box>
        ) : (
          // Render image
          <ImageWrapper
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {previewUrl && (
              <StyledImage
                src={previewUrl}
                alt="Beleg"
                zoom={zoom}
                rotation={rotation}
                style={{
                  transform: `scale(${zoom/100}) rotate(${rotation}deg) translate(${position.x}px, ${position.y}px)`,
                }}
                draggable={false}
              />
            )}
          </ImageWrapper>
        )}
        <ZoomIndicator>{zoom}%</ZoomIndicator>
        <StyledSpeedDial
          ariaLabel="Bildoptionen"
          icon={<MoreVertIcon />}
          direction="down"
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
            />
          ))}
        </StyledSpeedDial>
      </ScrollableContainer>
    </DialogContent>
  </Dialog>
);
};

export default PreviewDialog;