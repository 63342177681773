import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Paper, 
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Checkbox,
  IconButton,
  Tooltip,
  Divider,
  useTheme
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { styled } from '@mui/material/styles';
import { handleConfirmKassaKreditReceipt } from './handlers';

// Styled components
const StyledPaper = styled(Paper)(({ theme, type }) => ({
  width: '100%',
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 1.2,
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  transition: 'all 0.2s ease',
  borderTop: `5px solid ${type === 'kassa' 
    ? theme.palette.primary.dark 
    : theme.palette.secondary.dark}`,
  background: type === 'kassa'
    ? 'linear-gradient(to bottom, rgba(25, 118, 210, 0.03), rgba(255, 255, 255, 1))'
    : 'linear-gradient(to bottom, rgba(156, 39, 176, 0.03), rgba(255, 255, 255, 1))',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0,0,0,0.12)',
  }
}));

const UploadButton = styled(Button)(({ theme, type }) => ({
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.2s ease',
  fontWeight: 600,
  boxShadow: type === 'kassa'
    ? '0 2px 8px rgba(25, 118, 210, 0.2)'
    : '0 2px 8px rgba(156, 39, 176, 0.2)',
  '&:hover': {
    boxShadow: type === 'kassa'
      ? '0 3px 10px rgba(25, 118, 210, 0.3)'
      : '0 3px 10px rgba(156, 39, 176, 0.3)',
  }
}));

const BlockTitle = styled(Typography)(({ theme, type }) => ({
  position: 'relative',
  display: 'inline-block',
  fontWeight: 600,
  color: type === 'kassa' ? theme.palette.primary.dark : theme.palette.secondary.dark,
  marginBottom: theme.spacing(2),
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -4,
    left: 0,
    width: '40%',
    height: 3,
    backgroundColor: type === 'kassa' 
      ? theme.palette.primary.dark 
      : theme.palette.secondary.dark,
    borderRadius: 2
  }
}));

const KassaKreditBlock = ({ 
  title, 
  receipts, 
  isProcessing, 
  onUpload, 
  onViewReceipt,
  type,
  disabled,
  sessionId,
  states
}) => {
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [error, setError] = useState('');
  const theme = useTheme();

  const handleSelectReceipt = (receiptId) => {
    setSelectedReceipts(prev => {
      if (prev.includes(receiptId)) {
        return prev.filter(id => id !== receiptId);
      } else {
        return [...prev, receiptId];
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedReceipts(receipts.map(receipt => receipt.id));
    } else {
      setSelectedReceipts([]);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      onUpload(file, type);
      // Reset the input value to allow selecting the same file again
      event.target.value = '';
    }
  };

  const handleConfirmReceipts = () => {
    if (selectedReceipts.length === 0) {
      setError('Bitte wählen Sie mindestens einen Beleg aus.');
      return;
    }

    console.log(`Confirming ${selectedReceipts.length} receipts`);
    
    // Get the full receipt objects for all selected receipt IDs
    const receiptsToProcess = receipts.filter(r => selectedReceipts.includes(r.id));
    console.log('Receipts to process:', receiptsToProcess.length);
    
    if (receiptsToProcess.length === 1) {
      // Process single receipt
      console.log('Processing single receipt');
      handleConfirmKassaKreditReceipt(
        receiptsToProcess[0],
        type,
        states,
        sessionId
      );
    } else {
      // Process multiple receipts
      console.log('Processing multiple receipts');
      handleConfirmKassaKreditReceipt(
        null,
        type,
        states,
        sessionId,
        true,
        receiptsToProcess
      );
    }
    
    // Clear selection after processing
    setSelectedReceipts([]);
  };

  const getReceiptDate = (receipt) => {
    if (receipt.receiptInfo) {
      const dateField = receipt.receiptInfo.find(
        field => field[0] === 'date' || 
                field[0] === 'invoice_date' || 
                field[0] === 'reservation_date'
      );
      
      if (dateField && dateField[1]) {
        return formatDate(dateField[1]);
      }
    }
    return 'Unbekannt';
  };

  const getReceiptAmount = (receipt) => {
    if (receipt.receiptInfo) {
      const amountField = receipt.receiptInfo.find(
        field => field[0] === 'amount_incl_vat' || 
                field[0] === 'total_amount' || 
                field[0] === 'amount'
      );
      
      if (amountField && amountField[1]) {
        return formatAmount(amountField[1]);
      }
    }
    return 'Unbekannt';
  };

  const getReceiptPartnerName = (receipt) => {
    console.log('Receipt object:', receipt);
    // Log the keys of the receipt object to better understand its structure
    console.log('Receipt object keys:', Object.keys(receipt));
    
    // If receiptInfo exists, log its structure
    if (receipt.receiptInfo) {
      console.log('receiptInfo structure:', receipt.receiptInfo);
      // Method 1: Check receiptInfo array for partner_name
      console.log('Checking receiptInfo array for partner_name');
      
      if (Array.isArray(receipt.receiptInfo)) {
        // Log all keys in receiptInfo for debugging
        receipt.receiptInfo.forEach(item => {
          if (Array.isArray(item) && item.length >= 1) {
            console.log(`receiptInfo field: ${item[0]}, value:`, item[1]);
          }
        });
        
        const partnerField = receipt.receiptInfo.find(
          field => field[0] === 'partner_name'
        );
        
        if (partnerField && partnerField[1]) {
          console.log('Found partner name in receiptInfo:', partnerField[1]);
          return partnerField[1];
        }
      }
    }
    
    // Method 2: Check if partner name is directly in the receipt object
    if (receipt.partner_name) {
      console.log('Found partner name directly in receipt object:', receipt.partner_name);
      return receipt.partner_name;
    }
    
    // Method 3: Check if there's a nested receiptData object with partner_name
    if (receipt.receiptData) {
      console.log('receiptData exists:', receipt.receiptData);
      if (receipt.receiptData.partner_name) {
        console.log('Found partner name in receiptData:', receipt.receiptData.partner_name);
        return receipt.receiptData.partner_name;
      }
    }
    
    // Method 4: Check if kontoMatches has usable information
    if (receipt.kontoMatches && receipt.kontoMatches.length > 0) {
      console.log('kontoMatches exists:', receipt.kontoMatches);
      const possiblePartner = receipt.kontoMatches[0].konto_name;
      if (possiblePartner) {
        console.log('Using konto match as fallback for partner:', possiblePartner);
        return possiblePartner;
      }
    }
    
    console.log('Partner name not found in any location, returning Unbekannt');
    return 'Unbekannt';
  };

  const getReceiptDescription = (receipt) => {
    if (receipt.receiptInfo) {
      const descriptionField = receipt.receiptInfo.find(
        field => field[0] === 'document_description' || 
                field[0] === 'description' || 
                field[0] === 'reservation_details'
      );
      
      if (descriptionField && descriptionField[1]) {
        return descriptionField[1];
      }
    }
    return receipt.name || `${type === 'kassa' ? 'Kassa' : 'Kredit'} Beleg`;
  };

  const formatDate = (dateStr) => {
    try {
      const date = new Date(dateStr);
      return format(date, 'dd.MM.yyyy', { locale: de });
    } catch (error) {
      return dateStr;
    }
  };

  const formatAmount = (amount) => {
    try {
      const numAmount = parseFloat(amount);
      return `${numAmount.toFixed(2)} €`;
    } catch (error) {
      return amount;
    }
  };

  return (
    <Box sx={{ mt: 6, mb: 4 }}>
      <BlockTitle variant="h6" gutterBottom type={type}>
        {title}
      </BlockTitle>
      
      <StyledPaper type={type}>
        <Box sx={{ p: 2.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <UploadButton
              variant="contained"
              component="label"
              startIcon={<CloudUploadIcon />}
              disabled={disabled || isProcessing}
              color={type === 'kassa' ? 'primary' : 'secondary'}
              type={type}
            >
              Beleg hochladen
              <input
                type="file"
                hidden
                accept="image/*,.pdf"
                onChange={handleFileUpload}
                disabled={disabled || isProcessing}
              />
            </UploadButton>
            
            {isProcessing && (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                backgroundColor: type === 'kassa' 
                  ? 'rgba(25, 118, 210, 0.08)' 
                  : 'rgba(156, 39, 176, 0.08)',
                padding: '8px 16px',
                borderRadius: '4px'
              }}>
                <CircularProgress size={20} color={type === 'kassa' ? 'primary' : 'secondary'} />
                <Typography 
                  variant="body2" 
                  color={type === 'kassa' ? 'primary.dark' : 'secondary.dark'}
                  sx={{ fontWeight: 500 }}
                >
                  Beleg wird verarbeitet...
                </Typography>
              </Box>
            )}
          </Box>
          
          {selectedReceipts.length > 0 && (
            <Button
              variant="outlined"
              color={type === 'kassa' ? 'primary' : 'secondary'}
              onClick={handleConfirmReceipts}
              startIcon={<CheckCircleIcon />}
              disabled={disabled}
              sx={{ 
                fontWeight: 500,
                borderWidth: '2px',
              }}
            >
              {selectedReceipts.length} bestätigen
            </Button>
          )}
        </Box>
        
        <Divider sx={{ 
          borderColor: type === 'kassa' 
            ? 'rgba(25, 118, 210, 0.1)' 
            : 'rgba(156, 39, 176, 0.1)',
          borderWidth: '1px'
        }} />
        
        {receipts && receipts.length > 0 ? (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ 
                  backgroundColor: type === 'kassa' 
                    ? 'rgba(25, 118, 210, 0.05)' 
                    : 'rgba(156, 39, 176, 0.05)'
                }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedReceipts.length > 0 && 
                        selectedReceipts.length < receipts.length
                      }
                      checked={
                        receipts.length > 0 && 
                        selectedReceipts.length === receipts.length
                      }
                      onChange={handleSelectAll}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell>Beleg</TableCell>
                  <TableCell sx={{ minWidth: '150px', backgroundColor: 'rgba(255, 235, 59, 0.1)' }}>Partner</TableCell>
                  <TableCell>Datum</TableCell>
                  <TableCell align="right">Betrag</TableCell>
                  <TableCell>Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receipts.map((receipt) => (
                  <TableRow 
                    key={receipt.id}
                    sx={{
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: type === 'kassa' 
                          ? 'rgba(25, 118, 210, 0.04)' 
                          : 'rgba(156, 39, 176, 0.04)',
                      }
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedReceipts.includes(receipt.id)}
                        onChange={() => handleSelectReceipt(receipt.id)}
                        disabled={disabled}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title={getReceiptDescription(receipt)}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <InfoIcon fontSize="small" color="action" />
                          <Typography variant="body2" noWrap sx={{ maxWidth: 150 }}>
                            {receipt.name || `Beleg ${receipt.id}`}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: 'rgba(255, 235, 59, 0.05)' }}>
                      <Typography variant="body2" noWrap sx={{ maxWidth: 150, fontWeight: 'bold' }}>
                        {getReceiptPartnerName(receipt)}
                      </Typography>
                    </TableCell>
                    <TableCell>{getReceiptDate(receipt)}</TableCell>
                    <TableCell align="right">
                      <Typography 
                        variant="body2" 
                        fontWeight="medium"
                        color={parseFloat(getReceiptAmount(receipt)) > 0 ? 'success.main' : 'error.main'}
                      >
                        {getReceiptAmount(receipt)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Beleg anzeigen">
                          <IconButton 
                            size="small" 
                            onClick={() => onViewReceipt(receipt)}
                            sx={{
                              transition: 'all 0.2s ease'
                            }}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Beleg bestätigen">
                          <IconButton 
                            size="small" 
                            onClick={() => {
                              setSelectedReceipts([receipt.id]);
                              handleConfirmReceipts();
                            }}
                            disabled={disabled}
                            sx={{
                              color: type === 'kassa' ? theme.palette.primary.dark : theme.palette.secondary.dark,
                              backgroundColor: type === 'kassa' 
                                ? 'rgba(25, 118, 210, 0.1)' 
                                : 'rgba(156, 39, 176, 0.1)',
                              transition: 'all 0.2s ease',
                              '&:hover': {
                                backgroundColor: type === 'kassa' 
                                  ? 'rgba(25, 118, 210, 0.2)' 
                                  : 'rgba(156, 39, 176, 0.2)',
                              }
                            }}
                          >
                            <CheckCircleIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography 
              variant="body2" 
              color={type === 'kassa' ? 'primary.dark' : 'secondary.dark'}
              sx={{ fontWeight: 500 }}
            >
              Keine {title} hochgeladen
            </Typography>
            <Typography 
              variant="caption" 
              color="text.secondary" 
              sx={{ display: 'block', mt: 1 }}
            >
              Laden Sie Belege hoch, um sie zu verarbeiten und zu bestätigen
            </Typography>
          </Box>
        )}
      </StyledPaper>
    </Box>
  );
};

export default KassaKreditBlock; 