import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import styled from '@emotion/styled';

// Complete default loading animation data
const defaultLoadingAnimation = {
  "v": "5.7.4",
  "fr": 29.9700012207031,
  "ip": 0,
  "op": 30.0000012219251,
  "w": 200,
  "h": 200,
  "nm": "Loading",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Circle",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [0.833],
                "y": [0.833]
              },
              "o": {
                "x": [0.167],
                "y": [0.167]
              },
              "t": 0,
              "s": [0]
            },
            {
              "t": 30.0000012219251,
              "s": [360]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [100, 100, 0],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [0, 0, 0],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [100, 100, 100],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "d": 1,
              "ty": "el",
              "s": {
                "a": 0,
                "k": [60, 60],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [0, 0],
                "ix": 3
              },
              "nm": "Ellipse Path 1",
              "mn": "ADBE Vector Shape - Ellipse",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.2, 0.4, 1, 1],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 6,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [0, 0],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [0, 0],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [100, 100],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Circle Group",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
};

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
`;

const AnimationWrapper = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressText = styled.div`
  margin-top: 16px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
`;

const ProgressBar = styled.div`
  width: 200px;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  margin-top: 12px;
  overflow: hidden;
`;

const Progress = styled.div`
  height: 100%;
  background: #4CAF50;
  width: ${props => props.progress}%;
  transition: width 0.3s ease;
`;

const SavingIndicator = ({ state, progress }) => {
  const [animationData, setAnimationData] = useState(defaultLoadingAnimation);

  useEffect(() => {
    const loadAnimation = async () => {
      if (state === 'idle') return;

      try {
        let url;
        switch (state) {
          case 'saving':
            url = 'https://assets10.lottiefiles.com/packages/lf20_qjosmr4w.json';
            break;
          case 'success':
            url = 'https://assets2.lottiefiles.com/packages/lf20_s2lryxtd.json';
            break;
          case 'error':
            url = 'https://assets5.lottiefiles.com/packages/lf20_cz6spxkx.json';
            break;
          default:
            return;
        }
        
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to load animation');
        const data = await response.json();
        
        // Validate animation data structure
        if (!data || !data.v || !data.layers || !Array.isArray(data.layers)) {
          throw new Error('Invalid animation data structure');
        }
        
        setAnimationData(data);
      } catch (error) {
        console.error('Error loading animation:', error);
        // Keep using the default animation if loading fails
      }
    };

    loadAnimation();
  }, [state]);

  const getMessage = () => {
    switch (state) {
      case 'saving':
        return 'Sitzung wird gespeichert...';
      case 'success':
        return 'Sitzung erfolgreich gespeichert!';
      case 'error':
        return 'Fehler beim Speichern';
      default:
        return '';
    }
  };

  if (state === 'idle') return null;

  return (
    <Container>
      <AnimationWrapper>
        <Lottie
          animationData={animationData}
          loop={state === 'saving'}
          autoplay
          style={{ width: '100%', height: '100%' }}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid meet',
            clearCanvas: true,
            progressiveLoad: true,
            hideOnTransparent: true
          }}
        />
      </AnimationWrapper>
      <ProgressText>{getMessage()}</ProgressText>
      {progress !== undefined && (
        <ProgressBar>
          <Progress progress={progress} />
        </ProgressBar>
      )}
    </Container>
  );
};

export default SavingIndicator;