// EnhancedProgressIndicator.jsx
import React, { useEffect, useState } from 'react';
import { 
Box, 
Typography, 
List, 
Fade, 
Stepper,
Step,
StepLabel,
StepContent,
CircularProgress,
Collapse,
Chip,
IconButton,
Tooltip,
useTheme,
styled,
keyframes
} from '@mui/material';
import { 
Description as DescriptionIcon,
CompareArrows as CompareArrowsIcon,
DoneAll as DoneAllIcon,
Speed as SpeedIcon,
Timeline as TimelineIcon,
CloudDone as CloudDoneIcon,
Error as ErrorIcon,
Pause as PauseIcon,
PlayArrow as PlayArrowIcon,
Refresh as RefreshIcon
} from '@mui/icons-material';

// Helper function to format percentages consistently
const formatPercentage = (value) => {
  return `${Math.round(value)}%`;
};

// Styled Components
const pulse = keyframes`
0% {
  transform: scale(1);
  opacity: 1;
}
50% {
  transform: scale(1.02);
  opacity: 0.9;
}
100% {
  transform: scale(1);
  opacity: 1;
}
`;

const colorChange = keyframes`
  0% { color: #3f51b5; }  /* Indigo */
  50% { color: #5c6bc0; } /* Lighter indigo */
  100% { color: #3f51b5; } /* Back to indigo */
`;

const shimmer = keyframes`
  0% { background-position: -100% 0; }
  100% { background-position: 100% 0; }
`;

const StyledProgressContainer = styled(Box)(({ theme }) => ({
background: theme.palette.background.paper,
borderRadius: theme.shape.borderRadius,
padding: theme.spacing(4),
boxShadow: '0 4px 16px rgba(0,0,0,0.08)',
position: 'relative',
overflow: 'hidden',
'&::before': {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '3px',
  background: theme.palette.primary.main
}
}));

const StyledProgressBar = styled(Box)(({ theme, value }) => ({
height: '6px',
borderRadius: '3px',
background: `linear-gradient(90deg, 
  ${theme.palette.primary.main} ${value}%, 
  ${theme.palette.grey[200]} ${value}%)`,
transition: 'all 0.3s ease-in-out',
marginBottom: theme.spacing(2)
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
position: 'absolute',
top: theme.spacing(2),
right: theme.spacing(2),
backgroundColor: status === 'error' 
  ? theme.palette.error.light 
  : status === 'paused'
  ? theme.palette.warning.light
  : theme.palette.success.light,
'& .MuiChip-label': {
  color: status === 'error' 
    ? theme.palette.error.contrastText 
    : status === 'paused'
    ? theme.palette.warning.contrastText
    : theme.palette.success.contrastText,
}
}));

const AnimatedValue = styled(Typography)(({ theme }) => ({
fontSize: '2.5rem',
fontWeight: 'bold',
color: theme.palette.primary.main,
textShadow: '1px 1px 2px rgba(0,0,0,0.08)'
}));

const AnimatedText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, 
    #3f51b5, /* Indigo */
    #5c6bc0, /* Lighter indigo */
    #3f51b5)`, /* Back to indigo */
  backgroundSize: '200% auto',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${shimmer} 3s linear infinite`,
  fontWeight: 'bold',
  display: 'inline-block'
}));

const ProcessingText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main
}));

const EnhancedProgressIndicator = ({ 
progress, 
currentStep, 
totalSteps, 
receiptsProcessed, 
totalReceipts,
matchesFound = 0,  
onPause,
onResume,
onRetry,
status = 'processing' // 'processing', 'paused', 'error', 'completed'
}) => {
const theme = useTheme();
const [displayedProgress, setDisplayedProgress] = useState(0);

useEffect(() => {
  const timer = setInterval(() => {
    setDisplayedProgress(prev => {
      if (prev < progress) {
        return Math.min(prev + 1, progress);
      }
      return prev;
    });
  }, 20);

  return () => clearInterval(timer);
}, [progress]);

const steps = [
  {
    icon: <DescriptionIcon />,
    primary: 'Belege verarbeiten',
    secondary: `${receiptsProcessed} von ${totalReceipts} verarbeitet`,
    detail: <ProcessingText component="span">Dokumentenanalyse und Texterkennung</ProcessingText>,
    metrics: {
      // speed: '2.3 Dokumente/Sekunde',
      // accuracy: '95%'
    }
  },
  {
    icon: <CompareArrowsIcon />,
    primary: 'Transaktionen zuordnen',
    secondary: 'Matching-Algorithmus anwenden',
    detail: <ProcessingText component="span">KI-basierte Zuordnung und Validierung</ProcessingText>,
    metrics: {
      matches: matchesFound > 0 ? (
        <Typography component="span">{`${matchesFound} gefunden`}</Typography>
      ) : (
        <AnimatedText component="span">Suche Übereinstimmungen...</AnimatedText>
      ),
      // confidence: '95.5%'
    }
  },
  {
    icon: <DoneAllIcon />,
    primary: 'Ergebnisse finalisieren',
    secondary: 'Abschließende Überprüfungen',
    detail: <ProcessingText component="span">Qualitätssicherung und Reporting</ProcessingText>,
    metrics: {
      validated: `${Math.floor(receiptsProcessed * 0.7)} Einträge`,
      pending: `${Math.floor(receiptsProcessed * 0.3)} ausstehend`
    }
  },
];

const getStatusIcon = () => {
  switch (status) {
    case 'error':
      return <ErrorIcon />;
    case 'paused':
      return <PauseIcon />;
    case 'completed':
      return <CloudDoneIcon />;
    default:
      return <SpeedIcon />;
  }
};

const getStatusText = () => {
  switch (status) {
    case 'error':
      return 'Fehler aufgetreten';
    case 'paused':
      return 'Pausiert';
    case 'completed':
      return 'Abgeschlossen';
    default:
      return 'In Bearbeitung';
  }
};

return (
  <Fade in={true} timeout={500}>
    <StyledProgressContainer>
      <StatusChip
        icon={getStatusIcon()}
        label={getStatusText()}
        status={status}
      />

      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
          Dokumentenverarbeitung
        </Typography>

        <Box position="relative" width="100%" mb={4}>
          <StyledProgressBar value={displayedProgress} />
          <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
            <AnimatedValue variant="h2">
              {formatPercentage(displayedProgress)}
            </AnimatedValue>
          </Box>
        </Box>

        <Box display="flex" gap={2} mb={3}>
          <Tooltip title="Verarbeitungsgeschwindigkeit">
            <Chip
              icon={<SpeedIcon />}
              label={`${formatPercentage(receiptsProcessed / totalReceipts * 100)} abgeschlossen`}
              color="primary"
              variant="outlined"
            />
          </Tooltip>
          <Tooltip title="Zeitlicher Verlauf">
            <Chip
              icon={<TimelineIcon />}
              label={`Schritt ${currentStep}/${totalSteps}`}
              color="secondary"
              variant="outlined"
            />
          </Tooltip>
        </Box>

        <Stepper activeStep={currentStep - 1} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={() => (
                  <Box sx={{ color: index < currentStep - 1 ? 'success.main' : 'grey.500' }}>
                    {step.icon}
                  </Box>
                )}
              >
                <Typography variant="subtitle1">{step.primary}</Typography>
              </StepLabel>
              <StepContent>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    {step.detail}
                  </Typography>
                  <Box display="flex" gap={1} mt={1}>
                    {Object.entries(step.metrics).map(([key, value]) => (
                      <Chip
                        key={key}
                        size="small"
                        label={React.isValidElement(value) ? value : `${key}: ${value}`}
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Box mt={3} display="flex" gap={1}>
          {status === 'processing' && (
            <Tooltip title="Verarbeitung pausieren">
              <IconButton onClick={onPause} color="primary">
                <PauseIcon />
              </IconButton>
            </Tooltip>
          )}
          {status === 'paused' && (
            <Tooltip title="Verarbeitung fortsetzen">
              <IconButton onClick={onResume} color="primary">
                <PlayArrowIcon />
              </IconButton>
            </Tooltip>
          )}
          {status === 'error' && (
            <Tooltip title="Erneut versuchen">
              <IconButton onClick={onRetry} color="error">
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Collapse in={status === 'error'}>
          <Box mt={2}>
            <Typography variant="body2" color="error" align="center">
              Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </StyledProgressContainer>
  </Fade>
);
};

export default EnhancedProgressIndicator;