import React, { useState, useEffect } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  Link,
  useLocation
} from 'react-router-dom';
import AccessGate from './components/AccessGate';
import {
  Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel,
  Paper, Grid, Container, CssBaseline, ThemeProvider, createTheme, AppBar, Toolbar,
  Avatar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Fade,
  useScrollTrigger, Zoom, Fab, Card, CardContent, CardMedia, CircularProgress, Alert, Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Lucide from 'lucide-react';
import { motion } from 'framer-motion';
import DOCSENSETaxBank from './DOCSENSETaxBank';
import { useAuth, AuthProvider } from './hooks/useAuth';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard/Dashboard';
import ClientDashboard from './components/Dashboard/ClientDashboard';
import ClientDetails from './components/Dashboard/ClientDetails';
import SessionsList from './components/SessionControls/SessionsList';
import SessionsOverview from './components/SessionsOverview';

const API_URL = process.env.REACT_APP_API_URL;
console.log('=== Environment Check ===');
console.log('API URL:', API_URL);
console.log('Environment:', process.env.NODE_ENV);
console.log('=======================');

// Custom theme with modern color palette
const theme = createTheme({
  palette: {
    primary: {
      main: '#2E3B55',
      light: '#4F5B75',
      dark: '#1A2238',
    },
    secondary: {
      main: '#FF6B6B',
      light: '#FF8E8E',
      dark: '#FF4848',
    },
    background: {
      default: '#F5F7FA',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    h1: { fontWeight: 600 },
    h2: { fontWeight: 600 },
    h3: { fontWeight: 600 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 500,
          padding: '10px 24px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        },
      },
    },
  },
});

// Styled Components
const StyledIconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 12px;
  background: ${props => props.theme.palette.background.paper};
  box-shadow: 0 4px 20px rgba(0,0,0,0.06);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 25px rgba(0,0,0,0.1);
  }
`;

const FeatureIcon = styled(motion.div)`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: linear-gradient(135deg, ${props => props.theme.palette.primary.light} 0%, ${props => props.theme.palette.primary.main} 100%);
  color: white;
  margin-bottom: 20px;
`;

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  transition: 'all 0.3s ease',
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  border: '2px solid white',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
  },
}));

const UserInfoContainer = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 8px 4px 4px',
  borderRadius: 50,
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.15)',
    transform: 'translateY(-2px)',
  },
}));

// Animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
};

// ScrollTop component and Layout component
function ScrollTop(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

const Layout = ({ children }) => {
  const { user, isAuthenticated, logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = user?.role;

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const getMenuItems = () => {
    if (userRole === 'tax_consultant') {
      return [
        { 
          text: 'Dashboard', 
          icon: <Lucide.LayoutDashboard size={24} strokeWidth={1.5} />, 
          path: '/app' 
        },
        { 
          text: 'Profil', 
          icon: <Lucide.UserCircle2 size={24} strokeWidth={1.5} />, 
          path: '/profile' 
        },
        { 
          text: 'Dokumente', 
          icon: <Lucide.FileText size={24} strokeWidth={1.5} />, 
          path: '/documents' 
        },
        { 
          text: 'Banking', 
          icon: <Lucide.Building2 size={24} strokeWidth={1.5} />, 
          path: '/banking' 
        },
      ];
    } else {
      return [
        { 
          text: 'Dashboard', 
          icon: <Lucide.LayoutDashboard size={24} strokeWidth={1.5} />, 
          path: '/client-dashboard'
        },
        { 
          text: 'Meine Dokumente', 
          icon: <Lucide.FileText size={24} strokeWidth={1.5} />, 
          path: `/app/workflow/${user?.id}`
        }
      ];
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="fixed" elevation={0} sx={{ 
        backgroundColor: 'transparent', 
        backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.9) 100%)',
        borderBottom: '1px solid rgba(0,0,0,0.08)',
        backdropFilter: 'blur(20px)',
      }}>
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ mr: 2 }}
            >
              <motion.div
                whileHover={{ rotate: 180 }}
                transition={{ duration: 0.3 }}
              >
                <Lucide.Menu size={24} strokeWidth={1.5} />
              </motion.div>
            </IconButton>
          )}
          
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography 
              variant="h6" 
              component={Link} 
              to="/" 
              sx={{ 
                textDecoration: 'none', 
                color: 'primary.main',
                fontWeight: 600,
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Lucide.FileStack size={24} strokeWidth={1.5} />
              DOCSENSE TAX Technology
            </Typography>
          </motion.div>

          <Box sx={{ flexGrow: 1 }} />

          {isAuthenticated ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <UserInfoContainer>
                <StyledAvatar>
                  {user?.email?.[0]?.toUpperCase()}
                </StyledAvatar>
                <Box sx={{ ml: 2, mr: 3 }}>
                  <Typography variant="subtitle2" sx={{ 
                    color: 'primary.main',
                    fontWeight: 600 
                  }}>
                    {user?.email?.split('@')[0]}
                  </Typography>
                  <Typography variant="caption" sx={{ 
                    color: 'text.secondary',
                    display: 'block',
                    lineHeight: 1 
                  }}>
                    {userRole === 'tax_consultant' ? 'Steuerberater' : 'Kunde'}
                  </Typography>
                </Box>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                  <IconButton
                    color="primary"
                    onClick={handleLogout}
                    sx={{ 
                      ml: 1,
                      background: 'rgba(0,0,0,0.04)',
                      '&:hover': {
                        background: 'rgba(0,0,0,0.08)',
                      }
                    }}
                  >
                    <Lucide.LogOut size={20} strokeWidth={1.5} />
                  </IconButton>
                </motion.div>
              </UserInfoContainer>
            </motion.div>
          ) : location.pathname !== '/login' && location.pathname !== '/signup' && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ display: 'flex', gap: '12px' }}
            >
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Button
                  color="primary"
                  component={Link}
                  to="/login"
                  startIcon={<Lucide.LogIn size={20} strokeWidth={1.5} />}
                  sx={{ 
                    borderRadius: '50px',
                    px: 3
                  }}
                >
                  Anmelden
                </Button>
              </motion.div>
              
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/signup"
                  startIcon={<Lucide.UserPlus size={20} strokeWidth={1.5} />}
                  sx={{ 
                    borderRadius: '50px',
                    px: 3,
                    background: 'linear-gradient(135deg, #2E3B55 0%, #4F5B75 100%)',
                    boxShadow: '0 4px 15px rgba(46,59,85,0.2)',
                    '&:hover': {
                      boxShadow: '0 6px 20px rgba(46,59,85,0.3)',
                    }
                  }}
                >
                  Registrieren
                </Button>
              </motion.div>
            </motion.div>
          )}
        </Toolbar>
      </AppBar>

      {isAuthenticated && (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box
            sx={{ 
              width: 280,
              pt: 2,
              background: 'linear-gradient(135deg, #F5F7FA 0%, #FFFFFF 100%)'
            }}
            role="presentation"
            onClick={() => setDrawerOpen(false)}
          >
            <List>
              {getMenuItems().map((item) => (
                <ListItem 
                  button 
                  key={item.text}
                  component={Link}
                  to={item.path}
                  selected={location.pathname === item.path}
                  sx={{
                    my: 0.5,
                    mx: 1,
                    borderRadius: 2,
                    '&.Mui-selected': {
                      backgroundColor: 'primary.light',
                      color: 'white',
                      '& .MuiListItemIcon-root': {
                        color: 'white',
                      }
                    }
                  }}
                >
                  <ListItemIcon sx={{ color: 'primary.main' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: { xs: 8, sm: 9 },
          pb: 4,
          px: { xs: 2, sm: 3 },
          backgroundColor: 'background.default'
        }}
      >
        {children}
      </Box>

      <ScrollTop>
        <Fab 
          color="primary" 
          size="small" 
          aria-label="scroll back to top"
          sx={{
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
            }
          }}
        >
          <Lucide.ChevronUp size={24} strokeWidth={1.5} />
        </Fab>
      </ScrollTop>
    </Box>
  );
};

// Landing Page, Login, SignUp, and App components
const LandingPage = () => {
  const features = [
    {
      title: 'Digitale Verwaltung',
      description: 'Verwalten Sie Ihre Steuerdokumente digital und sicher.',
      icon: <Lucide.FileStack size={32} strokeWidth={1.5} />,
      color: '#4CAF50'
    },
    {
      title: 'Expertenberatung',
      description: 'Direkter Zugang zu qualifizierten Steuerberatern.',
      icon: <Lucide.Users size={32} strokeWidth={1.5} />,
      color: '#2196F3'
    },
    {
      title: 'Zeitersparnis',
      description: 'Automatisierte Prozesse sparen wertvolle Zeit.',
      icon: <Lucide.Clock size={32} strokeWidth={1.5} />,
      color: '#FF9800'
    },
    {
      title: 'Sichere Daten',
      description: 'Höchste Sicherheitsstandards für Ihre Daten.',
      icon: <Lucide.Shield size={32} strokeWidth={1.5} />,
      color: '#E91E63'
    },
    {
      title: 'Smart Analytics',
      description: 'Intelligente Auswertungen Ihrer Finanzen.',
      icon: <Lucide.BarChart2 size={32} strokeWidth={1.5} />,
      color: '#9C27B0'
    },
    {
      title: 'Mobile First',
      description: 'Zugriff von allen Geräten, jederzeit.',
      icon: <Lucide.Smartphone size={32} strokeWidth={1.5} />,
      color: '#607D8B'
    }
  ];

  return (
    <Box>
      <Box
        sx={{
          background: 'linear-gradient(135deg, #2E3B55 30%, #4F5B75 90%)',
          color: 'white',
          py: 12,
          borderRadius: { xs: 0, md: 4 },
          mb: 6,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography variant="h2" gutterBottom sx={{ 
                  fontWeight: 700,
                  background: 'linear-gradient(135deg, #FFFFFF 0%, #E0E0E0 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}>
                  Ihre persönliche KI-Steuerlösung
                </Typography>
                <Typography variant="h5" sx={{ mb: 4, opacity: 0.9 }}>
                  Diskret. Sicher. Intelligent.
                </Typography>
                <motion.div 
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: theme.spacing(2) 
                  }} 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.6 }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    component={Link}
                    to="/signup"
                    sx={{ 
                      position: 'relative',
                      px: 4,
                      py: 1.5,
                      boxShadow: '0 8px 25px rgba(255,107,107,0.4)',
                      overflow: 'hidden',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: -100,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                        transition: 'all 0.6s',
                      },
                      '&:hover::before': {
                        left: '100%',
                      },
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 10px 30px rgba(255,107,107,0.5)',
                      }
                    }}
                    startIcon={<Lucide.ArrowRight size={20} strokeWidth={1.5} />}
                  >
                    Jetzt starten
                  </Button>


<Button
  variant="outlined"
  color="secondary"
  size="large"
  component={Link}
  to="/login"
  sx={{ 
    position: 'relative',
    px: 4,
    py: 1.5,
    borderWidth: 2,
    // Update these color properties for better readability
    color: 'white', // Changed from theme.palette.primary.main to white
    borderColor: 'white', // Changed from theme.palette.primary.main to white
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)', // Semi-transparent white background on hover
      color: 'white',
      borderColor: 'white',
      transform: 'translateY(-3px)',
      boxShadow: '0 4px 15px rgba(255,255,255,0.2)',
    },
    '& .MuiButton-startIcon': {
      transition: 'transform 0.3s ease',
    },
    '&:hover .MuiButton-startIcon': {
      transform: 'translateX(3px)',
    }
  }}
  startIcon={<Lucide.LogIn size={20} strokeWidth={1.5} />}
>
  Anmelden
</Button>
                </motion.div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ position: 'relative', height: '100%', minHeight: 300 }}>
                  {/* Add your hero illustration/image here */}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </motion.div>
      </Box>

      <Container maxWidth="lg">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div variants={itemVariants}>
                  <Card 
                    sx={{ 
                      height: '100%',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: '0 12px 30px rgba(0,0,0,0.12)'
                      }
                    }}
                  >
                    <CardContent>
                      <FeatureIcon
                        style={{ 
                          background: `linear-gradient(135deg, ${feature.color}40 0%, ${feature.color} 100%)`,
                          margin: '0 auto 20px auto'
                        }}
                      >
                        {feature.icon}
                      </FeatureIcon>
                      <Typography variant="h5" align="center" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        align="center" 
                        color="text.secondary"
                        sx={{ lineHeight: 1.7 }}
                      >
                        {feature.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const response = await fetch(`${API_URL}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.trim(),
          password: password
        }),
      });
  
      const data = await response.json();
      
      if (response.ok && data.token) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        
        const userRole = data.user.role;
        localStorage.setItem('userRole', userRole);
        
        login(data.user);
        
        if (userRole === 'tax_consultant') {
          navigate('/app');
        } else if (userRole === 'client') {
          navigate('/client-dashboard');
        }
      } else {
        setError(data.error || 'Invalid credentials');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 8 }}>
          <Paper elevation={3} sx={{ 
            p: 4, 
            borderRadius: 2,
            background: 'linear-gradient(135deg, #FFFFFF 0%, #F5F7FA 100%)'
          }}>
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{
              background: 'linear-gradient(135deg, #2E3B55 0%, #4F5B75 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}>
              Willkommen zurück
            </Typography>
            
            {error && (
              <Typography color="error" align="center" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}

            <TextField
              fullWidth
              margin="normal"
              label="E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoFocus
              error={!!error}
              InputProps={{
                startAdornment: <Lucide.Mail size={20} strokeWidth={1.5} style={{ marginRight: 8, color: '#2E3B55' }} />
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Passwort"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              error={!!error}
              InputProps={{
                startAdornment: <Lucide.Lock size={20} strokeWidth={1.5} style={{ marginRight: 8, color: '#2E3B55' }} />,
                endAdornment: (
                  <IconButton
                    onClick={handleTogglePassword}
                    edge="end"
                    sx={{ color: 'primary.main' }}
                  >
                    {showPassword ? 
                      <Lucide.EyeOff size={20} strokeWidth={1.5} /> : 
                      <Lucide.Eye size={20} strokeWidth={1.5} />
                    }
                  </IconButton>
                )
              }}
            />
            <motion.div whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Lucide.LogIn size={20} strokeWidth={1.5} />}
              >
                Anmelden
              </Button>
            </motion.div>
            <Box sx={{ textAlign: 'center' }}>
              <Link
                to="/signup"
                style={{
                  textDecoration: 'none',
                  color: theme.palette.primary.main,
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '4px'
                }}
              >
                <Lucide.UserPlus size={16} strokeWidth={1.5} />
                Noch kein Konto? Registrieren Sie sich hier
              </Link>
            </Box>
          </Paper>
        </Box>
      </motion.div>
    </Container>
  );
};

const SignUp = () => {
  const [taxConsultantFormData, setTaxConsultantFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    company_name: '',
    license_number: '',
    phone_number: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Password validation rules
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) errors.push('Mindestens 8 Zeichen');
    if (!/[A-Z]/.test(password)) errors.push('Mindestens ein Großbuchstabe');
    if (!/[a-z]/.test(password)) errors.push('Mindestens ein Kleinbuchstabe');
    if (!/[0-9]/.test(password)) errors.push('Mindestens eine Zahl');
    if (!/[!@#$%^&*]/.test(password)) errors.push('Mindestens ein Sonderzeichen (!@#$%^&*)');
    return errors;
  };

  // Field validation
  const validateField = (name, value) => {
    switch (name) {
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) {
          return 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
        }
        break;
      case 'phone_number':
        if (!/^[+]?[\d\s-]{8,}$/.test(value)) {
          return 'Bitte geben Sie eine gültige Telefonnummer ein';
        }
        break;
      case 'password':
        const passwordErrors = validatePassword(value);
        if (passwordErrors.length > 0) {
          return passwordErrors.join(', ');
        }
        break;
      case 'password_confirmation':
        if (value !== taxConsultantFormData.password) {
          return 'Die Passwörter stimmen nicht überein';
        }
        break;
      default:
        if (!value || value.trim() === '') {
          return 'Dieses Feld ist erforderlich';
        }
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaxConsultantFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Validate field on change
    const error = validateField(name, value);
    setFieldErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    // Validate all fields before submission
    const errors = {};
    Object.keys(taxConsultantFormData).forEach(field => {
      const error = validateField(field, taxConsultantFormData[field]);
      if (error) errors[field] = error;
    });

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      setLoading(false);
      setError('Bitte korrigieren Sie die markierten Felder.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/register_tax_consultant/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...taxConsultantFormData,
          role: 'tax_consultant'
        }),
      });

      let data = {};
      const textResponse = await response.text();
      
      if (textResponse) {
        try {
          data = JSON.parse(textResponse);
        } catch (parseError) {
          console.log('Response text:', textResponse);
          // If parsing fails but response is ok, we still treat it as success
          if (response.ok) {
            setShowSuccess(true);
            setTimeout(() => {
              navigate('/login', { 
                state: { 
                  success: true,
                  message: 'Registrierung erfolgreich! Sie können sich jetzt anmelden.' 
                } 
              });
            }, 2000);
            return;
          }
          throw new Error('Serverfehler: Ungültige Antwort vom Server');
        }
      }

      if (!response.ok) {
        if (data.errors) {
          setFieldErrors(data.errors);
          throw new Error('Bitte überprüfen Sie Ihre Eingaben.');
        } else if (data.error) {
          throw new Error(data.error);
        } else if (data.message) {
          throw new Error(data.message);
        }
        throw new Error('Ein unerwarteter Fehler ist aufgetreten.');
      }

      // Show success message and navigate
      setShowSuccess(true);
      setTimeout(() => {
        navigate('/login', { 
          state: { 
            success: true,
            message: 'Registrierung erfolgreich! Sie können sich jetzt anmelden.' 
          } 
        });
      }, 2000);

    } catch (err) {
      console.error('Registration error:', err);
      setError(err.message || 'Ein Fehler ist bei der Registrierung aufgetreten. Bitte versuchen Sie es später erneut.');
    } finally {
      setLoading(false);
    }
  };

  const formFields = [
    { 
      name: 'first_name', 
      label: 'Vorname', 
      type: 'text', 
      required: true,
      icon: <Lucide.User size={20} strokeWidth={1.5} />
    },
    { 
      name: 'last_name', 
      label: 'Nachname', 
      type: 'text', 
      required: true,
      icon: <Lucide.User size={20} strokeWidth={1.5} />
    },
    { 
      name: 'email', 
      label: 'E-Mail', 
      type: 'email', 
      required: true,
      icon: <Lucide.Mail size={20} strokeWidth={1.5} />
    },
    { 
      name: 'password', 
      label: 'Passwort', 
      type: showPassword ? 'text' : 'password', 
      required: true,
      icon: <Lucide.Lock size={20} strokeWidth={1.5} />,
      endIcon: (
        <IconButton
          onClick={handleTogglePassword}
          edge="end"
          sx={{ color: 'primary.main' }}
        >
          {showPassword ? 
            <Lucide.EyeOff size={20} strokeWidth={1.5} /> : 
            <Lucide.Eye size={20} strokeWidth={1.5} />
          }
        </IconButton>
      ),
      helperText: 'Mindestens 8 Zeichen, ein Großbuchstabe, eine Zahl und ein Sonderzeichen'
    },
    { 
      name: 'password_confirmation', 
      label: 'Passwort bestätigen', 
      type: showPassword ? 'text' : 'password', 
      required: true,
      icon: <Lucide.Lock size={20} strokeWidth={1.5} />,
      endIcon: (
        <IconButton
          onClick={handleTogglePassword}
          edge="end"
          sx={{ color: 'primary.main' }}
        >
          {showPassword ? 
            <Lucide.EyeOff size={20} strokeWidth={1.5} /> : 
            <Lucide.Eye size={20} strokeWidth={1.5} />
          }
        </IconButton>
      )
    },
    { 
      name: 'company_name', 
      label: 'Firmenname', 
      required: true,
      icon: <Lucide.Building size={20} strokeWidth={1.5} />
    },
    { 
      name: 'license_number', 
      label: 'Lizenznummer', 
      required: true,
      icon: <Lucide.Badge size={20} strokeWidth={1.5} />
    },
    { 
      name: 'phone_number', 
      label: 'Telefonnummer', 
      required: true,
      icon: <Lucide.Phone size={20} strokeWidth={1.5} />,
      helperText: 'Format: +49 123 4567890'
    }
  ];

  return (
    <Container maxWidth="md">
      <Snackbar 
        open={showSuccess} 
        autoHideDuration={2000} 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          severity="success" 
          variant="filled"
          sx={{ 
            width: '100%',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            '& .MuiAlert-icon': {
              fontSize: '24px'
            }
          }}
        >
          Registrierung erfolgreich! Sie werden weitergeleitet...
        </Alert>
      </Snackbar>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box component="form" onSubmit={handleSignUp} sx={{ mt: 8, mb: 4 }}>
          <Paper elevation={3} sx={{ 
            p: 4, 
            borderRadius: 2,
            background: 'linear-gradient(135deg, #FFFFFF 0%, #F5F7FA 100%)',
            position: 'relative',
            overflow: 'hidden'
          }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <Typography variant="h4" component="h1" gutterBottom align="center" sx={{
                background: 'linear-gradient(135deg, #2E3B55 0%, #4F5B75 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 1
              }}>
                Als Steuerberater registrieren
              </Typography>
              <Typography variant="body1" color="text.secondary" align="center" sx={{ mb: 4 }}>
                Erstellen Sie Ihr professionelles Konto
              </Typography>

              {error && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <Box sx={{ 
                    mb: 3,
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: 'error.light',
                    border: '1px solid',
                    borderColor: 'error.main',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <Lucide.AlertCircle size={20} color="error" />
                    <Typography color="error.main">
                      {error}
                    </Typography>
                  </Box>
                </motion.div>
              )}

              <Grid container spacing={3}>
                {formFields.map((field, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        type={field.type || 'text'}
                        value={taxConsultantFormData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        error={!!fieldErrors[field.name]}
                        helperText={fieldErrors[field.name] || field.helperText}
                        InputProps={{
                          startAdornment: (
                            <Box sx={{ color: 'primary.main', mr: 1, display: 'flex' }}>
                              {field.icon}
                            </Box>
                          ),
                          endAdornment: field.endIcon
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: 'primary.main',
                            },
                          },
                        }}
                      />
                    </motion.div>
                  </Grid>
                ))}
              </Grid>

              <motion.div 
                whileHover={{ scale: 1.01 }} 
                whileTap={{ scale: 0.99 }}
                style={{ marginTop: '24px' }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={loading}
                  sx={{ 
                    mt: 4, 
                    mb: 2,
                    height: 56,
                    background: 'linear-gradient(135deg, #2E3B55 0%, #4F5B75 100%)',
                    boxShadow: '0 8px 20px rgba(46,59,85,0.2)',
                    '&:hover': {
                      boxShadow: '0 12px 25px rgba(46,59,85,0.3)',
                    }
                  }}
                  startIcon={loading ? 
                    <CircularProgress size={20} color="inherit" /> : 
                    <Lucide.UserPlus size={20} strokeWidth={1.5} />
                  }
                >
                  {loading ? 'Registrierung läuft...' : 'Registrieren'}
                </Button>
              </motion.div>

              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Link
                  to="/login"
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '4px'
                  }}
                >
                  <Lucide.LogIn size={16} strokeWidth={1.5} />
                  Bereits registriert? Melden Sie sich hier an
                </Link>
              </Box>
            </motion.div>
          </Paper>
        </Box>
      </motion.div>
    </Container>
  );
};

// Remove the nested AppRoutes component and keep only one
const AppRoutes = () => {
  const { user } = useAuth();

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          
          {/* Tax Consultant Route */}
          <Route 
            path="/app" 
            element={
              <ProtectedRoute>
                {user?.role === 'tax_consultant' ? (
                  <Dashboard />
                ) : (
                  <Navigate to="/client-dashboard" replace />
                )}
              </ProtectedRoute>
            } 
          />
          
          {/* Client Route */}
          <Route 
            path="/client-dashboard" 
            element={
              <ProtectedRoute>
                {user?.role === 'client' ? (
                  <ClientDashboard />
                ) : (
                  <Navigate to="/app" replace />
                )}
              </ProtectedRoute>
            } 
          />

          {/* Client Details Route */}
          <Route 
            path="/app/clients/:clientId" 
            element={
              <ProtectedRoute>
                <ClientDetails />
              </ProtectedRoute>
            } 
          />
          {/* Shared Route for Documents */}
          <Route 
            path="/app/workflow/:clientId" 
            element={
              <ProtectedRoute>
                <DOCSENSETaxBank />
              </ProtectedRoute>
            } 
          />
          
          {/* Add this new route for sessions list */}
          <Route 
            path="/app/sessions" 
            element={
              <ProtectedRoute>
                <SessionsOverview />
              </ProtectedRoute>
            } 
          />
          
          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    </Router>
  );
};

const App = () => {
  const [hasAccess, setHasAccess] = useState(false);
  
  useEffect(() => {
    // Check if access has been granted previously
    const storedAccess = localStorage.getItem('companyAccess');
    if (storedAccess === 'granted') {
      setHasAccess(true);
    }
  }, []);
  
  if (!hasAccess) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AccessGate onAccessGranted={() => setHasAccess(true)} />
      </ThemeProvider>
    );
  }
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;