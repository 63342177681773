import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import PropTypes from 'prop-types';
import {
Table, TableHead, TableBody, TableRow, TableCell,
Paper, IconButton, Tooltip, Typography, Box, Alert, Collapse,
Chip, Dialog, DialogTitle, DialogContent, DialogActions,
TextField, Button
} from '@mui/material';
import { FiEye, FiPlus, FiTrash2, FiInfo } from 'react-icons/fi';
import KontoSelect from './KontoSelect';
import { getSuggestedKontos } from '../utils/kontoUtils';
import TaxCodeSelect from './TaxCodeSelect';
import { fetchKontos } from './ReceiptMatcher/config';
import { getReceiptUrl, getAuthHeaders } from '../services/api';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';

const DivisionBadge = styled.span`
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 8px;
  
  &.aufgeteilt {
    background-color: #e3f2fd;
    color: #1976d2;
    border: 1px solid #bbdefb;
  }
  
  &.teilbuchung {
    background-color: #e8f5e9;
    color: #388e3c;
    border: 1px solid #c8e6c9;
  }
`;

const StyledTableRow = styled(TableRow)(({ theme, isDivided, isPartial, hasParentWithSplits, needsTaxCode }) => ({
  ...(isDivided && {
    backgroundColor: alpha('#c8e6c9', 0.15),
    '&:hover': {
      backgroundColor: alpha('#c8e6c9', 0.25),
    },
    borderLeft: '4px solid #81c784',
  }),
  ...(hasParentWithSplits && {
    backgroundColor: alpha('#bbdefb', 0.15),
    '&:hover': {
      backgroundColor: alpha('#bbdefb', 0.25),
    },
    borderLeft: '4px solid #64b5f6',
  }),
  ...(isPartial && {
    backgroundColor: alpha('#c8e6c9', 0.15),
    '&:hover': {
      backgroundColor: alpha('#c8e6c9', 0.25),
    },
  }),
  ...(needsTaxCode && {
    backgroundColor: alpha('#fff9c4', 0.3),
    '&:hover': {
      backgroundColor: alpha('#fff9c4', 0.4),
    },
  }),
}));

const MatchedTransactionsTable = ({
  matchingTransactions = [],
  receiptFiles = [],
  handleOpenReceiptDialog,  // Changed from onOpenReceiptDialog to match parent
  selectedKontos = {},
  handleKontoChange,
  selectedTaxCodes = {},
  handleTaxCodeChange,
  transactionStatuses = {},
  onSaveSplits,
  disabled = false,
  clientId,
  transactionSplits = {},
  getSplitsForTransaction,
  removeSplitsForTransaction
}) => {
const [suggestedKontos, setSuggestedKontos] = useState({});
const [kontos, setKontos] = useState([]);
const [splitDialogOpen, setSplitDialogOpen] = useState(false);
const [selectedTransaction, setSelectedTransaction] = useState(null);
const [splits, setSplits] = useState({});
const [error, setError] = useState('');
const [showError, setShowError] = useState(false);
const [selectedReceipts, setSelectedReceipts] = useState([]);

// Helper function to determine if a transaction needs a tax code
const needsTaxCode = useCallback((transaction) => {
  // Check if transaction has receipt data
  if (!transaction.receiptData) return false;
  
  // Check if there's a manually selected tax code
  if (selectedTaxCodes[transaction.id]) return false;
  
  // Check if there's an AI suggestion for tax category
  if (transaction.receiptData.tax_category) return false;
  
  // If none of the above, this transaction needs a tax code
  return true;
}, [selectedTaxCodes]);

// Helper function to extract partner name from transaction
const getPartnerName = useCallback((transaction) => {
  // First check for partner_name which is set for Kassa/Kredit receipts
  if (transaction.partner_name) {
    console.log('Using partner_name from transaction:', transaction.partner_name);
    return transaction.partner_name;
  }
  
  // For debits (negative amounts), show creditor, for credits (positive) show debtor
  let partnerName = transaction.amount < 0
    ? transaction.creditorName
    : transaction.debtorName;
  
  // If no partner name, try to extract from details
  if (!partnerName && transaction.details) {
    // Clean up the detail text to get just the merchant name
    const detail = transaction.details.split('|')[0].trim();
    if (detail.startsWith('POS')) {
      // For POS transactions, get everything after the timestamp
      const parts = detail.split(' ');
      const timeIndex = parts.findIndex(p => p.includes(':'));
      if (timeIndex >= 0 && parts.length > timeIndex + 1) {
        partnerName = parts.slice(timeIndex + 1)
          .join(' ')
          .split('WIEN')[0]  // Remove city and postal code
          .trim();
      }
    } else if (detail.includes('ENI') || detail.includes('SHELL') || detail.includes('BP')) {
      // For gas stations, use the brand name
      partnerName = detail.split(' ')[0];
    }
  }
  
  // Also check in receiptData if available
  if (!partnerName && transaction.receiptData && transaction.receiptData.partner_name) {
    console.log('Using partner_name from receiptData:', transaction.receiptData.partner_name);
    return transaction.receiptData.partner_name;
  }
  
  // Fallback to partner field or Unbekannt
  return partnerName || transaction.partner || 'Unbekannt';
}, []);

useEffect(() => {
  const loadKontos = async () => {
    if (clientId) {
      const data = await fetchKontos(clientId);
      setKontos(data);
    }
  };
  loadKontos();
}, [clientId]);

useEffect(() => {
  console.log('matchingTransactions updated:', matchingTransactions);
  const splitTransactions = matchingTransactions.filter(t => t.isSubTransaction);
  const parentTransactions = matchingTransactions.filter(t => t.hasSplits);
  console.log('Split transactions:', splitTransactions);
  console.log('Parent transactions:', parentTransactions);
}, [matchingTransactions]);

useEffect(() => {
  matchingTransactions.forEach(transaction => {
    if (transaction?.id && !suggestedKontos[transaction.id]) {
      const suggestions = getSuggestedKontos(transaction, kontos);
      setSuggestedKontos(prev => ({
        ...prev,
        [transaction.id]: suggestions
      }));
    }
  });
}, [matchingTransactions, kontos]);

useEffect(() => {
  if (error) {
    setShowError(true);
    const timer = setTimeout(() => {
      setShowError(false);
      setError('');
    }, 5000);
    return () => clearTimeout(timer);
  }
}, [error]);

const handleSplitChange = (index, field, value) => {
  setSplits(prev => ({
    ...prev,
    [selectedTransaction.id]: prev[selectedTransaction.id].map((split, i) => 
      i === index ? { ...split, [field]: value } : split
    )
  }));
};

const addNewSplit = () => {
  setSplits(prev => ({
    ...prev,
    [selectedTransaction.id]: [
      ...(prev[selectedTransaction.id] || []),
      { amount: '', konto: '', taxCode: '' }
    ]
  }));
};

const removeSplit = (index) => {
  setSplits(prev => ({
    ...prev,
    [selectedTransaction.id]: prev[selectedTransaction.id].filter((_, i) => i !== index)
  }));
};

const handleSplitDialogOpen = (transaction) => {
  console.log('Opening split dialog for transaction:', transaction);
  setSelectedTransaction(transaction);
  setSplits({
    [transaction.id]: [{ amount: '', konto: '', taxCode: '' }]
  });
  setSplitDialogOpen(true);
};

const handleSaveSplits = () => {
  console.log('Starting handleSaveSplits with:', {
    selectedTransaction,
    splits: splits[selectedTransaction?.id]
  });

  if (!selectedTransaction || !splits[selectedTransaction.id]) return;

  const totalSplit = splits[selectedTransaction.id]
    .reduce((sum, split) => sum + Number(split.amount), 0);

  console.log('Calculated total split:', totalSplit);
  console.log('Original transaction amount:', selectedTransaction.amount);

  if (Math.abs(totalSplit - selectedTransaction.amount) > 0.01) {
    setError('Summe der Aufteilung muss dem Ursprungsbetrag entsprechen');
    return;
  }

  const newSplits = splits[selectedTransaction.id].map((split, index) => ({
    ...selectedTransaction,
    id: `${selectedTransaction.id}_split_${index}`,
    amount: parseFloat(split.amount),
    konto: split.konto,
    taxCode: split.taxCode,
    isSubTransaction: true,
    parentTransactionId: selectedTransaction.id,
    // Ensure these properties are preserved
    date: selectedTransaction.date,
    partner_name: selectedTransaction.partner_name,
    partner_iban: selectedTransaction.partner_iban,
    partner_account_number: selectedTransaction.partner_account_number
  }));

  console.log('Created new splits:', newSplits);

  const dataToSave = {
    originalTransaction: {
      ...selectedTransaction,
      hasSplits: true,  // Explicitly set this flag
      id: selectedTransaction.id  // Ensure ID is preserved
    },
    splits: newSplits
  };

  console.log('Sending to onSaveSplits:', dataToSave);
  
  onSaveSplits(selectedTransaction.id, dataToSave);

  setSplitDialogOpen(false);
  setSplits({});
};

const handleReceiptClick = async (receipt) => {
  console.log('handleReceiptClick called with:', receipt);
  console.log('selectedReceipts:', selectedReceipts);
  try {
    if (!handleOpenReceiptDialog) {
      console.error('handleOpenReceiptDialog prop is not defined');
      setError('handleOpenReceiptDialog prop is not defined');
      return;
    }
    
    // If we already have a previewUrl, use it directly
    if (receipt.previewUrl) {
      console.log('Using existing previewUrl:', receipt.previewUrl);
      handleOpenReceiptDialog(receipt);
      return;
    }
    
    // If this is a PDF with a converted image
    if (receipt.isPdf && receipt.convertedImage) {
      console.log('Using converted image for PDF receipt');
      try {
        const blob = new Blob([await receipt.convertedImage.arrayBuffer()], { type: receipt.convertedImage.type });
        const previewUrl = URL.createObjectURL(blob);
        handleOpenReceiptDialog({
          ...receipt,
          previewUrl
        });
        return;
      } catch (error) {
        console.warn('Failed to use converted image, falling back to other methods:', error);
      }
    }
    
    if (receipt instanceof File) {
      console.log('Receipt is a File object');
      handleOpenReceiptDialog(receipt);
    } else if (receipt?.name) {  
      console.log('Receipt info found:', receipt);
      console.log('Full receipt object:', JSON.stringify(receipt, null, 2));
      
      // If path is missing, construct it
      if (!receipt.path) {
        console.warn('Receipt path is missing, constructing path');
        receipt.path = `/api/workflow-sessions/receipts/${clientId}/${receipt.name}/`;
      }
      
      // Check if this is a Kassa/Kredit receipt
      const isKassaKredit = receipt.isKassaKredit || receipt.type === 'kassa' || receipt.type === 'kredit';
      console.log('Is Kassa/Kredit receipt:', isKassaKredit);
      
      // For PDFs that should use the original file
      if (isKassaKredit && receipt.isPdf && receipt.useOriginalPdf && receipt.originalFile) {
        console.log('Using original PDF file for Kassa/Kredit receipt');
        try {
          const blob = new Blob([await receipt.originalFile.arrayBuffer()], { type: 'application/pdf' });
          const previewUrl = URL.createObjectURL(blob);
          handleOpenReceiptDialog({
            ...receipt,
            previewUrl
          });
          return;
        } catch (error) {
          console.warn('Failed to use original PDF file, falling back to server fetch:', error);
        }
      }
      
      // If we have the original file for Kassa/Kredit receipts, use it directly
      if (isKassaKredit && receipt.originalFile) {
        console.log('Using original file for Kassa/Kredit receipt');
        try {
          const blob = new Blob([await receipt.originalFile.arrayBuffer()], { type: receipt.originalFile.type });
          const previewUrl = URL.createObjectURL(blob);
          handleOpenReceiptDialog({
            ...receipt,
            previewUrl
          });
          return;
        } catch (error) {
          console.warn('Failed to use original file, falling back to server fetch:', error);
          // Continue with normal flow if original file access fails
        }
      }
      
      console.log('Using receipt path:', receipt.path);
      const url = getReceiptUrl(clientId, receipt.name);
      console.log('Fetching receipt from:', url);
      
      // Get fresh token from localStorage
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in localStorage');
        setError('No authentication token found. Please log in again.');
        return;
      }
      
      try {
        const response = await fetch(url, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch receipt: ${response.status}`);
        }
        
        const blob = await response.blob();
        const previewUrl = URL.createObjectURL(blob);
        
        // Check if it's a PDF
        const isPdf = receipt.name.toLowerCase().endsWith('.pdf') || blob.type === 'application/pdf';
        
        handleOpenReceiptDialog({
          ...receipt,
          previewUrl,
          isPdf
        });
      } catch (error) {
        console.error('Error fetching receipt:', error);
        
        // If this is a Kassa/Kredit receipt and fetching failed, try an alternative URL
        if (isKassaKredit) {
          console.log('Trying alternative URL for Kassa/Kredit receipt');
          const alternativeUrl = `/api/workflow-sessions/kassa-kredit-receipts/${clientId}/${receipt.type}/${receipt.name}/`;
          try {
            const response = await fetch(alternativeUrl, {
              headers: {
                'Authorization': `Token ${token}`
              }
            });
            
            if (!response.ok) {
              throw new Error(`Failed to fetch receipt from alternative URL: ${response.status}`);
            }
            
            const blob = await response.blob();
            const previewUrl = URL.createObjectURL(blob);
            
            // Check if it's a PDF
            const isPdf = receipt.name.toLowerCase().endsWith('.pdf') || blob.type === 'application/pdf';
            
            handleOpenReceiptDialog({
              ...receipt,
              previewUrl,
              isPdf
            });
          } catch (altError) {
            console.error('Error fetching receipt from alternative URL:', altError);
            setError(`Failed to load receipt: ${altError.message}`);
          }
        } else {
          setError(`Failed to load receipt: ${error.message}`);
        }
      }
    } else {
      console.error('Invalid receipt data:', receipt);
      setError('Invalid receipt data');
    }
  } catch (error) {
    console.error('Error in handleReceiptClick:', error);
    setError(`Error viewing receipt: ${error.message}`);
  }
};

const transactionsWithoutTax = matchingTransactions.filter(transaction => needsTaxCode(transaction)).length;

const StatusChip = ({ label, variant = 'success' }) => (
  <Chip
    label={label}
    size="small"
    sx={{
      backgroundColor: 
        variant === 'success' ? 'rgba(52, 168, 83, 0.15)' :  // Soft green with transparency
        variant === 'teilbuchung' ? 'rgba(76, 175, 80, 0.15)' :  // Soft green for Teilbuchung
        variant === 'aufgeteilt' ? 'rgba(25, 118, 210, 0.15)' :  // Soft blue for Aufgeteilt
        variant === 'manual' ? 'rgba(156, 39, 176, 0.15)' :  // Soft purple for manual matches
        variant === 'receiptless' ? 'rgba(255, 152, 0, 0.15)' :  // Soft orange for receipt-less
        'rgba(234, 67, 53, 0.15)',  // Soft red with transparency (default error)
      color: 
        variant === 'success' ? '#1b5e20' :  // Darker green for text
        variant === 'teilbuchung' ? '#388e3c' :  // Darker green for Teilbuchung
        variant === 'aufgeteilt' ? '#1976d2' :  // Darker blue for Aufgeteilt
        variant === 'manual' ? '#7b1fa2' :  // Darker purple for manual matches
        variant === 'receiptless' ? '#e65100' :  // Darker orange for receipt-less
        '#d32f2f',  // Darker red for text (default error)
      border: 'none',
      borderRadius: '16px',
      padding: '0 12px',
      height: '24px',
      '& .MuiChip-label': {
        fontWeight: 500,
        fontSize: '0.75rem',
        lineHeight: '1.2'
      },
      boxShadow: 
        variant === 'success' ? '0 0 0 1px rgba(52, 168, 83, 0.2)' :  // Subtle green glow
        variant === 'teilbuchung' ? '0 0 0 1px rgba(76, 175, 80, 0.2)' :  // Subtle green glow for Teilbuchung
        variant === 'aufgeteilt' ? '0 0 0 1px rgba(25, 118, 210, 0.2)' :  // Subtle blue glow for Aufgeteilt
        variant === 'manual' ? '0 0 0 1px rgba(156, 39, 176, 0.2)' :  // Subtle purple glow for manual matches
        variant === 'receiptless' ? '0 0 0 1px rgba(255, 152, 0, 0.2)' :  // Subtle orange glow for receipt-less
        '0 0 0 1px rgba(234, 67, 53, 0.2)',  // Subtle red glow (default error)
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: 
          variant === 'success' ? 'rgba(52, 168, 83, 0.25)' :  // Slightly darker on hover
          variant === 'teilbuchung' ? 'rgba(76, 175, 80, 0.25)' :  // Slightly darker on hover for Teilbuchung
          variant === 'aufgeteilt' ? 'rgba(25, 118, 210, 0.25)' :  // Slightly darker on hover for Aufgeteilt
          variant === 'manual' ? 'rgba(156, 39, 176, 0.25)' :  // Slightly darker on hover for manual matches
          variant === 'receiptless' ? 'rgba(255, 152, 0, 0.25)' :  // Slightly darker on hover for receipt-less
          'rgba(234, 67, 53, 0.25)'  // Slightly darker on hover (default error)
      }
    }}
  />
);

if (!matchingTransactions?.length) {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="body2" color="text.secondary">
        Keine zugeordneten Transaktionen gefunden
      </Typography>
    </Box>
  );
}

return (
  <Box sx={{ width: '100%' }}>
    <Collapse in={showError}>
      <Alert 
        severity="error" 
        onClose={() => setShowError(false)}
        sx={{ mb: 2 }}
      >
        {error}
      </Alert>
    </Collapse>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {transactionsWithoutTax > 0 && (
        <Alert severity="warning" sx={{ borderRadius: 0 }}>
          {transactionsWithoutTax} Transaktion(en) benötigen noch eine manuelle Steuerkategorie
        </Alert>
      )}
      <Box sx={{ overflowX: 'auto', width: '100%' }}>
        <Table size="small" sx={{ minWidth: 1200 }}>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Partner</TableCell>
              <TableCell>Partner-UID</TableCell>
              <TableCell>Verwendungszweck</TableCell>
              <TableCell>Referenz</TableCell>
              <TableCell align="right">Betrag</TableCell>
              <TableCell>Konto</TableCell>
              <TableCell>Steuer</TableCell>
              <TableCell>Beleg</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchingTransactions.map((transaction) => {
              const transactionStatus = transactionStatuses[transaction.id];
              const isPrivateTransaction = transactionStatus?.isPrivate;
              const isSplitTransaction = transaction.isSubTransaction;
              const hasParentWithSplits = transaction.hasSplits;

              return (
                <StyledTableRow 
                  key={transaction.id}
                  isDivided={isSplitTransaction}
                  isPartial={isPrivateTransaction}
                  hasParentWithSplits={hasParentWithSplits}
                  needsTaxCode={needsTaxCode(transaction)}
                  sx={{
                    opacity: 1 // Remove the opacity reduction
                  }}
                >
                  <TableCell>
                    {(() => {
                      // Try valutaDate first, then date as fallback
                      const dateStr = transaction.valutaDate || transaction.date;
                      if (!dateStr) return 'Kein Datum';
                      try {
                        return format(new Date(dateStr), 'dd.MM.yyyy', { locale: de });
                      } catch (e) {
                        return dateStr;
                      }
                    })()}
                  </TableCell>
                  <TableCell>
                    {isSplitTransaction ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">{getPartnerName(transaction)}</Typography>
                        <DivisionBadge className="teilbuchung">Teilbuchung</DivisionBadge>
                      </Box>
                    ) : transaction.hasSplits ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">{getPartnerName(transaction)}</Typography>
                        <DivisionBadge className="aufgeteilt">Aufgeteilt</DivisionBadge>
                      </Box>
                    ) : (
                      <Typography variant="body2">{getPartnerName(transaction)}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {(() => {
                      console.log('Receipt Data for transaction:', transaction.id, transaction.receiptData);
                      
                      // Check for UID in receipt_info array
                      let uidFromReceiptInfo = null;
                      if (transaction.receiptData && Array.isArray(transaction.receiptData.receipt_info)) {
                        const uidEntry = transaction.receiptData.receipt_info.find(entry => 
                          Array.isArray(entry) && entry[0] === 'uid_number'
                        );
                        if (uidEntry) {
                          uidFromReceiptInfo = uidEntry[1];
                        }
                      }
                      
                      // Check all possible locations for the UID
                      const uid = transaction.uid_number || 
                                 (transaction.receiptData && transaction.receiptData.uid_number) ||
                                 (transaction.receiptData && transaction.receiptData.receiptData && transaction.receiptData.receiptData.uid_number) ||
                                 uidFromReceiptInfo ||
                                 '-';
                      
                      console.log('Final UID value:', uid);
                      return uid;
                    })()}
                  </TableCell>
                  <TableCell>
                    {transaction.details ? (
                      <Tooltip title={transaction.details}>
                        <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                          {transaction.details.length > 30 ? 
                            `${transaction.details.substring(0, 30)}...` : 
                            transaction.details}
                        </Typography>
                      </Tooltip>
                    ) : '-'}
                  </TableCell>
                  <TableCell>
                    {transaction.reference ? (
                      <Tooltip title={transaction.reference}>
                        <Typography variant="body2" noWrap sx={{ maxWidth: 150 }}>
                          {transaction.reference.length > 20 ? 
                            `${transaction.reference.substring(0, 20)}...` : 
                            transaction.reference}
                        </Typography>
                      </Tooltip>
                    ) : '-'}
                  </TableCell>
                  <TableCell align="right">
                    {parseFloat(transaction.amount).toFixed(2)} €
                  </TableCell>
                  <TableCell>
                    <Box>
                      {/* Debug logging */}
                      {console.log('Transaction before KontoSelect:', {
                        id: transaction.id,
                        receiptData: transaction.receiptData,
                        konto_matches: transaction.receiptData?.konto_matches
                      })}
                      
                      {/* Manual Selection with AI Suggestions */}
                      <KontoSelect
                        transaction={{
                          ...transaction,
                          receiptData: {
                            ...transaction.receiptData,
                            konto_matches: transaction.receiptData?.konto_matches?.map(match => ({
                              konto_number: Number(match.konto_number),
                              konto_name: match.konto_name,
                              confidence: Number(match.confidence)
                            })) || []
                          }
                        }}
                        selectedKontos={selectedKontos}
                        handleKontoChange={handleKontoChange}
                        suggestedKontos={suggestedKontos}
                        clientId={clientId}
                        disabled={disabled || (hasParentWithSplits && !isSplitTransaction)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <TaxCodeSelect 
                      transaction={transaction}
                      selectedTaxCodes={selectedTaxCodes}
                      handleTaxCodeChange={handleTaxCodeChange}
                      disabled={disabled || (hasParentWithSplits && !isSplitTransaction)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {(transaction.receiptIndex !== undefined && receiptFiles[transaction.receiptIndex]) || 
                       (transaction.receiptInfo && !transaction.receiptInfo.isReceiptless) ? (
                        <>
                          <Tooltip title="Beleg anzeigen">
                            <IconButton
                              size="small"
                              onClick={() => {
                                console.log('Clicked eye icon for transaction:', transaction);
                                console.log('Receipt files:', receiptFiles);
                                console.log('Receipt info:', transaction.receiptInfo);
                                
                                // First try to get the receipt from receiptFiles, then fall back to receiptInfo
                                const receipt = transaction.receiptIndex !== undefined && receiptFiles[transaction.receiptIndex]
                                  ? receiptFiles[transaction.receiptIndex]
                                  : transaction.receiptInfo;
                                  
                                console.log('Selected receipt:', receipt);
                                handleReceiptClick(receipt);
                              }}
                            >
                              <FiEye />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={(transaction.receiptIndex !== undefined && receiptFiles[transaction.receiptIndex]?.name) || 
                             transaction.receiptInfo?.name || 
                             'Beleg'}>
                            <IconButton
                              size="small"
                            >
                              <FiInfo />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : transaction.receiptInfo?.isReceiptless ? (
                        <Tooltip title={transaction.receiptInfo.comment || "Beleglose Transaktion"}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                              Beleglos: {transaction.receiptInfo.comment ? 
                                (transaction.receiptInfo.comment.length > 20 ? 
                                  transaction.receiptInfo.comment.substring(0, 20) + '...' : 
                                  transaction.receiptInfo.comment) : 
                                ''}
                            </Typography>
                            <IconButton size="small">
                              <FiInfo />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      ) : (
                        'Kein Beleg'
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {isPrivateTransaction ? (
                      <StatusChip label="Privat" variant="manual" />
                    ) : transaction.receiptInfo?.isReceiptless ? (
                      <StatusChip label="Beleglos" variant="receiptless" />
                    ) : transaction.manuallyMatched ? (
                      <StatusChip label="Manuell abgeglichen" variant="manual" />
                    ) : (
                      <StatusChip label="Automatisch abgeglichen" variant="success" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {!isSplitTransaction && !hasParentWithSplits && !disabled && (
                        <Tooltip title="Buchung aufteilen">
                          <IconButton
                            size="small" 
                            onClick={() => handleSplitDialogOpen(transaction)}
                          >
                            <FiPlus />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Paper>

    <Dialog 
      open={splitDialogOpen} 
      onClose={() => setSplitDialogOpen(false)} 
      maxWidth="md" 
      fullWidth
    >
      <DialogTitle>Buchung aufteilen</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">
            Ursprungsbetrag: {selectedTransaction?.amount} €
          </Typography>
        </Box>

        {splits[selectedTransaction?.id]?.map((split, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
            <TextField
              label="Betrag"
              type="number"
              value={split.amount}
              onChange={(e) => handleSplitChange(index, 'amount', e.target.value)}
              size="small"
              sx={{ width: '150px' }}
            />
            <KontoSelect
              transaction={selectedTransaction}
              selectedKontos={{ [selectedTransaction?.id]: split.konto }}
              handleKontoChange={(id, value) => handleSplitChange(index, 'konto', value)}
              suggestedKontos={{}}
              allKontos={kontos}
            />
            <TaxCodeSelect
              transaction={selectedTransaction}
              selectedTaxCodes={{ [selectedTransaction?.id]: split.taxCode }}
              handleTaxCodeChange={(id, value) => handleSplitChange(index, 'taxCode', value)}
            />
            <IconButton onClick={() => removeSplit(index)} color="error">
              <FiTrash2 />
            </IconButton>
          </Box>
        ))}

        <Button
          startIcon={<FiPlus />}
          onClick={addNewSplit}
          variant="outlined"
          size="small"
          sx={{ mt: 2 }}
        >
          Neue Zeile
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setSplitDialogOpen(false)}>Abbrechen</Button>
        <Button onClick={handleSaveSplits} variant="contained">Speichern</Button>
      </DialogActions>
    </Dialog>
  </Box>
);
};

MatchedTransactionsTable.propTypes = {
matchingTransactions: PropTypes.array,
receiptFiles: PropTypes.array,
handleOpenReceiptDialog: PropTypes.func.isRequired,
selectedKontos: PropTypes.object,
handleKontoChange: PropTypes.func.isRequired,
selectedTaxCodes: PropTypes.object,
handleTaxCodeChange: PropTypes.func.isRequired,
transactionStatuses: PropTypes.object,
onSaveSplits: PropTypes.func.isRequired,
clientId: PropTypes.string.isRequired,
disabled: PropTypes.bool,
transactionSplits: PropTypes.object,
getSplitsForTransaction: PropTypes.func,
removeSplitsForTransaction: PropTypes.func
};

export default MatchedTransactionsTable;