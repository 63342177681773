import React, { useState } from 'react';
import { 
Box, 
Button, 
Typography, 
Chip,
Dialog,
DialogTitle,
DialogContent,
DialogActions,
TextField,
IconButton,
Tooltip,
CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
FiSave, 
FiCheck, 
FiX, 
FiClock, 
FiLock, 
FiUnlock,
FiEye,
FiAlertCircle
} from 'react-icons/fi';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { 
ControlsContainer, 
StatusChip, 
ActionButton, 
ReviewDialog,
SessionInfo 
} from './styles';
import { useSession } from '../../hooks/useSession';
import SavingIndicator from '../SavingIndicator';

const HistoryDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 500px;
  }
`;

const HistoryEntry = styled(Box)`
  padding: 16px;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
`;

const SessionControls = ({
isReviewer,
sessionId,
reviewStatus,
lastSaved,
onSave,
onApprove,
onReject,
reviewerNotes,
setReviewerNotes,
isLocked,
lockedBy,
onLock,
onUnlock,
loading,
currentUser,
savingState,
savingProgress,
sessionHistory
}) => {
const [openReviewDialog, setOpenReviewDialog] = useState(false);
const [action, setAction] = useState(null);
const [showHistory, setShowHistory] = useState(false);

const handleReviewAction = (actionType) => {
  if (loading) return;
  setAction(actionType);
  setOpenReviewDialog(true);
};

const handleSubmitReview = async () => {
  if (loading) return;

  if (action === 'approve') {
    await onApprove(reviewerNotes);
  } else {
    await onReject(reviewerNotes);
  }
  setOpenReviewDialog(false);
};

const getStatusColor = () => {
  switch (reviewStatus) {
    case 'reviewed':
      return 'success';
    case 'rejected':
      return 'error';
    case 'pending':
      return 'warning';
    default:
      return 'default';
  }
};

const getStatusLabel = () => {
  switch (reviewStatus) {
    case 'reviewed':
      return 'Geprüft';
    case 'rejected':
      return 'Abgelehnt';
    case 'pending':
      return 'Ausstehend';
    default:
      return 'Unbekannt';
  }
};

return (
  <ControlsContainer>
    <SavingIndicator state={savingState} progress={savingProgress} />
    <SessionInfo>
      <Typography variant="subtitle1" component="span">
        Session ID: {sessionId || 'Nicht gespeichert'}
      </Typography>
      {lastSaved && (
        <Typography variant="body2" color="text.secondary">
          Zuletzt gespeichert: {format(new Date(lastSaved), 'dd.MM.yyyy HH:mm', { locale: de })}
        </Typography>
      )}
      <StatusChip
        label={getStatusLabel()}
        color={getStatusColor()}
        icon={reviewStatus === 'pending' ? <FiClock /> : undefined}
      />
    </SessionInfo>

    <Box display="flex" gap={2} alignItems="center">
      {isLocked && (
        <Tooltip title={`Gesperrt von ${lockedBy}`}>
          <Chip
            icon={<FiLock />}
            label="Gesperrt"
            color="warning"
            size="small"
          />
        </Tooltip>
      )}

      {!isReviewer && (
        <ActionButton
          variant="contained"
          startIcon={loading ? <CircularProgress size={20} /> : <FiSave />}
          onClick={onSave}
          disabled={loading || reviewStatus === 'reviewed' || isLocked}
        >
          Speichern
        </ActionButton>
      )}

      {isReviewer && (
        <>
          {!isLocked ? (
            <Tooltip title="Session für Prüfung sperren">
              <ActionButton
                variant="outlined"
                onClick={() => onLock(currentUser.id)}
                disabled={loading}
                startIcon={<FiLock />}
              >
                Sperren
              </ActionButton>
            </Tooltip>
          ) : (
            <Tooltip title="Sperre aufheben">
              <ActionButton
                variant="outlined"
                onClick={() => onUnlock(currentUser.id)}
                disabled={loading || lockedBy !== currentUser.id}
                startIcon={<FiUnlock />}
              >
                Entsperren
              </ActionButton>
            </Tooltip>
          )}

          {reviewStatus === 'pending' && isLocked && lockedBy === currentUser.id && (
            <>
              <ActionButton
                variant="contained"
                color="success"
                startIcon={loading ? <CircularProgress size={20} /> : <FiCheck />}
                onClick={() => handleReviewAction('approve')}
                disabled={loading}
              >
                Genehmigen
              </ActionButton>
              <ActionButton
                variant="contained"
                color="error"
                startIcon={loading ? <CircularProgress size={20} /> : <FiX />}
                onClick={() => handleReviewAction('reject')}
                disabled={loading}
              >
                Ablehnen
              </ActionButton>
            </>
          )}
        </>
      )}

      <Tooltip title="Änderungsverlauf anzeigen">
        <IconButton
          onClick={() => setShowHistory(true)}
          disabled={loading}
        >
          <FiEye />
        </IconButton>
      </Tooltip>
    </Box>

    <ReviewDialog
      open={openReviewDialog}
      onClose={() => setOpenReviewDialog(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {action === 'approve' ? 'Buchungen genehmigen' : 'Buchungen ablehnen'}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Anmerkungen"
          fullWidth
          multiline
          rows={4}
          value={reviewerNotes}
          onChange={(e) => setReviewerNotes(e.target.value)}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => setOpenReviewDialog(false)}
          disabled={loading}
        >
          Abbrechen
        </Button>
        <Button 
          onClick={handleSubmitReview}
          variant="contained"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          Bestätigen
        </Button>
      </DialogActions>
    </ReviewDialog>

    <HistoryDialog
      open={showHistory}
      onClose={() => setShowHistory(false)}
      maxWidth="md"
    >
      <DialogTitle>Änderungsverlauf</DialogTitle>
      <DialogContent>
        {sessionHistory && sessionHistory.length > 0 ? (
          sessionHistory.map((entry, index) => (
            <HistoryEntry key={index}>
              <Typography variant="subtitle1">
                {entry.action === 'Aktualisierung' ? 'Änderung' : 'Erstellung'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Benutzer: {entry.user}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Zeitpunkt: {entry.formattedTimestamp}
              </Typography>
              {entry.notes && (
                <Typography variant="body2" color="text.secondary">
                  Anmerkungen: {entry.notes}
                </Typography>
              )}
              {entry.changes && (
                <Box mt={1}>
                  <Typography variant="body2" color="text.secondary">
                    Änderungen:
                  </Typography>
                  <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                    {entry.changes.modifiedFields.map((field, i) => (
                      <li key={i}>{field}</li>
                    ))}
                  </ul>
                </Box>
              )}
            </HistoryEntry>
          ))
        ) : (
          <Typography variant="body1" color="text.secondary" textAlign="center" py={3}>
            Keine Änderungshistorie verfügbar
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowHistory(false)}>Schließen</Button>
      </DialogActions>
    </HistoryDialog>
  </ControlsContainer>
);
};

export default SessionControls;
