import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Alert,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import * as Lucide from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';

const API_URL = process.env.REACT_APP_API_URL;

const SubscriptionManagement = () => {
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [overview, setOverview] = useState(null);
  const [clientSubscriptions, setClientSubscriptions] = useState([]);
  
  // Dialog states
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openRenewDialog, setOpenRenewDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState('trial');
  const [paymentMethod, setPaymentMethod] = useState('bank_transfer');
  const [transactionId, setTransactionId] = useState('');
  
  // Fetch subscription overview and client subscriptions on component mount
  useEffect(() => {
    fetchSubscriptionOverview();
    fetchClientSubscriptions();
  }, []);
  
  const fetchSubscriptionOverview = async () => {
    try {
      setLoading(true);
      const token = getToken();
      
      if (!token) {
        throw new Error('No authentication token available');
      }
      
      const response = await fetch(`${API_URL}/api/payments/tax-consultant/overview/`, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || `Failed to fetch subscription overview: ${response.status}`);
      }
      
      const data = await response.json();
      setOverview(data.statistics);
    } catch (error) {
      console.error('Error fetching subscription overview:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchClientSubscriptions = async () => {
    try {
      setLoading(true);
      const token = getToken();
      
      if (!token) {
        throw new Error('No authentication token available');
      }
      
      const response = await fetch(`${API_URL}/api/payments/tax-consultant/client-subscriptions/`, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || `Failed to fetch client subscriptions: ${response.status}`);
      }
      
      const data = await response.json();
      setClientSubscriptions(data);
    } catch (error) {
      console.error('Error fetching client subscriptions:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCreateSubscription = async () => {
    try {
      setLoading(true);
      setError('');
      setSuccess('');
      
      const token = getToken();
      
      if (!token) {
        throw new Error('No authentication token available');
      }
      
      const response = await fetch(`${API_URL}/api/payments/tax-consultant/create-subscription/`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: selectedClient.id,
          subscription_type: subscriptionType,
          payment_method: paymentMethod,
          transaction_id: transactionId,
        }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || `Failed to create subscription: ${response.status}`);
      }
      
      setSuccess(data.message || 'Subscription created successfully');
      setOpenCreateDialog(false);
      
      // Refresh data
      fetchSubscriptionOverview();
      fetchClientSubscriptions();
    } catch (error) {
      console.error('Error creating subscription:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleRenewSubscription = async () => {
    try {
      setLoading(true);
      setError('');
      setSuccess('');
      
      const token = getToken();
      
      if (!token) {
        throw new Error('No authentication token available');
      }
      
      const response = await fetch(`${API_URL}/api/payments/tax-consultant/renew-subscription/`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscription_id: selectedSubscription.id,
          payment_method: paymentMethod,
          transaction_id: transactionId,
        }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || `Failed to renew subscription: ${response.status}`);
      }
      
      setSuccess(data.message || 'Subscription renewed successfully');
      setOpenRenewDialog(false);
      
      // Refresh data
      fetchSubscriptionOverview();
      fetchClientSubscriptions();
    } catch (error) {
      console.error('Error renewing subscription:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const getSubscriptionStatusChip = (subscription) => {
    if (!subscription) {
      return <Chip label="No Subscription" color="default" size="small" />;
    }
    
    if (subscription.is_active) {
      return <Chip label="Active" color="success" size="small" />;
    }
    
    if (subscription.is_trial) {
      return <Chip label="Trial" color="primary" size="small" />;
    }
    
    if (subscription.is_expired) {
      return <Chip label="Expired" color="error" size="small" />;
    }
    
    return <Chip label={subscription.status} color="default" size="small" />;
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  };
  
  if (loading && !overview && !clientSubscriptions.length) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ mt: 4, mb: 6 }}>
      {/* Header */}
      <Typography variant="h5" gutterBottom>
        Subscription Management
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        Manage your clients' subscriptions and payments
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {success}
        </Alert>
      )}
      
      {/* Overview Cards */}
      {overview && (
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={3}>
            <Card sx={{ bgcolor: 'primary.main', color: 'white' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Lucide.Users size={20} />
                  <Typography variant="h6" sx={{ ml: 1 }}>Total Clients</Typography>
                </Box>
                <Typography variant="h3">{overview.total_clients}</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ bgcolor: 'success.main', color: 'white' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Lucide.CheckCircle size={20} />
                  <Typography variant="h6" sx={{ ml: 1 }}>Active Subscriptions</Typography>
                </Box>
                <Typography variant="h3">{overview.active_subscriptions}</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ bgcolor: 'info.main', color: 'white' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Lucide.Clock size={20} />
                  <Typography variant="h6" sx={{ ml: 1 }}>Trial Subscriptions</Typography>
                </Box>
                <Typography variant="h3">{overview.trial_subscriptions}</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card sx={{ bgcolor: 'warning.main', color: 'white' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Lucide.EuroIcon size={20} />
                  <Typography variant="h6" sx={{ ml: 1 }}>Monthly Revenue</Typography>
                </Box>
                <Typography variant="h3">{overview.monthly_revenue.toFixed(2)} €</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      
      {/* Client Subscriptions Table */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h6">Client Subscriptions</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Lucide.Plus size={16} />}
              onClick={() => {
                const clientsWithoutSubscription = clientSubscriptions.filter(client => !client.subscription);
                if (clientsWithoutSubscription.length > 0) {
                  setSelectedClient(clientsWithoutSubscription[0]);
                  setOpenCreateDialog(true);
                } else {
                  setError('All clients already have subscriptions');
                }
              }}
              disabled={clientSubscriptions.filter(client => !client.subscription).length === 0}
            >
              Create Subscription
            </Button>
          </Box>
          
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Last Payment</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientSubscriptions.map((client) => (
                  <TableRow key={client.id}>
                    <TableCell>{client.client_name}</TableCell>
                    <TableCell>{client.company_name}</TableCell>
                    <TableCell>{getSubscriptionStatusChip(client.subscription)}</TableCell>
                    <TableCell>
                      {client.subscription ? formatDate(client.subscription.start_date) : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {client.subscription ? 
                        (client.subscription.is_trial ? 
                          formatDate(client.subscription.trial_end_date) : 
                          formatDate(client.subscription.end_date)) : 
                        'N/A'}
                    </TableCell>
                    <TableCell>
                      {client.latest_payment ? 
                        `${client.latest_payment.amount} € (${formatDate(client.latest_payment.payment_date)})` : 
                        'No payments'}
                    </TableCell>
                    <TableCell>
                      {client.subscription ? (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<Lucide.RefreshCw size={16} />}
                          onClick={() => {
                            setSelectedClient(client);
                            setSelectedSubscription(client.subscription);
                            setOpenRenewDialog(true);
                          }}
                          disabled={client.subscription.is_active}
                        >
                          Renew
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<Lucide.Plus size={16} />}
                          onClick={() => {
                            setSelectedClient(client);
                            setOpenCreateDialog(true);
                          }}
                        >
                          Create
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                
                {clientSubscriptions.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No clients found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      
      {/* Create Subscription Dialog */}
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create Subscription</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {selectedClient && (
              <Typography variant="subtitle1" gutterBottom>
                Creating subscription for: <strong>{selectedClient.client_name}</strong> ({selectedClient.company_name})
              </Typography>
            )}
            
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel>Subscription Type</InputLabel>
              <Select
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                label="Subscription Type"
              >
                <MenuItem value="trial">Trial (14 days free)</MenuItem>
                <MenuItem value="paid">Paid (30€ monthly)</MenuItem>
              </Select>
            </FormControl>
            
            {subscriptionType === 'paid' && (
              <>
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel>Payment Method</InputLabel>
                  <Select
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    label="Payment Method"
                  >
                    <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
                    <MenuItem value="credit_card">Credit Card</MenuItem>
                    <MenuItem value="paypal">PayPal</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
                
                <TextField
                  fullWidth
                  label="Transaction ID (optional)"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  margin="normal"
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateSubscription} 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Renew Subscription Dialog */}
      <Dialog open={openRenewDialog} onClose={() => setOpenRenewDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Renew Subscription</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {selectedClient && selectedSubscription && (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Renewing subscription for: <strong>{selectedClient.client_name}</strong> ({selectedClient.company_name})
                </Typography>
                
                <Typography variant="body2" color="text.secondary" paragraph>
                  Current status: {selectedSubscription.status}
                  {selectedSubscription.end_date && ` (Expires: ${formatDate(selectedSubscription.end_date)})`}
                </Typography>
                
                <Alert severity="info" sx={{ mb: 3 }}>
                  Renewing will extend the subscription for 1 month and charge 30€
                </Alert>
              </>
            )}
            
            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel>Payment Method</InputLabel>
              <Select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                label="Payment Method"
              >
                <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
                <MenuItem value="credit_card">Credit Card</MenuItem>
                <MenuItem value="paypal">PayPal</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            
            <TextField
              fullWidth
              label="Transaction ID (optional)"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRenewDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleRenewSubscription} 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Renew'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubscriptionManagement; 