import React, { useMemo, useState } from 'react';
import { 
  Box, 
  Button, 
  Card, 
  CardContent, 
  Typography,
  Avatar,
  Paper,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import BusinessIcon from '@mui/icons-material/Business';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';

// ================ PART 1: Constants & Styles ================

const COLOR_SCHEMES = {
  blue: {
    name: 'Ozean',
    primary: '#2563eb',
    secondary: '#60a5fa',
    gradient: 'linear-gradient(135deg, #2563eb 0%, #3b82f6 100%)',
    light: '#eff6ff',
    text: '#1e40af',
    hover: '#1d4ed8'
  },
  emerald: {
    name: 'Smaragd',
    primary: '#059669',
    secondary: '#34d399',
    gradient: 'linear-gradient(135deg, #059669 0%, #10b981 100%)',
    light: '#ecfdf5',
    text: '#065f46',
    hover: '#047857'
  },
  violet: {
    name: 'Amethyst',
    primary: '#7c3aed',
    secondary: '#a78bfa',
    gradient: 'linear-gradient(135deg, #7c3aed 0%, #8b5cf6 100%)',
    light: '#f5f3ff',
    text: '#5b21b6',
    hover: '#6d28d9'
  },
  amber: {
    name: 'Bernstein',
    primary: '#d97706',
    secondary: '#fbbf24',
    gradient: 'linear-gradient(135deg, #d97706 0%, #f59e0b 100%)',
    light: '#fffbeb',
    text: '#b45309',
    hover: '#b45309'
  },
  rose: {
    name: 'Rosengarten',
    primary: '#e11d48',
    secondary: '#fb7185',
    gradient: 'linear-gradient(135deg, #e11d48 0%, #f43f5e 100%)',
    light: '#fff1f2',
    text: '#be123c',
    hover: '#be123c'
  }
};

const STATUS_COLORS = {
  locked: {
    background: '#fef2f2',
    color: '#ef4444',
    hover: '#dc2626',
    icon: '#dc2626',
    border: '#fee2e2'
  },
  available: {
    background: '#f0fdf4',
    color: '#22c55e',
    hover: '#16a34a',
    icon: '#16a34a',
    border: '#dcfce7'
  }
};

const SURFACE_COLORS = {
  background: '#f8fafc',
  card: '#ffffff',
  divider: 'rgba(0, 0, 0, 0.06)',
  hover: 'rgba(0, 0, 0, 0.02)'
};

const TEXT_COLORS = {
  primary: '#0f172a',
  secondary: '#64748b',
  tertiary: '#94a3b8'
};

// Helper Functions
const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
};

const getInitials = (name) => {
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

// ================ PART 2: Component ================

const SessionsListWithActions = ({ sessions, onSelectSession, onNewSession, onDeleteSession, isTaxConsultant }) => {
  const theme = useTheme();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);

  const handleDeleteClick = (e, session) => {
    e.stopPropagation(); // Prevent session selection when clicking delete
    setSessionToDelete(session);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (sessionToDelete) {
      await onDeleteSession(sessionToDelete.session_id);
      setDeleteDialogOpen(false);
      setSessionToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setSessionToDelete(null);
  };

  const clientStyles = useMemo(() => {
    const uniqueClients = [...new Set(sessions.map(session => session.client_name))];
    return Object.fromEntries(
      uniqueClients.map((client, index) => [
        client,
        Object.values(COLOR_SCHEMES)[index % Object.keys(COLOR_SCHEMES).length]
      ])
    );
  }, [sessions]);

  return (
    <Box sx={{ 
      width: '100%', 
      p: { xs: 2, md: 4 }, 
      backgroundColor: SURFACE_COLORS.background,
      minHeight: '100vh'
    }}>
      {/* Header Section */}
      <Paper 
        elevation={0}
        sx={{ 
          p: 3,
          mb: 4,
          background: `linear-gradient(to right, ${SURFACE_COLORS.card}, ${SURFACE_COLORS.background})`,
          borderRadius: '16px',
          border: `1px solid ${SURFACE_COLORS.divider}`
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between', 
          alignItems: { xs: 'stretch', sm: 'center' },
          gap: 2
        }}>
          <Box>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 800,
                color: TEXT_COLORS.primary,
                mb: 1,
                background: COLOR_SCHEMES.blue.gradient,
                backgroundClip: 'text',
                textFillColor: 'transparent'
              }}
            >
              Aktive Sessions
            </Typography>
            <Typography variant="body1" sx={{ color: TEXT_COLORS.secondary }}>
              Verwalten Sie Ihre Klientensitzungen effizient
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={onNewSession}
            sx={{
              backgroundColor: COLOR_SCHEMES.blue.primary,
              color: '#fff',
              borderRadius: '12px',
              px: 4,
              py: 1.5,
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 600,
              boxShadow: `0 4px 6px -1px ${COLOR_SCHEMES.blue.primary}40`,
              '&:hover': {
                backgroundColor: COLOR_SCHEMES.blue.hover,
                boxShadow: `0 6px 8px -1px ${COLOR_SCHEMES.blue.primary}60`,
              }
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Neue Session
          </Button>
        </Box>
      </Paper>

      {/* Sessions List */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {sessions.map((session) => {
          const clientStyle = clientStyles[session.client_name];
          const statusColor = session.is_locked ? STATUS_COLORS.locked : STATUS_COLORS.available;
          
          return (
            <Card 
              key={session.session_id}
              sx={{ 
                cursor: 'pointer',
                borderRadius: '16px',
                position: 'relative',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                backgroundColor: SURFACE_COLORS.card,
                border: `1px solid ${SURFACE_COLORS.divider}`,
                overflow: 'hidden',
                '&:hover': { 
                  transform: 'translateY(-4px)',
                  boxShadow: theme.shadows[8],
                  borderColor: clientStyle.secondary,
                  '& .session-arrow': {
                    transform: 'translateX(4px)',
                  }
                },
              }}
              onClick={() => onSelectSession(session)}
            >
              <Box 
                sx={{ 
                  position: 'absolute',
                  top: '12px',
                  right: '12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  padding: '4px 8px',
                  borderRadius: '6px',
                  fontSize: '0.75rem',
                  color: TEXT_COLORS.secondary,
                  backdropFilter: 'blur(4px)',
                  border: `1px solid ${SURFACE_COLORS.divider}`,
                  zIndex: 1,
                  fontWeight: 500
                }}
              >
                Session ID: {session.session_id}
              </Box>

              <Box 
                sx={{ 
                  height: '6px',
                  background: clientStyle.gradient
                }} 
              />

              <CardContent sx={{ p: 3 }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 4
                }}>
                  {/* Client Info */}
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    minWidth: '300px'
                  }}>
                    <Avatar 
                      sx={{ 
                        background: clientStyle.gradient,
                        width: 64,
                        height: 64,
                        mr: 2,
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        boxShadow: `0 4px 6px -1px ${clientStyle.primary}40`,
                      }}
                    >
                      {getInitials(session.client_name)}
                    </Avatar>
                    <Box>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontWeight: 700,
                          mb: 0.5,
                          color: clientStyle.text
                        }}
                      >
                        {session.client_name}
                      </Typography>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        color: TEXT_COLORS.secondary
                      }}>
                        <BusinessIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                        <Typography variant="body2">
                          {session.company_name || 'Firma nicht angegeben'}
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        color: TEXT_COLORS.secondary
                      }}>
                        {/* <PersonIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                        <Typography variant="body2">
                          {session.locked_by_username ? `Gesperrt von: ${session.tax_consultant_name}` : 'Gesperrt von: (leer)'}
                        </Typography> */}
                      </Box>
                    </Box>
                  </Box>

                  {/* Info Cards */}
                  <Box sx={{ 
                    display: 'flex',
                    gap: 2
                  }}>
                    {/* Tax Year Card */}
                    <Paper 
                      elevation={0}
                      sx={{ 
                        p: 2,
                        backgroundColor: clientStyle.light,
                        borderRadius: '12px',
                        border: `1px solid ${clientStyle.primary}20`,
                        minWidth: '120px'
                      }}
                    >
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: clientStyle.text,
                          display: 'block',
                          mb: 0.5
                        }}
                      >
                        Steuerjahr
                      </Typography>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          color: clientStyle.primary,
                          fontWeight: 700
                        }}
                      >
                        {session.tax_year || (session.client_fiscal_year_start ? 
                          new Date(session.client_fiscal_year_start).getFullYear() : 
                          new Date().getFullYear())}
                      </Typography>
                    </Paper>

                    {/* Status Card */}
                    <Paper 
                      elevation={0}
                      sx={{ 
                        p: 2,
                        backgroundColor: statusColor.background,
                        borderRadius: '12px',
                        border: `1px solid ${statusColor.border}`,
                        minWidth: '120px'
                      }}
                    >
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: statusColor.color,
                          display: 'block',
                          mb: 0.5
                        }}
                      >
                        Status
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {session.is_locked ? (
                          <LockIcon sx={{ color: statusColor.icon, mr: 1 }} />
                        ) : (
                          <LockOpenIcon sx={{ color: statusColor.icon, mr: 1 }} />
                        )}
                        <Typography 
                          sx={{ 
                            color: statusColor.color,
                            fontWeight: 600
                          }}
                        >
                          {session.is_locked ? 'Abgeschlossen' : 'Verfügbar'}
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>

                  {/* Details and Action */}
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    ml: 'auto'
                  }}>
                    <Box>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        mb: 1,
                        color: TEXT_COLORS.secondary
                      }}>
                        <AccessTimeIcon sx={{ fontSize: '1.1rem', mr: 1 }} />
                        <Typography variant="body2">
                          Zuletzt: <strong>{formatDateTime(session.updated_at)}</strong>
                        </Typography>
                      </Box>
                      {session.is_locked && (
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          color: TEXT_COLORS.secondary
                        }}>
                          <PersonIcon sx={{ fontSize: '1.1rem', mr: 1 }} />
                          <Typography variant="body2">
                            Gesperrt von: <strong>{session.tax_consultant_name}</strong>
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    {/* Delete Button - Only show for tax consultants and unlocked sessions */}
                    {isTaxConsultant && !session.is_locked && (
                      <Tooltip title="Session löschen">
                        <IconButton
                          onClick={(e) => handleDeleteClick(e, session)}
                          sx={{
                            color: STATUS_COLORS.locked.color,
                            '&:hover': {
                              color: STATUS_COLORS.locked.hover
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    <KeyboardArrowRightIcon 
                      className="session-arrow"
                      sx={{ 
                        color: clientStyle.primary,
                        transition: 'transform 0.2s ease-in-out'
                      }} 
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          );
        })}

        {/* Empty State */}
        {sessions.length === 0 && (
          <Paper
            elevation={0}
            sx={{ 
              textAlign: 'center', 
              py: 8,
              px: 4,
              backgroundColor: SURFACE_COLORS.card,
              borderRadius: '16px',
              border: `2px dashed ${SURFACE_COLORS.divider}`,
            }}
          >
            <DescriptionIcon 
              sx={{ 
                fontSize: '4rem', 
                color: TEXT_COLORS.tertiary,
                mb: 2
              }} 
            />
            <Typography 
              variant="h5" 
              sx={{ 
                fontWeight: 700,
                color: TEXT_COLORS.primary,
                mb: 1
              }}
            >
              Keine aktiven Sessions
            </Typography>
            <Typography 
              sx={{ 
                color: TEXT_COLORS.secondary,
                mb: 3 
              }}
            >
              Erstellen Sie eine neue Session, um mit der Arbeit zu beginnen
            </Typography>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={onNewSession}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                px: 3,
                py: 1,
                borderColor: COLOR_SCHEMES.blue.primary,
                color: COLOR_SCHEMES.blue.primary,
                '&:hover': {
                  borderColor: COLOR_SCHEMES.blue.hover,
                  backgroundColor: COLOR_SCHEMES.blue.light
                }
              }}
            >
              Neue Session erstellen
            </Button>
          </Paper>
        )}
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: '16px',
            p: 1
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1 }}>
          Session löschen bestätigen
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <Typography>
            Sind Sie sicher, dass Sie diese Session löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.
          </Typography>
          {sessionToDelete && (
            <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Session Details:
              </Typography>
              <Typography>
                Client: {sessionToDelete.client_name}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Steuerjahr: {sessionToDelete.tax_year || (sessionToDelete.client_fiscal_year_start ? 
                  new Date(sessionToDelete.client_fiscal_year_start).getFullYear() : 
                  new Date().getFullYear())}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button 
            onClick={handleDeleteCancel}
            sx={{ 
              color: TEXT_COLORS.secondary,
              '&:hover': {
                backgroundColor: SURFACE_COLORS.hover
              }
            }}
          >
            Abbrechen
          </Button>
          <Button 
            onClick={handleDeleteConfirm}
            variant="contained"
            sx={{
              bgcolor: STATUS_COLORS.locked.color,
              '&:hover': {
                bgcolor: STATUS_COLORS.locked.hover
              }
            }}
          >
            Löschen bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SessionsListWithActions;