import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { 
  Select, 
  MenuItem, 
  Tooltip, 
  Typography, 
  Divider, 
  Box, 
  Chip,
  Fade,
  Zoom,
  Paper,
  ListItemIcon,
  ListItemText,
  Badge
} from '@mui/material';
import { FiChevronDown, FiAward, FiZap, FiTrendingUp, FiPercent } from 'react-icons/fi';
import { getConfidenceExplanation } from '../utils/kontoUtils';
import { fetchKontos } from './ReceiptMatcher/config';
import { styled, keyframes } from '@mui/system';

// Animation keyframes
const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.2); }
  70% { box-shadow: 0 0 0 5px rgba(25, 118, 210, 0); }
  100% { box-shadow: 0 0 0 0 rgba(25, 118, 210, 0); }
`;

const shimmer = keyframes`
  0% { background-position: -100% 0; }
  100% { background-position: 100% 0; }
`;

const glow = keyframes`
  0% { filter: drop-shadow(0 0 1px rgba(25, 118, 210, 0.3)); }
  50% { filter: drop-shadow(0 0 2px rgba(25, 118, 210, 0.5)); }
  100% { filter: drop-shadow(0 0 1px rgba(25, 118, 210, 0.3)); }
`;

// Styled components
const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: '200px',
  transition: 'all 0.2s ease',
  '&:hover': {
    boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    transition: 'all 0.2s ease',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
  }
}));

const AIBadge = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(25, 118, 210, 0.1)',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: '0.7rem',
  height: '22px',
  marginLeft: theme.spacing(0.5),
  border: `1px solid ${theme.palette.primary.main}40`,
  '& .MuiChip-label': {
    padding: '0 8px',
  }
}));

const ConfidenceChip = styled(Chip)(({ theme, confidence }) => {
  // Color based on confidence level
  let color = '#f44336'; // Red for low confidence
  if (confidence >= 0.7) {
    color = '#4caf50'; // Green for high confidence
  } else if (confidence >= 0.4) {
    color = '#ff9800'; // Orange for medium confidence
  }
  
  return {
    minWidth: '50px',
    backgroundColor: `${color}20`,
    color: color,
    border: `1px solid ${color}40`,
    fontWeight: 'bold',
    fontSize: '0.7rem',
    height: '22px',
    '& .MuiChip-label': {
      padding: '0 8px',
    },
    '&:hover': {
      backgroundColor: `${color}30`,
    }
  };
});

const AIMenuItem = styled(MenuItem)(({ theme, isSelected, isTopMatch }) => ({
  transition: 'all 0.2s ease',
  padding: theme.spacing(1, 2),
  borderLeft: isTopMatch ? `4px solid ${theme.palette.primary.main}` : 'none',
  backgroundColor: isSelected 
    ? `${theme.palette.primary.main}20` 
    : isTopMatch 
      ? `${theme.palette.primary.main}10`
      : 'inherit',
  '&:hover': {
    backgroundColor: isSelected 
      ? `${theme.palette.primary.main}30` 
      : `${theme.palette.primary.main}15`
  }
}));

const AIIcon = styled(FiZap)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: theme.spacing(1)
}));

const TooltipContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
  maxWidth: 280,
  fontSize: '0.8rem',
  lineHeight: 1.5
}));

// Add a helper function to format confidence percentage
const formatConfidence = (confidence) => {
  // Check if confidence is already a percentage (>= 1) or a decimal (< 1)
  const value = confidence >= 1 ? confidence : confidence * 100;
  // Return just the number followed by % symbol with no decimal places
  return `${Math.round(value)}%`;
};

// Add a helper function to truncate text
const truncateText = (text, maxLength = 30) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const KontoSelect = ({ 
  transaction, 
  selectedKontos = {}, 
  handleKontoChange, 
  suggestedKontos = {},
  clientId,
  disabled = false
}) => {
  const [kontos, setKontos] = useState([]);
  const [manualOptions, setManualOptions] = useState([]);
  const [localValue, setLocalValue] = useState('');
  const [selectedKonto, setSelectedKonto] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const transactionId = transaction?.id;
  
  // Get AI suggestions from the correct path - moved before any conditional returns
  const aiSuggestions = transaction?.receiptData?.konto_matches || [];
  const hasAiSuggestions = aiSuggestions && aiSuggestions.length > 0;

  // Create a memoized handleKontoSelect function to handle direct selection
  // Moved before any conditional returns to comply with React Hooks rules
  const handleKontoSelect = useCallback((kontoNumber) => {
    if (!transactionId) return; // Early return if no transaction ID
    
    console.log('handleKontoSelect called with:', kontoNumber);
    
    // Convert to string for consistent comparison
    const kontoNumberStr = String(kontoNumber);
    
    // Find the selected konto details
    const selectedFromAI = aiSuggestions.find(k => String(k.konto_number) === kontoNumberStr);
    const selectedFromManual = manualOptions.find(k => String(k.number) === kontoNumberStr);
    
    const selectedKontoInfo = selectedFromAI || selectedFromManual || null;
    console.log('Selected konto info in handleKontoSelect:', selectedKontoInfo);
    
    // Update local state
    setLocalValue(kontoNumber);
    setSelectedKonto(selectedKontoInfo);
    
    // Call the parent handler
    handleKontoChange(transactionId, kontoNumber);
  }, [aiSuggestions, manualOptions, transactionId, handleKontoChange]);

  // Initialize and update local value when selectedKontos changes
  useEffect(() => {
    if (transactionId) {
      const selectedValue = selectedKontos[transactionId] || '';
      console.log('Setting localValue from selectedKontos:', selectedValue);
      setLocalValue(selectedValue);
      
      // Find and set the selected konto details
      // Convert to string for comparison
      const selectedValueStr = String(selectedValue);
      
      const selectedFromAI = aiSuggestions.find(k => String(k.konto_number) === selectedValueStr);
      const selectedFromManual = manualOptions.find(k => String(k.number) === selectedValueStr);
      
      const selectedKontoInfo = selectedFromAI || selectedFromManual || null;
      console.log('Selected konto info:', selectedKontoInfo);
      setSelectedKonto(selectedKontoInfo);
    }
  }, [selectedKontos, transactionId, aiSuggestions, manualOptions]);

  // Auto-select the AI suggestion with highest confidence when component mounts or AI suggestions change
  useEffect(() => {
    // Only auto-select if there are AI suggestions and no value is already selected
    if (hasAiSuggestions && aiSuggestions.length > 0 && 
        localValue === '' && 
        transactionId && 
        !selectedKontos[transactionId]) {
      // Sort suggestions by confidence (highest first)
      const sortedSuggestions = [...aiSuggestions].sort((a, b) => 
        (b.confidence || 0) - (a.confidence || 0)
      );
      
      // Get the suggestion with highest confidence
      const bestSuggestion = sortedSuggestions[0];
      
      if (bestSuggestion && bestSuggestion.konto_number) {
        console.log('Auto-selecting AI suggestion with highest confidence:', bestSuggestion);
        handleKontoSelect(bestSuggestion.konto_number);
      }
    }
  }, [aiSuggestions, hasAiSuggestions, localValue, transactionId, handleKontoSelect, selectedKontos]);

  useEffect(() => {
    const loadKontos = async () => {
      if (!clientId) return;
      const data = await fetchKontos(clientId);
      console.log('Loaded kontos:', data);
      setKontos(data);
    };
    loadKontos();
  }, [clientId]);

  useEffect(() => {
    // Get AI suggestions
    console.log('AI suggestions:', aiSuggestions);
    
    const aiKontoNumbers = new Set(aiSuggestions.map(match => String(match.konto_number)));
    
    // Filter out AI suggestions from manual options
    const filteredManualOptions = kontos.filter(konto => 
      !aiKontoNumbers.has(String(konto.number))
    );
    
    console.log('Filtered manual options:', filteredManualOptions);
    setManualOptions(filteredManualOptions);
  }, [kontos, aiSuggestions]);

  // Return null if no transaction ID
  if (!transactionId) return null;

  const handleChange = (event) => {
    const newValue = event.target.value;
    console.log('handleChange called with:', newValue);
    
    // Use the common handler function
    handleKontoSelect(newValue);
  };

  // Render function for the selected value
  const renderValue = (selected) => {
    if (!selected || !selectedKonto) return '';
    
    const selectedStr = String(selected);
    const isAISuggestion = aiSuggestions.some(k => String(k.konto_number) === selectedStr);
    const displayNumber = selectedKonto.number || selectedKonto.konto_number;
    const displayName = selectedKonto.description || selectedKonto.konto_name;
    
    // For AI suggestions, extract just the name without the number if it's already in the name
    let formattedName = displayName;
    if (isAISuggestion && displayName) {
      // Check if the name already starts with the number
      const nameStartsWithNumber = displayName.trim().startsWith(String(displayNumber));
      formattedName = nameStartsWithNumber ? displayName : `${displayNumber} ${displayName}`;
    } else {
      formattedName = `${displayNumber} ${displayName}`;
    }
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body2" fontWeight={isAISuggestion ? 'medium' : 'regular'}>
          {formattedName}
        </Typography>
        {isAISuggestion && selectedKonto.confidence && (
          <Fade in={true}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ConfidenceChip
                size="small"
                label={formatConfidence(selectedKonto.confidence)}
                confidence={selectedKonto.confidence}
              />
              <AIBadge
                size="small"
                label="KI"
                icon={<FiZap size={10} />}
              />
            </Box>
          </Fade>
        )}
      </Box>
    );
  };

  // Function to render confidence explanation tooltip
  const renderConfidenceExplanation = (match) => {
    const confidence = match.confidence || 0;
    const formattedConfidence = formatConfidence(confidence);
    const reasoning = match.reasoning || '';
    
    // Format the display name to avoid duplicate numbers
    let displayName = match.konto_name || '';
    const nameStartsWithNumber = displayName.trim().startsWith(String(match.konto_number));
    const formattedName = nameStartsWithNumber ? displayName : `${match.konto_number} ${displayName}`;
    
    return (
      <TooltipContent>
        <Typography variant="subtitle2" gutterBottom>
          {truncateText(formattedName, 40)}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Typography variant="body2" sx={{ mr: 1 }}>Confidence:</Typography>
          <ConfidenceChip
            size="small"
            label={formattedConfidence}
            confidence={confidence}
          />
        </Box>
        
        {reasoning && (
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" fontWeight="medium">Begründung:</Typography>
            <Typography variant="body2">{truncateText(reasoning, 150)}</Typography>
          </Box>
        )}
      </TooltipContent>
    );
  };

  return (
    <StyledSelect
      value={localValue}
      onChange={handleChange}
      renderValue={renderValue}
      variant="outlined"
      size="small"
      IconComponent={FiChevronDown}
      disabled={disabled}
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300
          }
        },
        TransitionComponent: Fade,
        transitionDuration: 200
      }}
    >
      {/* AI Suggestions Section */}
      {hasAiSuggestions && (
        <>
          <MenuItem disabled>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AIIcon size={16} />
              <Typography variant="caption" color="primary" sx={{ fontWeight: 'bold', ml: 1 }}>
                KI-VORSCHLÄGE
              </Typography>
            </Box>
          </MenuItem>
          {aiSuggestions.slice(0, 3).map((match, index) => {
            // Convert to string for comparison
            const matchKontoStr = String(match.konto_number);
            const localValueStr = String(localValue);
            const isSelected = matchKontoStr === localValueStr;
            const isTopMatch = index === 0;
            
            // Format the display name to avoid duplicate numbers
            let displayName = match.konto_name || '';
            const nameStartsWithNumber = displayName.trim().startsWith(String(match.konto_number));
            const formattedName = nameStartsWithNumber ? displayName : `${match.konto_number} ${displayName}`;
            
            return (
              <Tooltip 
                key={`ai-suggestion-${match.konto_number}-${index}`}
                title={renderConfidenceExplanation(match)}
                placement="right"
                arrow
              >
                <AIMenuItem 
                  value={match.konto_number}
                  selected={isSelected}
                  onClick={() => handleKontoSelect(match.konto_number)}
                  isSelected={isSelected}
                  isTopMatch={isTopMatch}
                >
                  <Fade in={menuOpen} style={{ transitionDelay: `${index * 50}ms` }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}>
                      <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                        {isTopMatch ? (
                          <FiAward size={16} color="#4caf50" />
                        ) : (
                          <FiTrendingUp size={16} color="#2196f3" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2" sx={{ fontWeight: isTopMatch ? 'medium' : 'regular' }}>
                            {truncateText(formattedName, 40)}
                          </Typography>
                        }
                      />
                      <ConfidenceChip 
                        size="small" 
                        label={formatConfidence(match.confidence)}
                        confidence={match.confidence}
                      />
                    </Box>
                  </Fade>
                </AIMenuItem>
              </Tooltip>
            );
          })}
          <Divider sx={{ my: 1 }} />
        </>
      )}

      {/* Manual Selection Section */}
      <MenuItem disabled>
        <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
          MANUELLE AUSWAHL
        </Typography>
      </MenuItem>
      {manualOptions.map((konto, index) => {
        // Convert to string for comparison
        const kontoNumberStr = String(konto.number);
        const localValueStr = String(localValue);
        const isSelected = kontoNumberStr === localValueStr;
        
        return (
          <MenuItem 
            key={`konto-${konto.number}`} 
            value={konto.number}
            selected={isSelected}
            onClick={() => handleKontoSelect(konto.number)}
            sx={{
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': { 
                backgroundColor: 'action.hover'
              },
              '&.Mui-selected': { 
                backgroundColor: 'primary.light',
                fontWeight: 'medium'
              }
            }}
          >
            <Fade in={menuOpen} style={{ transitionDelay: `${index * 30}ms` }}>
              <Typography variant="body2">
                {konto.number} {truncateText(konto.description || konto.beschriftung, 40)}
              </Typography>
            </Fade>
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
};

KontoSelect.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    receiptData: PropTypes.shape({
      konto_matches: PropTypes.arrayOf(PropTypes.shape({
        konto_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        konto_name: PropTypes.string,
        confidence: PropTypes.number
      }))
    })
  }),
  selectedKontos: PropTypes.object,
  handleKontoChange: PropTypes.func.isRequired,
  suggestedKontos: PropTypes.object,
  clientId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default KontoSelect;