import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  ThemeProvider, 
  Box, 
  Typography, 
  Paper, 
  Breadcrumbs,
  Link,
  Button,
  Divider,
  Alert,
  CircularProgress
} from '@mui/material';
import * as Lucide from 'lucide-react';
import { motion } from 'framer-motion';
import theme from './styles/theme';
import ReceiptMatcher from './components/ReceiptMatcher';
import { useAuth } from './hooks/useAuth';

const API_URL = process.env.REACT_APP_API_URL;

// Add this for debugging (you can remove it later)
console.log('DOCSENSETaxBank API URL:', API_URL);

const DOCSENSETaxBank = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { getToken, currentUser } = useAuth();
  
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (location.state?.initialStep === 'Dateien hochladen') {
      setActiveStep(1);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        // First verify access rights
        if (currentUser.role === 'client' && currentUser.id !== parseInt(clientId)) {
          throw new Error('Unauthorized access');
        }

        const response = await fetch(`${API_URL}/tax_consultant/clients/${clientId}/`, {
          headers: {
            'Authorization': getToken(),
          },
        });
        
        if (!response.ok) {
          if (response.status === 403) {
            throw new Error('Unauthorized access');
          } else if (response.status === 404) {
            throw new Error('Client not found');
          } else {
            throw new Error('Failed to fetch client data');
          }
        }
        
        const data = await response.json();
        setClientData(data);
      } catch (err) {
        setError(err.message);
        // Redirect based on error and user role
        if (err.message === 'Unauthorized access' || err.message === 'Client not found') {
          setTimeout(() => {
            navigate(currentUser.role === 'tax_consultant' ? '/app' : '/client-dashboard');
          }, 3000);
        }
      } finally {
        setLoading(false);
      }
    };

    if (clientId) {
      fetchClientData();
    }
  }, [clientId, getToken, currentUser, navigate]);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={4}>
        <Alert severity="error" 
          action={
            <Button 
              color="inherit" 
              size="small" 
              onClick={() => navigate(currentUser.role === 'tax_consultant' ? '/app' : '/client-dashboard')}
            >
              Back to Dashboard
            </Button>
          }
        >
          {error}
          {(error === 'Unauthorized access' || error === 'Client not found') && 
            <Typography variant="caption" display="block">
              Redirecting to dashboard in 3 seconds...
            </Typography>
          }
        </Alert>
      </Box>
    );
  }

  const statsData = [
    // { 
    //   label: 'Total Documents', 
    //   value: clientData?.total_documents || 0, 
    //   icon: <Lucide.Files size={24} /> 
    // },
    // { 
    //   label: 'Processed', 
    //   value: clientData?.processed_documents || 0, 
    //   icon: <Lucide.CheckCircle size={24} /> 
    // },
    // { 
    //   label: 'Pending', 
    //   value: clientData?.pending_documents || 0, 
    //   icon: <Lucide.Clock size={24} /> 
    // },
    // { 
    //   label: 'Last Update', 
    //   value: clientData?.last_update || 'N/A', 
    //   icon: <Lucide.RefreshCw size={24} /> 
    // }
  ];

  return (
    <ThemeProvider theme={theme}>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
        transition={{ duration: 0.5 }}
      >
        <Box p={4} sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
          <Breadcrumbs 
            separator={<Lucide.ChevronRight size={16} />} 
            aria-label="breadcrumb"
            sx={{ mb: 3 }}
          >
            <Link 
              component="button"
              onClick={() => navigate('/app')}
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                textDecoration: 'none',
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                color: 'inherit',
                fontFamily: 'inherit',
                padding: 0,
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              <Lucide.Home size={16} style={{ marginRight: 4 }} />
              Dashboard
            </Link>
            <Typography color="text.primary">
              {clientData?.company_name || 'Client Details'}
            </Typography>
          </Breadcrumbs>

          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mb: 4, 
              borderRadius: 2,
              background: 'linear-gradient(135deg, #fff 0%, #f8f9fa 100%)'
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h4" gutterBottom color="primary">
                  {clientData?.company_name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Client ID: {clientId}
                </Typography>
              </Box>
              {/* <Button
                variant="outlined"
                startIcon={<Lucide.FileText size={20} />}
                onClick={handleGenerateReport}
              >
                Generate Report
              </Button> */}
            </Box>
          </Paper>

          <Box 
            sx={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
              gap: 3,
              mb: 4 
            }}
          >
            {statsData.map((stat, index) => (
              <Paper
                key={index}
                sx={{
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  borderRadius: 2,
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <Box sx={{ color: 'primary.main' }}>
                  {stat.icon}
                </Box>
                <Box>
                  <Typography variant="h6">{stat.value}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {stat.label}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box mt={4}>
            <ReceiptMatcher 
              clientId={clientId} 
              initialStep={activeStep}
            />
          </Box>
        </Box>
      </motion.div>
    </ThemeProvider>
  );
};

export default DOCSENSETaxBank;