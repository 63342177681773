import { useState } from 'react';

export const useReceiptMatcherState = () => {
  // File states
  const [csvFile, setCsvFile] = useState(null);
  const [camtFile, setCamtFile] = useState(null);
  const [receiptFiles, setReceiptFiles] = useState([]);
  const [receiptInfos, setReceiptInfos] = useState([]);
  const [processingPdf, setProcessingPdf] = useState(false);
  const [pdfProcessingProgress, setPdfProcessingProgress] = useState(0);
  
  // Kassa and Kredit states
  const [kassaReceipts, setKassaReceipts] = useState([]);
  const [kreditReceipts, setKreditReceipts] = useState([]);
  const [isProcessing, setIsProcessing] = useState({ kassa: false, kredit: false });
  
  // Transaction states
  const [csvTransactions, setCsvTransactions] = useState([]);
  const [camtTransactions, setCamtTransactions] = useState([]);
  const [matchingTransactions, setMatchingTransactions] = useState([]);
  const [unmatchedTransactions, setUnmatchedTransactions] = useState([]);
  const [manuallyMatchedReceipts, setManuallyMatchedReceipts] = useState({});
  const [selectedKontos, setSelectedKontos] = useState({});
  const [selectedTaxCodes, setSelectedTaxCodes] = useState({});
  const [transactionStatuses, setTransactionStatuses] = useState({});

  // UI states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const [currentReceiptPreviewUrl, setCurrentReceiptPreviewUrl] = useState('');
  const [openCsvDialog, setOpenCsvDialog] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(3);
  const [receiptsProcessed, setReceiptsProcessed] = useState(0);
  
  // Animation states
  const [transitionAnimation, setTransitionAnimation] = useState({
    open: false,
    receipt: null,
    type: 'kassa',
    kontoMatches: []
  });

  return {
    fileStates: {
      csvFile, setCsvFile,
      camtFile, setCamtFile,
      receiptFiles, setReceiptFiles,
      receiptInfos, setReceiptInfos,
      processingPdf, setProcessingPdf,
      pdfProcessingProgress, setPdfProcessingProgress,
    },
    
    kassaKreditStates: {
      kassaReceipts, setKassaReceipts,
      kreditReceipts, setKreditReceipts,
      isProcessing, setIsProcessing,
    },
    
    transactionStates: {
      csvTransactions, setCsvTransactions,
      camtTransactions, setCamtTransactions,
      matchingTransactions, setMatchingTransactions,
      unmatchedTransactions, setUnmatchedTransactions,
      manuallyMatchedReceipts, setManuallyMatchedReceipts,
      selectedKontos, setSelectedKontos,
      selectedTaxCodes, setSelectedTaxCodes,
      transactionStatuses, setTransactionStatuses,
    },
    
    uiStates: {
      loading, setLoading,
      error, setError,
      activeStep, setActiveStep,
      successMessage, setSuccessMessage,
      openReceiptDialog, setOpenReceiptDialog,
      currentReceiptPreviewUrl, setCurrentReceiptPreviewUrl,
      openCsvDialog, setOpenCsvDialog,
      progress, setProgress,
      currentStep, setCurrentStep,
      totalSteps, setTotalSteps,
      receiptsProcessed, setReceiptsProcessed,
    },
    
    animationStates: {
      transitionAnimation, setTransitionAnimation
    }
  };
};