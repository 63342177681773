import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { 
  Table, TableHead, TableBody, TableRow, TableCell, 
  Paper, IconButton, Tooltip, Select, MenuItem,
  Typography, Box, TextField, Dialog, DialogTitle,
  DialogContent, DialogActions, Button, FormControlLabel,
  Switch, Chip, Backdrop, CircularProgress
} from '@mui/material';
import { FiUpload, FiEye, FiEdit2, FiInfo, FiZap } from 'react-icons/fi';
import { fetchKontos } from './ReceiptMatcher/config';
import TaxCodeSelect from './TaxCodeSelect';

const UnmatchedTransactionsTable = ({
  unmatchedTransactions = [],
  manuallyMatchedReceipts = {},
  selectedKontos = {},
  selectedTaxCodes = {},
  handleKontoChange,
  handleTaxCodeChange,
  handleManuallyMatchedReceiptUpload,
  handleViewManuallyMatchedReceipt,
  transactionStatuses = {},
  clientId,
  disabled
}) => {
  const [kontos, setKontos] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [comment, setComment] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isReceiptless, setIsReceiptless] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const loadKontos = async () => {
      if (clientId) {
        const data = await fetchKontos(clientId);
        setKontos(data);
      }
    };
    loadKontos();
  }, [clientId]);

  const handleOpenUploadDialog = (transaction) => {
    setSelectedTransaction(transaction);
    const existingReceipt = manuallyMatchedReceipts[transaction.id];
    if (existingReceipt) {
      setComment(existingReceipt.comment || '');
      setIsPrivate(existingReceipt.isPrivate || false);
      setIsReceiptless(existingReceipt.isReceiptless || false);
    } else {
      setComment('');
      setIsPrivate(false);
      setIsReceiptless(false);
    }
    setSelectedFile(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTransaction(null);
    setComment('');
    setIsPrivate(false);
    setIsReceiptless(false);
    setSelectedFile(null);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsReceiptless(false);
    }
  };

  const handleSubmit = async () => {
    if (selectedTransaction) {
      const formData = new FormData();
      
      if (selectedFile && !isReceiptless) {
        formData.append('file', selectedFile);
      }
      
      formData.append('transactionId', selectedTransaction.id);
      formData.append('comment', comment);
      formData.append('isPrivate', isPrivate);
      formData.append('isReceiptless', isReceiptless);

      try {
        setProcessing(true);
        handleCloseDialog();
        
        await handleManuallyMatchedReceiptUpload(selectedTransaction.id, formData);
      } catch (error) {
        console.error('Error uploading receipt:', error);
      } finally {
        setProcessing(false);
      }
    }
  };

  const handleViewReceipt = (transactionId) => {
    const receipt = manuallyMatchedReceipts[transactionId];
    if (receipt) {
      handleViewManuallyMatchedReceipt(receipt);
    }
  };

  const StatusChip = ({ isMatched, isPrivate, isReceiptless }) => (
    <Chip
      label={isMatched ? 
        (isPrivate ? 'Privat' : 
         isReceiptless ? 'Beleglos' : 
         'Manuell abgeglichen') : 
        'Offen'}
      size="small"
      sx={{
        backgroundColor: isMatched 
          ? isPrivate ? 'rgba(156, 39, 176, 0.15)' // Purple for private
          : isReceiptless ? 'rgba(255, 152, 0, 0.15)' // Orange for receipt-less
          : 'rgba(52, 168, 83, 0.15)'  // Green for matched
          : 'rgba(234, 67, 53, 0.15)',  // Red for unmatched
        color: isMatched 
          ? isPrivate ? '#7b1fa2' // Darker purple for text
          : isReceiptless ? '#e65100' // Darker orange for text
          : '#1b5e20'  // Darker green for text
          : '#d32f2f',  // Darker red for text
        border: 'none',
        borderRadius: '16px',
        padding: '0 12px',
        height: '24px',
        '& .MuiChip-label': {
          fontWeight: 500,
          fontSize: '0.75rem',
          lineHeight: '1.2'
        },
        boxShadow: isMatched 
          ? isPrivate ? '0 0 0 1px rgba(156, 39, 176, 0.2)' // Purple glow for private
          : isReceiptless ? '0 0 0 1px rgba(255, 152, 0, 0.2)' // Orange glow for receipt-less
          : '0 0 0 1px rgba(52, 168, 83, 0.2)'  // Green glow for matched
          : '0 0 0 1px rgba(234, 67, 53, 0.2)',  // Red glow for unmatched
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          backgroundColor: isMatched 
            ? isPrivate ? 'rgba(156, 39, 176, 0.25)' // Darker purple on hover
            : isReceiptless ? 'rgba(255, 152, 0, 0.25)' // Darker orange on hover
            : 'rgba(52, 168, 83, 0.25)'  // Darker green on hover
            : 'rgba(234, 67, 53, 0.25)'  // Darker red on hover
        }
      }}
    />
  );

  if (!unmatchedTransactions.length) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Keine nicht zugeordneten Transaktionen gefunden
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box sx={{ overflowX: 'auto', width: '100%' }}>
          <Table size="small" sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Partner</TableCell>
                <TableCell>Verwendungszweck</TableCell>
                <TableCell>Referenz</TableCell>
                <TableCell align="right">Betrag</TableCell>
                <TableCell>Konto</TableCell>
                <TableCell>Steuer</TableCell>
                <TableCell>Beleg</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unmatchedTransactions.map((transaction) => {
                const transactionStatus = transactionStatuses[transaction.id];
                const receipt = manuallyMatchedReceipts[transaction.id];
                const isMatched = receipt || transactionStatus?.status === 'matched';  // Check for manual receipt

                return (
                  <TableRow 
                    key={transaction.id}
                    sx={{
                      backgroundColor: isMatched ? 'rgba(52, 168, 83, 0.04)' : 'inherit'
                    }}
                  >
                    <TableCell>
                      {(() => {
                        const dateStr = transaction.valutaDate || transaction.date;
                        if (!dateStr) return 'Kein Datum';
                        try {
                          return format(new Date(dateStr), 'dd.MM.yyyy', { locale: de });
                        } catch (e) {
                          return dateStr;
                        }
                      })()}
                    </TableCell>
                    <TableCell>
                      {(() => {
                        let partnerName = transaction.amount < 0
                          ? transaction.creditorName
                          : transaction.debtorName;
                        
                        if (!partnerName && transaction.details) {
                          const detail = transaction.details.split('|')[0].trim();
                          if (detail.includes('POS')) {
                            const posMatch = detail.match(/POS.*?(\d{2}:\d{2})\s*(.+?)(?:\s+WIEN|\s+\d{4}|$)/);
                            if (posMatch) {
                              partnerName = posMatch[2].trim();
                            }
                          } else if (detail.includes('ENI') || detail.includes('SHELL')) {
                            partnerName = detail.split(' ')[0];
                          } else {
                            const words = detail.split(' ');
                            const meaningfulWord = words.find(w => w.length > 2 && !/^\d+$/.test(w));
                            if (meaningfulWord) {
                              partnerName = meaningfulWord;
                            }
                          }
                        }
                        
                        return partnerName || transaction.partner || 'Unbekannt';
                      })()}
                    </TableCell>
                    <TableCell>
                      {transaction.details ? (
                        <Tooltip title={transaction.details}>
                          <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                            {transaction.details.length > 30 ? 
                              `${transaction.details.substring(0, 30)}...` : 
                              transaction.details}
                          </Typography>
                        </Tooltip>
                      ) : '-'}
                    </TableCell>
                    <TableCell>
                      {transaction.reference ? (
                        <Tooltip title={transaction.reference}>
                          <Typography variant="body2" noWrap sx={{ maxWidth: 150 }}>
                            {transaction.reference.length > 20 ? 
                              `${transaction.reference.substring(0, 20)}...` : 
                              transaction.reference}
                          </Typography>
                        </Tooltip>
                      ) : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {parseFloat(transaction.amount).toFixed(2)} €
                    </TableCell>
                    <TableCell>
                      <Select
                        size="small"
                        value={selectedKontos[transaction.id] || ''}
                        onChange={(e) => handleKontoChange(transaction.id, e.target.value)}
                        sx={{ minWidth: 200 }}
                        disabled={disabled}
                      >
                        {kontos.map((konto) => (
                          <MenuItem key={konto.number} value={konto.number}>
                            {konto.number} {konto.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TaxCodeSelect
                        transaction={transaction}
                        selectedTaxCodes={selectedTaxCodes}
                        handleTaxCodeChange={handleTaxCodeChange}
                      />
                    </TableCell>
                    <TableCell>
                      {receipt ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Tooltip title={receipt.name || 'Beleg'}>
                            <IconButton size="small">
                              <FiInfo />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : (
                        'Kein Beleg'
                      )}
                    </TableCell>
                    <TableCell>
                      <StatusChip 
                        isMatched={isMatched} 
                        isPrivate={transactionStatus?.isPrivate}
                        isReceiptless={transactionStatus?.isReceiptless}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {receipt ? (
                          <Tooltip title="Beleg anzeigen">
                            <IconButton 
                              size="small" 
                              onClick={() => handleViewReceipt(transaction.id)}
                              disabled={disabled}
                            >
                              <FiEye />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Beleg hochladen">
                            <IconButton 
                              size="small" 
                              onClick={() => handleOpenUploadDialog(transaction)}
                              disabled={disabled}
                            >
                              <FiUpload />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Beleg hochladen</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Transaktion:
            </Typography>
            {selectedTransaction && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">
                  <strong>Datum:</strong> {
                    (() => {
                      const dateStr = selectedTransaction.valutaDate || selectedTransaction.date;
                      if (!dateStr) return 'Kein Datum';
                      try {
                        return format(new Date(dateStr), 'dd.MM.yyyy', { locale: de });
                      } catch (e) {
                        return dateStr;
                      }
                    })()
                  }
                </Typography>
                <Typography variant="body2">
                  <strong>Betrag:</strong> {parseFloat(selectedTransaction.amount).toFixed(2)} €
                </Typography>
                <Typography variant="body2">
                  <strong>Partner:</strong> {
                    (() => {
                      let partnerName = selectedTransaction.amount < 0
                        ? selectedTransaction.creditorName
                        : selectedTransaction.debtorName;
                      
                      if (!partnerName && selectedTransaction.details) {
                        const detail = selectedTransaction.details.split('|')[0].trim();
                        if (detail.includes('POS')) {
                          const posMatch = detail.match(/POS.*?(\d{2}:\d{2})\s*(.+?)(?:\s+WIEN|\s+\d{4}|$)/);
                          if (posMatch) {
                            partnerName = posMatch[2].trim();
                          }
                        } else if (detail.includes('ENI') || detail.includes('SHELL')) {
                          partnerName = detail.split(' ')[0];
                        } else {
                          const words = detail.split(' ');
                          const meaningfulWord = words.find(w => w.length > 2 && !/^\d+$/.test(w));
                          if (meaningfulWord) {
                            partnerName = meaningfulWord;
                          }
                        }
                      }
                      
                      return partnerName || selectedTransaction.partner || 'Unbekannt';
                    })()
                  }
                </Typography>
                <Typography variant="body2">
                  <strong>Verwendungszweck:</strong> {selectedTransaction.details || '-'}
                </Typography>
              </Box>
            )}

            <FormControlLabel
              control={
                <Switch 
                  checked={isReceiptless} 
                  onChange={(e) => setIsReceiptless(e.target.checked)}
                  disabled={disabled}
                />
              }
              label="Beleglos (kein Beleg verfügbar)"
              sx={{ mb: 2 }}
            />

            {!isReceiptless && (
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="outlined"
                  component="label"
                  disabled={disabled}
                >
                  Beleg auswählen
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    hidden
                    onChange={handleFileSelect}
                  />
                </Button>
                {selectedFile && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Ausgewählte Datei: {selectedFile.name}
                  </Typography>
                )}
              </Box>
            )}

            <TextField
              label="Kommentar"
              multiline
              rows={3}
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              disabled={disabled}
              sx={{ mb: 2 }}
            />

            <FormControlLabel
              control={
                <Switch 
                  checked={isPrivate} 
                  onChange={(e) => setIsPrivate(e.target.checked)}
                  disabled={disabled}
                />
              }
              label="Als privat markieren"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={disabled || processing}>
            Abbrechen
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={disabled || processing || (!selectedFile && !isReceiptless)}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={processing}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <CircularProgress color="inherit" />
          <Typography variant="body1">Beleg wird verarbeitet...</Typography>
        </Box>
      </Backdrop>
    </>
  );
};

UnmatchedTransactionsTable.propTypes = {
  unmatchedTransactions: PropTypes.array,
  manuallyMatchedReceipts: PropTypes.object,
  selectedKontos: PropTypes.object,
  selectedTaxCodes: PropTypes.object,
  handleKontoChange: PropTypes.func.isRequired,
  handleTaxCodeChange: PropTypes.func.isRequired,
  handleManuallyMatchedReceiptUpload: PropTypes.func.isRequired,
  handleViewManuallyMatchedReceipt: PropTypes.func.isRequired,
  transactionStatuses: PropTypes.object,
  clientId: PropTypes.string,
  disabled: PropTypes.bool
};

export default UnmatchedTransactionsTable;