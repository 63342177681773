// Generated Kontos Array
export const kontos = [
  { konto: '100', beschriftung: 'Konzessionen' },
  { konto: '110', beschriftung: 'Patentrechte und Lizenzrechte' },
  { konto: '120', beschriftung: 'Warenzeichen, Musterschutzrechte' },
  { konto: '130', beschriftung: 'Datenverarbeitungsprogramme' },
  { konto: '135', beschriftung: 'Sonstige ähnliche Rechte' },
  { konto: '140', beschriftung: 'Pacht- und Mietrechte' },
  { konto: '145', beschriftung: 'Immaterielle geringwertige Verm.Gegenst' },
  { konto: '150', beschriftung: 'Geschäfts- oder Firmenwert' },
  { konto: '180', beschriftung: 'Anzahlungen immaterielle Verm.Gegenst.' },
  { konto: '200', beschriftung: 'Unbebaute Grundstücke' },
  { konto: '210', beschriftung: 'Bebaute Grundstücke' },
  { konto: '220', beschriftung: 'Grundstücksgleiche Rechte' },
  { konto: '230', beschriftung: 'Grundanteile im Miteigentum' },
  { konto: '300', beschriftung: 'Betriebs-, Geschäftsgebäude eigen. Grund' },
  { konto: '310', beschriftung: 'Wohn-, Sozialgebäude auf eigenem Grund' },
  { konto: '315', beschriftung: 'Grundstücke mit Substanzverzehr' },
  { konto: '320', beschriftung: 'Gebäudeanteile im Miteigentum' },
  { konto: '325', beschriftung: 'Betriebs-,Geschäftsgebäude fremd. Grund' },
  { konto: '330', beschriftung: 'Wohn-, Sozialgebäude auf fremdem Grund' },
  { konto: '340', beschriftung: 'Grundstückseinrichtungen eigenem Grund' },
  { konto: '350', beschriftung: 'Grundstückseinrichtungen fremdem Grund' },
  { konto: '355', beschriftung: 'Garagen' },
  { konto: '357', beschriftung: 'Hof- und Wegebefestigungen' },
  { konto: '359', beschriftung: 'Außenanlagen' },
  { konto: '360', beschriftung: 'Baul. Investitionen in fremde Gebäude' },
  { konto: '370', beschriftung: 'Baul. Investitionen in fremde Wohnbauten' },
  { konto: '400', beschriftung: 'Maschinen und maschinelle Anlagen' },
  { konto: '410', beschriftung: 'Antriebsmaschinen' },
  { konto: '420', beschriftung: 'Energieversorgungsanlagen' },
  { konto: '430', beschriftung: 'Transportanlagen' },
  { konto: '440', beschriftung: 'Fertigungsmaschinen' },
  { konto: '500', beschriftung: 'Maschinenwerkzeuge' },
  { konto: '510', beschriftung: 'Allgemeine Werkzeuge und Handwerkzeuge' },
  { konto: '520', beschriftung: 'Vorrichtungen, Formen und Modelle' },
  { konto: '530', beschriftung: 'Andere Erzeugungshilfsmittel' },
  { konto: '540', beschriftung: 'Hebezeuge und Montageanlagen' },
  { konto: '550', beschriftung: 'Geringwertige Wirtschaftsgüter' },
  { konto: '600', beschriftung: 'Betriebs- und Geschäftsausstattung' },
  { konto: '610', beschriftung: 'Nachrichten- und Kontrollanlagen' },
  { konto: '620', beschriftung: 'Büromaschinen, EDV-Anlagen' },
  { konto: '625', beschriftung: 'Kommunikationsanlagen' },
  { konto: '630', beschriftung: 'Personenkraftwagen' },
  { konto: '640', beschriftung: 'Lastkraftwagen' },
  { konto: '650', beschriftung: 'Andere Beförderungsmittel' },
  { konto: '660', beschriftung: 'Büroeinrichtungen' },
  { konto: '665', beschriftung: 'Einrichtung von Verkaufsräumen' },
  { konto: '670', beschriftung: 'Gebinde' },
  { konto: '680', beschriftung: 'Geringwertige Wirtschaftsgüter' },
  { konto: '700', beschriftung: 'Geleistete Anzahlungen' },
  { konto: '750', beschriftung: 'Anlagen im Bau' },
  { konto: '840', beschriftung: 'Anteile an Kapitalgesellschaften' },
  { konto: '845', beschriftung: 'Anteile an Personengesellschaften' },
  { konto: '850', beschriftung: 'Typisch stille Beteiligungen' },
  { konto: '855', beschriftung: 'Atypische stille Beteiligungen' },
  { konto: '860', beschriftung: 'Sonstige Beteiligungen' },
  { konto: '880', beschriftung: 'Genossenschaftsanteile' },
  { konto: '890', beschriftung: 'Anteile an Investmentfonds' },
  { konto: '900', beschriftung: 'Festverzinsliche Wertpapiere des AV' },
  { konto: '910', beschriftung: 'Sonstige Finanzanlagen, Wertrechte' },
  { konto: '920', beschriftung: 'Rückdeckungsansprüche gg. Versicherung' },
  { konto: '930', beschriftung: 'Sonstige Ausleihungen' },
  { konto: '931', beschriftung: 'Sonstige Ausleihungen bis 1 Jahr' },
  { konto: '932', beschriftung: 'Sonstige Ausleihungen größer 1 Jahr' },
  { konto: '1100', beschriftung: 'Rohstoffe' },
  { konto: '1400', beschriftung: 'Unfertige Erzeugnisse' },
  { konto: '1500', beschriftung: 'Fertige Erzeugnisse' },
  { konto: '1600', beschriftung: 'Waren' },
  { konto: '1800', beschriftung: 'Geleistete Anzahlungen' },
  { konto: '1801', beschriftung: 'Geleistete Anzahlungen 20% Vorsteuer' },
  { konto: '1805', beschriftung: 'Geleistete Anzahlungen 10% Vorsteuer' },
  { konto: '1809', beschriftung: 'Geleistete Anzahlungen 13% Vorsteuer' },
  { konto: '1810', beschriftung: 'Geleistete Anzahlungen 16% Vorsteuer' },
  { konto: '1811', beschriftung: 'Geleistete Anzahlungen 19% Vorsteuer' },
  { konto: '1890', beschriftung: 'Geleistete Anzahlungen auf Bestellungen' },
  { konto: '2000', beschriftung: 'Forderungen aus Lieferungen, Leistungen' },
  { konto: '2001', beschriftung: 'Forderungen aus Lieferungen, Leistungen' },
  { konto: '2010', beschriftung: 'Forderungen aus Lieferungen, Leistungen' },
  { konto: '2040', beschriftung: 'Forderungen aus Lieferg., Leistg. b. 1J' },
  { konto: '2041', beschriftung: 'Forderungen aus Lieferg., Leistg. g. 1J' },
  { konto: '2048', beschriftung: 'Forderungen nach § 19 Abs.1 für § 4/3' },
  { konto: '2050', beschriftung: 'Besitzwechsel' },
  { konto: '2100', beschriftung: 'Forderungen aus Lieferungen, Leistungen' },
  { konto: '2110', beschriftung: 'Forderungen aus Lieferungen, Leistungen' },
  { konto: '2150', beschriftung: 'Forderungen aus Lieferungen, Leistungen' },
  { konto: '2160', beschriftung: 'Forderungen aus Lieferungen, Leistungen' },
  { konto: '2195', beschriftung: 'Evidenzkonto erhaltene Anzahlungen' },
  { konto: '2199', beschriftung: 'Gegenkonto zur Aufteilung Debitoren' },
  { konto: '2300', beschriftung: 'Sonst. Forderungen u. Vermögensgegenst.' },
  { konto: '2301', beschriftung: 'Sonstige Forderungen' },
  { konto: '2310', beschriftung: 'Sonstige Vermögensgegenstände' },
  { konto: '2321', beschriftung: 'Gewinnermittl nicht ergebniswirksam §4/3' },
  { konto: '2325', beschriftung: 'Kautionen' },
  { konto: '2326', beschriftung: 'Forderungen Leasingdepots' },
  { konto: '2340', beschriftung: 'Forderungen aus Guthaben Lieferanten' },
  { konto: '2350', beschriftung: 'Forderungen aus Versicherungsansprüchen' },
  { konto: '2370', beschriftung: 'Forderungen gg. Vorstand,Geschäftsführ.' },
  { konto: '2400', beschriftung: 'Forderung. a. Lohn-,Gehaltsvorschüssen' },
  { konto: '2410', beschriftung: 'Forderungen Dienstnehmerdarlehen' },
  { konto: '2420', beschriftung: 'Forderung. a. Lohn-, Gehaltsexekutionen' },
  { konto: '2430', beschriftung: 'Forderungen Bauarbeiterurlaubskasse' },
  { konto: '2435', beschriftung: 'Forderungen Auftraggeberhaftung ggb GKK' },
  { konto: '2440', beschriftung: 'Forderungen gg. Finanzamt Lohnabgaben' },
  { konto: '2450', beschriftung: 'Übrige Forderungen Lohn- und Gehalt' },
  { konto: '2460', beschriftung: 'Forderungen gegen Gesellschafter' },
  { konto: '2461', beschriftung: 'Forderungen gg. Ges. b. 1J' },
  { konto: '2462', beschriftung: 'Forderungen gg. Ges. gr.1J' },
  { konto: '2500', beschriftung: 'Vorsteuer aus Lieferungen, Leistungen' },
  { konto: '2505', beschriftung: 'Noch nicht abzugsfähige Vorsteuer' },
  { konto: '2506', beschriftung: 'Noch nicht abzugsfähige Vorsteuer 20%' },
  { konto: '2507', beschriftung: 'Noch nicht abzugsfähige Vorsteuer 10%' },
  { konto: '2509', beschriftung: 'Noch nicht abzugsfähige Vorsteuer 13%' },
  { konto: '2510', beschriftung: 'Vorsteuer aus EU-Erwerb' },
  { konto: '2511', beschriftung: 'Vorsteuer aus EU-Erwerb 20%' },
  { konto: '2512', beschriftung: 'Vorsteuer aus EU-Erwerb 10%' },
  { konto: '2513', beschriftung: 'Vorsteuer aus EU-Erwerb 13%' },
  { konto: '2514', beschriftung: 'Vorsteuer aus EU-Erwerb 19%' },
  { konto: '2519', beschriftung: 'Vorsteuer EU-Erwerb Neufahrzeug ohne UID' },
  { konto: '2520', beschriftung: 'Aufzuteilende Vorsteuer' },
  { konto: '2521', beschriftung: 'Aufzuteilende Vorsteuer 20%' },
  { konto: '2522', beschriftung: 'Aufzuteilende Vorsteuer 10%' },
  { konto: '2523', beschriftung: 'Aufzuteilende Vorsteuer 13%' },
  { konto: '2524', beschriftung: 'Aufzuteilende Vorsteuer 19%' },
  { konto: '2528', beschriftung: 'Aufzuteilende Vorsteuer EU-Leistungen' },
  { konto: '2529', beschriftung: 'Aufzuteilende Vorsteuer aus EU-Erwerb' },
  { konto: '2530', beschriftung: 'Vorsteuerberichtigung §12 Abs.10 UStG' },
  { konto: '2531', beschriftung: 'Vorsteuerberichtigung §12 Abs.11 UStG' },
  { konto: '2538', beschriftung: 'Vorst. in Folgeperiode /-jahr abziehbar' },
  { konto: '2540', beschriftung: 'Gegenkonto Vorsteuer § 4/3 EStG' },
  { konto: '2541', beschriftung: 'Auflösung Vorsteuer Vorjahr § 4/3 EStG' },
  { konto: '2542', beschriftung: 'Vorsteuer aus Investitionen § 4/3 EStG' },
  { konto: '2543', beschriftung: 'Vorsteuer die gemäß § 4/3, noch keine BA' },
  { konto: '2544', beschriftung: 'Vorsteuer gemäß Art. 25 Abs. 5 UStG' },
  { konto: '2545', beschriftung: 'Vorsteuer gemäß § 19 Abs. 1 UStG' },
  { konto: '2546', beschriftung: 'Vorsteuer gemäß § 19 Abs. 1d UStG' },
  { konto: '2548', beschriftung: 'Vorsteuer aus Bauleistungen § 19, 1a' },
  { konto: '2549', beschriftung: 'Vorsteuer gemäß § 19 Abs. 1b UStG' },
  { konto: '2550', beschriftung: 'Entrichtete EUSt gemäß § 12 Abs. 1 Z 2a' },
  { konto: '2551', beschriftung: 'Noch nicht abzugsfähige Einfuhr-USt' },
  { konto: '2552', beschriftung: 'Geschuldete EUSt gemäß § 12 Abs. 1 Z 2b' },
  { konto: '2555', beschriftung: 'Umsatzsteuerforderung laufendes Jahr' },
  { konto: '2556', beschriftung: 'Umsatzsteuerforderungen Vorjahr' },
  { konto: '2557', beschriftung: 'Umsatzsteuerforderungen frühere Jahre' },
  { konto: '2560', beschriftung: 'Noch nicht verrechenbare Vorsteuer' },
  { konto: '2565', beschriftung: 'Steuererstattungsanspruch and. EU-Land' },
  { konto: '2566', beschriftung: 'Steuererstattungsanspruch andere Länder' },
  { konto: '2575', beschriftung: 'Forderungen gg. FA Kapitalertragsteuer' },
  { konto: '2580', beschriftung: 'Forderungen gg. FA sonst. lfd. Steuern' },
  { konto: '2585', beschriftung: 'Forderungen gg. FA übrige Abgaben' },
  { konto: '2680', beschriftung: 'Besitzwechsel' },
  { konto: '2700', beschriftung: 'Kassa' },
  { konto: '2710', beschriftung: 'Kassa 1' },
  { konto: '2720', beschriftung: 'Nebenkassa 1' },
  { konto: '2725', beschriftung: 'Nebenkassa 2' },
  { konto: '2730', beschriftung: 'Postwertzeichen' },
  { konto: '2740', beschriftung: 'Stempelmarken' },
  { konto: '2760', beschriftung: 'Kassa' },
  { konto: '2770', beschriftung: 'Nebenkassa 1' },
  { konto: '2775', beschriftung: 'Nebenkassa 2' },
  { konto: '2780', beschriftung: 'Schecks' },
  { konto: '2790', beschriftung: 'Schecks in Inlandswährung' },
  { konto: '2795', beschriftung: 'Schecks in Fremdwährung' },
  { konto: '2800', beschriftung: 'Bank AT3620111839964' },
  { konto: '2810', beschriftung: 'Bank 1' },
  { konto: '2820', beschriftung: 'Bank 2' },
  { konto: '2830', beschriftung: 'Bank 3' },
  { konto: '2840', beschriftung: 'Bank 4' },
  { konto: '2850', beschriftung: 'Bank 5' },
  { konto: '2880', beschriftung: 'Schwebende Geldbewegungen' },
  { konto: '2881', beschriftung: 'Schwebende Geldbewegungen Bankomat' },
  { konto: '2882', beschriftung: 'Schwebende Geldbewegungen Kreditkarte' },
  { konto: '2890', beschriftung: 'Durchlaufende Posten' },
  { konto: '2895', beschriftung: 'Unklare Buchungen' },
  { konto: '2900', beschriftung: 'Aktive Rechnungsabgrenzungsposten' },
  { konto: '2955', beschriftung: 'Geldbeschaffungskosten' },
  { konto: '2970', beschriftung: 'Disagio' },
  { konto: '3000', beschriftung: 'Rückstellungen für Abfertigungen' },
  { konto: '3001', beschriftung: 'Rückstellung für Abfertigung versteuert' },
  { konto: '3005', beschriftung: 'Rückstellung für Abfertig. unversteuert' },
  { konto: '3110', beschriftung: 'Verbindlichkeiten gg. Kreditinstitute' },
  { konto: '3130', beschriftung: 'Verbindlichk. Kreditinstitute b. 1 J' },
  { konto: '3140', beschriftung: 'Verbindlichk. Kreditinstitute 1-5 J' },
  { konto: '3150', beschriftung: 'Verbindlichk. Kreditinstitute gr.5 J' },
  { konto: '3160', beschriftung: 'Verbindlichk. Kreditinstitute b. 1 J' },
  { konto: '3168', beschriftung: 'Verbindlichk. Kreditinstitute 1-5 J' },
  { konto: '3169', beschriftung: 'Verbindlichk. Kreditinstitute gr.5 J' },
  { konto: '3170', beschriftung: 'Verbindlichk. Kreditinstitute b. 1 J' },
  { konto: '3178', beschriftung: 'Verbindlichk. Kreditinstitute 1-5 J' },
  { konto: '3179', beschriftung: 'Verbindlichk. Kreditinstitute gr.5 J' },
  { konto: '3201', beschriftung: 'Erhaltene Anzahlungen 20% USt' },
  { konto: '3205', beschriftung: 'Erhaltene Anzahlungen 10% USt' },
  { konto: '3209', beschriftung: 'Erhaltene Anzahlungen 13% USt' },
  { konto: '3210', beschriftung: 'Erhaltene Anzahlungen 16% USt' },
  { konto: '3211', beschriftung: 'Erhaltene Anzahlungen 19% USt' },
  { konto: '3212', beschriftung: 'Erhaltene Anzahlungen 0% USt' },
  { konto: '3220', beschriftung: 'Erhaltene Anzahlungen bis 1 Jahr' },
  { konto: '3221', beschriftung: 'Erhaltene Anzahlungen 1-5 Jahre' },
  { konto: '3222', beschriftung: 'Erhaltene Anzahlungen gr. 5 Jahre' },
  { konto: '3230', beschriftung: 'Umsatzsteuer-Evidenzkonto Anzahlung' },
  { konto: '3250', beschriftung: 'Erhaltene Anzahlungen bis 1 Jahr' },
  { konto: '3255', beschriftung: 'Erhaltene Anzahlungen 1-5 Jahre' },
  { konto: '3260', beschriftung: 'Erhaltene Anzahlungen gr. 5 Jahre' },
  { konto: '3300', beschriftung: 'Verbindl. aus Lieferungen u. Leistungen' },
  { konto: '3301', beschriftung: 'R: Verbindlichkeiten aus L+L' },
  { konto: '3305', beschriftung: 'Evidenzkonto geleistete Anzahlungen' },
  { konto: '3309', beschriftung: 'Gegenkonto zur Aufteilung Kreditoren' },
  { konto: '3310', beschriftung: 'Verbindl. aus Lieferungen u. Leistungen' },
  { konto: '3330', beschriftung: 'Verbindl. a. Abgrenzung v. Lief./Leist.' },
  { konto: '3340', beschriftung: 'Verbindlichk. Investitionen § 4/3 EStG' },
  { konto: '3355', beschriftung: 'Verbindlichkeiten L+L bis 1 Jahr' },
  { konto: '3358', beschriftung: 'Verbindlichkeiten L+L 1-5 Jahre' },
  { konto: '3359', beschriftung: 'Verbindlichkeiten L+L gr. 5 Jahre' },
  { konto: '3360', beschriftung: 'Verbindlichk. L+L Währungsunion' },
  { konto: '3365', beschriftung: 'Verbindlichk. L+L EWU-Land bis 1 Jahr' },
  { konto: '3368', beschriftung: 'Verbindlichk. L+L EWU-Land 1-5 Jahre' },
  { konto: '3369', beschriftung: 'Verbindlichk. L+L EWU-Land gr. 5 Jahre' },
  { konto: '3370', beschriftung: 'Verbindlichk. L+L sonstiges Ausland' },
  { konto: '3375', beschriftung: 'Verbindlichk. L+L Ausland bis 1 Jahr' },
  { konto: '3378', beschriftung: 'Verbindlichk. L+L Ausland 1-5 Jahre' },
  { konto: '3379', beschriftung: 'Verbindlichk. L+L Ausland gr. 5 Jahre' },
  { konto: '3380', beschriftung: 'Gezogene Schuldwechsel' },
  { konto: '3385', beschriftung: 'Gezogene Schuldwechsel bis 1 Jahr' },
  { konto: '3388', beschriftung: 'Gezogene Schuldwechsel 1-5 Jahre' },
  { konto: '3389', beschriftung: 'Gezogene Schuldwechsel gr. 5 Jahre' },
  { konto: '3390', beschriftung: 'Eigene Schuldwechsel' },
  { konto: '3395', beschriftung: 'Eigene Schuldwechsel bis 1 Jahr' },
  { konto: '3398', beschriftung: 'Eigene Schuldwechsel 1-5 Jahre' },
  { konto: '3399', beschriftung: 'Eigene Schuldwechsel gr. 5 Jahre' },
  { konto: '3480', beschriftung: 'Verbindlichk. gg. still.Gesellschafter' },
  { konto: '3485', beschriftung: 'Verbindl. gg. stillen Gesellsch. b.1 J' },
  { konto: '3488', beschriftung: 'Verbindl. gg. stillen Gesellsch. 1-5 J' },
  { konto: '3489', beschriftung: 'Verbindl. gg. stillen Gesellsch. g.5 J' },
  { konto: '3500', beschriftung: 'Umsatzsteuer' },
  { konto: '3501', beschriftung: 'Umsatzsteuer 20%' },
  { konto: '3502', beschriftung: 'Umsatzsteuer 10%' },
  { konto: '3503', beschriftung: 'Umsatzsteuer 13%' },
  { konto: '3504', beschriftung: 'Umsatzsteuer 19%' },
  { konto: '3510', beschriftung: 'Umsatzsteuer aus EU-Erwerb' },
  { konto: '3511', beschriftung: 'Umsatzsteuer aus EU-Erwerb 20%' },
  { konto: '3512', beschriftung: 'Umsatzsteuer aus EU-Erwerb 10%' },
  { konto: '3513', beschriftung: 'Umsatzsteuer aus EU-Erwerb 13%' },
  { konto: '3514', beschriftung: 'Umsatzsteuer aus EU-Erwerb 19%' },
  { konto: '3519', beschriftung: 'USt EU-Erwerb Neufahrzeuge ohne UID' },
  { konto: '3520', beschriftung: 'Umsatzsteuer nicht fällig' },
  { konto: '3521', beschriftung: 'Umsatzsteuer nicht fällig 20%' },
  { konto: '3522', beschriftung: 'Umsatzsteuer nicht fällig 10%' },
  { konto: '3523', beschriftung: 'Umsatzsteuer nicht fällig 13%' },
  { konto: '3524', beschriftung: 'Umsatzsteuer nicht fällig 19%' },
  { konto: '3530', beschriftung: 'USt nicht fällig, EU-Lieferungen' },
  { konto: '3531', beschriftung: 'USt nicht fällig, EU-Lieferungen 20%' },
  { konto: '3532', beschriftung: 'USt nicht fällig, EU-Lieferungen 10%' },
  { konto: '3533', beschriftung: 'USt nicht fällig, EU-Lieferungen 13%' },
  { konto: '3534', beschriftung: 'USt nicht fällig, EU-Lieferungen 19%' },
  { konto: '3536', beschriftung: 'USt aus im Inland stpfl.EU-Lief.,nur OSS' },
  { konto: '3538', beschriftung: 'Umsatzsteuer in Folgeperiode fällig' },
  { konto: '3539', beschriftung: 'USt im and. EU-Land stpfl.elektr. Leistg' },
  { konto: '3540', beschriftung: 'USt aus EU-Erwerb ohne Vorsteuerabzug' },
  { konto: '3541', beschriftung: 'USt aus im Inland stpfl. EU-Lieferung' },
  { konto: '3542', beschriftung: 'USt im anderen EU-Land stpfl.Lieferung' },
  { konto: '3543', beschriftung: 'USt im anderen EU-Land stpfl. Leistung' },
  { konto: '3544', beschriftung: 'Umsatzsteuer gemäß Art. 25 Abs. 5 UStG' },
  { konto: '3545', beschriftung: 'Umsatzsteuer reverse charge' },
  { konto: '3546', beschriftung: 'Steuerschuld gemäß § 27 Abs. 4 UStG' },
  { konto: '3547', beschriftung: 'Unberechtigt ausgewiesene Umsatzsteuer' },
  { konto: '3548', beschriftung: 'USt aus Bauleistungen § 19 Abs 1a UStG' },
  { konto: '3549', beschriftung: 'Umsatzsteuer gemäß § 16 UStG' },
  { konto: '3550', beschriftung: 'Umsatzsteuervorauszahlungen' },
  { konto: '3555', beschriftung: 'Umsatzsteuer laufendes Jahr' },
  { konto: '3556', beschriftung: 'Umsatzsteuer Vorjahr' },
  { konto: '3557', beschriftung: 'Umsatzsteuer frühere Jahre' },
  { konto: '3560', beschriftung: 'Verrechnung Umsatzsteuer Zahllast' },
  { konto: '3565', beschriftung: 'Steuerzahlungen an andere EU-Länder' },
  { konto: '3567', beschriftung: 'Steuerzahlg aus im and.EU-Land stpfl.DL' },
  { konto: '3569', beschriftung: 'Verbindlich. gg. FA aus geschuldeter USt' },
  { konto: '3575', beschriftung: 'Verbindlich. gg. FA Lohnabgaben' },
  { konto: '3576', beschriftung: 'Verbindlich. gg. FA Lohnsteuer' },
  { konto: '3577', beschriftung: 'Verbindlich. gg. FA Dienstgeberbeitrag' },
  { konto: '3578', beschriftung: 'Verbindlich. gg. FA Zuschlag DG-Beitrag' },
  { konto: '3579', beschriftung: 'Verbindlich. gg. FA Kammerumlage' },
  { konto: '3580', beschriftung: 'Verrechnungskonto Finanzamt' },
  { konto: '3581', beschriftung: 'Verbindlich. gg. FA Verbrauchsteuern' },
  { konto: '3584', beschriftung: 'Verbindl. Betriebssteuern u. -abgaben' },
  { konto: '3587', beschriftung: 'Verbindlichk. gg. Gemeinde, KommSt' },
  { konto: '3589', beschriftung: 'Verbindlichk. gg.Gemeinde, U-Bahnabgabe' },
  { konto: '3590', beschriftung: 'Sonstige Abgaben' },
  { konto: '3595', beschriftung: 'Betriebsratsumlage' },
  { konto: '3600', beschriftung: 'Verbindlichk. gg. Sozialversicherung' },
  { konto: '3610', beschriftung: 'Verbindl. gg. Mitarbeitervorsorgekasse' },
  { konto: '3620', beschriftung: 'Verbindl. Auftraggeberhaftung ggb. GKK' },
  { konto: '3700', beschriftung: 'Sonstige Verbindlichkeiten' },
  { konto: '3705', beschriftung: 'Sonstige Verbindlichkeiten b. 1 Jahr' },
  { konto: '3708', beschriftung: 'Sonstige Verbindlichkeiten 1-5 Jahre' },
  { konto: '3709', beschriftung: 'Sonstige Verbindlichkeit gr. 5 Jahre' },
  { konto: '3720', beschriftung: 'Erhaltene Darlehen' },
  { konto: '3730', beschriftung: 'Verbindlichkeiten gegen Dienstnehmern' },
  { konto: '3740', beschriftung: 'Verbindl. a. Abgrenzung v. Aufwendungen' },
  { konto: '3745', beschriftung: 'Lohn- und Gehaltsverrechnung' },
  { konto: '3750', beschriftung: 'Verbindl. aus Überzahlung von Kunden' },
  { konto: '3760', beschriftung: 'Verbindlichkeiten gg. Gesellschafter' },
  { konto: '3765', beschriftung: 'Verbindlichk. gg. Gesellschafter b.1 J' },
  { konto: '3768', beschriftung: 'Verbindlichk. gg. Gesellschafter 1-5 J' },
  { konto: '3769', beschriftung: 'Verbindlichk. gg. Gesellschafter g.5 J' },
  { konto: '3770', beschriftung: 'Darlehen typisch stiller Gesellschaft' },
  { konto: '3775', beschriftung: 'Darlehen typisch stiller Gesell. b.1J' },
  { konto: '3778', beschriftung: 'Darlehen typisch stiller Gesell. 1-5J' },
  { konto: '3779', beschriftung: 'Darlehen typisch stiller Gesell. g.5J' },
  { konto: '3780', beschriftung: 'Darlehen atypisch stiller Gesellschaft' },
  { konto: '3785', beschriftung: 'Darlehen atypisch stiller Gesell. b.1J' },
  { konto: '3788', beschriftung: 'Darlehen atypisch stiller Gesell. 1-5J' },
  { konto: '3789', beschriftung: 'Darlehen atypisch stiller Gesell. g.5J' },
  { konto: '3900', beschriftung: 'Passive Rechnungsabgrenzungsposten' },
  { konto: '3950', beschriftung: 'Wertberichtigungen (Kostenverrech. BWA)' },
  { konto: '4000', beschriftung: 'Erlöse 20% USt' },
  { konto: '4030', beschriftung: 'Erlöse EU-Lieferungen 20% USt' },
  { konto: '4044', beschriftung: 'Erlöse a. Verschrottung §19 Abs.1d UStG' },
  { konto: '4045', beschriftung: 'Erbrachte Bauleistungen § 19 Abs. 1a' },
  { konto: '4099', beschriftung: 'Direkt mit dem Umsatz verb. Steuern' },
  { konto: '4100', beschriftung: 'Erlöse 10% USt' },
  { konto: '4120', beschriftung: 'Erlöse 13% USt' },
  { konto: '4130', beschriftung: 'Erlöse EU-Lieferungen 10% USt' },
  { konto: '4140', beschriftung: 'Erlöse EU-Lieferungen 13% USt' },
  { konto: '4170', beschriftung: 'Erlöse 0% USt' },
  { konto: '4181', beschriftung: 'Erlöse 19% USt' },
  { konto: '4186', beschriftung: 'Erlöse EU-Lieferungen 19% USt' },
  { konto: '4190', beschriftung: 'Erl.a.im and.EU-Land stpfl.Lieferungen' },
  { konto: '4200', beschriftung: 'Steuerfreie Ausfuhrlieferungen § 7 UStG' },
  { konto: '4210', beschriftung: 'Steuerfreie Lohnveredelungen § 8 UStG' },
  { konto: '4220', beschriftung: 'Steuerfreie Umsätze mit Vorsteuerabzug' },
  { konto: '4230', beschriftung: 'Steuerfreie EU-Lieferungen' },
  { konto: '4240', beschriftung: 'Steuerfr. EU-Lief.v.Neufahrzeugen o. UID' },
  { konto: '4250', beschriftung: 'Steuerfreie Grundstücksumsätze' },
  { konto: '4260', beschriftung: 'Steuerfreie Umsätze Kleinunternehmer' },
  { konto: '4270', beschriftung: 'Steuerfreie Umsätze ohne Vorsteuerabzug' },
  { konto: '4280', beschriftung: 'Innergemeinschaftl. Dreiecksgeschäft' },
  { konto: '4285', beschriftung: 'Erlöse s. Leistung EU, USt Empfänger' },
  { konto: '4286', beschriftung: 'Erlöse elektr.DL im anderen EU-Land stpf' },
  { konto: '4290', beschriftung: 'Nicht steuerbare Umsätze' },
  { konto: '4295', beschriftung: 'Nicht steuerbare Auslandsumsätze' },
  { konto: '4300', beschriftung: 'Umsatzerlöse' },
  { konto: '4399', beschriftung: 'Erlöse elektr. DL Inland stpf 20 % USt' },
  { konto: '4400', beschriftung: 'Erlösschmälerungen' },
  { konto: '4401', beschriftung: 'Erlösschmälerungen 20% USt' },
  { konto: '4405', beschriftung: 'Erlösschmälerungen 10% USt' },
  { konto: '4409', beschriftung: 'Erlösschmälerungen 13% USt' },
  { konto: '4410', beschriftung: 'Erlösschmälerungen EU-Lieferung 20% USt' },
  { konto: '4415', beschriftung: 'Erlösschmälerungen EU-Lieferung 10% USt' },
  { konto: '4418', beschriftung: 'Erlösschmälerungen EU-Lieferung 13% USt' },
  { konto: '4419', beschriftung: 'Erlösschmäl. i. and.EU-Land stpfl. Lief.' },
  { konto: '4420', beschriftung: 'Erlösschmälerungen 16% USt' },
  { konto: '4421', beschriftung: 'Erlösschmälerungen 19% USt' },
  { konto: '4422', beschriftung: 'Erlösschmälerungen 0% USt' },
  { konto: '4423', beschriftung: 'Erlösschmäl. Umsatz steuerfr. ohne Vorst' },
  { konto: '4424', beschriftung: 'Erlösschmäl. stfr. EU-Lief. Nfz ohne ID' },
  { konto: '4425', beschriftung: 'Erlösschmäl. Umsatz stf §6,1 Z.2-6 §23,5' },
  { konto: '4426', beschriftung: 'Erlösschmälerungen steuerfr. EU-Lieferg' },
  { konto: '4427', beschriftung: 'Erlösschmälerungen stfr. Lohnveredelung' },
  { konto: '4428', beschriftung: 'Erlösschmälerungen stfr. Ausfuhrlieferg' },
  { konto: '4430', beschriftung: 'Gewährte Skonti' },
  { konto: '4431', beschriftung: 'Gewährte Skonti 20% USt' },
  { konto: '4433', beschriftung: 'Gewährte Skonti 0% USt' },
  { konto: '4434', beschriftung: 'Gewährte Skonti EU-Lieferung 20% USt' },
  { konto: '4435', beschriftung: 'Gewährte Skonti 10% USt' },
  { konto: '4436', beschriftung: 'Gewährte Skonti 13% USt' },
  { konto: '4437', beschriftung: 'Gewährte Skonti EU-Lieferung 13% USt' },
  { konto: '4438', beschriftung: 'Gewährte Skonti EU-Lieferung 10% USt' },
  { konto: '4439', beschriftung: 'Gewährte Skonti stpfl. EU-Lieferung' },
  { konto: '4440', beschriftung: 'Gewährte Rabatte' },
  { konto: '4441', beschriftung: 'Gewährte Rabatte 20% USt' },
  { konto: '4445', beschriftung: 'Gewährte Rabatte 10% USt' },
  { konto: '4449', beschriftung: 'Gewährte Rabatte 13% USt' },
  { konto: '4450', beschriftung: 'Gewährte Boni' },
  { konto: '4451', beschriftung: 'Gewährte Boni 20% USt' },
  { konto: '4455', beschriftung: 'Gewährte Boni 10% USt' },
  { konto: '4459', beschriftung: 'Gewährte Boni 13% USt' },
  { konto: '4460', beschriftung: 'Gewährte Skonti stfreie Ausfuhrlief.' },
  { konto: '4464', beschriftung: 'Gewährte Skonti USt Leistungsempfänger' },
  { konto: '4465', beschriftung: 'Gewährte Skonti stfreie EU-Lieferung' },
  { konto: '4466', beschriftung: 'Gew. Skonti s.Leist.EU, USt Empfänger' },
  { konto: '4469', beschriftung: 'Gewährte Skonti aus EU-Dreiecksgeschäft' },
  { konto: '4470', beschriftung: 'Gewährte Rabatte stfreie Ausfuhrlief.' },
  { konto: '4475', beschriftung: 'Gewährte Rabatte EU-Lieferung steuerfrei' },
  { konto: '4480', beschriftung: 'Gewährte Boni stfreie Ausfuhrlief.' },
  { konto: '4485', beschriftung: 'Gewährte Boni EU-Lieferung steuerfrei' },
  { konto: '4580', beschriftung: 'Andere aktivierte Eigenleistungen' },
  { konto: '4585', beschriftung: 'Im Anlagevermögen aktiv. Eigenleistung' },
  { konto: '4589', beschriftung: 'Akt.Eigenleistg. FK-Zins zu Herst.kosten' },
  { konto: '4600', beschriftung: 'Erlöse aus Verkauf Sachanlagen 20% USt' },
  { konto: '4602', beschriftung: 'Verkauf GuB 20% USt (3,5/15% ESt)' },
  { konto: '4603', beschriftung: 'Verkauf GuB ustfrei (3,5/15% ESt)' },
  { konto: '4604', beschriftung: 'Verkauf GuB ustfrei KleinUN (3,5/15%ESt)' },
  { konto: '4606', beschriftung: 'Erlöse aus Verkauf Sachanlagen 19% USt' },
  { konto: '4607', beschriftung: 'Verkauf Grundstück 20% USt (25/30% ESt)' },
  { konto: '4608', beschriftung: 'Verkauf Grundstück ustfrei (25/30% ESt)' },
  { konto: '4610', beschriftung: 'Erlöse Verkauf Sachanl. stfr. § 6,1, §7' },
  { konto: '4611', beschriftung: 'Erlöse aus Verkauf Sachanlagen Klein-UN' },
  { konto: '4612', beschriftung: 'Erlöse Verkauf Sachanlagen stfr.o.Vorst' },
  { konto: '4614', beschriftung: 'Erlöse aus stfr. innergem. Verk. SAV' },
  { konto: '4615', beschriftung: 'Erlöse aus Verkauf Sachanlagen ohne USt' },
  { konto: '4620', beschriftung: 'Buchwert abgegangener Sachanlagen' },
  { konto: '4621', beschriftung: 'Buchwert abgegangener GuB (Altvermögen)' },
  { konto: '4622', beschriftung: 'Buchwert abgegang. Grundst., 25/30% ESt' },
  { konto: '4630', beschriftung: 'Erlöse aus Verkauf immat.VermögensG 20%' },
  { konto: '4636', beschriftung: 'Erlöse aus Verkauf immat.VermögensG 19%' },
  { konto: '4640', beschriftung: 'Erlöse Verkauf imm. VG stfr. § 6,1, § 7' },
  { konto: '4641', beschriftung: 'Erlöse Verkauf imm. VermG stfr. Klein-UN' },
  { konto: '4642', beschriftung: 'Erlöse Verkauf imm. VermG stfr. o.Vorst.' },
  { konto: '4645', beschriftung: 'Erlöse aus Verkauf immat.VermögensG 0%' },
  { konto: '4650', beschriftung: 'Buchwert abgegangener immat. VermG.' },
  { konto: '4660', beschriftung: 'Erträge aus Abgang von Sachanlagen' },
  { konto: '4665', beschriftung: 'Erträge aus Abgang von immat. VermG.' },
  { konto: '4800', beschriftung: 'Übrige betriebliche Erträge' },
  { konto: '4801', beschriftung: 'Sonstige Erlöse 20% USt' },
  { konto: '4805', beschriftung: 'Sonstige Erlöse 10% USt' },
  { konto: '4810', beschriftung: 'Sonstige Erlöse 13% USt' },
  { konto: '4811', beschriftung: 'Sonstige Erlöse 19% USt' },
  { konto: '4815', beschriftung: 'Sonstige Erlöse Ausfuhrlieferung' },
  { konto: '4819', beschriftung: 'Sonstige Erlöse nicht steuerbar' },
  { konto: '4820', beschriftung: 'Erlöse Sachbezüge, Waren' },
  { konto: '4821', beschriftung: 'Erlöse Sachbezüge, Waren 20% USt' },
  { konto: '4825', beschriftung: 'Erlöse Sachbezüge, Waren 10% USt' },
  { konto: '4829', beschriftung: 'Erlöse Sachbezüge, Waren 13% USt' },
  { konto: '4830', beschriftung: 'Personalverpflegung 20% USt' },
  { konto: '4835', beschriftung: 'Personalverpflegung 10% USt' },
  { konto: '4838', beschriftung: 'Personalverpflegung 13% USt' },
  { konto: '4839', beschriftung: 'Personalverpflegung nicht steuerbar' },
  { konto: '4840', beschriftung: 'Eigenverbrauch' },
  { konto: '4841', beschriftung: 'Eigenverbrauch 20% USt' },
  { konto: '4845', beschriftung: 'Eigenverbrauch 10% USt' },
  { konto: '4850', beschriftung: 'Eigenverbrauch 13% USt' },
  { konto: '4851', beschriftung: 'Eigenverbrauch 19% USt' },
  { konto: '4854', beschriftung: 'Eigenverbrauch 0% USt' },
  { konto: '4855', beschriftung: 'Eigenverbrauch nicht steuerbar' },
  { konto: '4860', beschriftung: 'Privatanteil' },
  { konto: '4861', beschriftung: 'Privatanteil 20% USt' },
  { konto: '4865', beschriftung: 'Privatanteil 10% USt' },
  { konto: '4870', beschriftung: 'Privatanteil 13% USt' },
  { konto: '4871', beschriftung: 'Privatanteil 19% USt' },
  { konto: '4874', beschriftung: 'Privatanteil 0% USt' },
  { konto: '4875', beschriftung: 'Privatanteil steuerfrei' },
  { konto: '4876', beschriftung: 'Privatanteil nicht steuerbar' },
  { konto: '4880', beschriftung: 'Mieterlöse' },
  { konto: '4881', beschriftung: 'Mieterlöse 20% USt' },
  { konto: '4885', beschriftung: 'Mieterlöse 10% USt' },
  { konto: '4890', beschriftung: 'Mieterlöse 13% USt' },
  { konto: '4891', beschriftung: 'Mieterlöse 19% USt' },
  { konto: '4895', beschriftung: 'Mieterlöse steuerfrei' },
  { konto: '4900', beschriftung: 'Provisionserlöse' },
  { konto: '4901', beschriftung: 'Provisionserlöse 20% USt' },
  { konto: '4905', beschriftung: 'Provisionserlöse 10% USt' },
  { konto: '4907', beschriftung: 'Provisionserlöse 13% USt' },
  { konto: '4908', beschriftung: 'Provisionserlöse steuerfr. ohne Vorst' },
  { konto: '4909', beschriftung: 'Provisionserlöse nicht steuerbar' },
  { konto: '4910', beschriftung: 'Erhaltener Kostenersatz' },
  { konto: '4911', beschriftung: 'Erhaltener Kostenersatz 20% USt' },
  { konto: '4915', beschriftung: 'Erhaltener Kostenersatz 10% USt' },
  { konto: '4917', beschriftung: 'Erhaltener Kostenersatz 13% USt' },
  { konto: '4918', beschriftung: 'Erhaltener Kostenersatz steuerfrei' },
  { konto: '4919', beschriftung: 'Erhaltener Kostenersatz n. steuerbar' },
  { konto: '4920', beschriftung: 'Schadenersatz' },
  { konto: '4921', beschriftung: 'Schadenersatz 20% USt' },
  { konto: '4925', beschriftung: 'Schadenersatz 10% USt' },
  { konto: '4927', beschriftung: 'Schadenersatz 13% USt' },
  { konto: '4928', beschriftung: 'Schadenersatz steuerfrei' },
  { konto: '4929', beschriftung: 'Schadenersatz nicht steuerbar' },
  { konto: '4930', beschriftung: 'Erträge Eingang abgeschrieb. Forderung' },
  { konto: '4931', beschriftung: 'Erträge Eingang abgeschr. Forderg. 20%' },
  { konto: '4935', beschriftung: 'Erträge Eingang abgeschr. Forderg. 10%' },
  { konto: '4937', beschriftung: 'Erträge Eingang abgeschr. Forderg. 13%' },
  { konto: '4938', beschriftung: 'Erträge Eingang abgeschr. Forderg. EU' },
  { konto: '4939', beschriftung: 'Erträge Eingang abgeschr. Forderg.stf.' },
  { konto: '4940', beschriftung: 'Verrechnete sonst. Sachbezüge' },
  { konto: '4941', beschriftung: 'Sachbezüge 20% USt keine Waren' },
  { konto: '4945', beschriftung: 'Sachbezüge 10% USt keine Waren' },
  { konto: '4948', beschriftung: 'Sachbezüge 13% USt keine Waren' },
  { konto: '4949', beschriftung: 'Sachbezüge nicht steuerbar' },
  { konto: '4950', beschriftung: 'Erträge aus Kursdifferenzen' },
  { konto: '4964', beschriftung: 'Erlöse gemäß § 109a EStG 20% USt' },
  { konto: '4966', beschriftung: 'Erlöse gemäß § 109a EStG 10% USt' },
  { konto: '4968', beschriftung: 'Erlöse steuerfrei ohne Vorsteuer §109a' },
  { konto: '4995', beschriftung: 'Investitionszuschüsse' },
  { konto: '4996', beschriftung: 'Zuschüsse Arbeitsmarktservice' },
  { konto: '4997', beschriftung: 'Öffentliche Zuschüsse' },
  { konto: '5000', beschriftung: 'Roh-, Hilfs- und Betriebsstoffe, Waren' },
  { konto: '5010', beschriftung: 'Verbrauch von Rohstoffen' },
  { konto: '5020', beschriftung: 'Verbrauch von bezog. Fertig-, Einbaut.' },
  { konto: '5030', beschriftung: 'Verbrauch von Hilfsstoffen' },
  { konto: '5040', beschriftung: 'Verbrauch von Betriebsstoffen' },
  { konto: '5050', beschriftung: 'Verbrauch von Werkzeugen' },
  { konto: '5060', beschriftung: 'Verbrauch Brenn-, Treibstoffen,Energie' },
  { konto: '5061', beschriftung: 'Verbrauch von Brennstoffen' },
  { konto: '5062', beschriftung: 'Verbrauch von Energie' },
  { konto: '5063', beschriftung: 'Verbrauch von Gas' },
  { konto: '5064', beschriftung: 'Verbrauch von Wasser' },
  { konto: '5070', beschriftung: 'Sonstige bezogene Herstellungsleistung' },
  { konto: '5100', beschriftung: 'Wareneinkauf' },
  { konto: '5150', beschriftung: 'Einkauf Roh-,Hilfs- und Betriebsstoffe' },
  { konto: '5190', beschriftung: 'Einkauf Energiestoffe' },
  { konto: '5200', beschriftung: 'Wareneinkauf 20% Vorsteuer' },
  { konto: '5240', beschriftung: 'EU-Erwerb 20% Vorsteuer und 20% USt' },
  { konto: '5245', beschriftung: 'EU-Erwerb ohne Vorsteuer und 20% USt' },
  { konto: '5250', beschriftung: 'EU-Erw. Nfz. o. UID 20% Vorsteuer/USt' },
  { konto: '5260', beschriftung: 'Erwerb Dreiecksgeschäft 20% Vorst/USt' },
  { konto: '5265', beschriftung: 'Erwerb Dreiecksgesch. 0% Vorst/20%USt' },
  { konto: '5270', beschriftung: 'EU-Erwerb 20% Vorsteuer und 20% USt' },
  { konto: '5300', beschriftung: 'Wareneinkauf 10% Vorsteuer' },
  { konto: '5320', beschriftung: 'Wareneinkauf 13% Vorsteuer' },
  { konto: '5340', beschriftung: 'EU-Erwerb 10% Vorsteuer und 10% USt' },
  { konto: '5344', beschriftung: 'EU-Erwerb 13% Vorsteuer und 13% USt' },
  { konto: '5345', beschriftung: 'EU-Erwerb ohne Vorsteuer und 10% USt' },
  { konto: '5349', beschriftung: 'EU-Erwerb ohne Vorsteuer und 13% USt' },
  { konto: '5360', beschriftung: 'Erwerb Dreiecksgeschäft 10% Vorst/USt' },
  { konto: '5364', beschriftung: 'Erwerb Dreiecksgeschäft 13% Vorst/USt' },
  { konto: '5365', beschriftung: 'Erwerb Dreiecksgesch. 0% Vorst/10%USt' },
  { konto: '5369', beschriftung: 'Erwerb Dreiecksgesch. 0% Vorst/13%USt' },
  { konto: '5370', beschriftung: 'EU-Erwerb 10% Vorsteuer und 10% USt' },
  { konto: '5390', beschriftung: 'EU-Erwerb 1. Abnehmer Dreiecksgeschäft' },
  { konto: '5391', beschriftung: 'Nicht zu versteuernder innergem. Erwerb' },
  { konto: '5395', beschriftung: 'Steuerfreier EU-Erwerb Art. 6 Abs. 2' },
  { konto: '5410', beschriftung: 'Wareneinkauf 19% Vorsteuer' },
  { konto: '5441', beschriftung: 'EU-Erwerb 19% Vorsteuer und 19% USt' },
  { konto: '5446', beschriftung: 'EU-Erwerb ohne Vorsteuer und 19% USt' },
  { konto: '5461', beschriftung: 'Erwerb Dreiecksgeschäft 19% Vorst/USt' },
  { konto: '5466', beschriftung: 'Erwerb Dreiecksgesch. 0% Vorst/19%USt' },
  { konto: '5500', beschriftung: 'Wareneinkauf 12% Vorsteuer' },
  { konto: '5600', beschriftung: 'Nicht abziehbare Vorsteuer' },
  { konto: '5611', beschriftung: 'Nicht abziehbare Vorsteuer 20%' },
  { konto: '5612', beschriftung: 'Nicht abziehbare Vorsteuer 10%' },
  { konto: '5613', beschriftung: 'Nicht abziehbare Vorsteuer 13%' },
  { konto: '5614', beschriftung: 'Nicht abziehbare Vorsteuer 19%' },
  { konto: '5730', beschriftung: 'Bezugsnebenkosten' },
  { konto: '5735', beschriftung: 'Frachten' },
  { konto: '5740', beschriftung: 'Leergut' },
  { konto: '5745', beschriftung: 'Zölle und Einfuhrabgaben' },
  { konto: '5750', beschriftung: 'Verrechnete Stoffkosten' },
  { konto: '5770', beschriftung: 'Fremdleistungen' },
  { konto: '5771', beschriftung: 'Bezogene Leistungen' },
  { konto: '5772', beschriftung: 'Bauleistungen 20% Vorst. und 20% USt' },
  { konto: '5773', beschriftung: 'Bauleistungen ohne Vorst. und 20% USt' },
  { konto: '5775', beschriftung: 'Sonstige Leistung ausl. UN 20% Vorst.' },
  { konto: '5779', beschriftung: 'Sonstige Leistung ausl. UN 19% Vorst.' },
  { konto: '5780', beschriftung: 'Sonstige Leistung ausl.UN 20%Vorst/USt' },
  { konto: '5782', beschriftung: 'Sonst. Leistung EU 20% Vorst., 20% USt' },
  { konto: '5786', beschriftung: 'Sonstige Leistung ausl.UN 19%Vorst/USt' },
  { konto: '5790', beschriftung: 'Sonst. Leistg. ausl.UN 0% Vorst./20% USt' },
  { konto: '5792', beschriftung: 'Sonst. Leistung EU ohne Vorst., 20% USt' },
  { konto: '5796', beschriftung: 'Sonst. Leistg. ausl.UN 0% Vorst./19% USt' },
  { konto: '5797', beschriftung: 'L+L USt-Schrott-VO 20% Vorst, 20% USt' },
  { konto: '5798', beschriftung: 'L+L USt-Schrott-VO ohne Vorst., 20% USt' },
  { konto: '5800', beschriftung: 'Nachlässe' },
  { konto: '5801', beschriftung: 'Nachlässe 20% Vorsteuer' },
  { konto: '5805', beschriftung: 'Nachlässe 10% Vorsteuer' },
  { konto: '5808', beschriftung: 'Nachlässe 13% Vorsteuer' },
  { konto: '5809', beschriftung: 'Nachlässe ohne Vorsteuer' },
  { konto: '5810', beschriftung: 'Nachlässe steuerfreier EU-Erwerb' },
  { konto: '5811', beschriftung: 'Nachlässe EU-Erwerb 20% Vorsteuer/USt' },
  { konto: '5815', beschriftung: 'Nachlässe EU-Erwerb 10% Vorsteuer/USt' },
  { konto: '5817', beschriftung: 'Nachlässe EU-Erwerb 13% Vorsteuer/USt' },
  { konto: '5820', beschriftung: 'Nachlässe 16% Vorsteuer' },
  { konto: '5821', beschriftung: 'Nachlässe 19% Vorsteuer' },
  { konto: '5830', beschriftung: 'Erhaltene Skonti' },
  { konto: '5831', beschriftung: 'Erhaltene Skonti 20% Vorsteuer' },
  { konto: '5835', beschriftung: 'Erhaltene Skonti 10% Vorsteuer' },
  { konto: '5839', beschriftung: 'Erhaltene Skonti 13% Vorsteuer' },
  { konto: '5840', beschriftung: 'Erhaltene Rabatte' },
  { konto: '5841', beschriftung: 'Erhaltene Rabatte 20% Vorsteuer' },
  { konto: '5845', beschriftung: 'Erhaltene Rabatte 10% Vorsteuer' },
  { konto: '5849', beschriftung: 'Erhaltene Rabatte 13% Vorsteuer' },
  { konto: '5850', beschriftung: 'Erhaltene Boni' },
  { konto: '5851', beschriftung: 'Erhaltene Boni 20% Vorsteuer' },
  { konto: '5855', beschriftung: 'Erhaltene Boni 10% Vorsteuer' },
  { konto: '5859', beschriftung: 'Erhaltene Boni 13% Vorsteuer' },
  { konto: '5860', beschriftung: 'Erhaltene Skonti steuerfrei' },
  { konto: '5862', beschriftung: 'Erh. Skonti EU-Dreiecksg. 20% Vorst/USt' },
  { konto: '5863', beschriftung: 'Erh. Skonti EU-Dreiecksg.o.Vorst/20%USt' },
  { konto: '5864', beschriftung: 'Erhaltene Skonti EU-Erwerb' },
  { konto: '5865', beschriftung: 'Erhaltene Skonti EU-Erwerb 20% Vorst/USt' },
  { konto: '5866', beschriftung: 'Erhaltene Skonti EU-Erwerb 10% Vorst/USt' },
  { konto: '5867', beschriftung: 'Erhaltene Skonti EU-Erwerb 13% Vorst/USt' },
  { konto: '5868', beschriftung: 'Erh. Skonti EU-Dreiecksg. 10% Vorst/USt' },
  { konto: '5869', beschriftung: 'Erh. Skonti EU-Dreiecksg.o.Vorst/10%USt' },
  { konto: '5870', beschriftung: 'Erhaltene Rabatte steuerfrei' },
  { konto: '5871', beschriftung: 'Erh. Skonti EU-Dreiecksg. 13% Vorst/USt' },
  { konto: '5872', beschriftung: 'Erh. Skonti EU-Dreiecksg.o.Vorst/13%USt' },
  { konto: '5875', beschriftung: 'Erhalt. Rabatte EU-Erwerb 20% Vorst/USt' },
  { konto: '5876', beschriftung: 'Erhalt. Rabatte EU-Erwerb 10% Vorst/USt' },
  { konto: '5877', beschriftung: 'Erhalt. Rabatte EU-Erwerb 13% Vorst/USt' },
  { konto: '5880', beschriftung: 'Erhaltene Boni steuerfrei' },
  { konto: '5885', beschriftung: 'Erhaltene Boni EU-Erwerb 20% Vorst/USt' },
  { konto: '5886', beschriftung: 'Erhaltene Boni EU-Erwerb 10% Vorst/USt' },
  { konto: '5887', beschriftung: 'Erhalt. Boni EU-Erwerb 13% Vorst/USt' },
  { konto: '5960', beschriftung: 'Skonti Bauleistung. 20% Vorst./ 20% USt' },
  { konto: '5965', beschriftung: 'Skonti Bauleistung. ohne Vorst./ 20% USt' },
  { konto: '5970', beschriftung: 'Skonti sonst. Leistungen ausl. UN 20%' },
  { konto: '5974', beschriftung: 'Erhalt. Skonti USt Leistungsempfänger' },
  { konto: '5975', beschriftung: 'Skonti so. Leistung.ausl.UN 20%Vorst/USt' },
  { konto: '5979', beschriftung: 'Erh. Skonti Leist.-empf. 20% Vorst/USt' },
  { konto: '5980', beschriftung: 'Skonti so. Leistung.ausl.UN o.VSt/20%USt' },
  { konto: '5984', beschriftung: 'Erh. Skonti Leist.-empf. o.Vorst/m.USt' },
  { konto: '5985', beschriftung: 'Skonti, Nachlässe aus Fremdleistung' },
  { konto: '5988', beschriftung: 'Skonti, Nachlässe bezogene Leistungen' },
  { konto: '6000', beschriftung: 'Löhne' },
  { konto: '6010', beschriftung: 'Überstunden und Zuschläge Arbeiter' },
  { konto: '6020', beschriftung: 'Zulagen Arbeiter' },
  { konto: '6030', beschriftung: 'Lehrlingsentschädigungen Arbeiter' },
  { konto: '6040', beschriftung: 'Fahrtkostenersatz Arbeiter' },
  { konto: '6050', beschriftung: 'Leistungsprämien Arbeiter' },
  { konto: '6060', beschriftung: 'Sachbezüge Arbeiter' },
  { konto: '6070', beschriftung: 'Entgelte und Zulagen AUVA' },
  { konto: '6080', beschriftung: 'Erstattungen AUVA' },
  { konto: '6090', beschriftung: 'Lohnabgrenzung' },
  { konto: '6100', beschriftung: 'Sonderzahlungen Arbeiter' },
  { konto: '6120', beschriftung: 'Beiträge Urlaubs- u. Abfertigungskasse' },
  { konto: '6130', beschriftung: 'Erstattung Urlaubs- u. Abfertigungskasse' },
  { konto: '6140', beschriftung: 'Urlaubsentschädigungen, -abfindungen' },
  { konto: '6150', beschriftung: 'Aushilfskräfte' },
  { konto: '6155', beschriftung: 'Lohnsteuer Aushilfskräfte' },
  { konto: '6160', beschriftung: 'Provisionen Arbeiter' },
  { konto: '6195', beschriftung: 'Zuschüsse Lohnaufwand' },
  { konto: '6200', beschriftung: 'Gehälter' },
  { konto: '6205', beschriftung: 'Geschäftsführergehälter' },
  { konto: '6210', beschriftung: 'Überstunden und Zuschläge Angestellte' },
  { konto: '6220', beschriftung: 'Zulagen Angestellte' },
  { konto: '6230', beschriftung: 'Lehrlingsentschädigungen Angestellte' },
  { konto: '6240', beschriftung: 'Fahrtkostenersatz Angestellte' },
  { konto: '6250', beschriftung: 'Leistungsprämien Angestellte' },
  { konto: '6260', beschriftung: 'Sachbezüge Angestellte' },
  { konto: '6280', beschriftung: 'Gehaltsabgrenzung' },
  { konto: '6300', beschriftung: 'Sonderzahlungen für Angestellte' },
  { konto: '6340', beschriftung: 'Urlaubsentschädigungen und -abfindungen' },
  { konto: '6360', beschriftung: 'Provisionen Angestellte' },
  { konto: '6395', beschriftung: 'Zuschüsse Gehälter' },
  { konto: '6400', beschriftung: 'Aufwendungen für Abfertigungen' },
  { konto: '6410', beschriftung: 'Abfertigungszahlungen' },
  { konto: '6430', beschriftung: 'Veränderung Abfertigungsrückstellungen' },
  { konto: '6440', beschriftung: 'Beiträge an Mitarbeitervorsorgekasse' },
  { konto: '6450', beschriftung: 'Aufwendungen für Altersversorgung' },
  { konto: '6455', beschriftung: 'Pensionskassenbeiträge, § 4 Abs.4 Z.2' },
  { konto: '6460', beschriftung: 'Pensionszahlungen' },
  { konto: '6500', beschriftung: 'Gesetzlicher Sozialaufwand' },
  { konto: '6600', beschriftung: 'Lohn-,gehaltsabhängige Abgabe,Pflichtb.' },
  { konto: '6610', beschriftung: 'Dienstgeberbeitrag' },
  { konto: '6620', beschriftung: 'Dienstgeberzuschlag' },
  { konto: '6630', beschriftung: 'Kommunalsteuer' },
  { konto: '6640', beschriftung: 'Wiener Dienstgeberabgabe' },
  { konto: '6650', beschriftung: 'Invalidenausgleichstaxe' },
  { konto: '6700', beschriftung: 'Sonstige Sozialaufwendungen' },
  { konto: '6710', beschriftung: 'Freiwillige Sozialaufwendungen' },
  { konto: '6720', beschriftung: 'Fahrtkostenersatz' },
  { konto: '6740', beschriftung: 'Kostenersatz Dienstnehmer' },
  { konto: '6750', beschriftung: 'Berufskleidung Dienstnehmer' },
  { konto: '6760', beschriftung: 'Zukunftssicherung Dienstnehmer' },
  { konto: '7010', beschriftung: 'Abschreibungen immater. Vermögensg.' },
  { konto: '7015', beschriftung: 'Abschreibungen Geschäfts-/Firmenwert' },
  { konto: '7020', beschriftung: 'Abschreibungen GWG immater. Vermögensg.' },
  { konto: '7030', beschriftung: 'Abschreibungen auf Sachanlagen' },
  { konto: '7040', beschriftung: 'Abschreibungen GWG Sachanlagen' },
  { konto: '7050', beschriftung: 'Sofortabschreibung GWG' },
  { konto: '7060', beschriftung: 'Apl. Abschreibung immater. Vermögensg.' },
  { konto: '7065', beschriftung: 'Apl.Abschreibung Geschäfts-/Firmenwert' },
  { konto: '7070', beschriftung: 'Apl. Abschreibungen auf Sachanlagen' },
  { konto: '7080', beschriftung: 'Apl. Abschreibungen geringw. Vermögensg.' },
  { konto: '7100', beschriftung: 'Sonstige Steuern' },
  { konto: '7103', beschriftung: 'Grundsteuer' },
  { konto: '7106', beschriftung: 'Kraftfahrzeugsteuer' },
  { konto: '7108', beschriftung: 'Verbrauchsteuern (sonstige Steuern)' },
  { konto: '7120', beschriftung: 'Nicht abzugsfähige Vorsteuer' },
  { konto: '7135', beschriftung: 'Tourismusabgabe' },
  { konto: '7150', beschriftung: 'Ortstaxe' },
  { konto: '7160', beschriftung: 'AKM Beiträge' },
  { konto: '7200', beschriftung: 'Instandhaltung, Reinigung, Entsorgung' },
  { konto: '7210', beschriftung: 'Instandhaltung' },
  { konto: '7215', beschriftung: 'Instandhaltung Gebäude' },
  { konto: '7220', beschriftung: 'Instandhaltung Maschinen' },
  { konto: '7225', beschriftung: 'Instandhaltung Betriebsausstattungen' },
  { konto: '7226', beschriftung: 'Instandhaltung und Wartung EDV' },
  { konto: '7230', beschriftung: 'Reinigung' },
  { konto: '7240', beschriftung: 'Müllabfuhr- und Kanalgebühren' },
  { konto: '7250', beschriftung: 'Entsorgungs- und Deponiegebühren' },
  { konto: '7260', beschriftung: 'Strom' },
  { konto: '7270', beschriftung: 'Gas' },
  { konto: '7280', beschriftung: 'Wasser' },
  { konto: '7290', beschriftung: 'Heizung' },
  { konto: '7299', beschriftung: 'Sonstige Betriebskosten Betriebsräume' },
  { konto: '7300', beschriftung: 'Transport durch Dritte' },
  { konto: '7310', beschriftung: 'Ausgangsfrachten' },
  { konto: '7319', beschriftung: 'Sonstige Transportaufwendungen' },
  { konto: '7320', beschriftung: 'Kfz-Betriebsstoffe PKW' },
  { konto: '7321', beschriftung: 'Kfz-Betriebsstoffe LKW' },
  { konto: '7325', beschriftung: 'Kfz-Reparaturen und Service PKW' },
  { konto: '7326', beschriftung: 'Kfz-Reparaturen und Service LKW' },
  { konto: '7330', beschriftung: 'Kfz-Versicherungen PKW' },
  { konto: '7331', beschriftung: 'Kfz-Versicherungen LKW' },
  { konto: '7332', beschriftung: 'Leihwagen PKW' },
  { konto: '7333', beschriftung: 'Leihwagen LKW' },
  { konto: '7334', beschriftung: 'Park- und Mautgebühren PKW' },
  { konto: '7335', beschriftung: 'Park- und Mautgebühren LKW' },
  { konto: '7338', beschriftung: 'Sonstiger Kfz-Aufwand PKW' },
  { konto: '7339', beschriftung: 'Sonstiger Kfz-Aufwand LKW' },
  { konto: '7340', beschriftung: 'Reise- und Fahrtaufwand' },
  { konto: '7341', beschriftung: 'Reise- und Fahrtaufwand Untern. Inland' },
  { konto: '7342', beschriftung: 'Reise- und Fahrtaufwand Untern. Ausland' },
  { konto: '7345', beschriftung: 'Reise- und Fahrtaufwand Dienstn. Inland' },
  { konto: '7346', beschriftung: 'Reise- und Fahrtaufwand Dienstn.Ausland' },
  { konto: '7348', beschriftung: 'Reise- und Fahrtkostenvergütung Inland' },
  { konto: '7349', beschriftung: 'Reise- und Fahrtkostenvergütung Ausland' },
  { konto: '7350', beschriftung: 'Kilometergeld' },
  { konto: '7360', beschriftung: 'Tag- und Nächtigungsgelder' },
  { konto: '7361', beschriftung: 'Taggelder Unternehmer Inland' },
  { konto: '7362', beschriftung: 'Taggelder Unternehmer Ausland' },
  { konto: '7365', beschriftung: 'Taggelder Dienstnehmer Inland' },
  { konto: '7366', beschriftung: 'Taggelder Dienstnehmer Ausland' },
  { konto: '7367', beschriftung: 'Diäten Dienstnehmer Inland steuerfrei' },
  { konto: '7368', beschriftung: 'Diäten Dienstnehmer Inland steuerpflicht' },
  { konto: '7369', beschriftung: 'Diäten Dienstnehmer Ausland steuerfrei' },
  { konto: '7370', beschriftung: 'Diäten Dienstnehmer Ausland steuerpfl.' },
  { konto: '7371', beschriftung: 'Nachtgelder Unternehmer Inland' },
  { konto: '7372', beschriftung: 'Nachtgelder Unternehmer Ausland' },
  { konto: '7375', beschriftung: 'Nachtgelder Dienstnehmer Inland' },
  { konto: '7376', beschriftung: 'Nachtgelder Dienstnehmer Ausland' },
  { konto: '7380', beschriftung: 'Portogebühren' },
  { konto: '7385', beschriftung: 'Telefongebühren' },
  { konto: '7388', beschriftung: 'Telefaxgebühren' },
  { konto: '7389', beschriftung: 'Internetgebühren' },
  { konto: '7390', beschriftung: 'Miete und Wartung Telefon' },
  { konto: '7392', beschriftung: 'Miete und Wartung Telefax' },
  { konto: '7400', beschriftung: 'Miet- und Pachtaufwand' },
  { konto: '7410', beschriftung: 'Mietaufwand unbeweg.so. Wirtschaftsgüter' },
  { konto: '7415', beschriftung: 'Mietaufwand bewegl. Wirtschaftsgüter' },
  { konto: '7420', beschriftung: 'Pachtaufwand' },
  { konto: '7440', beschriftung: 'Leasingaufwand' },
  { konto: '7450', beschriftung: 'Leasingaufwand unbewegl. Wirtschaftsgut' },
  { konto: '7455', beschriftung: 'Leasingaufwand bewegl. Wirtschaftsgut' },
  { konto: '7460', beschriftung: 'Leasingaufwand Kraftfahrzeuge' },
  { konto: '7480', beschriftung: 'Lizenzaufwand' },
  { konto: '7485', beschriftung: 'Gebühren' },
  { konto: '7490', beschriftung: 'Lizenzaufwand Inland' },
  { konto: '7495', beschriftung: 'Lizenzaufwand Ausland' },
  { konto: '7500', beschriftung: 'Aufwand für beigestelltes Personal' },
  { konto: '7510', beschriftung: 'Leihpersonal' },
  { konto: '7520', beschriftung: 'Honorare freie Dienstnehmer' },
  { konto: '7525', beschriftung: 'Sozialversicherung freie Dienstnehmer' },
  { konto: '7527', beschriftung: 'Dienstgeberbeitrag freie Dienstnehmer' },
  { konto: '7528', beschriftung: 'Dienstgeberzuschlag freie Dienstnehmer' },
  { konto: '7529', beschriftung: 'Kommunalsteuer für freie Dienstnehmer' },
  { konto: '7540', beschriftung: 'Provisionen an Dritte' },
  { konto: '7560', beschriftung: 'Geschäftsführervergütungen' },
  { konto: '7565', beschriftung: 'Sozialversicherung Geschäftsführer' },
  { konto: '7600', beschriftung: 'Büromaterial und Drucksorten' },
  { konto: '7601', beschriftung: 'Kopieraufwand' },
  { konto: '7602', beschriftung: 'Vordrucke und Formulare' },
  { konto: '7608', beschriftung: 'Sonstiger Büroaufwand' },
  { konto: '7610', beschriftung: 'Druckerzeugnisse u. Vervielfältigungen' },
  { konto: '7630', beschriftung: 'Fachliteratur und Zeitungen' },
  { konto: '7650', beschriftung: 'Werbung und Repräsentation' },
  { konto: '7655', beschriftung: 'Werbeaufwand' },
  { konto: '7659', beschriftung: 'Werbeabgabe' },
  { konto: '7660', beschriftung: 'Messen und Ausstellungen' },
  { konto: '7670', beschriftung: 'Auslagendekoration' },
  { konto: '7671', beschriftung: 'Prospekte und Kataloge' },
  { konto: '7672', beschriftung: 'Werbegeschenke' },
  { konto: '7675', beschriftung: 'Anzeigen- und Ankündigungsabgaben' },
  { konto: '7680', beschriftung: 'Geschäftsanbahnung' },
  { konto: '7681', beschriftung: 'Bewirtung steuerlich abzugsfähig' },
  { konto: '7682', beschriftung: 'Bewirtung steuerl. nicht abzugsfähig' },
  { konto: '7689', beschriftung: 'Repräsentationsaufwand' },
  { konto: '7690', beschriftung: 'Trinkgelder' },
  { konto: '7695', beschriftung: 'Spenden abzugsfähig' },
  { konto: '7696', beschriftung: 'Betriebl. Spenden § 4a Abs.2 Z.1 und 2' },
  { konto: '7697', beschriftung: 'Betr.Spenden mildtätige Organisationen' },
  { konto: '7698', beschriftung: 'Betr.Spenden Umweltschutzorg.,Tierheime' },
  { konto: '7699', beschriftung: 'Betr. Spenden freiwillige Feuerwehren' },
  { konto: '7700', beschriftung: 'Versicherungen' },
  { konto: '7701', beschriftung: 'Feuerversicherung' },
  { konto: '7702', beschriftung: 'Maschinenbruchversicherung' },
  { konto: '7703', beschriftung: 'Betriebsunterbrechungsversicherung' },
  { konto: '7704', beschriftung: 'Haftpflichtversicherung' },
  { konto: '7705', beschriftung: 'Einbruch- und Diebstahlversicherung' },
  { konto: '7706', beschriftung: 'Transportversicherung' },
  { konto: '7710', beschriftung: 'Sonstige Sachversicherungen' },
  { konto: '7720', beschriftung: 'Gewerbliche Sozialversicherung' },
  { konto: '7725', beschriftung: 'Beitrag Selbständigenvorsorge BMSVG' },
  { konto: '7750', beschriftung: 'Rechts- und Beratungskosten' },
  { konto: '7751', beschriftung: 'Rechtsberatung' },
  { konto: '7755', beschriftung: 'Betriebsberatung' },
  { konto: '7759', beschriftung: 'Stempel- und Rechtsgebühren' },
  { konto: '7760', beschriftung: 'Jahresabschluss- und Steuerberatung' },
  { konto: '7761', beschriftung: 'Lohnverrechnung' },
  { konto: '7762', beschriftung: 'Buchhaltung' },
  { konto: '7765', beschriftung: 'Prüfung Jahresabschluss' },
  { konto: '7769', beschriftung: 'Sonstige Beratung' },
  { konto: '7770', beschriftung: 'Aus- und Fortbildung' },
  { konto: '7771', beschriftung: 'Fortbildung Unternehmer' },
  { konto: '7772', beschriftung: 'Aus- und Fortbildung Dienstnehmer' },
  { konto: '7773', beschriftung: 'Seminargebühren' },
  { konto: '7780', beschriftung: 'Mitgliedsbeiträge' },
  { konto: '7783', beschriftung: 'Sonstige Abgaben' },
  { konto: '7785', beschriftung: 'Kammerumlagen' },
  { konto: '7786', beschriftung: 'Personalsuchanzeigen' },
  { konto: '7787', beschriftung: 'Betriebsratsfonds' },
  { konto: '7790', beschriftung: 'Spesen des Geldverkehrs' },
  { konto: '7795', beschriftung: 'Gerichts- und Mahnspesen' },
  { konto: '7800', beschriftung: 'Betriebsbedingte gewöhnl. Schadensfälle' },
  { konto: '7803', beschriftung: 'Forderungsausfälle' },
  { konto: '7804', beschriftung: 'Forderungsausfälle 20% USt' },
  { konto: '7805', beschriftung: 'Forderungsausfälle 10% USt' },
  { konto: '7809', beschriftung: 'Forderungsausfälle 13% USt' },
  { konto: '7810', beschriftung: 'Aufwendungen aus Kursdifferenzen' },
  { konto: '7820', beschriftung: 'Centausgleich' },
  { konto: '7825', beschriftung: 'Gewährleistungsaufwand' },
  { konto: '7827', beschriftung: 'Produkthaftungsaufwand' },
  { konto: '7835', beschriftung: 'Skontoerträge auf sonst. Aufwendungen' },
  { konto: '7837', beschriftung: 'Aufwendungen für ein Arbeitszimmer' },
  { konto: '7840', beschriftung: 'Erlöse aus Verkauf Sachanlagen 20% USt' },
  { konto: '7846', beschriftung: 'Erlöse aus Verkauf Sachanlagen 19% USt' },
  { konto: '7850', beschriftung: 'Erlöse Verkauf Sachanl. stfr. § 6,1, §7' },
  { konto: '7855', beschriftung: 'Erlöse aus Verkauf Sachanlagen ohne USt' },
  { konto: '7859', beschriftung: 'Erlöse aus stfr. innergem. Verk. SAV' },
  { konto: '7860', beschriftung: 'Buchwert abgegangener Sachanlagen' },
  { konto: '7870', beschriftung: 'Erlöse aus Verkauf immat.VermögensG 20%' },
  { konto: '7876', beschriftung: 'Erlöse aus Verkauf immat.VermögensG 19%' },
  { konto: '7880', beschriftung: 'Erlöse Verkauf imm. VG stfr. § 6,1, § 7' },
  { konto: '7885', beschriftung: 'Erlöse aus Verkauf immat.VermögensG 0%' },
  { konto: '7890', beschriftung: 'Buchwert abgegangener immat. VermG.' },
  { konto: '7895', beschriftung: 'Verlust aus Abgang von Sachanlagen' },
  { konto: '7896', beschriftung: 'Verlust aus Abgang von immat. VermG.' },
  { konto: '7897', beschriftung: 'Aufw. i.Z.m. Grundstücksveräuß., abz.' },
  { konto: '7898', beschriftung: 'Aufw. i.Z.m. Grundstücksveräuß. n.abz.' },
  { konto: '7899', beschriftung: 'Verschiedene betriebliche Aufwendungen' },
  { konto: '7990', beschriftung: 'Sonstige betriebliche Aufwendungen' },
  { konto: '8000', beschriftung: 'Erträge aus Beteiligungen' },
  { konto: '8010', beschriftung: 'Gewinnanteile Beteiligung PersG. Inland' },
  { konto: '8011', beschriftung: 'Gewinnanteile Beteiligung PersG.Ausland' },
  { konto: '8015', beschriftung: 'Gewinnausschüttung Beteilig. KapG. Inl.' },
  { konto: '8016', beschriftung: 'Gewinnausschüttung Beteilig. KapG. Ausl' },
  { konto: '8050', beschriftung: 'Erträge Wertpapiere und Ausleihungen' },
  { konto: '8055', beschriftung: 'Erträge Dividenden anderer UN Inland' },
  { konto: '8056', beschriftung: 'Erträge Dividenden anderer UN Ausland' },
  { konto: '8060', beschriftung: 'Erträge aus Anteilen an anderen UN' },
  { konto: '8065', beschriftung: 'Zinserträge aus Ausleihungen' },
  { konto: '8070', beschriftung: 'Zinserträge aus Guthaben Kreditinstitut' },
  { konto: '8075', beschriftung: 'Zinserträge aus gewährten Finanzdarl.' },
  { konto: '8090', beschriftung: 'Zinserträge a. festverzinsl. Wertpapier' },
  { konto: '8100', beschriftung: 'Sonstige Zinsen und ähnliche Erträge' },
  { konto: '8110', beschriftung: 'Erhaltene Diskontzinsen' },
  { konto: '8120', beschriftung: 'Erhaltene Verzugszinsen' },
  { konto: '8130', beschriftung: 'Sonstige Finanzerträge' },
  { konto: '8132', beschriftung: 'Verrechnungszinsen' },
  { konto: '8140', beschriftung: 'Erlöse Abgang Beteiligungen, Buchgewinn' },
  { konto: '8150', beschriftung: 'Erlöse Abgang s. Finanzanl., Buchgewinn' },
  { konto: '8160', beschriftung: 'Erlöse Abgang Wertpapiere UV, Buchgew.' },
  { konto: '8170', beschriftung: 'Buchwert Abgang Beteiligungen, Buchgew' },
  { konto: '8180', beschriftung: 'Buchwert Abgang s. Finanzanl., Buchgew.' },
  { konto: '8190', beschriftung: 'Buchw. Abgang Wertpapiere UV, Buchgew.' },
  { konto: '8200', beschriftung: 'Erträge aus dem Abgang Finanzanlagen' },
  { konto: '8210', beschriftung: 'Erträge aus dem Abgang Wertpapiere UV' },
  { konto: '8220', beschriftung: 'Aufwendungen aus Beteiligungen' },
  { konto: '8230', beschriftung: 'Verlustanteil aus Beteiligungen an UN' },
  { konto: '8240', beschriftung: 'Verluste aus Abgang von Beteiligungen' },
  { konto: '8260', beschriftung: 'Aufwendungen sonstige Finanzanlagen' },
  { konto: '8265', beschriftung: 'Verluste aus Abgang sonst. Finanzanlagen' },
  { konto: '8275', beschriftung: 'Verluste aus Abgang Wertpapiere des UV' },
  { konto: '8280', beschriftung: 'Erlöse Abgang Beteiligungen,Buchverlust' },
  { konto: '8282', beschriftung: 'Erlöse Abgang s. Finanzanl.,Buchverlust' },
  { konto: '8285', beschriftung: 'Erlöse Abgang Wertpapiere UV,Buchverl.' },
  { konto: '8290', beschriftung: 'Buchwert Abgang Beteiligung, Buchverl.' },
  { konto: '8292', beschriftung: 'Buchwert Abgang s. Finanzanl. Buchverl.' },
  { konto: '8295', beschriftung: 'Buchw. Abgang Wertpapiere UV,Buchverl.' },
  { konto: '8300', beschriftung: 'Zinsen und ähnliche Aufwendungen' },
  { konto: '8310', beschriftung: 'Zinsen für Darlehen' },
  { konto: '8315', beschriftung: 'Zinsen für Lieferantenkredite' },
  { konto: '8320', beschriftung: 'Wechselzinsen und Diskontspesen' },
  { konto: '8325', beschriftung: 'Verzugszinsen und Säumniszuschlag' },
  { konto: '8330', beschriftung: 'Verrechnungszinsen' },
  { konto: '8345', beschriftung: 'Disagio' },
  { konto: '8346', beschriftung: 'Geldbeschaffungskosten' },
  { konto: '8347', beschriftung: 'Wertsicherungsaufwand' },
  { konto: '8348', beschriftung: 'Zuzählungsprovision' },
  { konto: '8349', beschriftung: 'Bereitstellungsprovision' },
  { konto: '8350', beschriftung: 'Überziehungsprovision' },
  { konto: '8355', beschriftung: 'Nicht ausgenützte Lieferantenskonti' },
  { konto: '8405', beschriftung: 'Erträge sale and lease back-Transakt.' },
  { konto: '8410', beschriftung: 'Einmalige öffentliche Zuschüsse' },
  { konto: '8420', beschriftung: 'Sanierungsgewinne' },
  { konto: '8430', beschriftung: 'Erträge aus Betriebsstilllegung' },
  { konto: '8440', beschriftung: 'Sonstige Erträge' },
  { konto: '8470', beschriftung: 'Außergewöhnliche Schadensfälle' },
  { konto: '8480', beschriftung: 'Verluste aus Betriebsstilllegung' },
  { konto: '8490', beschriftung: 'Sonstige Aufwendungen' },
  { konto: '8500', beschriftung: 'Steuern vom Einkommen und vom Ertrag' },
  { konto: '8510', beschriftung: 'Kapitalertragsteuer' },
  { konto: '8580', beschriftung: 'Ausländische Quellensteuer' },
  { konto: '8950', beschriftung: 'Gewinnanteil stiller Gesellschafter' },
  { konto: '9000', beschriftung: 'Festkapital VH' },
  { konto: '9001', beschriftung: 'Kapital (fester Anteil, nur EU)' },
  { konto: '9010', beschriftung: 'Variables Kapital VH' },
  { konto: '9011', beschriftung: 'Kapital (variabler Anteil, nur EU)' },
  { konto: '9120', beschriftung: 'Privatentnahmen allgemein VH' },
  { konto: '9121', beschriftung: 'Privatentnahmen allgemein (nur EU)' },
  { konto: '9125', beschriftung: 'Verrg. Kto. E/A' },
  { konto: '9130', beschriftung: 'Eigenverbrauch VH' },
  { konto: '9131', beschriftung: 'Eigenverbrauch (nur EU)' },
  { konto: '9140', beschriftung: 'Privatsteuern VH' },
  { konto: '9141', beschriftung: 'Privatsteuern (nur EU)' },
  { konto: '9150', beschriftung: 'Privateinlagen VH' },
  { konto: '9151', beschriftung: 'Privateinlagen (nur EU)' },
  { konto: '9160', beschriftung: 'Privatanteile VH' },
  { konto: '9161', beschriftung: 'Privatanteile (nur EU)' },
  { konto: '9170', beschriftung: 'Kranken-/Unfallversicherung' },
  { konto: '9171', beschriftung: 'Wohnbauförderung' },
  { konto: '9172', beschriftung: 'Ausgaben Wohnung' },
  { konto: '9180', beschriftung: 'Übertragene Investitionsfreibeträge VH' },
  { konto: '9181', beschriftung: 'Übertrag. Investitionsfreibetr. (nur EU)' },
  { konto: '9190', beschriftung: 'Außergewöhnliche Belastungen VH' },
  { konto: '9191', beschriftung: 'Außergewöhnliche Belastungen (nur EU)' },
  { konto: '9490', beschriftung: 'Kapital stiller Gesellschafter' },
  { konto: '9495', beschriftung: 'Ausstehende Einlage nicht eingefordert' },
  { konto: '9499', beschriftung: 'Verr.kto. stiller Gesellschafter' },
  { konto: '9893', beschriftung: 'Umsatzsteuer in Forderungen allg. Satz' },
  { konto: '9894', beschriftung: 'Umsatzsteuer in Forderungen ermäß. Satz' },
  { konto: '9895', beschriftung: 'Gegenkonto Aufteilung Umsatzsteuer' },
  { konto: '9896', beschriftung: 'Vorsteuer in Verbindl. allgem. Satz' },
  { konto: '9897', beschriftung: 'Vorsteuer in Verbindl. ermäßig. Satz' },
  { konto: '9899', beschriftung: 'Gegenkonto Aufteilung Vorsteuer' },
  { konto: '9900', beschriftung: 'Evidenzkonten' },
  { konto: '9920', beschriftung: 'V&V Mieterlöse 0%' },
  { konto: '9921', beschriftung: 'V&V Mieterlöse 10%' },
  { konto: '9922', beschriftung: 'V&V Mieterlöse 20%' },
  { konto: '9923', beschriftung: 'V&V Betriebskosten 0%' },
  { konto: '9924', beschriftung: 'V&V Betriebskosten 10%' },
  { konto: '9925', beschriftung: 'V&V Betriebskosten 20%' },
  { konto: '9926', beschriftung: 'V&V Sonstige Erlöse 0%' },
  { konto: '9927', beschriftung: 'V&V Sonstige Erlöse 10%' },
  { konto: '9928', beschriftung: 'V&V Sonstige Erlöse 20%' },
  { konto: '9929', beschriftung: 'V&V Umsatzsteuer' },
  { konto: '9930', beschriftung: 'V&V USt-Gutschrift' },
  { konto: '9931', beschriftung: 'V&V Aufwendungen § 28 (2)' },
  { konto: '9932', beschriftung: 'V&V Aufwendungen § 28 (3)' },
  { konto: '9933', beschriftung: 'V&V Aufwendungen § 28 (4)' },
  { konto: '9934', beschriftung: 'V&V Absetzung für Abnutzung' },
  { konto: '9935', beschriftung: 'V&V Fremdfinanzierungskosten' },
  { konto: '9936', beschriftung: 'V&V Vorsteuer Fremdfinanzierungskosten' },
  { konto: '9937', beschriftung: 'V&V Instandhaltung' },
  { konto: '9938', beschriftung: 'V&V Vorsteuer auf Instandhaltung' },
  { konto: '9939', beschriftung: 'V&V Betriebskosten' },
  { konto: '9940', beschriftung: 'V&V Vorsteuer auf Betriebskosten' },
  { konto: '9941', beschriftung: 'V&V Übrige Werbungskosten' },
  { konto: '9944', beschriftung: 'V&V Vorsteuer übrige Werbungskosten' },
  { konto: '9945', beschriftung: 'V&V Umsatzsteuer Zahllast' },
  { konto: '9946', beschriftung: 'V&V Investitionen § 28 (2)' },
  { konto: '9947', beschriftung: 'V&V Investitionen § 28 (3)' },
  { konto: '9948', beschriftung: 'V&V Investitionen § 28 (4)' },
  { konto: '9949', beschriftung: 'V&V Gegenkonto' },
  { konto: '9950', beschriftung: 'Saldenvorträge Sachkonten' },
  { konto: '9951', beschriftung: 'Saldenvorträge' },
  { konto: '9958', beschriftung: 'Saldenvorträge Debitoren' },
  { konto: '9959', beschriftung: 'Saldenvorträge Kreditoren' },
  { konto: '9960', beschriftung: 'Summenvortragskonto' },
  { konto: '9970', beschriftung: 'Offene Posten 2000' },
  { konto: '9971', beschriftung: 'Offene Posten 2001' },
  { konto: '9972', beschriftung: 'Offene Posten 2002' },
  { konto: '9973', beschriftung: 'Offene Posten 2003' },
  { konto: '9974', beschriftung: 'Offene Posten 2004' },
  { konto: '9975', beschriftung: 'Offene Posten 2005' },
  { konto: '9976', beschriftung: 'Offene Posten 2006' },
  { konto: '9977', beschriftung: 'Offene Posten 2007' },
  { konto: '9978', beschriftung: 'Offene Posten 2008' },
  { konto: '9979', beschriftung: 'Offene Posten 2009' },
  { konto: '9980', beschriftung: 'Offene Posten 2010' },
  { konto: '9981', beschriftung: 'Offene Posten 2011' },
  { konto: '9982', beschriftung: 'Offene Posten 2012' },
  { konto: '9983', beschriftung: 'Offene Posten 2013' },
  { konto: '9984', beschriftung: 'Offene Posten 2014' },
  { konto: '9985', beschriftung: 'Offene Posten 2015' },
  { konto: '9986', beschriftung: 'Offene Posten 2016' },
  { konto: '9987', beschriftung: 'Offene Posten 2017' },
  { konto: '9988', beschriftung: 'Offene Posten 2018' },
  { konto: '9989', beschriftung: 'Offene Posten 2019' },
  { konto: '9990', beschriftung: 'Offene Posten 2020' },
  { konto: '9991', beschriftung: 'Offene Posten 2021' },
  { konto: '9992', beschriftung: 'Offene Posten 2022' },
  { konto: '9993', beschriftung: 'Offene Posten 2023' },
  { konto: '9994', beschriftung: 'Offene Posten 2024' },
];
