import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Typography,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Chip,
  Paper,
  CircularProgress,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Sort as SortIcon,
} from '@mui/icons-material';
import { useSession } from '../../hooks/useSession';
import { useAuth } from '../../hooks/useAuth';
import { useRoleAuth } from '../../hooks/useRoleAuth';

const SessionsOverview = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { checkIsTaxConsultant } = useRoleAuth();
  const [sessions, setSessions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientColors] = useState({});  // Will store client-specific colors

  const { listSessions, deleteSession } = useSession();

  // Generate a pastel color for each client
  const getClientColor = (clientId) => {
    if (!clientColors[clientId]) {
      const hue = Math.random() * 360;
      clientColors[clientId] = `hsl(${hue}, 70%, 95%)`;  // Pastel shade
    }
    return clientColors[clientId];
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      setLoading(true);
      const response = await listSessions();
      // Group sessions by client
      const groupedSessions = response.reduce((acc, session) => {
        const clientId = session.client;
        if (!acc[clientId]) {
          acc[clientId] = {
            clientName: session.client_name,
            sessions: []
          };
        }
        acc[clientId].sessions.push(session);
        return acc;
      }, {});
      setSessions(groupedSessions);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Filter and sort sessions
  const getFilteredAndSortedSessions = () => {
    const filteredSessions = { ...sessions };
    
    // Apply search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      Object.keys(filteredSessions).forEach(clientId => {
        filteredSessions[clientId] = {
          ...filteredSessions[clientId],
          sessions: filteredSessions[clientId].sessions.filter(session => 
            session.client_name?.toLowerCase().includes(searchLower) ||
            session.matching_transactions?.some(t => t.description?.toLowerCase().includes(searchLower)) ||
            session.unmatched_transactions?.some(t => t.description?.toLowerCase().includes(searchLower))
          )
        };
      });
    }

    // Remove empty clients after filtering
    Object.keys(filteredSessions).forEach(clientId => {
      if (filteredSessions[clientId].sessions.length === 0) {
        delete filteredSessions[clientId];
      }
    });

    return filteredSessions;
  };

  const handleSessionClick = (sessionId) => {
    navigate(`/app/session/${sessionId}`);
  };

  const handleDeleteSession = async (sessionId, e) => {
    e.stopPropagation();
    if (window.confirm('Sind Sie sicher, dass Sie diese Sitzung löschen möchten?')) {
      try {
        await deleteSession(sessionId);
        await fetchSessions();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed': return 'success';
      case 'in_progress': return 'warning';
      case 'error': return 'error';
      default: return 'default';
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Sitzungsübersicht
        </Typography>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Suche nach Kundenname oder Transaktionsbeschreibung..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              variant="outlined"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              InputProps={{
                startAdornment: <SortIcon sx={{ mr: 1, color: 'text.secondary' }} />,
              }}
            >
              <MenuItem value="date">Datum</MenuItem>
              <MenuItem value="status">Status</MenuItem>
              <MenuItem value="client">Kunde</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Box>

      {Object.entries(getFilteredAndSortedSessions()).map(([clientId, { clientName, sessions }]) => (
        <Paper 
          key={clientId} 
          sx={{ 
            mb: 4, 
            p: 3,
            backgroundColor: getClientColor(clientId),
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ 
            display: 'flex', 
            alignItems: 'center',
            color: 'text.primary',
            fontWeight: 600
          }}>
            {clientName}
            <Chip 
              label={`${sessions.length} Sitzungen`} 
              size="small" 
              sx={{ ml: 2 }}
            />
          </Typography>
          <Grid container spacing={2}>
            {sessions.map((session) => (
              <Grid item xs={12} md={6} lg={4} key={session.session_id}>
                <Card
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: (theme) => theme.shadows[8],
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    position: 'relative',
                    overflow: 'visible',
                  }}
                  onClick={() => handleSessionClick(session.session_id)}
                >
                  <Box sx={{ 
                    position: 'absolute',
                    top: -10,
                    right: 16,
                    backgroundColor: (theme) => theme.palette.background.paper,
                    borderRadius: '16px',
                    boxShadow: 1,
                    px: 2,
                    py: 0.5,
                  }}>
                    <Chip
                      label={session.status === 'completed' ? 'Abgeschlossen' : 
                             session.status === 'in_progress' ? 'In Bearbeitung' : 
                             session.status === 'error' ? 'Fehler' : 'In Bearbeitung'}
                      color={getStatusColor(session.status)}
                      size="small"
                    />
                  </Box>

                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Sitzung #{session.session_id}
                  </Typography>

                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body2" color="text.secondary" paragraph>
                      <strong>Transaktionen:</strong> {
                        (session.matching_transactions?.length || 0) + 
                        (session.unmatched_transactions?.length || 0)
                      } insgesamt
                    </Typography>
                    
                    <Typography variant="body2" color="text.secondary" paragraph>
                      <strong>Zugeordnet:</strong> {session.matching_transactions?.length || 0}
                      <br />
                      <strong>Nicht zugeordnet:</strong> {session.unmatched_transactions?.length || 0}
                    </Typography>

                    <Typography variant="body2" color="text.secondary" paragraph>
                      <strong>Erstellt von:</strong> {session.created_by || 'Unbekannt'}
                      <br />
                      <strong>Zuletzt bearbeitet von:</strong> {session.last_modified_by || 'Unbekannt'}
                    </Typography>
                  </Box>

                  <Box sx={{ mt: 2, pt: 2, borderTop: 1, borderColor: 'divider' }}>
                    <Typography variant="caption" display="block" color="text.secondary">
                      Erstellt am: {formatDate(session.created_at)}
                    </Typography>
                    <Typography variant="caption" display="block" color="text.secondary">
                      Zuletzt bearbeitet am: {formatDate(session.updated_at)}
                    </Typography>
                  </Box>

                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    mt: 2,
                    pt: 1,
                    borderTop: 1,
                    borderColor: 'divider'
                  }}>
                    <Tooltip title="Bearbeiten">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSessionClick(session.session_id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Löschen">
                      <IconButton
                        size="small"
                        onClick={(e) => handleDeleteSession(session.session_id, e)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Box sx={{ mt: 4 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default SessionsOverview;