// /Users/aliasaad/Desktop/dstt_react_flask/Frontend/src/components/SessionControls/styles.js

import { styled } from '@mui/material/styles';
import { Box, Chip, Button, Dialog } from '@mui/material';

export const ControlsContainer = styled(Box)(({ theme }) => ({
display: 'flex',
justifyContent: 'space-between',
alignItems: 'center',
padding: theme.spacing(2),
marginBottom: theme.spacing(3),
backgroundColor: theme.palette.background.paper,
borderRadius: theme.shape.borderRadius,
boxShadow: theme.shadows[1],
position: 'sticky',
top: 0,
zIndex: 1000,
backdropFilter: 'blur(8px)',
transition: 'all 0.3s ease',

'&:hover': {
  boxShadow: theme.shadows[3],
}
}));

export const SessionInfo = styled(Box)(({ theme }) => ({
display: 'flex',
flexDirection: 'column',
gap: theme.spacing(0.5),
}));

export const StatusChip = styled(Chip)(({ theme, color }) => ({
marginLeft: theme.spacing(1),
transition: 'all 0.3s ease',
'&:hover': {
  transform: 'scale(1.05)',
}
}));

export const ActionButton = styled(Button)(({ theme }) => ({
transition: 'all 0.2s ease',
'&:hover': {
  transform: 'translateY(-1px)',
},
'&:active': {
  transform: 'translateY(1px)',
}
}));

export const ReviewDialog = styled(Dialog)(({ theme }) => ({
'& .MuiDialog-paper': {
  borderRadius: theme.shape.borderRadius * 2,
}
}));