import { useState } from 'react';

export const useSplitTransactions = () => {
const [transactionSplits, setTransactionSplits] = useState({});

const saveSplit = (transactionId, splits) => {
  setTransactionSplits(prev => ({
    ...prev,
    [transactionId]: splits
  }));
};

const getSplitsForTransaction = (transactionId) => {
  return transactionSplits[transactionId] || [];
};

const removeSplitsForTransaction = (transactionId) => {
  setTransactionSplits(prev => {
    const newSplits = { ...prev };
    delete newSplits[transactionId];
    return newSplits;
  });
};

return {
  transactionSplits,
  saveSplit,
  getSplitsForTransaction,
  removeSplitsForTransaction
};
};