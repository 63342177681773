import { useState } from 'react';
import { TextField, Button, Box, Typography, Paper } from '@mui/material';
import axios from 'axios';
import LockIcon from '@mui/icons-material/Lock';
import ShieldIcon from '@mui/icons-material/Shield';
import { keyframes } from '@mui/system';

const AccessGate = ({ onAccessGranted }) => {
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Use the API_URL from environment variables or default to localhost for development
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.post(
        `${API_URL}/api/verify-access-code/`,
        { access_code: accessCode }
      );
      
      if (response.data.valid) {
        // Store access in localStorage or cookies
        localStorage.setItem('companyAccess', 'granted');
        localStorage.setItem('companyName', response.data.company);
        onAccessGranted();
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Ungültiger Zugangscode');
    } finally {
      setLoading(false);
    }
  };

  // Keyframes for subtle animations
  const subtleGlow = keyframes`
    0% { box-shadow: 0 0 15px rgba(212, 175, 55, 0.2); }
    50% { box-shadow: 0 0 20px rgba(212, 175, 55, 0.3); }
    100% { box-shadow: 0 0 15px rgba(212, 175, 55, 0.2); }
  `;

  const subtleShimmer = keyframes`
    0% { opacity: 0.7; }
    50% { opacity: 1; }
    100% { opacity: 0.7; }
  `;

  // Custom styles for this component only
  const styles = {
    container: {
      backgroundColor: '#000000',
      backgroundImage: 'radial-gradient(circle, rgba(20, 20, 20, 1) 0%, rgba(0, 0, 0, 1) 100%)',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative',
    },
    paper: {
      backgroundColor: 'rgba(10, 10, 10, 0.9)',
      color: '#D4AF37',
      borderRadius: '8px',
      padding: '2.5rem',
      maxWidth: 500,
      width: '100%',
      border: '1px solid #D4AF37',
      boxShadow: '0 0 25px rgba(212, 175, 55, 0.2)',
      position: 'relative',
      backdropFilter: 'blur(5px)',
      transition: 'all 0.4s ease',
      animation: `${subtleGlow} 4s infinite ease-in-out`,
    },
    title: {
      color: '#D4AF37',
      fontWeight: 600,
      marginBottom: '1.5rem',
      textShadow: '0 0 5px rgba(212, 175, 55, 0.3)',
      letterSpacing: '1px',
      fontFamily: '"Playfair Display", serif',
    },
    subtitle: {
      color: '#BCA858',
      marginBottom: '2rem',
      fontFamily: '"Lato", sans-serif',
      lineHeight: 1.6,
      transition: 'color 0.3s ease',
      '&:hover': {
        color: '#D4AF37',
      },
    },
    textField: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#8B7D39',
          borderWidth: '1px',
          transition: 'all 0.3s ease',
        },
        '&:hover fieldset': {
          borderColor: '#D4AF37',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#D4AF37',
          boxShadow: '0 0 5px rgba(212, 175, 55, 0.3)',
        },
      },
      '& .MuiInputLabel-root': {
        color: '#BCA858',
        fontFamily: '"Lato", sans-serif',
      },
      '& .MuiInputBase-input': {
        color: '#D4AF37',
        fontFamily: '"Lato", sans-serif',
      },
      '& .MuiFormHelperText-root': {
        color: '#ff6b6b',
      },
    },
    button: {
      background: 'linear-gradient(45deg, #8B7D39 0%, #D4AF37 50%, #8B7D39 100%)',
      backgroundSize: '200% auto',
      color: '#000',
      padding: '0.8rem',
      marginTop: '1.5rem',
      fontWeight: 500,
      fontFamily: '"Playfair Display", serif',
      letterSpacing: '0.5px',
      textTransform: 'none',
      border: '1px solid #D4AF37',
      transition: 'all 0.3s ease',
      boxShadow: '0 0 10px rgba(212, 175, 55, 0.2)',
      '&:hover': {
        backgroundPosition: 'right center',
        boxShadow: '0 0 15px rgba(212, 175, 55, 0.3)',
      },
      '&:disabled': {
        background: '#333',
        color: '#777',
        border: '1px solid #555',
        boxShadow: 'none',
      },
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '1.5rem',
      position: 'relative',
    },
    lockIcon: {
      fontSize: '3rem',
      color: '#D4AF37',
      filter: 'drop-shadow(0 0 3px rgba(212, 175, 55, 0.3))',
      transition: 'all 0.3s ease',
    },
    shieldIcon: {
      position: 'absolute',
      fontSize: '4.5rem',
      color: 'rgba(212, 175, 55, 0.15)',
      animation: `${subtleShimmer} 5s infinite ease-in-out`,
      zIndex: -1,
    },
  };

  return (
    <Box sx={styles.container}>
      <Paper elevation={4} sx={styles.paper}>
        <Box sx={styles.iconContainer}>
          <ShieldIcon sx={styles.shieldIcon} />
          <LockIcon sx={styles.lockIcon} />
        </Box>
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={styles.title}>
          Willkommen bei DocSense
        </Typography>
        <Typography variant="body1" paragraph align="center" sx={styles.subtitle}>
          Dies ist eine geschützte Anwendung. Bitte geben Sie Ihren Unternehmenszugangscode ein, um fortzufahren.
        </Typography>
        
        <form onSubmit={handleSubmit}>
          <TextField
            label="Zugangscode"
            variant="outlined"
            fullWidth
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            margin="normal"
            error={!!error}
            helperText={error}
            sx={styles.textField}
          />
          <Button 
            type="submit" 
            variant="contained" 
            fullWidth 
            disabled={loading}
            sx={styles.button}
          >
            {loading ? 'Überprüfung...' : 'Zugang beantragen'}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default AccessGate;