export const kontoFrequency = {
    '2800': 0.1, '3580': 0.05, '4000': 0.15, '5200': 0.1, '5770': 0.05,
    '7320': 0.03, '7325': 0.02, '7330': 0.03, '7334': 0.01, '7340': 0.02,
    '7380': 0.02, '7385': 0.03, '7600': 0.05, '7630': 0.02, '7650': 0.03,
    '7681': 0.02, '7720': 0.01, '7750': 0.01, '7780': 0.01, '7790': 0.02
  };
  
  export const kontoRules = [
    { keyword: 'finanzamt', konto: '3580', strength: 0.9 },
    { keyword: 'bank', konto: '2800', strength: 0.9 },
    { keyword: 'tankstelle', konto: '7320', strength: 0.8 },
    { keyword: 'werkstatt', konto: '7325', strength: 0.8 },
    { keyword: 'versicherung', konto: '7330', strength: 0.7 },
    { keyword: 'maut', konto: '7334', strength: 0.9 },
    { keyword: 'reise', konto: '7340', strength: 0.7 },
    { keyword: 'post', konto: '7380', strength: 0.6 },
    { keyword: 'telekom', konto: '7385', strength: 0.8 },
    { keyword: 'büro', konto: '7600', strength: 0.6 },
    { keyword: 'buch', konto: '7630', strength: 0.7 },
    { keyword: 'werbung', konto: '7650', strength: 0.7 },
    { keyword: 'restaurant', konto: '7681', strength: 0.6 },
    { keyword: 'sozialversicherung', konto: '7720', strength: 0.9 },
    { keyword: 'anwalt', konto: '7750', strength: 0.8 },
    { keyword: 'beitrag', konto: '7780', strength: 0.6 },
    { keyword: 'gebühr', konto: '7790', strength: 0.6 }
  ];